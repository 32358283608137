import React from 'react';
import styled from 'styled-components';

const Component = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-weight: normal;
  }
`;

interface CardHeaderLayoutProps {
  className?: string;
  children?: React.ReactNode;
}

const CardHeaderLayout = ({ className, children }: CardHeaderLayoutProps) => {
  return <Component className={className}>{children}</Component>;
};

export default CardHeaderLayout;
