import React, { ReactNodeArray, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import { P1, P2, P3 } from '../../../Atoms/Typo';
import ReportInflowIcon from '../../../../assets/icons/icon-report-inflow.png';
import DounutChart from '../../../Molecule/Chart/DonutChart';
import {
  GetReportInflowGlobalResponseType,
  GetReportInflowKeywordResponseType,
  GetReportInflowNationalResponseType,
  GetReportInflowPathResponseType,
} from '../../../../api/report/type';
import Spinner from '../../../Molecule/Spinner';
import DefaultLink from '../../../Atoms/Link';
import DataError from '../../../Atoms/DataError';

const Component = styled(CardLayout)`
  padding: 0;
  flex-direction: row;
  border-radius: 14px;
  height: 510px;
  width: 100%;
`;

const InnerGrid = styled.div`
  display: flex;
  flex-direction: column;
  width: 37%;
  padding: 40px 0 40px 40px;
  :nth-child(1) {
    width: 26%;
    padding-right: 10px;
    border-right: 1px solid #d7dbe6;
    border-radius: 14px 0px 0px 14px;
    background-color: #fcfcfc;
    justify-content: space-between;
  }
  img {
    width: 71px;
    height: 70px;
  }
`;

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(P1)`
  font-size: 20px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 25px;
`;

const RegionWrapper = styled.div<{ $last?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 40px;
  border-right: 1px solid #d7dbe6;
  justify-content: space-around;
  ${(props) => {
    if (props.$last) {
      return css`
        border-right: none;
      `;
    }
    return css``;
  }}
  .last-wrapper {
  }
`;

const RegionInner = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
`;

const RegionBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const RankingGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
`;

const GridTitle = styled(P2)`
  font-weight: 600;
  font-size: 15px;
  text-decoration: underline;
`;

const RankingWrap = styled.div`
  width: 100%;
`;

const RankingItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  :last-child {
    margin-bottom: 0;
  }
`;

const RankingIndex = styled.div<{ $isKeyword?: boolean }>`
  min-width: 22px;
  height: 22px;
  text-align: center;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.grey900};
  ${(props) => {
    if (props.$isKeyword) {
      return css`
        background-color: #7851e7;
      `;
    }
    return css``;
  }}
  margin-right: 8px;
  ${P2} {
    font-size: 15px;
    color: ${(props) => props.theme.colors.white};
  }
`;

const ChartWrapper = styled.div`
  width: 50%;
  position: relative;
  padding: 10px 0;
  .apexcharts-pie-series + .apexcharts-datalabels {
    rect {
      fill: #ff3b56;
      stroke: #ff3b56;
    }
    text {
      fill: #fff;
    }
  }
`;

const KeywordInner = styled.div`
  width: 100%;
  height: 200px;
`;

const KeywordRankingWrap = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
`;

const KeywordGrid = styled.div`
  width: 50%;
  padding-right: 10px;
  :last-child {
    padding-right: 0;
    padding-left: 10px;
  }
`;

const KeywordItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  :last-child {
    margin-bottom: 0;
  }
`;

const KeywordTitle = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  overflow: hidden;
  ${P2} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const CompareVal = styled.div<{ $compare: number | null }>`
  display: flex;
  width: 27px;
  align-items: center;
  justify-content: center;

  ${(props) => {
    if (props.$compare) {
      if (props.$compare > 0) {
        return css`
          ${P3} {
            font-size: 13px;
            color: #ff3b56;
          }
          .compare {
            border-bottom: 6px solid #ff3b56;
          }
        `;
      }
      if (props.$compare < 0) {
        return css`
          ${P3} {
            font-size: 13px;
            color: #2962ff;
          }
          .compare {
            border-bottom: 6px solid #2962ff;
            transform: rotate(180deg);
          }
        `;
      }
      if (props.$compare === 0) {
        return css`
          ${P3} {
            font-size: 13px;
            color: #6d6d6d;
          }
          .compare {
            display: none;
          }
        `;
      }
    }
    return css`
      ${P3} {
        font-size: 13px;
        color: #1d2fc7;
        font-weight: 600;
      }
      .compare {
        display: none;
      }
    `;
  }}
`;

const Triangle = styled.div`
  width: 0px;
  height: 0px;
  border-bottom: 6px solid red;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  margin-right: 3px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  .inflow-path {
    width: 140px;
    margin-left: 6px;
  }
`;

const DefaultLinkWrap = styled(DefaultLink)`
  :nth-child(1) {
    width: 38%;
  }
  :nth-child(2) {
    width: 62%;
  }
`;

const LinkButton = styled.button`
  border: none;
  width: 127px;
  height: 38px;
  background-color: #ffffff;
  border: 1px solid #cfcfcf;
  border-radius: 23px;
  color: #767676;
  font-size: 15px;
  :hover {
    cursor: pointer;
  }
`;

interface UserInflowReportCardState {
  inflowArr: {
    name: string;
    rate: number;
  }[];
  donutArrState: {
    name: string;
    cnt: number;
    rate: number;
  }[];
}

interface UserInflowReportCardProps {
  nationalLoading?: boolean;
  globalLoading?: boolean;
  pathLoading?: boolean;
  keywordLoading?: boolean;
  nationalData: GetReportInflowNationalResponseType['payload'] | null;
  globalData: GetReportInflowGlobalResponseType['payload'] | null;
  pathData: GetReportInflowPathResponseType['payload'] | null;
  keywordData: GetReportInflowKeywordResponseType['payload'];
}

const UserInflowReportCard = ({
  nationalLoading,
  globalLoading,
  pathLoading,
  keywordLoading,
  nationalData,
  globalData,
  pathData,
  keywordData,
}: UserInflowReportCardProps) => {
  // state
  const [rebNationalData, setRebNationalData] = useState<
    UserInflowReportCardState['donutArrState']
  >([]);
  const [rebGlobalData, setRebGlobalData] = useState<
    UserInflowReportCardState['donutArrState']
  >([]);
  const [rebPathData, setRebPathData] = useState<
    UserInflowReportCardState['donutArrState']
  >([]);
  const [localRank, setLocalRank] = useState<
    UserInflowReportCardState['inflowArr']
  >([]);
  const [globalRank, setGlobalRank] = useState<
    UserInflowReportCardState['inflowArr']
  >([]);
  const [pathRank, setPathRank] = useState<
    UserInflowReportCardState['inflowArr']
  >([]);

  const rebuildArrFunc = (
    data: {
      name: string;
      rate: number;
    }[]
  ) => {
    let others = 0;
    const updatedArr = data.map((element) => {
      others += element.rate;
      return {
        ...element,
        cnt: element.rate,
      };
    });
    updatedArr.push({
      name: 'others',
      cnt: others,
      rate: Number((100 - others).toFixed(2)),
    });

    return updatedArr;
  };

  const printRank = (data: UserInflowReportCardState['inflowArr']) => {
    return (
      <RankingWrap>
        {data.map((element, index) => {
          return (
            <RankingItem key={element.name}>
              <RankingIndex>
                <P2>{index + 1}</P2>
              </RankingIndex>
              <P2>
                {element.name}({element.rate}%)
              </P2>
            </RankingItem>
          );
        })}
      </RankingWrap>
    );
  };

  const printCompare = (rate: number | null) => {
    if (rate) {
      if (rate === 0) {
        return <P3>-</P3>;
      }
      return <P3>{Math.abs(rate)}</P3>;
    }
    return <P3>NEW</P3>;
  };

  const printKeywordRank = () => {
    const result_left: ReactNodeArray = [];
    const result_right: ReactNodeArray = [];

    if (keywordData.length > 0) {
      keywordData.forEach((element, idx) => {
        const Item = (
          <KeywordItem key={element.name}>
            <KeywordTitle>
              <RankingIndex $isKeyword>
                <P2>{idx + 1}</P2>
              </RankingIndex>
              <P2>{element.name}</P2>
            </KeywordTitle>
            <CompareVal $compare={element.compare}>
              <Triangle className="compare" />
              {printCompare(element.compare)}
            </CompareVal>
          </KeywordItem>
        );
        if (idx % 2 === 0) {
          result_left.push(Item);
        } else {
          result_right.push(Item);
        }
      });

      return (
        <KeywordRankingWrap>
          <KeywordGrid>{result_left}</KeywordGrid>
          <KeywordGrid>{result_right}</KeywordGrid>
        </KeywordRankingWrap>
      );
    }
    return <DataError addText="23년 7월부터 제공되는 카드입니다." />;
  };

  // national
  useEffect(() => {
    if (nationalData && Object.keys(nationalData).length > 0) {
      setRebNationalData(rebuildArrFunc(nationalData.rank));
      setLocalRank(nationalData.rank);
    } else setRebNationalData([]);
  }, [nationalData]);

  // global
  useEffect(() => {
    if (globalData && Object.keys(globalData).length > 0) {
      setRebGlobalData(rebuildArrFunc(globalData.rank));
      setGlobalRank(globalData.rank);
    } else setRebGlobalData([]);
  }, [globalData]);

  // path
  useEffect(() => {
    if (pathData && Object.keys(pathData).length > 0) {
      setRebPathData(rebuildArrFunc(pathData.rank));
      setPathRank(pathData.rank.slice(0, 3));
    } else setRebPathData([]);
  }, [pathData]);

  return (
    <Component>
      <InnerGrid>
        <SummaryWrapper>
          <img alt="" src={ReportInflowIcon} />
          <Title>
            사용자가 어디서, 어떻게
            <br />
            들어왔나요?
          </Title>
        </SummaryWrapper>
        <ButtonWrapper>
          <DefaultLinkWrap to="/userAnalytics/region">
            <LinkButton>
              지역별 분석 <span className="ri-arrow-down-s-line"></span>
            </LinkButton>
          </DefaultLinkWrap>
          <DefaultLinkWrap to="/userAnalytics/inflowPath">
            <LinkButton className="inflow-path">
              유입경로 분석 <span className="ri-arrow-down-s-line"></span>
            </LinkButton>
          </DefaultLinkWrap>
        </ButtonWrapper>
      </InnerGrid>

      <InnerGrid>
        <RegionWrapper>
          {nationalLoading ? (
            <Spinner />
          ) : (
            <RegionInner>
              <GridTitle>국내 유입 Top 3</GridTitle>
              {rebNationalData.length > 0 ? (
                <RegionBody>
                  <RankingGrid>{printRank(localRank)}</RankingGrid>
                  <ChartWrapper>
                    <DounutChart
                      data={rebNationalData}
                      colorList={['#F99E32', '#00B8D4', '#0091EA', '#CFD8DC']}
                      isDashboard
                    />
                  </ChartWrapper>
                </RegionBody>
              ) : (
                <DataError addText="23년 7월부터 제공되는 카드입니다." />
              )}
            </RegionInner>
          )}
          {globalLoading ? (
            <Spinner />
          ) : (
            <RegionInner>
              <GridTitle>국외 유입 Top 3</GridTitle>
              {rebGlobalData.length > 0 ? (
                <RegionBody>
                  <RankingGrid>{printRank(globalRank)}</RankingGrid>
                  <ChartWrapper>
                    <DounutChart
                      data={rebGlobalData}
                      colorList={['#F99E32', '#00B8D4', '#0091EA', '#CFD8DC']}
                      isDashboard
                    />
                  </ChartWrapper>
                </RegionBody>
              ) : (
                <DataError addText="23년 7월부터 제공되는 카드입니다." />
              )}
            </RegionInner>
          )}
        </RegionWrapper>
      </InnerGrid>

      <InnerGrid>
        <RegionWrapper $last>
          {pathLoading ? (
            <Spinner />
          ) : (
            <RegionInner>
              <GridTitle>유입경로</GridTitle>
              {rebPathData.length > 0 ? (
                <RegionBody>
                  <RankingGrid>{printRank(pathRank)}</RankingGrid>
                  <ChartWrapper>
                    <DounutChart
                      data={rebPathData}
                      colorList={['#00258D', '#2962FF', '#3FECB7', '#CFD8DC']}
                      isDashboard
                    />
                  </ChartWrapper>
                </RegionBody>
              ) : (
                <DataError addText="23년 7월부터 제공되는 카드입니다." />
              )}
            </RegionInner>
          )}
          {keywordLoading ? (
            <Spinner />
          ) : (
            <KeywordInner>
              <GridTitle>유입검색어</GridTitle>
              {printKeywordRank()}
            </KeywordInner>
          )}
        </RegionWrapper>
      </InnerGrid>
    </Component>
  );
};

export default UserInflowReportCard;
