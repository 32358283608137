import { createReducer } from 'typesafe-actions';
import {
  GET_TOTAL_USER_STATISTICS,
  GET_TOTAL_USER_STATISTICS_ERROR,
  GET_TOTAL_USER_STATISTICS_SUCCESS,
  GET_PV_PER_SESSION_STATISTICS,
  GET_PV_PER_SESSION_STATISTICS_ERROR,
  GET_PV_PER_SESSION_STATISTICS_SUCCESS,
  GET_TOTAL_SESSION_STATISTICS,
  GET_TOTAL_SESSION_STATISTICS_ERROR,
  GET_TOTAL_SESSION_STATISTICS_SUCCESS,
  GET_USAGE_MEAN_STATISTICS,
  GET_USAGE_MEAN_STATISTICS_ERROR,
  GET_USAGE_MEAN_STATISTICS_SUCCESS,
  SET_ERROR_NULL_DASHBAORD,
  GET_USER_VISIT_STATISTICS,
  GET_USER_VISIT_STATISTICS_SUCCESS,
  GET_USER_VISIT_STATISTICS_ERROR,
  GET_HOURLY_USER_STATISTIC,
  GET_HOURLY_USER_STATISTIC_SUCCESS,
  GET_HOURLY_USER_STATISTIC_ERROR,
  GET_INFLOW_KEYWORD_STATISTICS,
  GET_INFLOW_KEYWORD_STATISTICS_SUCCESS,
  GET_INFLOW_KEYWORD_STATISTICS_ERROR,
  GET_POPULAR_PAGE_STATISTICS,
  GET_POPULAR_PAGE_STATISTICS_SUCCESS,
  GET_POPULAR_PAGE_STATISTICS_ERROR,
  GET_USER_ENVIRONMENT_STATISTICS,
  GET_USER_ENVIRONMENT_STATISTICS_SUCCESS,
  GET_USER_ENVIRONMENT_STATISTICS_ERROR,
  GET_INFLOW_PATH_STATISTICS,
  GET_INFLOW_PATH_STATISTICS_SUCCESS,
  GET_INFLOW_PATH_STATISTICS_ERROR,
} from './actions';
import { dashboardAction, dashboardState } from './types';

const initialState: dashboardState = {
  isLoadingTotalUser: false,
  isLoadingTotalSession: false,
  isLoadingPV: false,
  isLoadingUsage: false,
  isLoadingVisit: false,
  isLoadingHourly: false,
  isLoadingInflow: false,
  isLoadingPage: false,
  isLoadingEnv: false,
  isLoadingPath: false,

  totalUserData: null,
  totalSessionData: null,
  pvData: null,
  usageData: null,
  VSD: null,
  HSD: null,
  ISD: [],
  PSD: [],
  ESD: [],
  pathData: [],

  dashboardError: null,
};

const reducer = createReducer<dashboardState, dashboardAction>(initialState, {
  [GET_TOTAL_USER_STATISTICS]: (state) => ({
    ...state,
    isLoadingTotalUser: true,
    totalUserData: null,
    dashboardError: null,
  }),
  [GET_TOTAL_USER_STATISTICS_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingTotalUser: false,
    totalUserData: action.payload.payload,
    dashboardError: null,
  }),
  [GET_TOTAL_USER_STATISTICS_ERROR]: (state, action) => ({
    ...state,
    isLoadingTotalUser: false,
    totalUserData: null,
    dashboardError: action.payload,
  }),

  [GET_TOTAL_SESSION_STATISTICS]: (state) => ({
    ...state,
    isLoadingTotalSession: true,
    totalSessionData: null,
    dashboardError: null,
  }),
  [GET_TOTAL_SESSION_STATISTICS_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingTotalSession: false,
    totalSessionData: action.payload.payload,
    dashboardError: null,
  }),
  [GET_TOTAL_SESSION_STATISTICS_ERROR]: (state, action) => ({
    ...state,
    isLoadingTotalSession: false,
    totalSessionData: null,
    dashboardError: action.payload,
  }),

  [GET_PV_PER_SESSION_STATISTICS]: (state) => ({
    ...state,
    isLoadingPV: true,
    pvData: null,
    dashboardError: null,
  }),
  [GET_PV_PER_SESSION_STATISTICS_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingPV: false,
    pvData: action.payload.payload,
    dashboardError: null,
  }),
  [GET_PV_PER_SESSION_STATISTICS_ERROR]: (state, action) => ({
    ...state,
    isLoadingPV: false,
    pvData: null,
    dashboardError: action.payload,
  }),

  [GET_USAGE_MEAN_STATISTICS]: (state) => ({
    ...state,
    isLoadingUsage: true,
    usageData: null,
    dashboardError: null,
  }),
  [GET_USAGE_MEAN_STATISTICS_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingUsage: false,
    usageData: action.payload.payload,
    dashboardError: null,
  }),
  [GET_USAGE_MEAN_STATISTICS_ERROR]: (state, action) => ({
    ...state,
    isLoadingUsage: false,
    usageData: null,
    dashboardError: action.payload,
  }),

  [GET_USER_VISIT_STATISTICS]: (state) => ({
    ...state,
    isLoadingVisit: true,
    VSD: null,
    dashboardError: null,
  }),
  [GET_USER_VISIT_STATISTICS_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingVisit: false,
    VSD: action.payload.payload,
    dashboardError: null,
  }),
  [GET_USER_VISIT_STATISTICS_ERROR]: (state, action) => ({
    ...state,
    isLoadingVisit: false,
    VSD: null,
    dashboardError: action.payload,
  }),

  [GET_HOURLY_USER_STATISTIC]: (state) => ({
    ...state,
    isLoadingHourly: true,
    HSD: null,
    dashboardError: null,
  }),
  [GET_HOURLY_USER_STATISTIC_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingHourly: false,
    HSD: action.payload.payload,
    dashboardError: null,
  }),
  [GET_HOURLY_USER_STATISTIC_ERROR]: (state, action) => ({
    ...state,
    isLoadingHourly: false,
    HSD: null,
    dashboardError: action.payload,
  }),

  [GET_INFLOW_KEYWORD_STATISTICS]: (state) => ({
    ...state,
    isLoadingInflow: true,
    ISD: [],
    dashboardError: null,
  }),
  [GET_INFLOW_KEYWORD_STATISTICS_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingInflow: false,
    ISD: action.payload.payload,
    dashboardError: null,
  }),
  [GET_INFLOW_KEYWORD_STATISTICS_ERROR]: (state, action) => ({
    ...state,
    isLoadingInflow: false,
    ISD: [],
    dashboardError: action.payload,
  }),

  [GET_POPULAR_PAGE_STATISTICS]: (state) => ({
    ...state,
    isLoadingPage: true,
    PSD: [],
    dashboardError: null,
  }),
  [GET_POPULAR_PAGE_STATISTICS_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingPage: false,
    PSD: action.payload.payload,
    dashboardError: null,
  }),
  [GET_POPULAR_PAGE_STATISTICS_ERROR]: (state, action) => ({
    ...state,
    isLoadingPage: false,
    PSD: [],
    dashboardError: action.payload,
  }),

  [GET_USER_ENVIRONMENT_STATISTICS]: (state) => ({
    ...state,
    isLoadingEnv: true,
    ESD: [],
    dashboardError: null,
  }),
  [GET_USER_ENVIRONMENT_STATISTICS_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingEnv: false,
    ESD: action.payload.payload,
    dashboardError: null,
  }),
  [GET_USER_ENVIRONMENT_STATISTICS_ERROR]: (state, action) => ({
    ...state,
    isLoadingEnv: false,
    ESD: [],
    dashboardError: action.payload,
  }),

  [GET_INFLOW_PATH_STATISTICS]: (state) => ({
    ...state,
    isLoadingPath: true,
    pathData: [],
    dashboardError: null,
  }),
  [GET_INFLOW_PATH_STATISTICS_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingPath: false,
    pathData: action.payload.payload,
    dashboardError: null,
  }),
  [GET_INFLOW_PATH_STATISTICS_ERROR]: (state, action) => ({
    ...state,
    isLoadingPath: false,
    pathData: [],
    dashboardError: action.payload,
  }),

  [SET_ERROR_NULL_DASHBAORD]: (state) => ({
    ...state,
    dashboardError: null,
  }),
});

export default reducer;
