import axios from 'axios';
import config from '../../config-api.json';
import {
  GetInflowPathParamType,
  GetInflowPathResponseType,
  GetInflowPathCountParamType,
  GetInflowPathCountResponseType,
  GetSearchInflowParamType,
  GetSearchInflowResponseType,
  GetSocialInflowParamType,
  GetSocialInflowResponseType,
  GetRecommendInflowParamType,
  GetRecommendInflowResponseType,
  GetDetailSearchParamType,
  GetDetailSearchResponseType,
  GetDetailSocialParamType,
  GetDetailSocialResponseType,
  GetTotalKeywordParamType,
  GetTotalKeywordResponseType,
  GetTotalInflowCountParamType,
  GetTotalInflowCountResponseType,
} from './type';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

// 전체 유입 수
export async function apiGetTotalInflowCount(
  info: GetTotalInflowCountParamType
) {
  const response = await axios.get<GetTotalInflowCountResponseType>(
    `${apiInfo.api_url}/total_inflow_count/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 유입경로
export async function apiGetInflowPath(info: GetInflowPathParamType) {
  const response = await axios.get<GetInflowPathResponseType>(
    `${apiInfo.api_url}/inflow_path/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 일별 유입경로
export async function apiGetInflowPathCount(info: GetInflowPathCountParamType) {
  const response = await axios.get<GetInflowPathCountResponseType>(
    `${apiInfo.api_url}/inflow_path_count/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 검색 유입
export async function apiGetSearchInflow(info: GetSearchInflowParamType) {
  const response = await axios.get<GetSearchInflowResponseType>(
    `${apiInfo.api_url}/search_inflow/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 소셜 유입
export async function apiGetSocialInflow(info: GetSocialInflowParamType) {
  const response = await axios.get<GetSocialInflowResponseType>(
    `${apiInfo.api_url}/social_inflow/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 추천 유입
export async function apiGetRecommendInflow(info: GetRecommendInflowParamType) {
  const response = await axios.get<GetRecommendInflowResponseType>(
    `${apiInfo.api_url}/recommend_inflow/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// search detail
export async function apiGetDetailSearch(info: GetDetailSearchParamType) {
  const response = await axios.get<GetDetailSearchResponseType>(
    `${apiInfo.api_url}/detail_search_inflow/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
        name: info.name,
        device: info.device,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// search rank
export async function apiGetTotalKeyword(info: GetTotalKeywordParamType) {
  const response = await axios.get<GetTotalKeywordResponseType>(
    `${apiInfo.api_url}/total_keyword_inflow/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// social detail
export async function apiGetDetailSocial(info: GetDetailSocialParamType) {
  const response = await axios.get<GetDetailSocialResponseType>(
    `${apiInfo.api_url}/detail_social_inflow/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
        name: info.name,
        device: info.device,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
