import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import { H3 } from '../../Atoms/Typo';
import DounutChart from '../../Molecule/Chart/DonutChart';
import Legend from '../../Molecule/Chart/DonutChart/Legend';
import SimpleTable from '../../Molecule/SimpleTable';
import Spinner from '../../Molecule/Spinner';

const Component = styled(CardLayout)`
  height: 100%;
`;

const Header = styled.div`
  width: 100%;
  ${H3} {
    font-weight: bold;
  }
`;

const CardBody = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const DonutWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-bottom: 2px solid #222222;
  .apexcharts-pie-series + .apexcharts-datalabels {
    rect {
      fill: #1d2fc7;
      stroke: #1d2fc7;
    }
    text {
      fill: #fff;
    }
  }
`;

const TableWrapper = styled.div`
  width: 100%;
`;

interface EnvironmentDonutCardProps {
  isLoading?: boolean;
  title: string;
  data: {
    [key: string]: string | number;
    cnt: number;
    rate: number;
  }[];
  colorList: string[];
}

const EnvironmentDonutCard = ({
  isLoading,
  title,
  data,
  colorList,
}: EnvironmentDonutCardProps) => {
  const [donutData, setDonutData] = useState<EnvironmentDonutCardProps['data']>(
    []
  );

  useEffect(() => {
    const data_temp: EnvironmentDonutCardProps['data'] = [];
    let cnt_sum = 0;
    let rate_sum = 0;
    if (data.length > 0) {
      data.forEach((element, idx) => {
        const keyArr = Object.keys(element);
        const key = keyArr[0];
        if (idx > 4) {
          cnt_sum += element.cnt;
          rate_sum += element.rate;
          if (idx === data.length - 1) {
            data_temp.push({
              name: 'Etc.',
              cnt: cnt_sum,
              rate: Math.round(rate_sum * 1e12) / 1e12,
            });
          }
        } else {
          data_temp.push({
            name: element[key],
            cnt: element.cnt,
            rate: element.rate,
          });
        }
      });
      setDonutData(data_temp);
    }
  }, [data]);

  return (
    <Component>
      <Header>
        <H3>{title}</H3>
      </Header>
      {isLoading ? (
        <Spinner />
      ) : (
        <CardBody>
          <DonutWrapper className="donut-wrapper">
            <DounutChart data={donutData} colorList={colorList} isEnv />
            <Legend data={donutData} colorList={colorList} />
          </DonutWrapper>
          <TableWrapper>
            <SimpleTable data={donutData} />
          </TableWrapper>
        </CardBody>
      )}
    </Component>
  );
};

export default EnvironmentDonutCard;
