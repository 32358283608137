import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment';

import { RootState } from '../../../../modules';

import {
  GetDetailSearchParamType,
  GetDetailSocialParamType,
  GetRecommendInflowParamType,
  GetSearchInflowParamType,
  GetSocialInflowParamType,
  GetTotalInflowCountParamType,
  GetTotalKeywordParamType,
} from '../../../../api/userAnalytics/inflowPath/type';
import {
  GetDetailSearchAsync,
  GetDetailSocialAsync,
  GetRecommendInflowAsync,
  GetTotalKeywordAsync,
  getSearchInflowAsync,
  getSocialInflowAsync,
  getTotalInflowCountAsync,
} from '../../../../modules/userAnalytics/inflowPath';

import ContentsLayout from '../../../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../../../components/Atoms/Layout/Contents/ContentsItemLayout';
import Header from '../../../../components/Organisms/Header';
import InflowDetailCard from '../../../../components/Organisms/Card/InflowDetailCard';

interface InflowPathDetailProps extends RouteComponentProps {}

const InflowPathDetail = ({ location }: InflowPathDetailProps) => {
  const [pageType, setPageType] = useState('');
  const [searchPathID, setSearchPathID] = useState(1);
  const [socialPathID, setSocialPathID] = useState(1);
  const [activeTabID, setActiveTabID] = useState(1);

  // redux
  const { startDate, endDate, isDatePickerToggle } = useSelector(
    (state: RootState) => state.datePicker
  );

  const {
    isLoadingSearch,
    isLoadingDetailSearch,
    isLoadingTotalKeyword,
    isLoadingSocial,
    isLoadingDetailSocial,
    isLoadingRecommend,

    totalInflowData,
    searchInflowData,
    detailSearchData,
    totalKeywordData,
    socialInflowData,
    detailSocialData,
    recommendInflowData,
  } = useSelector((state: RootState) => state.inflowPath);

  const dispatch = useDispatch();

  const onGetTotalInflowCount = (info: GetTotalInflowCountParamType) => {
    dispatch(getTotalInflowCountAsync.request(info));
  };

  const onGetSearchInflow = (info: GetSearchInflowParamType) => {
    dispatch(getSearchInflowAsync.request(info));
  };

  const onGetSocialInflow = (info: GetSocialInflowParamType) => {
    dispatch(getSocialInflowAsync.request(info));
  };

  const onGetRecommendInflow = (info: GetRecommendInflowParamType) => {
    dispatch(GetRecommendInflowAsync.request(info));
  };

  const onGetDetailSearch = (info: GetDetailSearchParamType) => {
    dispatch(GetDetailSearchAsync.request(info));
  };

  const onGetTotalKeyword = (info: GetTotalKeywordParamType) => {
    dispatch(GetTotalKeywordAsync.request(info));
  };

  const onGetDetailSocial = (info: GetDetailSocialParamType) => {
    dispatch(GetDetailSocialAsync.request(info));
  };

  const detailSearchPathParam = (
    setname: string,
    setdevice: string,
    setActiveID: number
  ) => {
    // id
    setSearchPathID(setActiveID + 1);
    onGetDetailSearch({
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
      name: setname,
      device: setdevice,
    });
  };

  const detailSocialParam = (
    setname: string,
    setdevice: string,
    setActiveID: number
  ) => {
    // id
    setSocialPathID(setActiveID + 1);
    onGetDetailSocial({
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
      name: setname,
      device: setdevice,
    });
  };

  useEffect(() => {
    setPageType(location.search.replace('?', ''));
  }, [location]);

  useEffect(() => {
    switch (pageType) {
      case 'search':
        setActiveTabID(1);
        break;
      case 'social':
        setActiveTabID(2);
        break;
      case 'recommend':
        setActiveTabID(3);
        break;

      default:
        break;
    }
  }, [pageType]);

  useEffect(() => {
    if (!isDatePickerToggle) {
      // total count
      onGetTotalInflowCount({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });

      // search
      onGetSearchInflow({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
      onGetTotalKeyword({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });

      // social
      onGetSocialInflow({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });

      // recommend
      onGetRecommendInflow({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
    }
  }, [startDate, endDate, isDatePickerToggle]);

  // 날짜가 변경되었을 때 searchInflowData가 변경되면서 1번째 index 값 가지고 옴
  useEffect(() => {
    setSearchPathID(1);
    if (searchInflowData.length > 0) {
      onGetDetailSearch({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        name: searchInflowData[0].name,
        device: searchInflowData[0].device,
      });
    }
  }, [searchInflowData]);

  // 날짜가 변경되었을 때 socialInflowData가 변경되면서 1번째 index 값 가지고 옴
  useEffect(() => {
    setSocialPathID(1);
    if (socialInflowData.length > 0) {
      onGetDetailSocial({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        name: socialInflowData[0].name,
        device: socialInflowData[0].device,
      });
    } else {
      onGetDetailSocial({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        name: '',
        device: '',
      });
    }
  }, [socialInflowData]);

  return (
    <ContentsLayout>
      <Header
        title="유입 경로 분석"
        icon=""
        iconClass="ri-user-received-line"
        isDatePicker
      />
      <ContentBodyLayout>
        <ContentsItemLayout desktop={12}>
          <InflowDetailCard
            pageType={pageType}
            isLoadingSearch={isLoadingSearch}
            isLoadingDetail={isLoadingDetailSearch}
            isLoadingSearchLank={isLoadingTotalKeyword}
            isLoadingSocial={isLoadingSocial}
            isLoadingSocialDetail={isLoadingDetailSocial}
            isLoadingRecommend={isLoadingRecommend}
            tabData={totalInflowData}
            pathData={searchInflowData}
            detailData={detailSearchData}
            rankingData={totalKeywordData}
            socialData={socialInflowData}
            SocialDetailData={detailSocialData}
            recommendData={recommendInflowData}
            activeTabID={activeTabID}
            activeSearchID={searchPathID}
            activeSocialID={socialPathID}
            setDetailPath={detailSearchPathParam}
            setDetailSocial={detailSocialParam}
          />
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default withRouter(InflowPathDetail);
