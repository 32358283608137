import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import ContentsLayout from '../../Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../Atoms/Layout/Contents/ContentBodyLayout';
// import ContentsItemLayout from '../../Atoms/Layout/Contents/ContentsItemLayout';
import Header from '../../Organisms/Header';
import ButtonGroup from '../../Molecule/ButtonGroup';
import DashboardTotalCard from '../../Organisms/Card/Dashboard/DashboardTotalCard';
import ContentsItemLayout from '../../Atoms/Layout/Contents/ContentsItemLayout';
import DashboardMultiChartCard from '../../Organisms/Card/Dashboard/DashboardMultiChartCard';
import DashboardHeatMapCard from '../../Organisms/Card/Dashboard/DashboardHeatMapCard';
import DashboardInflowCard from '../../Organisms/Card/Dashboard/DashboardInflowCard';
import {
  GetInflowKeywordStatisticsResponseType,
  GetInflowPathStatisticsResponseType,
  GetPopularPageStatisticsResponseType,
  GetUserEnvironmentStatisticsResponseType,
} from '../../../api/Dashboard/type';
import DashboardPopularPageCard from '../../Organisms/Card/Dashboard/DashboardPopularPageCard';
import DashboardEnvCard from '../../Organisms/Card/Dashboard/DashboardEnvCard';
import DashboardPathCard from '../../Organisms/Card/Dashboard/DashboardPathCard';

const UnitButtonGroup = styled(ButtonGroup)`
  button {
    border-radius: 30px;
    border: none;
    width: 80px;
    height: 32px;
    p {
      color: #767676;
      font-weight: 500 !important;
    }
    :first-child {
      width: 70px;
    }
    :last-child {
      width: 92px;
    }
  }
`;

const InflowGrid = styled.div`
  flex-grow: 0;
  padding: 5px;
  @media screen {
    max-width: 31%;
    flex-basis: 31%;
  }
`;

const PopularPageGrid = styled.div`
  flex-grow: 0;
  padding: 5px;
  @media screen {
    max-width: 16.666667%;
    flex-basis: 16.666667%;
  }
`;

const EnvGrid = styled.div`
  flex-grow: 0;
  padding: 5px;
  @media screen {
    max-width: 19%;
    flex-basis: 19%;
  }
`;

interface DashboardTemplateState {
  totalData: {
    rate: number | null;
    count: number;
    compare: string | null;
  };
}

interface DashboardTemplateProps {
  userLoading?: boolean;
  sessionLoading?: boolean;
  pvLoading?: boolean;
  timeLoading?: boolean;
  hourlyLoading?: boolean;
  inflowLoading?: boolean;
  pageLoading?: boolean;
  envLoading?: boolean;
  pathLoading?: boolean;
  totalUser: DashboardTemplateState['totalData'] | null;
  session: DashboardTemplateState['totalData'] | null;
  pageView: DashboardTemplateState['totalData'] | null;
  time: DashboardTemplateState['totalData'] | null;
  multiChartData: {
    legendData: {
      title: string;
      status:
        | 'dashboard-all'
        | 'return'
        | 'new'
        | 'all_future'
        | 'return_future'
        | 'new_future';
    }[];
    Data: {
      user: {
        [key: string]: {
          total?: number;
          old: number;
          new: number;
          is_futrue?: boolean;
          holiday?: boolean;
        };
      };
      session: {
        [key: string]: {
          total?: number;
          old: number;
          new: number;
          is_futrue?: boolean;
          holiday?: boolean;
        };
      };
      pageview: {
        [key: string]: {
          total?: number;
          old: number;
          new: number;
          is_futrue?: boolean;
          holiday?: boolean;
        };
      };
    } | null;
    isLoading: boolean;
    lineLabel: string;
    bar1Label?: string;
    bar2Label?: string;
  };
  hourlyData: {
    [key: string]: {
      [key: string]: {
        new: number;
        old: number;
      };
    };
  } | null;
  inflowData: GetInflowKeywordStatisticsResponseType['payload'];
  pageData: GetPopularPageStatisticsResponseType['payload'];
  envData: GetUserEnvironmentStatisticsResponseType['payload'];
  pathData: GetInflowPathStatisticsResponseType['payload'];
  activeTabID: number;
  activeDurationName: string;
  onClickButtonGroup: (id: number) => void;
}

const DashboardTemplate = ({
  userLoading,
  sessionLoading,
  pvLoading,
  timeLoading,
  hourlyLoading,
  inflowLoading,
  pageLoading,
  envLoading,
  pathLoading,
  totalUser,
  session,
  pageView,
  time,
  multiChartData,
  hourlyData,
  inflowData,
  pageData,
  envData,
  pathData,
  activeTabID,
  activeDurationName,
  onClickButtonGroup,
}: DashboardTemplateProps) => {
  // state
  const [userData, setUserData] = useState<DashboardTemplateState['totalData']>(
    {
      rate: null,
      count: 0,
      compare: null,
    }
  );
  const [sessionData, setSessionData] = useState<
    DashboardTemplateState['totalData']
  >({
    rate: null,
    count: 0,
    compare: null,
  });
  const [pvData, setPvData] = useState<DashboardTemplateState['totalData']>({
    rate: null,
    count: 0,
    compare: null,
  });
  const [timeData, setTimeData] = useState<DashboardTemplateState['totalData']>(
    {
      rate: null,
      count: 0,
      compare: null,
    }
  );

  useEffect(() => {
    if (totalUser) {
      setUserData(totalUser);
    }
  }, [totalUser]);

  useEffect(() => {
    if (session) {
      setSessionData(session);
    }
  }, [session]);

  useEffect(() => {
    if (pageView) {
      setPvData(pageView);
    }
  }, [pageView]);

  useEffect(() => {
    if (time) {
      setTimeData(time);
    }
  }, [time]);

  return (
    <ContentsLayout>
      <Header
        title="대시보드"
        isDatePicker={false}
        icon=""
        iconClass="ri-home-5-line"
      >
        <UnitButtonGroup
          buttonData={[
            {
              id: 0,
              title: '오늘',
            },
            {
              id: 1,
              title: '최근7일',
            },
            {
              id: 2,
              title: '최근30일',
            },
          ]}
          activeID={activeTabID}
          onClick={onClickButtonGroup}
        />
      </Header>
      <ContentBodyLayout>
        <ContentsItemLayout desktop={12}>
          <DashboardTotalCard
            userLoading={userLoading}
            sessionLoading={sessionLoading}
            pvLoading={pvLoading}
            timeLoading={timeLoading}
            totalUser={userData}
            session={sessionData}
            pv={pvData}
            time={timeData}
          />
        </ContentsItemLayout>

        <ContentsItemLayout desktop={8}>
          <DashboardMultiChartCard {...multiChartData} />
        </ContentsItemLayout>

        <ContentsItemLayout desktop={4}>
          <DashboardHeatMapCard loading={hourlyLoading} data={hourlyData} />
        </ContentsItemLayout>

        <InflowGrid>
          <DashboardInflowCard loading={inflowLoading} data={inflowData} />
        </InflowGrid>

        <PopularPageGrid>
          <DashboardPopularPageCard loading={pageLoading} data={pageData} />
        </PopularPageGrid>

        <EnvGrid>
          <DashboardEnvCard loading={envLoading} data={envData} />
        </EnvGrid>

        <ContentsItemLayout desktop={4}>
          <DashboardPathCard loading={pathLoading} data={pathData} />
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default DashboardTemplate;
