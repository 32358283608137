import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { RootState } from '../../../modules';

import {
  GetSessionCountParamType,
  GetWeeklySessionCountParamType,
} from '../../../api/serviceAnalytics/sessionAnalysis/type';
import {
  getSessionCountAsync,
  getWeeklySessionCountAsync,
} from '../../../modules/serviceAnalytics/sessionAnalysis';

import ServiceAnalysisTemplate from '../../../components/Templates/ServiceAnalysisTemplate';
import { GetExportStatisticsExcelParamType } from '../../../api/authentication/type';
import { getExportExcelAsync } from '../../../modules/authentication';
import { TableChartState } from '../../../components/Molecule/Chart/TableChart';

const ServiceSessionAnalytics = () => {
  // redux
  const { SCData, WSCData, isGetSCLoading, isGetWSCLoading } = useSelector(
    (state: RootState) => state.serviceSessionAnalysis
  );
  const { startDate, endDate, isDatePickerToggle } = useSelector(
    (state: RootState) => state.datePicker
  );
  const dispatch = useDispatch();
  const onGetSessoinCount = (info: GetSessionCountParamType) => {
    dispatch(getSessionCountAsync.request(info));
  };
  const onGetWeeklySessoinCount = (info: GetWeeklySessionCountParamType) => {
    dispatch(getWeeklySessionCountAsync.request(info));
  };

  const onGetExportExcel = (info: GetExportStatisticsExcelParamType) => {
    dispatch(getExportExcelAsync.request(info));
  };

  // state
  const [selectedTimeSort, setSelectedTimeSort] = useState('day');
  // const [selectedUserSort, setSelectedUserSort] = useState('all');

  useEffect(() => {
    if (!isDatePickerToggle) {
      onGetSessoinCount({
        unit: selectedTimeSort,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
      onGetWeeklySessoinCount({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
    }
  }, [isDatePickerToggle, startDate, endDate]);

  const onChangeFliter = (timeSort: string) => {
    setSelectedTimeSort(timeSort);
    onGetSessoinCount({
      unit: timeSort,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
    });
  };

  const onClickExcel = (data: TableChartState['excelData']) => {
    onGetExportExcel({
      time: `${moment(startDate)
        .format('YYYY-MM-DD')
        .replace(/-/g, '')}-${moment(endDate)
        .format('YYYY-MM-DD')
        .replace(/-/g, '')}`,
      type: 'user',
      payload: data,
    });
  };

  const total_label = '전체';
  const label1 = '재방문';
  const label2 = '신규';

  return (
    <ServiceAnalysisTemplate
      headerTitle="방문 횟수 분석"
      icon=""
      iconClass="ri-user-received-line"
      multiChartHeader="방문 횟수"
      dayWeekChartHeader="요일별 방문 횟수"
      heatmapChartHeader="시간대별 방문 횟수"
      multiChartData={{
        Data: SCData || null,
        isLoading: isGetSCLoading,
        onChangeFliter,
        lineLabel: total_label,
        bar1Label: label1,
        bar2Label: label2,
        legendData: [
          {
            title: total_label,
            status: 'all',
          },
          {
            title: label1,
            status: 'return',
          },
          {
            title: label2,
            status: 'new',
          },
          {
            title: `${total_label}(추정)`,
            status: 'all_future',
          },
          {
            title: `${label1}(추정)`,
            status: 'return_future',
          },
          {
            title: `${label2}(추정)`,
            status: 'new_future',
          },
        ],
      }}
      dayWeekMultiChartData={{
        Data: WSCData?.weekday || null,
        isLoading: isGetWSCLoading,
        lineLabel: '전체 사용자 수',
        bar1Label: '재방문 사용자 수',
        bar2Label: '신규 사용자 수',
        legendData: [
          {
            title: '전체 사용자 수',
            status: 'all',
          },
          {
            title: '재방문 사용자 수',
            status: 'return',
          },
          {
            title: '신규 사용자 수',
            status: 'new',
          },
        ],
      }}
      heatmapChartData={{
        Data: WSCData,
        loading: isGetWSCLoading,
        totalLabel: total_label,
        label1,
        label2,
      }}
      onClickExcel={onClickExcel}
    />
  );
};

export default ServiceSessionAnalytics;
