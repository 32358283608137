import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import LegendTitleAllUser from '../../Molecule/Chart/Legend';
import CardHeaderLayout from '../../Atoms/Layout/Card/CardHeaderLayout';
import DefaultCardHeading from '../../Molecule/Heading/Card/DefaultCardHeading';
import DounutChart from '../../Molecule/Chart/DonutChart';
import MultiAreaChart from '../../Molecule/Chart/MultiAreaChart';
import Spinner from '../../Molecule/Spinner';

const Compoenent = styled(CardLayout)`
  display: flex;
  flex-direction: row;
  padding: 0;
  height: 380px;
`;

const InnerGrid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  :first-child {
    width: 35%;
  }
`;

const CardBody = styled(CardBodyLayout)<{ $topIdx?: number }>`
  padding: 30px 30px 0;

  .apexcharts-datalabels {
    ${(props) => {
      if (props.$topIdx) {
        return css`
          :nth-child(${props.$topIdx + 1}) {
            rect {
              fill: #ff3b56;
              stroke: #ff3b56;
            }
            text {
              fill: #fff;
            }
          }
        `;
      }
      return css``;
    }}
  }
`;

const LegendItem = styled(LegendTitleAllUser)``;

const LegendWrapper = styled.div<{ $isRight?: boolean }>`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 40px;
  background-color: ${(props) => props.theme.colors.grey100};
  border-radius: 0 0 0 14px;
  ${(props) => {
    if (props.$isRight) {
      return css`
        border-radius: 0 0 14px 0;
        justify-content: center;
        ${LegendItem} {
          margin-right: 15px;
        }
      `;
    }
    return css``;
  }}
`;

const Split = styled.div`
  width: 1px;
  height: 100%;
  background-color: #d7dbe6;
`;

interface InflowPathCardProps {
  isAreaLoading: boolean;
  MultiAreaData: {
    [key: string]: {
      direct: number;
      search: number;
      social: number;
      recommend: number;
      holiday?: boolean;
    };
  } | null;
  isDonutloading: boolean;
  DonutData: {
    name: string;
    cnt: number;
    rate: number;
  }[];
  legendLabel: {
    title: string;
  }[];

  label1: string;
  label2?: string;
  label3?: string;
  label4?: string;
}

const InflowPathCard = ({
  isDonutloading,
  isAreaLoading,
  MultiAreaData,
  DonutData,
  legendLabel,
  label1,
  label2,
  label3,
  label4,
}: InflowPathCardProps) => {
  // state
  const [maxIndex, setMaxIndex] = useState<number>();

  useEffect(() => {
    const cntArr: number[] = [];

    if (DonutData.length > 0) {
      DonutData.forEach((element) => {
        cntArr.push(element.rate);
      });
      const max = Math.max(...cntArr);
      cntArr.forEach((element, idx) => {
        if (element === max) {
          setMaxIndex(idx + cntArr.length);
        }
      });
    }
  }, [DonutData]);

  return (
    <Compoenent>
      <InnerGrid>
        <CardBody $topIdx={maxIndex}>
          <CardHeaderLayout>
            <DefaultCardHeading title="유입경로" />
          </CardHeaderLayout>
          {isDonutloading ? (
            <Spinner />
          ) : (
            <DounutChart
              data={DonutData}
              colorList={['#00258D', '#2962FF', '#7851E7', '#3FECB7']}
            />
          )}
        </CardBody>
        <LegendWrapper>
          {legendLabel.map((element) => {
            return (
              <LegendItem
                key={element.title}
                title={element.title}
                status={element.title}
                donut
              />
            );
          })}
        </LegendWrapper>
      </InnerGrid>

      <Split />

      <InnerGrid>
        <CardBody>
          <CardHeaderLayout>
            <DefaultCardHeading title="일별 유입경로" />
          </CardHeaderLayout>
          {isAreaLoading ? (
            <Spinner />
          ) : (
            <MultiAreaChart
              Data={MultiAreaData}
              areaLabel={label1}
              area2Label={label2}
              area3Label={label3}
              area4Label={label4}
            />
          )}
        </CardBody>
        <LegendWrapper $isRight>
          {legendLabel.map((element) => {
            return (
              <LegendItem
                key={element.title}
                title={element.title}
                status={element.title}
                donut
              />
            );
          })}
        </LegendWrapper>
      </InnerGrid>
    </Compoenent>
  );
};

export default InflowPathCard;
