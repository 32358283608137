import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';

import loading from './loading';

import authentication, { authenticationSaga } from './authentication';
import serviceUserAnalysis, {
  serviceUserAnalysisSaga,
} from './serviceAnalytics/userAnalysis';
import servicePageViewAnalysis, {
  servicePageViewAnalysisSaga,
} from './serviceAnalytics/pageViewAnalysis';
import serviceSessionAnalysis, {
  serviceSessionAnalysisSaga,
} from './serviceAnalytics/sessionAnalysis';

import pageList, { pageListSaga } from './PageAnalytics/PageList';

import pageUXAnalytics, {
  pageUXAnalyticsSaga,
} from './PageAnalytics/UXAnalytics';
import userFlowAnalysis, {
  userFlowAnalysisSaga,
} from './PageAnalytics/UserFlowAnalysis';
import mouseFlow, {
  mouseFlowSaga,
} from './PageAnalytics/UXAnalytics/MouseFlow';
import scroll, { scrollSaga } from './PageAnalytics/UXAnalytics/Scroll';

import aivoryDashboard, {
  aivoryDashboardSaga,
} from './aivoryUsageAnalytics/dashboard';
import aivorySearchUsageAnalysis, {
  aivorySearchUsageAnalysisSaga,
} from './aivoryUsageAnalytics/SearchUsageAnalysis';
import aivoryPopularSearchAnalysis, {
  aivoryPopularSearchAnalysisSaga,
} from './aivoryUsageAnalytics/popularSearchAnalysis';
import aivoryContentKeywordAnalysis, {
  aivoryContentKeywordAnalysisSaga,
} from './aivoryUsageAnalytics/contentKeywordAnalysis';

import inflowPath, { inflowPathSaga } from './userAnalytics/inflowPath';
import contents, { contentsSaga } from './userAnalytics/contents';
import region, { regionSaga } from './userAnalytics/region';
import environment, { environmentSaga } from './userAnalytics/environment';

import report, { reportSaga } from './report';

import sidebarNav from './sidebarNav';
import datePicker, { datePickerSaga } from './datePicker';
import myPage, { myPageSaga } from './myPage';
import dashboard, { dashboardSaga } from './dashboard';
import pathPlot, { pathPlotSaga } from './PageAnalytics/PathPlot';

const rootReducer = combineReducers({
  loading,
  authentication,
  serviceUserAnalysis,
  servicePageViewAnalysis,
  serviceSessionAnalysis,
  pageUXAnalytics,
  userFlowAnalysis,
  pageList,
  sidebarNav,
  datePicker,
  aivoryDashboard,
  aivorySearchUsageAnalysis,
  aivoryPopularSearchAnalysis,
  aivoryContentKeywordAnalysis,
  myPage,
  mouseFlow,
  scroll,
  report,
  inflowPath,
  contents,
  region,
  environment,
  dashboard,
  pathPlot,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
export function* rootSaga() {
  yield all([
    authenticationSaga(),
    serviceUserAnalysisSaga(),
    servicePageViewAnalysisSaga(),
    serviceSessionAnalysisSaga(),
    pageUXAnalyticsSaga(),
    pageListSaga(),
    userFlowAnalysisSaga(),
    aivoryDashboardSaga(),
    aivorySearchUsageAnalysisSaga(),
    aivoryPopularSearchAnalysisSaga(),
    aivoryContentKeywordAnalysisSaga(),
    myPageSaga(),
    mouseFlowSaga(),
    scrollSaga(),
    datePickerSaga(),
    reportSaga(),
    inflowPathSaga(),
    contentsSaga(),
    regionSaga(),
    environmentSaga(),
    dashboardSaga(),
    pathPlotSaga(),
  ]);
}
