import { takeLatest } from 'redux-saga/effects';
import createRequestSaga from '../createRequestSaga';
import {
  apiGetCheckSession,
  apiIsAIVORYAvailable,
  apiSetLogin,
  apiSetLogout,
  apiGetVodaHealth,
  apiGetVodaSessionHealth,
  apiGetPVUsage,
  apiPostExportExcel,
  apiGetCheckScript,
} from '../../api/authentication/api';

import {
  SET_LOGIN,
  setLoginAsync,
  getCheckSessionAsync,
  GET_CHECK_SESSION,
  setLogoutAsync,
  SET_LOGOUT,
  getIsAIVORYAvailableAsync,
  GET_IS_AIVORY_AVAILABLE,
  getVodaHealthAsync,
  GET_VODA_HEALTH,
  getVodaSessionHealthAsync,
  GET_VODA_SESSION_HEALTH,
  getPVUsageAsync,
  GET_PV_USAGE,
  getExportExcelAsync,
  GET_EXPORT_EXCEL,
  getCheckScriptAsync,
  GET_CHECK_SCRIPT,
} from './actions';

const setLoginSaga = createRequestSaga(setLoginAsync, apiSetLogin);

const getCheckSessionSaga = createRequestSaga(
  getCheckSessionAsync,
  apiGetCheckSession
);

const setLogoutSaga = createRequestSaga(setLogoutAsync, apiSetLogout);

const getIsAIVORYAvailableSaga = createRequestSaga(
  getIsAIVORYAvailableAsync,
  apiIsAIVORYAvailable
);

const getVodaHealthSaga = createRequestSaga(
  getVodaHealthAsync,
  apiGetVodaHealth
);

const getVodaSessionHealthSaga = createRequestSaga(
  getVodaSessionHealthAsync,
  apiGetVodaSessionHealth
);

const getExportExcelSaga = createRequestSaga(
  getExportExcelAsync,
  apiPostExportExcel
);

const getCheckScriptSaga = createRequestSaga(
  getCheckScriptAsync,
  apiGetCheckScript
);

const getPVUsageSaga = createRequestSaga(getPVUsageAsync, apiGetPVUsage);

export function* authenticationSaga() {
  yield takeLatest(SET_LOGIN, setLoginSaga);
  yield takeLatest(SET_LOGOUT, setLogoutSaga);
  yield takeLatest(GET_CHECK_SESSION, getCheckSessionSaga);
  yield takeLatest(GET_IS_AIVORY_AVAILABLE, getIsAIVORYAvailableSaga);
  yield takeLatest(GET_VODA_HEALTH, getVodaHealthSaga);
  yield takeLatest(GET_VODA_SESSION_HEALTH, getVodaSessionHealthSaga);
  yield takeLatest(GET_PV_USAGE, getPVUsageSaga);
  yield takeLatest(GET_EXPORT_EXCEL, getExportExcelSaga);
  yield takeLatest(GET_CHECK_SCRIPT, getCheckScriptSaga);
}

export { authenticationSaga as default };
