import React, { ReactNodeArray, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { P3 } from '../../../Atoms/Typo';
import DataError from '../../../Atoms/DataError';

const Component = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const XaxisLabel = styled.div`
  position: relative;
  width: 1px;
  ${P3} {
    left: 50%;
    top: 0px;
    transform: translate(-50%, 0%);

    position: absolute;
    color: #767676;
    white-space: nowrap;
  }
  :last-child {
    ${P3} {
      color: ${(props) => props.theme.colors.grey900};
      font-weight: 600;
    }
  }
`;

const XaxisGrid = styled.div`
  width: 100%;
  height: 1px;
  background-color: #7d7d7d;
  margin-bottom: 6px;
`;

const ChartBarArea = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-end;
  justify-content: space-around;

  &:first-child {
    flex: 1;
  }
`;

const BarWrapper = styled.div<{ $height: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 40px;
  height: ${(props) => props.$height}%;
`;

const Label = styled.div<{ $isHighlight: boolean }>`
  ${P3} {
    color: ${(props) => props.theme.colors.grey900};
    white-space: nowrap;
    ${(props) => {
      if (props.$isHighlight) {
        return css`
          color: #7851e7;
          font-weight: 600;
        `;
      }
      return css``;
    }}
  }
`;

const Bar = styled.div<{ $isHighlight: boolean; $isForecast?: boolean }>`
  margin-top: 8px;
  width: 100%;
  flex: 1;
  background-color: #3fecb7;

  ${(props) => {
    if (props.$isHighlight && props.$isForecast) {
      return css`
        background-color: #7851e7;
      `;
    }
    if (props.$isHighlight) {
      return css`
        background-color: #7851e7;
      `;
    }
    return css``;
  }};
`;

interface BarChartProps {
  data: {
    [key: string]: number | boolean;
    isHighlight: boolean;
  }[];
  isLabelInChart: boolean;
  isTime?: boolean;
  isRate?: boolean;
  isForecast?: boolean;
}

const BarChart = ({
  data,
  isLabelInChart,
  isTime,
  isRate,
  isForecast,
}: BarChartProps) => {
  const [category, setCategory] = useState<string[]>([]);
  const [max, setMax] = useState(0);

  function numberFormat(x: number) {
    try {
      if (isRate) {
        return `${x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}%`;
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } catch (error) {
      return '';
    }
  }

  const convertSec = (sec: number) => {
    const cvMin = Math.floor(sec / 60);
    const cvSec = Math.floor(sec % 60);

    if (cvMin === 0) {
      return `${cvSec}초`;
    }
    return `${cvMin}분 ${cvSec}초`;
  };

  const printChart = () => {
    const result: ReactNodeArray = [];

    if (data.length > 0) {
      data.forEach((element, index) => {
        let height = ((element[category[index]] as number) / max) * 100;
        if (height < 20) {
          height = 20;
        }

        if (isLabelInChart) {
          result.push(
            <BarWrapper $height={height} key={JSON.stringify(element)}>
              <Label $isHighlight={element.isHighlight}>
                <P3>{category[index]}</P3>
              </Label>
              <Bar
                $isHighlight={element.isHighlight}
                $isForecast={isForecast}
              />
            </BarWrapper>
          );
        } else {
          result.push(
            <BarWrapper $height={height} key={JSON.stringify(element)}>
              <Label $isHighlight={element.isHighlight}>
                <P3>
                  {isTime
                    ? convertSec(element[category[index]] as number)
                    : numberFormat(element[category[index]] as number)}
                </P3>
              </Label>
              <Bar
                $isHighlight={element.isHighlight}
                $isForecast={isForecast}
              />
            </BarWrapper>
          );
        }
      });
      return result;
    }
    return <DataError />;
  };

  useEffect(() => {
    const tempCategory: string[] = [];
    let tempMax = 0;
    data.forEach((element) => {
      const keyArr = Object.keys(element);
      tempCategory.push(keyArr[0]);

      if (element[keyArr[0]] > tempMax) {
        tempMax = element[keyArr[0]] as number;
      }
    });
    setMax(tempMax);
    setCategory(tempCategory);
  }, [data]);

  return (
    <Component>
      <ChartBarArea>{printChart()}</ChartBarArea>
      <XaxisGrid />
      {isLabelInChart ? null : (
        <ChartBarArea>
          {category.map((element) => {
            return (
              <XaxisLabel key={element}>
                <P3>{element}</P3>
              </XaxisLabel>
            );
          })}
        </ChartBarArea>
      )}
    </Component>
  );
};

export default BarChart;
