import React, { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import styled from 'styled-components';

import { tooltip } from '../../../Atoms/ChartTooltip/HeatmapChartTooltip';

const Component = styled.div`
  width: 100%;
  height: 100%;
  min-height: 300px;
  .apexcharts-svg {
    overflow: initial;
    .apexcharts-inner {
      .apexcharts-heatmap-series {
        rect {
          filter: none;
        }
      }
    }
  }
  .apexcharts-yaxis-label {
    :nth-last-child(-n + 2) {
      fill: red;
    }
  }

  ${tooltip};
`;

interface HeatmapChartProps {
  dashboard?: boolean;
  Data: {
    [key: string]: {
      [key: string]: {
        new: number;
        old: number;
      };
    };
  } | null;
  label1: string;
  label2: string;
  totalLabel: string;
}

const HeatmapChart = ({
  dashboard,
  Data,
  label1,
  label2,
  totalLabel,
}: HeatmapChartProps) => {
  const yAxisCategory = ['일', '토', '금', '목', '수', '화', '월'];
  const xAxisCategory = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
  ];
  let prevFill = '';

  function printCustomTooltip({ series, seriesIndex, dataPointIndex, w }: any) {
    if (Data && series[seriesIndex][dataPointIndex] !== null) {
      const lineValue = series[seriesIndex][dataPointIndex];
      const yAxisKey = yAxisCategory[seriesIndex];
      const xAxisKey = xAxisCategory[dataPointIndex];
      const barValue1 = Data[yAxisKey][xAxisKey].old;
      const barValue2 = Data[yAxisKey][xAxisKey].new;

      const returnRate = Math.round((barValue1 / lineValue) * 100);
      const newRate = 100 - returnRate;

      return `<div class="tooltip">
              <div class="innerBox">
                <div class="title all">${totalLabel}</div>
                <div class="value-all">${Number(
                  lineValue
                ).toLocaleString()}</div>
              </div>
              <div class="innerBox">
                <div class="title return">${label1}</div>
                <div class="value-wrap">
                  <div class="value">${barValue1.toLocaleString()}</div>
                  <div class="rate">(${returnRate || 0}%)</div>
                </div>
              </div>
              <div class="innerBox">
                <div class="title new">${label2}</div>
                <div class="value-wrap">
                  <div class="value">${barValue2.toLocaleString()}</div>
                  <div class="rate">(${newRate || 0}%)</div>
                </div>
              </div>
            </div>`;
    }
    return ``;
  }

  const BarOnMouseEnter = (e: any, chartContext: any, config: any) => {
    if (e.target) {
      const target = e.target as HTMLElement;
      if (target.nodeName === 'rect') {
        const fill = target.getAttribute('fill');
        if (fill) {
          prevFill = fill;
        }

        target.style.fill = '#2962FF';
      }
    }
  };

  const BarOnMouseLeave = (e: any, chartContext: any, config: any) => {
    if (e.target) {
      const target = e.target as HTMLElement;
      if (target.nodeName === 'rect') {
        target.style.fill = prevFill;
      }
    }
  };

  const options = {
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },

    noData: {
      text: '데이터를 불러오지 못했습니다.',
      align: 'center' as const,
      verticalAlign: 'middle' as const,
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#424242',
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Pretendard',
      },
    },
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      redrawOnParentResize: false,
      redrawOnWindowResize: true,
      events: {
        dataPointMouseLeave: BarOnMouseLeave,
        dataPointMouseEnter: BarOnMouseEnter,
      },
    },
    xaxis: {
      categories: xAxisCategory,
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          fontWeight: 400,
          fontFamily: 'Pretendard',
          fontSize: '14px',
          colors: '#757575',
        },
      },

      position: 'top',
    },
    plotOptions: {
      heatmap: {
        enableShades: true,
        shadeIntensity: 0,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 10000,
              color: '#e8dcfd',
            },
          ],
        },
      },
    },
    stroke: {
      width: 4,
    },
    grid: {
      padding: {
        right: -8,
        top: -15,
        bottom: -25,
      },
    },
    yaxis: {
      labels: {
        show: true,
        offsetX: -14,
        offsetY: 0,
        rotate: 0,
        style: {
          fontWeight: 400,
          fontFamily: 'Pretendard',
          fontSize: '14px',
          colors: '#757575',
        },
      },
    },
    tooltip: {
      enabled: true,
      custom: printCustomTooltip,
    },
  };

  const ChartRef = useRef<any>(null);
  const [valueRange, setValueRange] = useState({ min: 0, max: 0 });
  const [rebuildSeries, setRebuildSeries] = useState<
    {
      name: string;
      data: {
        x: string;
        y: number;
      }[];
    }[]
  >([]);
  useEffect(() => {
    const { current } = ChartRef;
    if (Data && current) {
      const seriesTemp: {
        name: string;
        data: {
          x: string;
          y: number;
        }[];
      }[] = [];
      let min = 0;
      let max = 0;

      yAxisCategory.forEach((element, index) => {
        const dataTemp: {
          x: string;
          y: number;
        }[] = [];
        xAxisCategory.forEach((xAxisElement, xAxisIndex) => {
          const y =
            Data[element][xAxisElement].new + Data[element][xAxisElement].old;
          dataTemp.push({
            x: `${xAxisElement}시`,
            y,
          });
          if (index === 0 && xAxisIndex === 0) {
            min = y;
            max = y;
          } else {
            if (max < y) {
              max = y;
            }
            if (min > y) {
              min = y;
            }
          }
        });
        seriesTemp.push({
          name: element,
          data: dataTemp,
        });
      });

      setRebuildSeries(seriesTemp);
      setValueRange({
        min,
        max,
      });
    }
  }, [Data]);

  useEffect(() => {
    const { current } = ChartRef;
    if (ChartRef.current && rebuildSeries.length > 0) {
      current.chart.updateOptions({
        plotOptions: {
          heatmap: {
            enableShades: true,
            shadeIntensity: 0.1,
            colorScale: {
              ranges: [
                {
                  from: 0,
                  to: valueRange.max * 0.1,
                  color: '#E3E4FD',
                },
                {
                  from: valueRange.max * 0.1,
                  to: valueRange.max * 0.2,
                  color: '#D8DAFC',
                },
                {
                  from: valueRange.max * 0.2,
                  to: valueRange.max * 0.3,
                  color: '#C1C4FB',
                },
                {
                  from: valueRange.max * 0.3,
                  to: valueRange.max * 0.4,
                  color: '#B6B9FA',
                },
                {
                  from: valueRange.max * 0.4,
                  to: valueRange.max * 0.5,
                  color: '#9FA3F9',
                },
                {
                  from: valueRange.max * 0.5,
                  to: valueRange.max * 0.6,
                  color: '#9499F8',
                },
                {
                  from: valueRange.max * 0.6,
                  to: valueRange.max * 0.7,
                  color: '#7E84F6',
                },
                {
                  from: valueRange.max * 0.7,
                  to: valueRange.max * 0.8,
                  color: '#7278F6',
                },
                {
                  from: valueRange.max * 0.8,
                  to: valueRange.max * 0.9,
                  color: '#5C63F4',
                },
                {
                  from: valueRange.max * 0.9,
                  to: valueRange.max,
                  color: '#4C53F3',
                  // opacity: 0.2,
                },
              ],
            },
          },
        },
      });
    }
  }, [ChartRef, valueRange, rebuildSeries]);

  useEffect(() => {
    const { current } = ChartRef;
    if (dashboard && ChartRef.current && rebuildSeries.length > 0) {
      current.chart.updateOptions({
        xaxis: {
          tickAmount: 10,
          position: 'bottom',
          labels: {
            offsetX: -3,
            offsetY: -3,
          },
          axisBorder: {
            show: false,
          },
        },
        stroke: {
          width: 1,
        },
        grid: {
          padding: {
            bottom: 20,
          },
        },
      });
    }
  }, [ChartRef, dashboard, rebuildSeries]);

  return (
    <Component>
      <Chart
        type="heatmap"
        series={rebuildSeries}
        options={options}
        width="100%"
        height="100%"
        ref={ChartRef}
      />
    </Component>
  );
};

export default HeatmapChart;
