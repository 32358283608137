import { createAsyncAction, createAction } from 'typesafe-actions';
import { AxiosErrorType } from '../../createRequestSaga';
import {
  GetNationalSessionEnvStatisticsParamType,
  GetNationalSessionEnvStatisticsResponseType,
  GetGlobalSessionEnvStatisticsParamType,
  GetGlobalSessionEnvStatisticsResponseType,
} from '../../../api/userAnalytics/region/type';

export const GET_NATIONAL_SESSION_ENV_STATISTICS =
  'userAnalytics/region/GET_NATIONAL_SESSION_ENV_STATISTICS' as const;
export const GET_NATIONAL_SESSION_ENV_STATISTICS_SUCCESS =
  'userAnalytics/region/GET_NATIONAL_SESSION_ENV_STATISTICS_SUCCESS' as const;
export const GET_NATIONAL_SESSION_ENV_STATISTICS_ERROR =
  'userAnalytics/region/GET_NATIONAL_SESSION_ENV_STATISTICS_ERROR' as const;

export const getNationalSessionEnvStatisticsAsync = createAsyncAction(
  GET_NATIONAL_SESSION_ENV_STATISTICS,
  GET_NATIONAL_SESSION_ENV_STATISTICS_SUCCESS,
  GET_NATIONAL_SESSION_ENV_STATISTICS_ERROR
)<
  GetNationalSessionEnvStatisticsParamType,
  GetNationalSessionEnvStatisticsResponseType,
  AxiosErrorType
>();

export const GET_GLOBAL_SESSION_ENV_STATISTICS =
  'userAnalytics/region/GET_GLOBAL_SESSION_ENV_STATISTICS' as const;
export const GET_GLOBAL_SESSION_ENV_STATISTICS_SUCCESS =
  'userAnalytics/region/GET_GLOBAL_SESSION_ENV_STATISTICS_SUCCESS' as const;
export const GET_GLOBAL_SESSION_ENV_STATISTICS_ERROR =
  'userAnalytics/region/GET_GLOBAL_SESSION_ENV_STATISTICS_ERROR' as const;

export const getGlobalSessionEnvStatisticsAsync = createAsyncAction(
  GET_GLOBAL_SESSION_ENV_STATISTICS,
  GET_GLOBAL_SESSION_ENV_STATISTICS_SUCCESS,
  GET_GLOBAL_SESSION_ENV_STATISTICS_ERROR
)<
  GetGlobalSessionEnvStatisticsParamType,
  GetGlobalSessionEnvStatisticsResponseType,
  AxiosErrorType
>();

export const SET_ERROR_NULL_SERVICE_ENV =
  'userAnalytics/region/SET_ERROR_NULL_SERVICE_ENV';

export const setErrorNullServiceEnv = createAction(
  SET_ERROR_NULL_SERVICE_ENV
)();
