import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const Component = styled.div`
  width: 220px;
  height: 20px;
  display: flex;
  align-items: center;
`;

const PrettoSlider = withStyles({
  root: {
    color: '#222222',
    height: 4,
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    marginTop: -4,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
  },
})(Slider);

interface OpacitySliderProps {
  sliderRef?: React.RefObject<HTMLDivElement>;
  type: string;
  defaultVal: number;
  opacityVal: (val: number) => void;
}

const OpacitySlider = ({
  sliderRef,
  type,
  defaultVal,
  opacityVal,
}: OpacitySliderProps) => {
  const [value, setValue] = useState<number>(6);

  const handleChange = (event: any, val: number | number[]) => {
    if (typeof val === 'number') {
      setValue(val);
    }
  };

  useEffect(() => {
    opacityVal(value);
  }, [value]);

  useEffect(() => {
    switch (type) {
      case 'mouse':
        setValue(10);
        break;
      case 'scroll':
        setValue(6);
        break;

      default:
        break;
    }
  }, []);

  return (
    <Component ref={sliderRef}>
      <PrettoSlider
        defaultValue={defaultVal}
        onChange={handleChange}
        min={1}
        max={10}
        aria-label="Default"
        valueLabelDisplay="off"
      />
    </Component>
  );
};

export default OpacitySlider;
