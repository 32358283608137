import { takeLatest } from 'redux-saga/effects';
import createRequestSaga from '../../createRequestSaga';
import {
  apiGetPathInflowChildren,
  apiGetPathPlot,
  apiGetPathInflow,
} from '../../../api/pageAnalytics/pathPlot/api';
import {
  GET_PATH_INFLOW_CHILDREN,
  GET_PATH_PLOT,
  GET_PATH_INFLOW,
  getPathInflowChildrenAsync,
  getPathPlotAsync,
  getPathInflowAsync,
} from './actions';

const getPathPlotSaga = createRequestSaga(getPathPlotAsync, apiGetPathPlot);

const getPathInflowChildrenSaga = createRequestSaga(
  getPathInflowChildrenAsync,
  apiGetPathInflowChildren
);

const getPathInflowSaga = createRequestSaga(
  getPathInflowAsync,
  apiGetPathInflow
);

export function* pathPlotSaga() {
  yield takeLatest(GET_PATH_PLOT, getPathPlotSaga);
  yield takeLatest(GET_PATH_INFLOW_CHILDREN, getPathInflowChildrenSaga);
  yield takeLatest(GET_PATH_INFLOW, getPathInflowSaga);
}

export { pathPlotSaga as default };
