import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { P1, P2 } from '../Typo';

const ListStyle = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #c7cee1;
  margin-right: 8px;
`;

const Component = styled(NavLink)`
  position: relative;
  ${P1} {
    color: ${(props) => props.theme.colors.grey900};
    font-size: 17px;
    font-weight: bold;
    span {
      display: none;
    }
    @media (max-width: 1440px) {
      font-size: 16px;
    }
  }

  ${P2} {
    font-size: 15px;
    color: #767676;
    font-weight: 500;

    @media (max-width: 1440px) {
      font-size: 14px;
    }
  }

  &.active {
    ${P1} {
      border-bottom: 2px solid ${(props) => props.theme.colors.grey900};
      span {
        display: inline-block;
        font-size: 15px;
        font-weight: normal;
        margin-left: 3px;
        @media (max-width: 1440px) {
          display: none;
        }
      }
    }
    ${ListStyle} {
      background-color: ${(props) => props.theme.colors.grey900};
    }
    ${P2} {
      color: ${(props) => props.theme.colors.grey900};
      font-weight: bold;
    }
  }
  :hover {
    ${P1} {
      color: ${(props) => props.theme.colors.grey900};
    }
    ${ListStyle} {
      background-color: ${(props) => props.theme.colors.grey900};
    }
    ${P2} {
      color: ${(props) => props.theme.colors.grey900};
      font-weight: bold;
    }
  }
`;

const SubWrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface DefaultNavLinkProps {
  to: string;
  title: string;
  exact?: boolean;
  subLink?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
}

const DefaultNavLink = ({
  to,
  title,
  exact,
  subLink,
  children,
  onClick,
}: DefaultNavLinkProps) => {
  return (
    <Component exact={exact !== undefined ? exact : true} to={to}>
      {children}
      {subLink ? (
        <SubWrapper>
          <ListStyle />
          <P2>{title}</P2>
        </SubWrapper>
      ) : (
        <P1>
          {title}
          <span className="ri-arrow-right-line"></span>
        </P1>
      )}
    </Component>
  );
};

export default DefaultNavLink;
