import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { P2, P3 } from '../../Atoms/Typo';

const Component = styled.div`
  width: 100%;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  display: flex;
  align-items: baseline;
  ${P2} {
    font-size: 15px;
    font-weight: 600;
  }
`;

const Rate = styled(P3)`
  color: #767676;
  font-size: 15px;
  font-weight: 500;
  padding: 0 5px;
`;

const Count = styled(P3)`
  color: #767676;
  font-size: 15px;
  font-weight: 500;
`;

const Bar = styled.div`
  display: flex;
  width: 100%;
  height: 6px;
  margin: 5px 0;
`;

const FirstBar = styled.div<{ $rate: number }>`
  width: ${(props) => `${props.$rate}%`};
  background-color: #3fecb7;
`;

const SecondBar = styled.div<{ $rate: number }>`
  width: ${(props) => `${props.$rate}%`};
  background-color: #1389ff;
`;

interface ComparisonBarProps {
  columns: string[];
  totalCount: number;
  firstVal: number;
  secVal: number;
  isPV?: boolean;
}

const ComparisonBar = ({
  columns,
  totalCount,
  firstVal,
  secVal,
  isPV,
}: ComparisonBarProps) => {
  const [firstRate, setFirstRate] = useState(0);
  const [secRate, setSecRate] = useState(0);

  useEffect(() => {
    if (totalCount) {
      setFirstRate(Math.ceil((firstVal / totalCount) * 100));
      setSecRate(Math.floor((secVal / totalCount) * 100));
    } else {
      setFirstRate(0);
      setSecRate(0);
    }
  }, [totalCount]);

  return (
    <Component>
      <TitleWrapper>
        <Title>
          <P2>{columns[0]}</P2>
          <Rate>{firstRate}%</Rate>
        </Title>
        <Title>
          <Rate>{secRate}%</Rate>
          <P2>{columns[1]}</P2>
        </Title>
      </TitleWrapper>
      <Bar>
        <FirstBar $rate={firstRate} />
        <SecondBar $rate={secRate} />
      </Bar>
      <TitleWrapper>
        <Count>
          {firstVal.toLocaleString()}
          {isPV ? 'PV' : '명'}
        </Count>
        <Count>
          {secVal.toLocaleString()}
          {isPV ? 'PV' : '명'}
        </Count>
      </TitleWrapper>
    </Component>
  );
};

export default ComparisonBar;
