import React from 'react';
import styled, { css } from 'styled-components';
import { P2, P3 } from '../../../Atoms/Typo';
import ElementThumbnailImage from '../../../Molecule/Thumbnail/ElementThumbnailImage';

const Component = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  position: relative;
  margin-bottom: 20px;
  border: 1px solid #dfdfdf;
  &:last-child {
    margin-bottom: 0px;
  }
`;

const Rank = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  border-radius: 0px 0px 10px 0px;
  background-color: #7851e7;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 8px;
  ${P2} {
    font-weight: 600;
    color: white;
  }
`;

const ElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding: 20px;
  border-left: 1px solid #d7dbe6;
  background-color: #f5f5f5;
`;

const ElementImage = styled(ElementThumbnailImage)<{ $isClicked: boolean }>`
  width: 50%;
  cursor: pointer;

  ${(props) => {
    if (props.$isClicked) {
      return css`
        border: 1px solid ${props.theme.colors.purple3};
        background-color: rgba(181, 151, 247, 0.2);
      `;
    }
    return css``;
  }}
`;

const DataDisplay = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 11px;

  &:last-child {
    margin-bottom: 0px;
  }

  ${P2} {
    font-size: 15px;
    font-weight: 600;
    margin: 0px 6px;
  }

  ${P3} {
    font-size: 15px;
  }
`;

const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: #a1a1a1;
`;

interface ElementCardProps {
  img_src: string;
  rank: number;
  data: {
    id: number;
    title: string;
    value: string;
  }[];
  isClicked: boolean;
  className?: string;
  onMouseOverImage?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onClickImage?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseLeaveImage?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
}

const ElementCard = ({
  img_src,
  rank,
  data,
  isClicked,
  className,
  onMouseOverImage,
  onClickImage,
  onMouseLeaveImage,
}: ElementCardProps) => {
  return (
    <Component className={className}>
      <Rank>
        <P2>{rank}</P2>
      </Rank>
      <ElementImage
        img_src={img_src}
        isBackground={false}
        $isClicked={isClicked}
        onMouseOver={onMouseOverImage}
        onMouseLeave={onMouseLeaveImage}
        onClick={onClickImage}
      />
      <ElementWrapper>
        {data.map((element) => {
          return (
            <DataDisplay key={element.id}>
              <Dot />
              <P2>{element.title}</P2>
              <P3>{element.value}</P3>
            </DataDisplay>
          );
        })}
      </ElementWrapper>
    </Component>
  );
};

export default ElementCard;
