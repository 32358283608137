import React from 'react';
import styled from 'styled-components';
import { P2, P3 } from '../../Atoms/Typo';

const Component = styled.div`
  width: 100%;
  height: 265px;
`;

const RowItem = styled.div`
  display: flex;
  height: 41px;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  border-bottom: 1px solid #dddddd;
`;

const Wrapper = styled.div`
  display: flex;
  /* width: 100%; */
`;

const Rank = styled(P2)`
  color: #7851e7;
  font-weight: bold;
`;

const Title = styled(P2)`
  font-weight: 400;
  margin-left: 14px;
`;

const Value = styled.div`
  display: flex;
  align-items: baseline;
  margin-right: 30px;
  ${P3} {
    color: #767676;
  }
`;

const Rate = styled(P2)`
  width: 33px;
  display: flex;
  justify-content: flex-end;
`;

interface SimpleTableProps {
  data: {
    [key: string]: string | number;
    cnt: number;
    rate: number;
  }[];
}

const SimpleTable = ({ data }: SimpleTableProps) => {
  return (
    <Component>
      {data.map((element, idx) => {
        return (
          <RowItem key={element.name}>
            <Wrapper>
              <Rank>{idx + 1}</Rank>
              <Title>{element.name}</Title>
            </Wrapper>
            <Wrapper>
              <Value>
                <P2>{element.cnt.toLocaleString()}</P2>
                <P3>UV</P3>
              </Value>
              <Rate>{element.rate}%</Rate>
            </Wrapper>
          </RowItem>
        );
      })}
    </Component>
  );
};

export default SimpleTable;
