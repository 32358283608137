import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../modules';

import ContentsTable from '../../../components/Molecule/Table/ContensTable';
import {
  GetAIVORYContentUsageBySearchWordParamType,
  GetAIVORYSearchKeywordNetworkParamType,
  GetAIVORYSearchUsageWordcloudParamType,
} from '../../../api/aivoryUsageAnalytics/popularSearchAnalysis/type';
import {
  getAIVORYContentUsageBySearchWordAsync,
  getAIVORYSearchKeywordNetworkAsync,
  getAIVORYSearchUsageWordcloudAsync,
} from '../../../modules/aivoryUsageAnalytics/popularSearchAnalysis';
import WordCloudChartCard from '../../../components/Organisms/Card/WordCloudChartCard';
import NetworkChartCard from '../../../components/Organisms/Card/NetworkChartCard';
import Header from '../../../components/Organisms/Header';
import KeywordRankTable from '../../../components/Organisms/Table/KeywordRankTable';
import ContentsLayout from '../../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../../components/Atoms/Layout/Contents/ContentsItemLayout';

const ContentsTableComponent = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;

  ${(props) => {
    if (props.$isOpen) {
      return css`
        overflow-x: auto !important;
      `;
    }
    return css`
      display: none;
    `;
  }}
`;

const ModalBackground = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  left: 0px;
  height: 0px;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
  z-index: 30;
  ${(props) => {
    if (props.$isOpen) {
      return css`
        overflow: hidden;
      `;
    }
    return css`
      display: none;
    `;
  }}
`;

const PopularSearchAnalysis = () => {
  const dispatch = useDispatch();
  const [isOpenContentsTable, setIsOpenContentsTable] = useState(false);
  const [clickKeyword, setClickKeyword] = useState('');
  const { startDate, endDate, isDatePickerToggle } = useSelector(
    (state: RootState) => state.datePicker
  );
  const {
    isLoadingSearchKeywordNetwork,
    isLoadingSearchUsageWordcloud,
    isLoadingContentUsageBySearchWord,
    searchKeywordNetworkData,
    searchUsageWordcloudData,
    contentUsageBySearchWordData,
  } = useSelector((state: RootState) => state.aivoryPopularSearchAnalysis);

  const onGetAIVORYSearchKeywordNetwork = (
    info: GetAIVORYSearchKeywordNetworkParamType
  ) => {
    dispatch(getAIVORYSearchKeywordNetworkAsync.request(info));
  };

  const onGetAIVORYSearchUsageWordcloud = (
    info: GetAIVORYSearchUsageWordcloudParamType
  ) => {
    dispatch(getAIVORYSearchUsageWordcloudAsync.request(info));
  };

  const onGetAIVORYContentUsageBySearchWord = (
    info: GetAIVORYContentUsageBySearchWordParamType
  ) => {
    dispatch(getAIVORYContentUsageBySearchWordAsync.request(info));
  };

  useEffect(() => {
    const start_date = moment(startDate).format('YYYY-MM-DD');
    const end_date = moment(endDate).format('YYYY-MM-DD');
    if (!isDatePickerToggle) {
      onGetAIVORYSearchKeywordNetwork({
        start_date,
        end_date,
      });
      onGetAIVORYSearchUsageWordcloud({
        start_date,
        end_date,
      });
    }
  }, [isDatePickerToggle, startDate, endDate]);

  const onCloseTable = () => {
    setIsOpenContentsTable(false);
  };

  const onOpenTable = () => {
    setIsOpenContentsTable(true);
  };

  const onClickKeyword = (word: string) => {
    const start_date = moment(startDate).format('YYYY-MM-DD');
    const end_date = moment(endDate).format('YYYY-MM-DD');
    setClickKeyword(word);
    onOpenTable();
    onGetAIVORYContentUsageBySearchWord({
      start_date,
      end_date,
      search_word: word,
    });
  };

  return (
    <ContentsLayout>
      <ContentsTableComponent $isOpen={isOpenContentsTable}>
        <ContentsTable
          onCloseTable={onCloseTable}
          data={contentUsageBySearchWordData}
          clickKeyword={clickKeyword}
          isLoading={isLoadingContentUsageBySearchWord}
        />
      </ContentsTableComponent>
      <ModalBackground $isOpen={isOpenContentsTable} />
      <Header title="인기 검색어 분석" isDatePicker />
      <ContentBodyLayout>
        <ContentsItemLayout desktop={3}>
          <KeywordRankTable
            title="인기 검색어"
            isLoading={isLoadingSearchUsageWordcloud}
            data={searchUsageWordcloudData?.search_cloud || undefined}
            onClickKeyword={onClickKeyword}
            clsName="PopularSearch"
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={9}>
          <WordCloudChartCard
            onClickKeyword={onClickKeyword}
            isTopic={false}
            isLoading={isLoadingSearchUsageWordcloud}
            data={searchUsageWordcloudData?.search_cloud || undefined}
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={12}>
          <NetworkChartCard
            onClickKeyword={onClickKeyword}
            isLoading={isLoadingSearchKeywordNetwork}
            propsData={searchKeywordNetworkData || undefined}
          />
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default PopularSearchAnalysis;
