import React from 'react';
import styled, { css } from 'styled-components';
import Dot from '../../../Atoms/Dot';
import { P3 } from '../../../Atoms/Typo';

const Component = styled.div`
  display: flex;
  align-items: center;
`;

const LegendDot = styled(Dot)<{ $status: string; $radius?: boolean }>`
  ${(props) => {
    switch (props.$status) {
      case 'all':
        return css`
          background-color: ${props.theme.colors.purple7};
        `;
      case 'dashboard-all':
        return css`
          background-color: ${props.theme.colors.grey900};
        `;
      case 'return':
        return css`
          background-color: ${props.theme.colors.bluegreen};
        `;
      case 'new':
        return css`
          background-color: ${props.theme.colors.blue};
        `;
      case 'all_future':
        return css`
          background-color: ${props.theme.colors.black};
        `;
      case 'return_future':
        return css`
          background-color: ${props.theme.colors.lightGray};
        `;
      case 'new_future':
        return css`
          background-color: ${props.theme.colors.darkGray};
        `;
      case '직접유입':
        return css`
          background-color: #00258d;
        `;
      case '검색유입':
        return css`
          background-color: ${props.theme.colors.blue};
        `;
      case '소셜유입':
        return css`
          background-color: ${props.theme.colors.purple7};
        `;
      case '추천유입':
        return css`
          /* background-color: ${props.theme.colors.bluegreen}; */
          background-color: #00bfa5;
        `;

      default:
        return css``;
    }
  }}
  ${(props) => {
    if (props.$radius) {
      return css`
        border-radius: 0;
      `;
    }
    return css``;
  }}
`;

const Title = styled(P3)`
  color: ${(props) => props.theme.colors.grey600};
  margin-left: 4px;
`;

interface LegendTitleProps {
  title?: string;
  status?: string;
  // status?:
  //   | 'all'
  //   | 'return'
  //   | 'new'
  //   | 'all_future'
  //   | 'return_future'
  //   | 'new_future';
  donut?: boolean;
  className?: string;
}

const LegendTitleAllUser = ({
  title,
  status = 'all',
  donut,
  className,
}: LegendTitleProps) => {
  return (
    <Component className={className}>
      <LegendDot $status={status} $radius={donut} />
      <Title>{title}</Title>
    </Component>
  );
};

export default LegendTitleAllUser;
