import React, { ReactNodeArray, useState } from 'react';
import styled, { css } from 'styled-components';
import { P1, P2, P3 } from '../../Atoms/Typo';
import { Button } from '../../Atoms/Button';
import DataError from '../../Atoms/DataError';
import Spinner from '../Spinner';

const Component = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ComponentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  ${P1} {
    font-size: 20px;
    font-weight: bold;
  }
  margin-bottom: 15px;
`;

const ExcelButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 32px;
  border-radius: 6px;
  border: 1px solid #c8c8c8;
  background-color: ${(props) => props.theme.colors.white};
  ${P2} {
    font-size: 15px;
  }
  span {
    font-size: 15px;
    color: ${(props) => props.theme.colors.grey900};
  }

  :hover {
    cursor: pointer;
  }
`;

const TableWrapper = styled.div`
  width: 100%;
`;

const Table = styled.div`
  width: 100%;
`;

const Header = styled.div<{ $scroll?: number }>`
  width: 100%;
  border-top: 2px solid ${(props) => props.theme.colors.grey900};
  border-bottom: 1px solid ${(props) => props.theme.colors.grey900};
  ${(props) => {
    if (props.$scroll) {
      return css`
        padding-right: 5px;
      `;
    }
    return css``;
  }}
`;

const Tr = styled.div`
  display: flex;
  width: 100%;
`;

const TableRow = styled.div`
  border-bottom: 1px solid #dddddd;
  display: flex;
`;

const TableBody = styled.div<{ $scroll?: number }>`
  width: 100%;
  ${(props) => {
    if (props.$scroll) {
      return css`
        height: ${props.$scroll}px;
        overflow: overlay;
        ::-webkit-scrollbar {
          width: 5px;
        }
        ::-webkit-scrollbar-thumb {
          background-color: hsla(0, 0%, 42%, 0.49);
          border-radius: 100px;
        }
      `;
    }
    return css``;
  }}
`;

const DetailButton = styled(Button)`
  margin-top: 20px;
  ${P3} {
    font-size: 15px;
    color: ${(props) => props.theme.colors.grey600};
  }
`;

export interface DefaultTableProps {
  tableTitle?: string;
  isExcel?: boolean;
  scrollHeight?: number;
  isLoading?: boolean;
  columns: ReactNodeArray;
  data: {
    id: string;
    node: ReactNodeArray;
  }[];
  buttonText?: string;
  onClickDetail?: () => void;
  onClickExcel?: () => void;
}

const DefaultTable = ({
  tableTitle,
  isExcel,
  scrollHeight,
  isLoading,
  columns,
  data,
  buttonText,
  onClickDetail,
  onClickExcel,
}: DefaultTableProps) => {
  // state
  const [arrLength, setArrLength] = useState(20);

  const printBody = (size: number) => {
    const data_temp: ReactNodeArray = [];

    if (data.length > 0) {
      data.forEach((element, idx) => {
        if (buttonText === '더보기') {
          if (idx < size) {
            data_temp.push(
              <TableRow key={element.id}>{element.node}</TableRow>
            );
          }
        } else {
          data_temp.push(<TableRow key={element.id}>{element.node}</TableRow>);
        }
      });
      return data_temp;
    }
    return <DataError />;
  };

  const onClickButton = () => {
    if (onClickDetail) {
      onClickDetail();
    }
  };

  const onClickMoreButton = () => {
    setArrLength(arrLength + 20);
  };

  const printDetailButton = () => {
    switch (buttonText) {
      case '자세히':
        return (
          <DetailButton size="detail" onClick={onClickButton}>
            <P3>자세히</P3>
            <span className="ri-arrow-down-s-line"></span>
          </DetailButton>
        );
      case '더보기':
        if (arrLength >= data.length) {
          return (
            <DetailButton size="detail" isDisabled>
              <P3>더보기</P3>
              <span className="ri-arrow-down-s-line"></span>
            </DetailButton>
          );
        }
        return (
          <DetailButton size="detail" onClick={onClickMoreButton}>
            <P3>더보기</P3>
            <span className="ri-arrow-down-s-line"></span>
          </DetailButton>
        );

      default:
        return null;
    }
  };

  return (
    <Component>
      {tableTitle ? (
        <ComponentHeader>
          <P1 className="table-header">{tableTitle}</P1>
          {isExcel ? (
            <ExcelButton
              onClick={() => {
                if (onClickExcel) {
                  onClickExcel();
                }
              }}
            >
              <P2>엑셀 다운로드 </P2>
              <span className="ri-download-line">&nbsp;</span>
            </ExcelButton>
          ) : null}
        </ComponentHeader>
      ) : null}
      <TableWrapper>
        {isLoading ? (
          <Spinner />
        ) : (
          <Table>
            <Header $scroll={scrollHeight}>
              <Tr>{columns}</Tr>
            </Header>
            <TableBody $scroll={scrollHeight}>{printBody(arrLength)}</TableBody>
          </Table>
        )}
      </TableWrapper>
      {printDetailButton()}
    </Component>
  );
};

export default DefaultTable;
