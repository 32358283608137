import axios from 'axios';
import config from '../../config-api.json';
import {
  GetPopularContentParamsType,
  GetPopularContentResponseType,
  GetPopularMenuParamsType,
  GetPopularMenuResponseType,
  GetTotalContentParamsType,
  GetTotalContentResponseType,
} from './type';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

// 메뉴 조회수 top5
export async function apiGetPopularMenu(info: GetPopularMenuParamsType) {
  const response = await axios.get<GetPopularMenuResponseType>(
    `${apiInfo.api_url}/popular_menu/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 게시글 조회수 top5
export async function apiGetPopularContent(info: GetPopularContentParamsType) {
  const response = await axios.get<GetPopularContentResponseType>(
    `${apiInfo.api_url}/popular_content/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 전체 URL별 페이지뷰
export async function apiGetTotalContent(info: GetTotalContentParamsType) {
  const response = await axios.get<GetTotalContentResponseType>(
    `${apiInfo.api_url}/total_content/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
