import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { P2, P3 } from '../../Atoms/Typo';

const Component = styled.div`
  width: 100%;
`;

const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  :first-child {
    padding: 0;
  }
`;

const KeywordItem = styled.div`
  display: flex;
  overflow: hidden;
  ${P2} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Rank = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  margin-right: 9px;
  border-radius: 4px;
  background-color: #1d2fc7;
  ${P2} {
    font-size: 15px;
    color: white;
  }
`;

const CompareVal = styled.div<{ $compare: number | null }>`
  display: flex;
  width: 27px;
  align-items: center;
  justify-content: center;

  ${(props) => {
    if (props.$compare) {
      if (props.$compare > 0) {
        return css`
          ${P3} {
            font-size: 13px;
            color: #ff3b56;
          }
          .compare {
            border-bottom: 6px solid #ff3b56;
          }
        `;
      }
      if (props.$compare < 0) {
        return css`
          ${P3} {
            font-size: 13px;
            color: #2962ff;
          }
          .compare {
            border-bottom: 6px solid #2962ff;
            transform: rotate(180deg);
          }
        `;
      }
      if (props.$compare === 0) {
        return css`
          ${P3} {
            font-size: 13px;
            color: #6d6d6d;
          }
          .compare {
            display: none;
          }
        `;
      }
    }
    return css`
      ${P3} {
        font-size: 13px;
        color: #1d2fc7;
        font-weight: 600;
      }
      .compare {
        display: none;
      }
    `;
  }}
`;

const Triangle = styled.div`
  width: 0px;
  height: 0px;
  border-bottom: 6px solid red;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  margin-right: 3px;
`;

interface KeywordRankProps {
  data: {
    name: string;
    cnt: number;
    compare: number | null;
  }[];
}

const KeywordRank = ({ data }: KeywordRankProps) => {
  const printCompare = (rate: number | null) => {
    if (rate !== null) {
      if (rate === 0) {
        return <P3>-</P3>;
      }
      return <P3>{Math.abs(rate)}</P3>;
    }
    return <P3>NEW</P3>;
  };

  return (
    <Component>
      {data.map((element, idx) => {
        return (
          <ItemWrapper key={element.name}>
            <KeywordItem>
              <Rank className="inflow-rank">
                <P2>{idx + 1}</P2>
              </Rank>
              <P2>{element.name}</P2>
            </KeywordItem>
            <CompareVal $compare={element.compare}>
              <Triangle className="compare" />
              {printCompare(element.compare)}
            </CompareVal>
          </ItemWrapper>
        );
      })}
    </Component>
  );
};

export default KeywordRank;
