import React from 'react';
import styled from 'styled-components';
import { H3 } from '../../../Atoms/Typo';

const Component = styled.div`
  ${H3} {
    font-weight: bold;
  }
`;

interface DefaultCardHeadingProps {
  className?: string;
  children?: React.ReactNode;
  title: string;
}

const DefaultCardHeading = ({
  title,
  className,
  children,
}: DefaultCardHeadingProps) => {
  return (
    <Component className={className}>
      <H3>
        {title}
        {children}
      </H3>
    </Component>
  );
};

export default DefaultCardHeading;
