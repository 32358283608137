import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled, { css } from 'styled-components';
import { PageListPayload } from '../../../api/pageAnalytics/pageList/type';
import DataError from '../../Atoms/DataError';
import ContentBodyLayout from '../../Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../Atoms/Layout/Contents/ContentsItemLayout';
import ContentsLayout from '../../Atoms/Layout/Contents/ContentsLayout';
import DefaultPagenation from '../../Molecule/Pagenation';
import Spinner from '../../Molecule/Spinner';
import PageThumbnailCard from '../../Organisms/Card/PageThumbnailCard';
import Header from '../../Organisms/Header';
import { P2 } from '../../Atoms/Typo';
import Input from '../../Atoms/Input';
import SelectBox from '../../Atoms/SelectBox';
import PageList from '../../Molecule/PageList';

const PaginationWrapper = styled.div`
  width: 100%;
  margin-top: 80px;
`;

const SearchPageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 64px;
  background-color: #dde1eb;
  border-radius: 8px;
  margin: 0 12px;
`;

const PageItemGrid = styled.div`
  padding: 10px;
  @media screen {
    max-width: 20%;
    flex-basis: 20%;
  }
`;

const SearchButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #7851e7;
  border: none;
  span {
    text-align: center;
    color: white;
  }
  :hover {
    cursor: pointer;
  }
`;

const SortingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 12px;
  padding: 10px 0;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey900};
`;

const TotalValue = styled.div`
  display: flex;
  ${P2} {
    span {
      color: #767676;
    }
  }
`;

const SortButtonList = styled.div`
  display: flex;
`;

const ChartFormFilter = styled.div`
  display: flex;
`;

const Split = styled.div`
  width: 1px;
  margin: 0px 10px;
  background-color: #d1d1d1;
`;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  background-color: #415587;
  padding: 15px 0;
  margin-top: 20px;
  margin-bottom: 6px;
  ${P2} {
    color: white;
    font-weight: bold;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #ececec;
  :nth-child(1) {
    width: 25%;
  }
  :nth-child(2) {
    width: 30%;
  }
  :nth-child(3) {
    width: 15%;
  }
  :nth-child(4) {
    width: 15%;
  }
  :nth-child(5) {
    width: 15%;
    border-right: none;
  }
`;

const FormButton = styled.div<{ $active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 6px;
  background-color: ${(props) => props.theme.colors.grey100};
  border: 1px solid #dddddd;
  :first-child {
    margin-right: 5px;
  }
  span {
    font-size: 18px;
    color: ${(props) => props.theme.colors.grey600};
  }
  :hover {
    cursor: pointer;
  }

  ${(props) => {
    if (props.$active) {
      return css`
        background-color: ${props.theme.colors.grey900};
        span {
          color: ${props.theme.colors.white};
        }
      `;
    }
    return css``;
  }}
`;

interface PageListTemplateProps {
  headerTitle: string;
  pageListData: PageListPayload[];
  isLoading: boolean;
  isPageUXAnalysis: boolean;
}

const PageListTemplate = ({
  headerTitle,
  isLoading,
  pageListData,
  isPageUXAnalysis,
}: PageListTemplateProps) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [lastPageNumber, setLastPageNumber] = useState(1);
  const [rebuildData, setRebuildData] = useState<PageListPayload[]>([]);
  const [chartForm, setChartForm] = useState('chart');
  const [search, setSearch] = useState('');
  const [searchType, setSearchType] = useState('page');
  const searchOptions = [
    {
      value: 'page',
      label: '페이지명',
    },
    {
      value: 'url',
      label: 'URL',
    },
  ];
  const PVOptions = [
    {
      value: 'desc',
      label: 'PV높은 순',
    },
    {
      value: 'asc',
      label: 'PV낮은 순',
    },
  ];

  // select search type change
  const stateChange = useCallback((inputValue) => {
    setSearchType(inputValue.value);
  }, []);

  // select sort
  const sortStateChange = useCallback(
    (inputValue) => {
      if (inputValue.value === 'asc') {
        setRebuildData([...rebuildData].reverse());
      } else {
        setRebuildData([...rebuildData].sort((a, b) => b.pv_cnt - a.pv_cnt));
      }
    },
    [rebuildData]
  );

  const onClickChartForm = (type: string) => {
    if (type !== chartForm) {
      if (!(chartForm === '' && type === 'chart')) {
        setChartForm(type);
      }
    }
  };

  const printPageList = useMemo(() => {
    const result: React.ReactNodeArray = [];
    const ux_analysis_list_url = '/uxAnalysisList/uxAnalysis';
    const user_flow_analysis_list = '/userFlowAnalysisList/userFlowAnalysis';

    if (rebuildData.length > 0) {
      for (
        let index = (pageNumber - 1) * 20;
        index <= pageNumber * 20 - 1;
        index += 1
      ) {
        if (index > rebuildData.length - 1) {
          break;
        }
        const element = rebuildData[index];

        if (chartForm === 'chart') {
          result.push(
            <PageItemGrid key={element.page_id}>
              <PageThumbnailCard
                page_id={element.page_id}
                simple_url={element.simple_url}
                page_name={element.page_name}
                pv_count={element.pv_cnt}
                to={`/pageAnalytics${
                  isPageUXAnalysis
                    ? ux_analysis_list_url
                    : user_flow_analysis_list
                }?pageid=${element.page_id}&pagename=${
                  element.page_name
                }&pagelocation=${element.simple_url}`}
              />
            </PageItemGrid>
          );
        } else {
          result.push(
            <PageList
              key={element.page_id}
              title={element.page_name}
              url={element.simple_url}
              pv={element.pv_cnt}
              time={element.total_duration}
              click={element.element_click}
              link={`/pageAnalytics${
                isPageUXAnalysis
                  ? ux_analysis_list_url
                  : user_flow_analysis_list
              }?pageid=${element.page_id}&pagename=${
                element.page_name
              }&pagelocation=${element.simple_url}`}
            />
          );
        }
      }
    } else {
      result.push(
        <ContentsItemLayout desktop={12} key="dataerror">
          <DataError />
        </ContentsItemLayout>
      );
    }
    return result;
  }, [rebuildData, pageNumber, chartForm]);

  const onChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setPageNumber(page);
    window.scrollTo(0, 0);
  };

  const onChangeSearch = (value: string) => {
    setSearch(value);
  };

  const onClickSearchButton = () => {
    let searchData: PageListTemplateProps['pageListData'] = [];

    if (search.length > 0) {
      switch (searchType) {
        case 'page':
          searchData = pageListData.filter((element) => {
            return element.page_name.includes(search);
          });
          break;
        case 'url':
          searchData = pageListData.filter((element) => {
            return element.simple_url.includes(search);
          });
          break;

        default:
          break;
      }
      setRebuildData(searchData);
    } else {
      setRebuildData(pageListData);
    }
  };

  useEffect(() => {
    setLastPageNumber(Math.ceil(pageListData.length / 20));
    setRebuildData(pageListData);
  }, [pageListData]);

  useEffect(() => {
    setLastPageNumber(Math.ceil(rebuildData.length / 20));
  }, [rebuildData]);

  return (
    <ContentsLayout>
      <Header
        title={headerTitle}
        icon=""
        iconClass="ri-ser-received-line"
        isDatePicker
      />

      <ContentBodyLayout>
        <SearchPageWrapper>
          <SelectBox
            type="page"
            option={searchOptions}
            defaultValue={searchOptions[0]}
            onChange={stateChange}
          />
          <Input
            size="search"
            placeholder="검색어 입력"
            onChange={onChangeSearch}
            onKeyPressEnter={onClickSearchButton}
          />
          <SearchButton
            onClick={() => {
              onClickSearchButton();
            }}
          >
            <span className="ri-search-line"></span>
          </SearchButton>
        </SearchPageWrapper>
        <SortingWrapper>
          <TotalValue>
            <P2>
              <span>Total. </span>
              {rebuildData.length}
            </P2>
          </TotalValue>
          <SortButtonList>
            <SelectBox
              type="page"
              option={PVOptions}
              defaultValue={PVOptions[0]}
              onChange={sortStateChange}
            />
            <Split />
            <ChartFormFilter>
              <FormButton
                $active={chartForm === 'chart' || chartForm === ''}
                onClick={() => {
                  onClickChartForm('chart');
                }}
              >
                <span className="ri-list-check"></span>
              </FormButton>
              <FormButton
                $active={chartForm === 'table'}
                onClick={() => {
                  onClickChartForm('table');
                }}
              >
                <span className="ri-list-check"></span>
              </FormButton>
            </ChartFormFilter>
          </SortButtonList>
        </SortingWrapper>
        {chartForm === 'table' ? (
          <TableHeader>
            <Item>
              <P2>페이지명</P2>
            </Item>
            <Item>
              <P2>URL</P2>
            </Item>
            <Item>
              <P2>페이지뷰</P2>
            </Item>
            <Item>
              <P2>체류시간</P2>
            </Item>
            <Item>
              <P2>유효클릭</P2>
            </Item>
          </TableHeader>
        ) : null}
        {isLoading ? <Spinner /> : printPageList}
        <PaginationWrapper>
          <DefaultPagenation count={lastPageNumber} onChange={onChangePage} />
        </PaginationWrapper>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default PageListTemplate;
