import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../Atoms/Layout/Card/CardHeaderLayout';
import DefaultCardHeading from '../../Molecule/Heading/Card/DefaultCardHeading';
import Spinner from '../../Molecule/Spinner';
import { H3, P2, P3 } from '../../Atoms/Typo';
import DefaultLink from '../../Atoms/Link';
import DataError from '../../Atoms/DataError';

const Component = styled(CardLayout)`
  height: 405px;
`;

const HeaderLayout = styled(CardHeaderLayout)<{ $type?: string }>`
  padding-bottom: 18px;
  border-bottom: 2px solid ${(props) => props.theme.colors.blue};
  ${H3} {
    font-size: 18px;
  }

  ${(props) => {
    switch (props.$type) {
      case 'search':
        return css`
          border-bottom: 2px solid ${props.theme.colors.blue};
        `;
      case 'social':
        return css`
          border-bottom: 2px solid ${props.theme.colors.purple7};
        `;
      case 'recommend':
        return css`
          border-bottom: 2px solid ${props.theme.colors.bluegreen};
        `;

      default:
        return css``;
    }
  }}
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
`;

const Tooltip = styled.div`
  display: none;
  width: 250px;
  /* height: 60px; */
  position: absolute;
  padding: 10px 10px;
  background-color: white;
  box-shadow: 0px 2px 4px #00000042;
  border: 1px solid #a7a7a7;
  border-radius: 25px;
  top: 0px;
  left: 0px;
  align-items: center;
  justify-content: center;
  transform: translate(0%, -100%);
  z-index: 2 !important;
  ${P3} {
    color: #767676;
    word-break: keep-all;
  }
`;

const InfoIcon = styled.span<{ $id?: number }>`
  position: relative;
  font-size: 17px;
  color: #aaaaaa;
  text-align: center;
  margin-left: 2px;
  :hover {
    cursor: pointer;
    ${Tooltip} {
      display: flex;
    }
  }
`;

const TotalCount = styled.div`
  display: flex;
  align-items: baseline;
  ${P3} {
    color: ${(props) => props.theme.colors.grey600};
  }
`;

const BodyLayout = styled(CardBodyLayout)`
  padding-top: 10px;
`;

const RankList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-height: 220px;
`;

const ItemWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: 44px;
  border-bottom: 1px solid #dddddd;
  padding-left: 10px;
`;

const Item = styled.div`
  display: flex;
  span {
    font-size: 15px;
    color: ${(props) => props.theme.colors.grey600};
    margin-left: 8px;
    :hover {
      cursor: pointer;
    }
  }
`;

const Rank = styled(P2)<{ $type?: string }>`
  width: 10px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.blue};
  ${(props) => {
    switch (props.$type) {
      case 'search':
        return css`
          color: ${props.theme.colors.blue};
        `;
      case 'social':
        return css`
          color: ${props.theme.colors.purple7};
        `;
      case 'recommend':
        return css`
          color: #0dae9d;
        `;

      default:
        return css``;
    }
  }}
`;

const Device = styled.div<{ $type: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #2962ff;
  border-radius: 4px;
  width: 60px;
  height: 24px;
  margin-left: 13px;
  margin-right: 10px;
  ${P3} {
    font-size: 15px;
  }

  ${(props) => {
    switch (props.$type) {
      case 'Mobile':
        return css`
          border: 1px solid #f99e32;
        `;
      case 'Tablet':
        return css`
          border: 1px solid #0c9e63;
        `;

      default:
        return css``;
    }
  }}
`;

const InflowName = styled(P2)`
  font-weight: 600;
`;

const InflowUrl = styled(P2)`
  margin-left: 13px;
  max-width: 190px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DetailLink = styled(DefaultLink)`
  width: 100%;
`;

const DetailButton = styled.button`
  display: flex;
  width: 100%;
  height: 45px;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.grey100};
  border: 1px solid ${(props) => props.theme.colors.grey100};
  border-radius: 6px;
  ${P3} {
    font-size: 15px;
    color: ${(props) => props.theme.colors.grey600};
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    font-size: 15px;
    color: ${(props) => props.theme.colors.grey600};
  }
  :hover {
    cursor: pointer;
  }
`;

interface InflowTableCardProps {
  isloading: boolean;
  data?: {
    name?: string;
    device?: string;
    cnt: number;
    rate: number;
  }[];
  cardTitle: string;
  cardType: string;
  description: string;
}

const InflowTableCard = ({
  isloading,
  data,
  cardTitle,
  cardType,
  description,
}: InflowTableCardProps) => {
  const [total, setTotal] = useState(0);
  const [inflowData, setInflowData] = useState<
    {
      rank: number;
      device: string;
      name: string;
      cnt: number;
    }[]
  >([]);
  const [recomData, setRecomData] = useState<
    {
      rank: number;
      url: string;
      cnt: number;
    }[]
  >([]);

  const onClickLink = (url: string) => {
    window.open(url, '_blank', 'noopener, noreferrer');
  };

  const printRankList = () => {
    switch (cardType) {
      case 'recommend':
        return recomData.length > 0 ? (
          <RankList>
            {recomData.map((element) => {
              return (
                <ItemWrap key={element.rank}>
                  <Item>
                    <Rank $type={cardType}>{element.rank}</Rank>
                    <InflowUrl>{element.url}</InflowUrl>
                    <span
                      className="ri-external-link-line"
                      onClick={() => {
                        onClickLink(element.url);
                      }}
                    >
                      
                    </span>
                  </Item>
                  <TotalCount>
                    <P2>{element.cnt.toLocaleString()}</P2>
                    <P3>세션</P3>
                  </TotalCount>
                </ItemWrap>
              );
            })}
          </RankList>
        ) : (
          <DataError />
        );

      default:
        return inflowData.length > 0 ? (
          <RankList>
            {inflowData.map((element) => {
              return (
                <ItemWrap key={element.rank}>
                  <Item>
                    <Rank $type={cardType}>{element.rank}</Rank>
                    <Device $type={element.device}>
                      <P3>{element.device}</P3>
                    </Device>
                    <InflowName>{element.name}</InflowName>
                  </Item>
                  <TotalCount>
                    <P2>{element.cnt.toLocaleString()}</P2>
                    <P3>세션</P3>
                  </TotalCount>
                </ItemWrap>
              );
            })}
          </RankList>
        ) : (
          <DataError />
        );
    }
  };

  useEffect(() => {
    let sum = 0;
    const arrTemp: {
      rank: number;
      device: string;
      name: string;
      cnt: number;
    }[] = [];
    const recomTemp: {
      rank: number;
      url: string;
      cnt: number;
    }[] = [];

    if (data && data.length > 0) {
      data.forEach((element, idx) => {
        sum += element.cnt;
        if (idx < 5 && element.device && element.name) {
          arrTemp.push({
            rank: idx + 1,
            device: element.device,
            name: element.name,
            cnt: element.cnt,
          });
        }
        if (idx < 5 && element.name && cardType === 'recommend') {
          recomTemp.push({
            rank: idx + 1,
            url: element.name,
            cnt: element.cnt,
          });
        }
      });
      setInflowData(arrTemp);
      setRecomData(recomTemp);
      setTotal(sum);
    }
  }, [data]);

  return (
    <Component>
      <HeaderLayout $type={cardType}>
        <TitleWrap>
          <DefaultCardHeading title={cardTitle} />
          <InfoIcon className="ri-question-fill">
            
            <Tooltip>
              <P3>{description}</P3>
            </Tooltip>
          </InfoIcon>
        </TitleWrap>
        <TotalCount>
          <P2>{total.toLocaleString()}</P2>
          <P3>세션</P3>
        </TotalCount>
      </HeaderLayout>
      {isloading ? (
        <Spinner />
      ) : (
        <BodyLayout>
          {printRankList()}
          {data && data.length > 0 ? (
            <DetailLink to={`/userAnalytics/inflowPath/detail?${cardType}`}>
              <DetailButton>
                <P3>자세히</P3>
                <span className="ri-arrow-down-s-line"></span>
              </DetailButton>
            </DetailLink>
          ) : null}
        </BodyLayout>
      )}
    </Component>
  );
};

export default InflowTableCard;
