import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import CardBodyLayout from '../../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../../Atoms/Layout/Card/CardHeaderLayout';
import { H3 } from '../../../Atoms/Typo';
import Filter from '../../../Molecule/Chart/Filter';
import MultiLineChart from '../../../Molecule/Chart/MultiLineChart';
import Spinner from '../../../Molecule/Spinner';
import LegendTitleAllUser from '../../../Molecule/Chart/Legend';
import DefaultLink from '../../../Atoms/Link';

const Component = styled(CardLayout)`
  height: 410px;
  padding: 25px 0 0;
`;

const HeaderLayout = styled(CardHeaderLayout)`
  padding: 0 30px;
  ${H3} {
    font-size: 19px;
  }
`;

const Group = styled.div`
  display: flex;
`;

const CardBody = styled(CardBodyLayout)`
  padding: 0 30px;
`;

const LegendWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 40px;
  background-color: ${(props) => props.theme.colors.grey100};
  border-radius: 0 0 10px 10px;
`;

const LegendItem = styled(LegendTitleAllUser)`
  margin-right: 16px;
`;

interface DashboardMultiChartCardProps {
  legendData: {
    title: string;
    status:
      | 'dashboard-all'
      | 'return'
      | 'new'
      | 'all_future'
      | 'return_future'
      | 'new_future';
  }[];
  Data: {
    user: {
      [key: string]: {
        total?: number;
        old: number;
        new: number;
        is_futrue?: boolean;
        holiday?: boolean;
      };
    };
    session: {
      [key: string]: {
        total?: number;
        old: number;
        new: number;
        is_futrue?: boolean;
        holiday?: boolean;
      };
    };
    pageview: {
      [key: string]: {
        total?: number;
        old: number;
        new: number;
        is_futrue?: boolean;
        holiday?: boolean;
      };
    };
  } | null;
  isLoading: boolean;
  lineLabel: string;
  bar1Label?: string;
  bar2Label?: string;
}

const DashboardMultiChartCard = ({
  legendData,
  Data,
  isLoading,
  lineLabel,
  bar1Label,
  bar2Label,
}: DashboardMultiChartCardProps) => {
  // state
  const [type, setType] = useState(0);
  const [title, setTitle] = useState('사용자 방문 분석');
  const [link, setLink] = useState('/sessionAnalytics/uniqueVisitor');

  const onClickButtonGroup = (id: number) => {
    setType(id);
  };

  const printCardBody = () => {
    if (Data) {
      switch (type) {
        case 0:
          return (
            <CardBody>
              <MultiLineChart
                Data={Data.user}
                selectedTimeSort="day"
                lineLabel={lineLabel}
                bar1Label={bar1Label}
                bar2Label={bar2Label}
                isDashboard
                notWeekly
                xLabels="voda-xLabel"
              />
            </CardBody>
          );

        case 1:
          return (
            <CardBody>
              <MultiLineChart
                Data={Data.session}
                selectedTimeSort="day"
                lineLabel={lineLabel}
                bar1Label={bar1Label}
                bar2Label={bar2Label}
                isDashboard
                notWeekly
                xLabels="voda-xLabel"
              />
            </CardBody>
          );

        case 2:
          return (
            <CardBody>
              <MultiLineChart
                Data={Data.pageview}
                selectedTimeSort="day"
                lineLabel={lineLabel}
                bar1Label={bar1Label}
                bar2Label={bar2Label}
                isDashboard
                notWeekly
                xLabels="voda-xLabel"
              />
            </CardBody>
          );

        default:
          return null;
      }
    }
    return null;
  };

  useEffect(() => {
    switch (type) {
      case 0:
        setTitle('사용자 방문 분석');
        setLink('/sessionAnalytics/uniqueVisitor');
        break;
      case 1:
        setTitle('방문 횟수 분석');
        setLink('/sessionAnalytics/session');
        break;
      case 2:
        setTitle('페이지뷰 수 분석');
        setLink('/sessionAnalytics/pageView');
        break;

      default:
        break;
    }
  }, [type]);

  return (
    <Component>
      <HeaderLayout>
        <DefaultLink to={link}>
          <H3>
            {title}
            <span className="ri-arrow-down-s-line"></span>
          </H3>
        </DefaultLink>
        <Group>
          <Filter
            dashboard
            buttonGroup={[
              {
                id: 0,
                timeSort: 'day',
                title: '사용자 수',
              },
              {
                id: 1,
                timeSort: 'week',
                title: '방문 횟수',
              },
              {
                id: 2,
                timeSort: 'month',
                title: '페이지뷰 수',
              },
            ]}
            onClick={onClickButtonGroup}
          />
        </Group>
      </HeaderLayout>
      {isLoading ? <Spinner /> : printCardBody()}
      <LegendWrapper>
        {legendData.map((element) => {
          return (
            <LegendItem
              key={element.title}
              title={element.title}
              status={element.status}
            />
          );
        })}
      </LegendWrapper>
    </Component>
  );
};

export default DashboardMultiChartCard;
