import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import CardBodyLayout from '../../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../../Atoms/Layout/Card/CardHeaderLayout';
import { H3, P3 } from '../../../Atoms/Typo';
import Spinner from '../../../Molecule/Spinner';
import { GetUserEnvironmentStatisticsResponseType } from '../../../../api/Dashboard/type';
import DashboardDonut from '../../../Molecule/Chart/DonutChart/DashboardDonut';
import DataError from '../../../Atoms/DataError';
import DefaultLink from '../../../Atoms/Link';

const Component = styled(CardLayout)`
  height: 280px;
  padding: 25px;
`;

const HeaderLayout = styled(CardHeaderLayout)`
  ${H3} {
    font-size: 19px;
  }
`;

const BodyLayout = styled(CardBodyLayout)`
  flex-direction: row;
  height: 100%;
`;

const EnvTitleWrap = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const EnvTitle = styled.div<{ $active: number }>`
  display: flex;
  margin-bottom: 13px;
  width: fit-content;
  :last-child {
    margin-bottom: 0;
  }
  ${P3} {
    display: flex;
    font-size: 15px;
    font-weight: 600;
    color: #bcbcbc;
  }
  :hover {
    cursor: pointer;
  }

  span {
    color: #7851e7;
    font-size: 15px;
    display: none;
  }

  ${(props) => {
    return css`
      :nth-child(${props.$active + 1}) {
        border-bottom: 1px solid #7851e7;
        ${P3} {
          color: #7851e7;
        }
        span {
          display: block;
        }
      }
    `;
  }}
`;

const DonutWrap = styled.div`
  width: 65%;
`;

interface DashboardEnvCardProps {
  loading?: boolean;
  data: GetUserEnvironmentStatisticsResponseType['payload'];
}

const DashboardEnvCard = ({ loading, data }: DashboardEnvCardProps) => {
  // state
  const [active, setActive] = useState(0);
  const category = [
    {
      id: 0,
      name: '디바이스',
    },
    {
      id: 1,
      name: '해상도',
    },
    {
      id: 2,
      name: '브라우저',
    },
    {
      id: 3,
      name: '운영체제',
    },
  ];

  const onClickCategory = (id: number) => {
    setActive(id);
  };

  const printChart = () => {
    switch (active) {
      case 0:
        return <DashboardDonut data={Object.values(data[0])} />;
      case 1:
        return <DashboardDonut data={Object.values(data[1])} />;
      case 2:
        return <DashboardDonut data={Object.values(data[2])} />;
      case 3:
        return <DashboardDonut data={Object.values(data[3])} />;

      default:
        return null;
    }
  };

  const printBody = () => {
    if (data.length > 0) {
      return (
        <BodyLayout>
          <EnvTitleWrap>
            {category.map((element) => {
              return (
                <EnvTitle
                  key={element.name}
                  onClick={() => {
                    onClickCategory(element.id);
                  }}
                  $active={active}
                >
                  <P3>{element.name}</P3>
                </EnvTitle>
              );
            })}
          </EnvTitleWrap>
          <DonutWrap>{printChart()}</DonutWrap>
        </BodyLayout>
      );
    }
    return <DataError />;
  };

  return (
    <Component>
      <HeaderLayout>
        <DefaultLink to="/userAnalytics/environment">
          <H3>
            사용자 환경<span className="ri-arrow-down-s-line"></span>
          </H3>
        </DefaultLink>
      </HeaderLayout>
      {loading ? <Spinner /> : printBody()}
    </Component>
  );
};

export default DashboardEnvCard;
