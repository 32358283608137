import React, { ReactNodeArray, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { P2, P3, P4 } from '../../Atoms/Typo';
import {
  PathPlotStartFlow,
  PathPlotpayload,
} from '../../../api/pageAnalytics/pathPlot/type';
import PathPlotInflowItem from '../PathPlotInflowItem';
import { ReactComponent as IconDropRateSVG } from '../../../assets/icons/icon-drop-rate.svg';

const Component = styled.div<{ $height?: number }>`
  width: 25%;
  min-width: 25%;
  height: 100%;
  padding: 30px 40px;
  /* :nth-child(2n) {
    background-color: #f5f5f5;
  } */
  ${(props) => {
    if (props.$height) {
      return css`
        height: ${props.$height + 40}px;
      `;
    }
    return css``;
  }}
  :hover {
    .down-item {
      display: block;
    }
  }
`;

const Title = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
  ${P2} {
    font-weight: 600;
    margin-right: 10px;
  }
`;

const TotalSession = styled(P3)`
  font-size: 15px;
  color: #767676;
`;

const ItemWrapper = styled.div<{ $height?: number }>`
  ${(props) => {
    if (props.$height) {
      return css`
        height: ${props.$height}px;
      `;
    }
    return css``;
  }}
`;

const DownButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  padding-top: 15px;
`;

const DownButton = styled.button<{ $disabled?: boolean }>`
  display: none;
  width: 35px;
  height: 35px;
  border-radius: 20px;
  border: 1px solid #dedede;
  background-color: #ffffff;
  span {
    font-size: 20px;
    color: #222222;
  }
  cursor: pointer;

  ${(props) => {
    if (props.$disabled) {
      return css`
        background-color: ${props.theme.colors.grey300};
        cursor: not-allowed;
        span {
          color: ${props.theme.colors.grey600};
        }
      `;
    }
    return css``;
  }}
`;

const HoverImageCard = styled.div<{ $show?: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 210px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 6px;
  display: none;
  z-index: 5;
  padding: 20px;
  ${(props) => {
    if (props.$show) {
      return css`
        display: block;
      `;
    }
    return css``;
  }}
  :hover {
    cursor: pointer;
  }
`;

const HoverCardTitle = styled.div`
  width: 100%;
  overflow: hidden;
  P {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const HoverURLWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 40px;
  justify-content: space-between;
  span {
    font-size: 15px;
    color: #ffffff;
  }
`;

const HoverURL = styled.div`
  overflow: hidden;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const HoverText = styled(P3)`
  font-size: 15px;
  color: #ffffff;
`;

const HoverCountWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${P3} {
    font-size: 15px;
    font-weight: 600;
    color: #ffffff;
  }
`;

const Split = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ffffff;
  margin-bottom: 10px;
`;

const ImageCard = styled.div<{ $active?: number; $hover?: number }>`
  position: relative;
  width: 100%;
  height: 210px;
  border: 1px solid #d7dbe6;
  background-color: white;
  border-radius: 6px;
  margin-bottom: 20px;
  :last-child {
    margin-bottom: 0;
  }

  ${(props) => {
    if (props.$hover !== undefined) {
      return css`
        :nth-child(${props.$hover + 1}) {
          box-shadow: 0px 3px 6px #2962ff4d;
          border: 1px solid #2962ff;
        }
      `;
    }
    return css``;
  }}

  ${(props) => {
    if (props.$active !== undefined) {
      return css`
        :nth-child(${props.$active + 1}) {
          box-shadow: 0px 3px 6px #2962ff4d;
          border: 3px solid #2962ff;
        }
      `;
    }
    return css``;
  }}

  .voda_inflow_line + .voda_conversion_line {
    path {
      stroke-width: 25px;
    }
  }
  .voda_inflow_line + .voda_conversion_line + .voda_conversion_line {
    path {
      stroke-width: 10px;
    }
  }
`;

const InflowRate = styled.div<{ $active?: boolean }>`
  position: absolute;
  background-color: white;
  border: 1px solid #78909c;
  display: flex;
  align-items: center;
  left: -5px;
  top: 35%;
  transform: translate(-100%, 0%);
  z-index: 5;
  padding: 3px 5px;
  border-radius: 20px;
  opacity: 0.7;
  ${P4} {
    font-size: 13px;
    font-weight: 500;
    color: #78909c;
  }

  ${(props) => {
    if (props.$active) {
      return css`
        border: none;
        box-shadow: 0px 3px 6px #2962ff4d;
        opacity: 1;
        ${P4} {
          color: #2962ff;
          font-weight: 500;
        }
      `;
    }
    return css``;
  }}
`;

const ItemCardBody = styled.div`
  padding: 13px 10px;
`;

const ImageCardTitle = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  P {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ThumbnailImage = styled.img`
  width: 100%;
  height: 120px;
  object-fit: cover;
  object-position: 50% 0%;
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
  backface-visibility: hidden;
  margin: 5px 0px 10px;
  position: relative;
`;

const CardCountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const CardSessionCount = styled.div`
  display: flex;
  align-items: baseline;
  span {
    font-size: 15px;
    color: #222222;
  }
  ${P3} {
    font-size: 15px;
    font-weight: 600;
  }
`;

const DropRate = styled(P3)`
  font-size: 15px;
  font-weight: 600;
  color: #ff3b56;
`;

const IconDropRate = styled(IconDropRateSVG)`
  position: absolute;
  right: -31px;
  top: 5px;
  :hover {
    cursor: default;
  }
  pointer-events: none;
`;

const InflowLineWrapper = styled.svg<{
  $width: number;
  $height: number;
  $isUpper: boolean;
}>`
  position: absolute;
  left: 0px;
  transform: translate(-100%);
  overflow: visible !important;
  pointer-events: none;

  :first-child {
    path {
      stroke-width: 12px;
    }
  }
  :nth-child(2) {
    path {
      stroke-width: 6px;
    }
  }

  ${(props) => {
    if (props.$height < 0) {
      return css`
        width: ${props.$width}px;
        height: ${-props.$height}px;
      `;
    }
    return css`
      width: ${props.$width}px;
      height: ${props.$height}px;
    `;
  }}
  ${(props) => {
    if (props.$isUpper) {
      return css`
        bottom: 50%;
      `;
    }
    return css`
      top: 50%;
    `;
  }}
`;

const FlowLineWrapper = styled.svg<{
  $width: number;
  $height: number;
  $isUpper: boolean;
  $index: number;
  $active: boolean;
}>`
  position: absolute;
  transform: translate(100%);
  overflow: visible !important;
  pointer-events: none;
  :hover {
    cursor: default;
  }

  ${(props) => {
    if (props.$height < 0) {
      return css`
        width: ${props.$width}px;
        height: ${-props.$height}px;
        left: ${-(props.$width * 2 + 3)}px;
      `;
    }
    if (props.$height === 0) {
      return css`
        width: ${props.$width}px;
        height: 1px;
        left: ${-(props.$width * 2 + 3)}px;
      `;
    }
    return css`
      width: ${props.$width}px;
      height: ${props.$height}px;
      left: ${-(props.$width * 2 + 3)}px;
    `;
  }}
  ${(props) => {
    if (props.$index === 0) {
      return css`
        path {
          stroke-width: 25px;
        }
      `;
    }
    if (props.$index === 1) {
      return css`
        path {
          stroke-width: 10px;
        }
      `;
    }
    return css``;
  }}
  ${(props) => {
    if (props.$active) {
      return css`
        path {
          stroke: #2962ff;
          opacity: 1;
        }
        z-index: 2;
      `;
    }
    return css``;
  }}
  ${(props) => {
    if (props.$isUpper) {
      return css`
        top: 50%;
      `;
    }
    return css`
      bottom: 50%;
    `;
  }}
`;

export interface PathPlotGridState {
  lineInfo: {
    width: number;
    height: number;
    isUpper: boolean;
  }[];
  itemInfo: {
    element_x: number;
    element_y: number;
    element_width: number;
    element_height: number;
  };
}

interface PathPlotGridProps {
  title: string;
  type: string;
  data?:
    | PathPlotpayload['start_flow']
    | PathPlotStartFlow['inflow']
    | PathPlotStartFlow['children_path'];
  childrenData?: PathPlotStartFlow['children_path'];
  inflowData?: PathPlotStartFlow['inflow'];
  flowClassName?: string;
  firstFlowID?: number;
  onClickStartItem?: (id: number, url: string, count: number) => void;
  onClickChildren?: (
    url: string,
    children: string[],
    id: number,
    seq: string,
    through_count: number,
    clickInfo: PathPlotGridState['itemInfo']
  ) => void;
}

const PathPlotGrid = ({
  title,
  type,
  data,
  childrenData,
  inflowData,
  flowClassName,
  firstFlowID,
  onClickStartItem,
  onClickChildren,
}: PathPlotGridProps) => {
  // state
  const [itemIndex, setItemIndex] = useState(3);
  const [arr, setArr] = useState<ReactNodeArray>([]);
  const [startIndex, setStartIndex] = useState(0);
  const [isDisable, setDisable] = useState(false);
  // line
  const [inflowLineInfo, setInflowLineInfo] = useState<
    PathPlotGridState['lineInfo']
  >([]);
  // component height
  const [componentHeight, setComponentHeight] = useState(0);

  const componentEl = useRef<HTMLDivElement | null>(null);
  const startItemEl = useRef<HTMLDivElement | null>(null);
  const flowItemEl = useRef<HTMLDivElement | null>(null);
  const wrapperEl = useRef<HTMLDivElement | null>(null);
  const inflowLineEl = useRef<SVGSVGElement | null>(null);

  // 흐름 grid active ID
  const [activeFlowID, setActiveFlowID] = useState<number | undefined>(0);
  const [sumCount, setSumCount] = useState(0);
  const [hoverFlowID, setHoverFlowID] = useState<number | undefined>();

  const [flowLineInfo, setFlowLineInfo] = useState<
    PathPlotGridState['lineInfo']
  >([]);

  const onClickLink = (url: string) => {
    window.open(url, '_blank', 'noopener, noreferrer');
  };

  // hover
  const childrenHoverEnter = (index: number) => {
    setHoverFlowID(index);
  };

  const childrenHoverLeave = () => {
    setHoverFlowID(undefined);
  };

  const onClickDownButton = () => {
    setItemIndex(itemIndex + 1);
  };

  // 생각중 / 선택한 카드아이템 info
  const prevSelectImageInfo = (childId: number, seq: string) => {
    const imageInfo: PathPlotGridState['itemInfo'] = {
      element_x: 0,
      element_y: 0,
      element_width: 0,
      element_height: 0,
    };
    const prevFlowItemArr = document.getElementsByClassName(`flow${seq}`);

    if (prevFlowItemArr) {
      const element = prevFlowItemArr.item(childId);
      if (element) {
        // 선택된 start grid imageCard의 x, y 정보
        imageInfo.element_x =
          window.pageXOffset + element.getBoundingClientRect().left;
        imageInfo.element_y =
          window.pageYOffset + element.getBoundingClientRect().top;
        imageInfo.element_width = (element as HTMLElement).offsetWidth;
        imageInfo.element_height = (element as HTMLElement).offsetHeight;
      }
    }
    return imageInfo;
  };

  // 시작 Grid의 ImageItem Click
  const clickImageItem = (id: number, url: string, count: number) => {
    setStartIndex(id);
    if (onClickStartItem) {
      onClickStartItem(id, url, count);
    }
  };

  // 흐름 Grid의 ImageItem Click
  const clickFlowImage = (
    url: string,
    children_path: string[],
    id: number,
    seq: string,
    through_count: number
  ) => {
    const info = prevSelectImageInfo(id, seq);
    if (onClickChildren) {
      onClickChildren(url, children_path, id, seq, through_count, info);
    }
    setActiveFlowID(id);
  };

  // svg 클릭 이벤트 방지
  const handleSvgClick = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    event.stopPropagation();
  };

  const generateInflowLine = () => {
    const lineData: PathPlotGridState['lineInfo'] = [];

    const imageInfo = {
      element_x: 0,
      element_y: 0,
      element_width: 0,
      element_height: 0,
    };

    if (startItemEl) {
      const { current } = startItemEl;
      if (current) {
        // 선택된 start grid imageCard의 x, y 정보
        imageInfo.element_x =
          window.pageXOffset + current.getBoundingClientRect().left;
        imageInfo.element_y =
          window.pageYOffset + current.getBoundingClientRect().top;
        imageInfo.element_width = current.offsetWidth;
        imageInfo.element_height = current.offsetHeight;
      }
    }

    const inflowItemCardArr = document.getElementsByClassName('inflowItemCard');
    if (inflowItemCardArr) {
      for (let index = 0; index < inflowItemCardArr.length; index += 1) {
        const element = inflowItemCardArr.item(index);
        if (element) {
          const element_x =
            window.pageXOffset + element.getBoundingClientRect().left;
          const element_y =
            window.pageYOffset + element.getBoundingClientRect().top;
          const element_width = (element as HTMLElement).offsetWidth;
          const element_height = (element as HTMLElement).offsetHeight;
          const startX = element_x + element_width;
          const startY = element_y + element_height / 2;
          const height =
            imageInfo.element_y + imageInfo.element_height / 2 - startY;

          lineData.push({
            width: imageInfo.element_x - startX,
            height,
            isUpper: height > 0,
          });
        }
      }
      if (inflowData && inflowData.length > 0) {
        setInflowLineInfo(lineData.slice(0, inflowData.length));
      } else setInflowLineInfo(lineData);
    }
  };

  const printInflowLine = () => {
    const result: ReactNodeArray = [];

    if (inflowLineInfo.length > 0) {
      inflowLineInfo.forEach((element) => {
        result.push(
          <InflowLineWrapper
            className="voda_inflow_line"
            $width={element.width}
            $height={element.height}
            $isUpper={element.isUpper}
            ref={inflowLineEl}
            key={JSON.stringify(element)}
            onClick={handleSvgClick}
          >
            {/* value 배치 테스트
            <Test>
              <LineValue>tt</LineValue>
            </Test> */}
            {element.isUpper ? (
              <path
                d={`M0 0 C ${element.width / 2} 0, ${element.width / 2} ${
                  element.height
                }, ${element.width} ${element.height}`}
                fill="none"
                stroke="#78909C"
                strokeWidth="2px"
                opacity="0.3"
              />
            ) : (
              <path
                d={`M0 ${-element.height} 
              C ${element.width / 2} ${-element.height}, ${
                  element.width / 2
                } 0 ${element.width} 0`}
                fill="none"
                stroke="#78909C"
                strokeWidth="2px"
                opacity="0.3"
              />
            )}
          </InflowLineWrapper>
        );
      });
    }

    if (result.length > 0) {
      return result;
    }
    return null;
  };

  const generateFlowLine = () => {
    const lineData: PathPlotGridState['lineInfo'] = [];
    let lineWidth = 0;
    let className = '';
    if (flowClassName) {
      className = flowClassName;
    }
    const imageInfo = {
      element_x: 0,
      element_y: 0,
      element_width: 0,
      element_height: 0,
    };
    const prevInfo = document.getElementsByClassName(
      `flow${Number(className.slice(-1)) - 1}-active`
    );
    if (prevInfo.item(0)) {
      imageInfo.element_x =
        window.pageXOffset +
        (prevInfo.item(0) as HTMLElement).getBoundingClientRect().left;
      imageInfo.element_y =
        window.pageYOffset +
        (prevInfo.item(0) as HTMLElement).getBoundingClientRect().top;
      imageInfo.element_width = (prevInfo.item(0) as HTMLElement).offsetWidth;
      imageInfo.element_height = (prevInfo.item(0) as HTMLElement).offsetHeight;
    }

    const conversionCardArr = document.getElementsByClassName(className);
    const lineArr = document.getElementsByClassName('voda_inflow_line');
    if (lineArr) {
      const element = lineArr.item(0);
      if (element) {
        lineWidth = element.getBoundingClientRect().width;
      }
    }

    if (className === 'flow1') {
      const firstFlowItemArr = document.getElementsByClassName(className);
      const startFlowItemArr = document.getElementsByClassName('flow0');
      if (firstFlowItemArr.item(0) && firstFlowItemArr.item(0)) {
        const flowElement = firstFlowItemArr.item(0);
        const startElement = startFlowItemArr.item(0);
        const start_x =
          window.pageXOffset +
          (startElement as HTMLElement).getBoundingClientRect().left;
        const startItem_width = (startElement as HTMLElement).offsetWidth;
        const flow_x =
          window.pageXOffset +
          (flowElement as HTMLElement).getBoundingClientRect().left;
        lineWidth = flow_x - (start_x + startItem_width);
      }
    }

    if (conversionCardArr && imageInfo.element_width > 0) {
      for (let index = 0; index < conversionCardArr.length; index += 1) {
        const element = conversionCardArr.item(index);
        if (element) {
          const element_y =
            window.scrollY + element.getBoundingClientRect().top;
          const element_height = (element as HTMLElement).offsetHeight;

          const endY = element_y + element_height / 2;
          const height =
            imageInfo.element_y + imageInfo.element_height / 2 - endY;

          lineData.push({
            width: lineWidth,
            height,
            isUpper: height > 0,
          });
        }
      }
      setFlowLineInfo(lineData);
    }
  };

  const printFlowLine = (index: number, seq: string, active?: number) => {
    const result: ReactNodeArray = [];

    if (flowLineInfo.length > 0) {
      flowLineInfo.forEach((element) => {
        result.push(
          <FlowLineWrapper
            className="voda_flow_conversion_line"
            $width={element.width}
            $height={element.height}
            $isUpper={element.isUpper}
            $active={active === index}
            $index={index}
            key={JSON.stringify(element)}
            onClick={handleSvgClick}
          >
            {element.isUpper ? (
              <path
                d={`M0 ${element.height} 
                C ${element.width / 2} ${element.height}, ${
                  element.width / 2
                } 0, 
                ${element.width} 0`}
                fill="none"
                stroke="#78909C"
                strokeWidth="2px"
                opacity="0.3"
              />
            ) : (
              <path
                d={`M0 0 
                C ${element.width / 2} 0, ${
                  element.width / 2
                } ${-element.height}
                ${element.width} ${-element.height}`}
                fill="none"
                stroke="#78909C"
                strokeWidth="2px"
                opacity="0.3"
              />
            )}
          </FlowLineWrapper>
        );
      });
    }

    if (result.length > 0) {
      return result[index];
    }
    return null;
  };

  useEffect(() => {
    generateInflowLine();
  }, [startIndex, startItemEl.current, inflowData]);

  useEffect(() => {
    generateFlowLine();
  }, [activeFlowID, flowItemEl.current, itemIndex, data, inflowLineEl.current]);

  const rebuildData = () => {
    const startArr: ReactNodeArray = [];
    let countTemp = 0;

    if (data) {
      switch (type) {
        case 'inflow':
          data.forEach((element) => {
            if ('name' in element) {
              countTemp += element.cnt;
              startArr.push(
                <PathPlotInflowItem
                  key={element.name + element.device}
                  data={element}
                />
              );
            }
          });
          setSumCount(countTemp);
          break;

        case 'start':
          setDisable(data.length === itemIndex);

          data.forEach((element, idx) => {
            if ('url' in element) {
              countTemp += element.session_count;
              if (idx < itemIndex) {
                const urlTemp = new URL(element.url);
                const urlPath = urlTemp.pathname + urlTemp.search;
                if (idx === startIndex) {
                  startArr.push(
                    <ImageCard
                      key={element.url}
                      ref={startItemEl}
                      className="flow0 flow0-active"
                      $active={startIndex}
                      $hover={hoverFlowID}
                      onClick={() => {
                        clickImageItem(idx, element.url, element.session_count);
                      }}
                    >
                      {printInflowLine()}
                      <HoverImageCard
                        $show={hoverFlowID === idx}
                        onMouseLeave={childrenHoverLeave}
                      >
                        <HoverCardTitle>
                          <HoverText>{element.title}</HoverText>
                        </HoverCardTitle>
                        <HoverURLWrapper>
                          <HoverURL>
                            <HoverText>{urlPath}</HoverText>
                          </HoverURL>
                          <span
                            className="ri-external-link-line"
                            onClick={(event) => {
                              event.stopPropagation();
                              onClickLink(element.url);
                            }}
                          >
                            
                          </span>
                        </HoverURLWrapper>
                        <HoverCountWrap>
                          <HoverText>세션</HoverText>
                          <P3>{element.session_count.toLocaleString()}</P3>
                        </HoverCountWrap>
                        <Split />
                        <HoverCountWrap>
                          <HoverText>경유</HoverText>
                          <HoverText>
                            {element.through_count.toLocaleString()} (
                            {element.through_rate}%)
                          </HoverText>
                        </HoverCountWrap>
                        <HoverCountWrap>
                          <HoverText>이탈률</HoverText>
                          <HoverText>
                            {element.drop_count.toLocaleString()} (
                            {element.drop_rate}%)
                          </HoverText>
                        </HoverCountWrap>
                      </HoverImageCard>
                      <ItemCardBody
                        onMouseOver={() => {
                          childrenHoverEnter(idx);
                        }}
                      >
                        <ImageCardTitle>
                          <P3>{urlPath}</P3>
                        </ImageCardTitle>
                        <ThumbnailImage
                          src={`https://voda-media.nerdfactory.ai/${localStorage.getItem(
                            'voda_tenant'
                          )}/auto/${element.page_id}/${encodeURIComponent(
                            'page_screenshot'
                          )}`}
                        />
                        <CardCountWrapper>
                          <CardSessionCount>
                            <span className="ri-user-received-line"> </span>
                            <P3>{element.session_count.toLocaleString()}</P3>
                          </CardSessionCount>
                          <DropRate>{element.drop_rate}%</DropRate>
                          <IconDropRate onClick={handleSvgClick} />
                        </CardCountWrapper>
                      </ItemCardBody>
                    </ImageCard>
                  );
                } else {
                  startArr.push(
                    <ImageCard
                      key={element.url}
                      className="flow0"
                      $active={startIndex}
                      $hover={hoverFlowID}
                      onClick={() => {
                        clickImageItem(idx, element.url, element.session_count);
                      }}
                    >
                      <HoverImageCard
                        $show={hoverFlowID === idx}
                        onMouseLeave={childrenHoverLeave}
                      >
                        <HoverCardTitle>
                          <HoverText>{element.title}</HoverText>
                        </HoverCardTitle>
                        <HoverURLWrapper>
                          <HoverURL>
                            <HoverText>{urlPath}</HoverText>
                          </HoverURL>
                          <span
                            className="ri-external-link-line"
                            onClick={(event) => {
                              event.stopPropagation();
                              onClickLink(element.url);
                            }}
                          >
                            
                          </span>
                        </HoverURLWrapper>
                        <HoverCountWrap>
                          <HoverText>세션</HoverText>
                          <P3>{element.session_count.toLocaleString()}</P3>
                        </HoverCountWrap>
                        <Split />
                        <HoverCountWrap>
                          <HoverText>경유</HoverText>
                          <HoverText>
                            {element.through_count.toLocaleString()} (
                            {element.through_rate}%)
                          </HoverText>
                        </HoverCountWrap>
                        <HoverCountWrap>
                          <HoverText>이탈률</HoverText>
                          <HoverText>
                            {element.drop_count.toLocaleString()} (
                            {element.drop_rate}%)
                          </HoverText>
                        </HoverCountWrap>
                      </HoverImageCard>
                      <ItemCardBody
                        onMouseOver={() => {
                          childrenHoverEnter(idx);
                        }}
                      >
                        <ImageCardTitle>
                          <P3>{urlPath}</P3>
                        </ImageCardTitle>
                        <ThumbnailImage
                          src={`https://voda-media.nerdfactory.ai/${localStorage.getItem(
                            'voda_tenant'
                          )}/auto/${element.page_id}/${encodeURIComponent(
                            'page_screenshot'
                          )}`}
                        />
                        <CardCountWrapper>
                          <CardSessionCount>
                            <span className="ri-user-received-line"> </span>
                            <P3>{element.session_count.toLocaleString()}</P3>
                          </CardSessionCount>
                          <DropRate>{element.drop_rate}%</DropRate>
                          <IconDropRate onClick={handleSvgClick} />
                        </CardCountWrapper>
                      </ItemCardBody>
                    </ImageCard>
                  );
                }
              }
            }
          });
          setSumCount(countTemp);
          break;

        default:
          break;
      }
    }

    if (childrenData) {
      setDisable(childrenData.length === itemIndex);
      if (childrenData.length <= 3) {
        setDisable(true);
      }

      childrenData.forEach((element, idx) => {
        countTemp += element.session_count;
        if (idx < itemIndex) {
          const urlTemp = new URL(element.url);
          const urlPath = urlTemp.pathname + urlTemp.search;
          startArr.push(
            <ImageCard
              key={element.url}
              ref={flowItemEl}
              className={
                activeFlowID === idx
                  ? `${flowClassName} ${flowClassName}-active`
                  : flowClassName
              }
              $active={activeFlowID}
              $hover={hoverFlowID}
              onClick={() => {
                clickFlowImage(
                  element.url,
                  element.children_path,
                  idx,
                  element.seq,
                  element.through_count
                );
              }}
            >
              <InflowRate $active={activeFlowID === idx}>
                <P4>{element.inflow_rate}%</P4>
              </InflowRate>
              {printFlowLine(idx, element.seq, activeFlowID)}
              <HoverImageCard
                $show={hoverFlowID === idx}
                onMouseLeave={childrenHoverLeave}
              >
                <HoverCardTitle>
                  <HoverText>{element.title}</HoverText>
                </HoverCardTitle>
                <HoverURLWrapper>
                  <HoverURL>
                    <HoverText>{urlPath}</HoverText>
                  </HoverURL>
                  <span
                    className="ri-external-link-line"
                    onClick={(event) => {
                      event.stopPropagation();
                      onClickLink(element.url);
                    }}
                  >
                    
                  </span>
                </HoverURLWrapper>
                <HoverCountWrap>
                  <HoverText>세션</HoverText>
                  <P3>{element.session_count.toLocaleString()}</P3>
                </HoverCountWrap>
                <Split />
                <HoverCountWrap>
                  <HoverText>경유</HoverText>
                  <HoverText>
                    {element.through_count.toLocaleString()} (
                    {element.through_rate}%)
                  </HoverText>
                </HoverCountWrap>
                <HoverCountWrap>
                  <HoverText>이탈률</HoverText>
                  <HoverText>
                    {element.drop_count.toLocaleString()} ({element.drop_rate}
                    %)
                  </HoverText>
                </HoverCountWrap>
              </HoverImageCard>
              <ItemCardBody
                onMouseOver={() => {
                  childrenHoverEnter(idx);
                }}
              >
                <ImageCardTitle>
                  <P3>{urlPath}</P3>
                </ImageCardTitle>
                <ThumbnailImage
                  src={`https://voda-media.nerdfactory.ai/${localStorage.getItem(
                    'voda_tenant'
                  )}/auto/${element.page_id}/${encodeURIComponent(
                    'page_screenshot'
                  )}`}
                />
                <CardCountWrapper>
                  <CardSessionCount>
                    <span className="ri-user-received-line"> </span>
                    <P3>{element.session_count.toLocaleString()}</P3>
                  </CardSessionCount>
                  <DropRate>{element.drop_rate}%</DropRate>
                  <IconDropRate onClick={handleSvgClick} />
                </CardCountWrapper>
              </ItemCardBody>
            </ImageCard>
          );
        }
      });
      setSumCount(countTemp);
    }

    setArr(startArr);
  };

  useEffect(() => {
    rebuildData();
  }, [
    data,
    childrenData,
    startIndex,
    startItemEl.current,
    activeFlowID,
    flowItemEl.current,
    hoverFlowID,
    firstFlowID,
    itemIndex,
    inflowLineInfo,
    flowLineInfo,
  ]);

  useEffect(() => {
    const { current } = componentEl;
    if (current) {
      setComponentHeight(current.scrollHeight);
    }
  }, [componentEl.current]);

  useEffect(() => {
    const { current } = wrapperEl;
    if (current) {
      setComponentHeight(current.scrollHeight + 30);
    }
  }, [wrapperEl.current?.scrollHeight]);

  return (
    <Component ref={componentEl} $height={componentHeight}>
      <Title>
        <P2>{title}</P2>
        <TotalSession>
          <span className="ri-user-received-line"> </span>
          {sumCount.toLocaleString()}
        </TotalSession>
      </Title>
      <ItemWrapper ref={wrapperEl}>
        {arr}
        <DownButtonWrapper>
          <DownButton
            className="down-item"
            onClick={onClickDownButton}
            disabled={isDisable}
            $disabled={isDisable}
          >
            <span className="ri-arrow-down-line"></span>
          </DownButton>
        </DownButtonWrapper>
      </ItemWrapper>
    </Component>
  );
};

export default PathPlotGrid;
