import { takeLatest } from 'redux-saga/effects';
import createRequestSaga from '../createRequestSaga';
import {
  apiGetTotalUserStatistics,
  apiGetPvPerSessionStatistics,
  apiGetTotalSessionStatistics,
  apiGetUsageMeanStatistics,
  apiGetUserVisitStatistics,
  apiGetHourlyUserStatistics,
  apiGetInflowKeywordStatistics,
  apiGetPopularPageStatistics,
  apiGetUserEnvironmentStatistics,
  apiGetInflowPathStatistics,
} from '../../api/Dashboard/api';
import {
  GET_TOTAL_USER_STATISTICS,
  GET_PV_PER_SESSION_STATISTICS,
  GET_TOTAL_SESSION_STATISTICS,
  GET_USAGE_MEAN_STATISTICS,
  GET_USER_VISIT_STATISTICS,
  GET_HOURLY_USER_STATISTIC,
  GET_INFLOW_KEYWORD_STATISTICS,
  GET_POPULAR_PAGE_STATISTICS,
  GET_USER_ENVIRONMENT_STATISTICS,
  GET_INFLOW_PATH_STATISTICS,
  getTotalUserStatisticsAsync,
  getTotalSessionStatisticsAsync,
  getPvPerSessionAsync,
  getUsageMeanStatisticsAsync,
  getUserVisitStatisticsAsync,
  getHourlyUserStatisticsAsync,
  getInflowKeywordStatisticsAsync,
  getPopularPageStatisticsAsync,
  getUserEnvironmentStatisticsAsync,
  getInflowPathStatisticsAsync,
} from './actions';

const getTotalUserStatisticsSaga = createRequestSaga(
  getTotalUserStatisticsAsync,
  apiGetTotalUserStatistics
);

const getTotalSessionStatisticsSaga = createRequestSaga(
  getTotalSessionStatisticsAsync,
  apiGetTotalSessionStatistics
);

const getPvPerSessionSaga = createRequestSaga(
  getPvPerSessionAsync,
  apiGetPvPerSessionStatistics
);

const getUsageMeanStatisticsSaga = createRequestSaga(
  getUsageMeanStatisticsAsync,
  apiGetUsageMeanStatistics
);

const getUserVisitStatisticsSaga = createRequestSaga(
  getUserVisitStatisticsAsync,
  apiGetUserVisitStatistics
);

const getHourlyUserStatisticsSaga = createRequestSaga(
  getHourlyUserStatisticsAsync,
  apiGetHourlyUserStatistics
);

const getInflowKeywordStatisticsSaga = createRequestSaga(
  getInflowKeywordStatisticsAsync,
  apiGetInflowKeywordStatistics
);

const getPopularPageStatisticsSaga = createRequestSaga(
  getPopularPageStatisticsAsync,
  apiGetPopularPageStatistics
);

const getUserEnvironmentStatisticsSaga = createRequestSaga(
  getUserEnvironmentStatisticsAsync,
  apiGetUserEnvironmentStatistics
);

const getInflowPathStatisticsSaga = createRequestSaga(
  getInflowPathStatisticsAsync,
  apiGetInflowPathStatistics
);

export function* dashboardSaga() {
  yield takeLatest(GET_TOTAL_USER_STATISTICS, getTotalUserStatisticsSaga);
  yield takeLatest(GET_TOTAL_SESSION_STATISTICS, getTotalSessionStatisticsSaga);
  yield takeLatest(GET_PV_PER_SESSION_STATISTICS, getPvPerSessionSaga);
  yield takeLatest(GET_USAGE_MEAN_STATISTICS, getUsageMeanStatisticsSaga);
  yield takeLatest(GET_USER_VISIT_STATISTICS, getUserVisitStatisticsSaga);
  yield takeLatest(GET_HOURLY_USER_STATISTIC, getHourlyUserStatisticsSaga);
  yield takeLatest(
    GET_INFLOW_KEYWORD_STATISTICS,
    getInflowKeywordStatisticsSaga
  );
  yield takeLatest(
    GET_USER_ENVIRONMENT_STATISTICS,
    getUserEnvironmentStatisticsSaga
  );
  yield takeLatest(GET_POPULAR_PAGE_STATISTICS, getPopularPageStatisticsSaga);
  yield takeLatest(GET_INFLOW_PATH_STATISTICS, getInflowPathStatisticsSaga);
}

export { dashboardSaga as default };
