import React from 'react';

import ContentBodyLayout from '../../Atoms/Layout/Contents/ContentBodyLayout';
import ContentsLayout from '../../Atoms/Layout/Contents/ContentsLayout';
import ContentsItemLayout from '../../Atoms/Layout/Contents/ContentsItemLayout';

import Header from '../../Organisms/Header';
import { uxAnalyticsState } from '../../../modules/PageAnalytics/UXAnalytics';
import { MouseHeatmapTooltipType } from '../../Molecule/Tooltip/MouseHeatmapTooltip';
import { PageElementsFlowPayload } from '../../../api/pageAnalytics/uxAnalytics/mouseflow/type';
import { ScrollEventsPayload } from '../../../api/pageAnalytics/uxAnalytics/scroll/type';
import { loadingState } from '../../../modules/loading';
import PageDetailAnalysisCard from '../../Organisms/Card/PageDetailAnalysisCard';

interface PageUXAnalysisTemplateProps {
  headerTitle: string;
  pageId: string;
  pageTitle: string;
  pageURL: string;
  loadingStatics: boolean;
  pageEventStaticsData: uxAnalyticsState['mouseStaticsData'];
  pageElementData: uxAnalyticsState['elementData'];
  clickedElementPath: string;
  mouseoverElementPath: string;

  flowData: {
    page: string;
    cnt: number;
    rate: number;
  }[];
  conversionData: {
    page: string;
    cnt: number;
    rate: number;
  }[];

  clickTooltipInfo: MouseHeatmapTooltipType | null;
  mouseoverTooltipInfo: MouseHeatmapTooltipType | null;
  pageUsabilityIsLoading: boolean;
  pageUsabilityData: {
    pageViewCount: number | null;
    effectiveClickCount: number | null;
    pageDuration: string | null;
  };
  activeDataType: number;

  mouseflowData: PageElementsFlowPayload[] | null;
  clickedPlusElementPath: string;
  scrollEventsData: ScrollEventsPayload | null;
  loading: loadingState;
  setClickedElementPath: React.Dispatch<React.SetStateAction<string>>;
  setMouseoverElementPath: React.Dispatch<React.SetStateAction<string>>;
  setClickTooltipInfo: React.Dispatch<
    React.SetStateAction<MouseHeatmapTooltipType | null>
  >;

  setMouseoverTooltipInfo: React.Dispatch<
    React.SetStateAction<MouseHeatmapTooltipType | null>
  >;
  setActiveDataType: React.Dispatch<React.SetStateAction<number>>;
  setClickedPlusElementPath: React.Dispatch<React.SetStateAction<string>>;
}

const PageUXAnalysisTemplate = ({
  headerTitle,
  pageId,
  pageTitle,
  pageURL,
  loadingStatics,
  pageEventStaticsData,
  pageElementData,
  clickedElementPath,
  mouseoverElementPath,
  flowData,
  conversionData,
  clickTooltipInfo,
  mouseoverTooltipInfo,
  pageUsabilityData,
  pageUsabilityIsLoading,
  activeDataType,
  mouseflowData,
  clickedPlusElementPath,
  scrollEventsData,
  loading,
  setClickedElementPath,
  setMouseoverElementPath,
  setClickTooltipInfo,
  setMouseoverTooltipInfo,
  setActiveDataType,
  setClickedPlusElementPath,
}: PageUXAnalysisTemplateProps) => {
  // state

  return (
    <ContentsLayout>
      <Header
        title={headerTitle}
        icon=""
        iconClass="ri-ser-received-line"
        isDatePicker
      />
      <ContentBodyLayout>
        <ContentsItemLayout desktop={12}>
          <PageDetailAnalysisCard
            pageTitle={pageTitle}
            pageLocation={pageURL}
            pageUsabilityData={pageUsabilityData}
            pageId={pageId}
            loadingStatics={loadingStatics}
            pageEventStaticsData={pageEventStaticsData}
            pageElementData={pageElementData}
            clickedElementPath={clickedElementPath}
            mouseoverElementPath={mouseoverElementPath}
            flowData={flowData}
            conversionData={conversionData}
            clickTooltipInfo={clickTooltipInfo}
            mouseoverTooltipInfo={mouseoverTooltipInfo}
            scrollEventsData={scrollEventsData}
            loading={loading}
            activeDataType={activeDataType}
            setActiveDataType={setActiveDataType}
            setClickedElementPath={setClickedElementPath}
            setMouseoverElementPath={setMouseoverElementPath}
            setClickTooltipInfo={setClickTooltipInfo}
            setMouseoverTooltipInfo={setMouseoverTooltipInfo}
          />
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default PageUXAnalysisTemplate;
