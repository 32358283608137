import React, { useEffect, useState } from 'react';

import {
  Component,
  Header,
  CardBody,
  LocalMapWrap,
  WorldMapWrap,
  DetailWorldMapWrap,
  ColumnsItem,
  DataItem,
  WorldColumnsItem,
  WorldDataItem,
  SimpleView,
} from './NationalSessionCardStyle';

import TabGroup from '../../Molecule/TabGroup';
import { P3, P2 } from '../../Atoms/Typo';
import Spinner from '../../Molecule/Spinner';
import LocalMapChart from '../../Molecule/Chart/LocalMapChart';
import DefaultTable, { DefaultTableProps } from '../../Molecule/DefaultTable';
import ComparisonBar from '../../Molecule/ComparisonBar';
import WorldMap from '../../Molecule/Chart/WorldMapChart';

export interface NationalSessionCardState {
  excelData: {
    [key: string]: string[];
  };
}

interface NationalSessionCardProps {
  nationalLoading: boolean;
  globalLoading: boolean;
  nationalData: {
    total: number;
    old: number;
    new: number;
    local_variant_en: string;
    local_variant_ko: string;
  }[];
  globalData: {
    code: string;
    country_name_en: string;
    country_name_ko: string;
    new: number;
    old: number;
    total: number;
  }[];
  onClickNationalExcel?: (data: NationalSessionCardState['excelData']) => void;
  onClickGlobalExcel?: (data: NationalSessionCardState['excelData']) => void;
}

const NationalSessionCard = ({
  nationalLoading,
  globalLoading,
  nationalData,
  globalData,
  onClickNationalExcel,
  onClickGlobalExcel,
}: NationalSessionCardProps) => {
  const [activeID, setActiveID] = useState(1);
  const [nationalMax, setNationalMax] = useState(0);
  const [tableData, setTableData] = useState<DefaultTableProps['data']>([]);
  const [worldTableData, setWorldTableData] = useState<
    DefaultTableProps['data']
  >([]);
  const [activeDetail, setActiveDetail] = useState(false);

  const localMapColumns = [
    <ColumnsItem key="순위">
      <P2>순위</P2>
    </ColumnsItem>,
    <ColumnsItem key="시/도">
      <P2>시/도</P2>
    </ColumnsItem>,
    <ColumnsItem key="uv">
      <P2>전체 방문자 수(UV)</P2>
    </ColumnsItem>,
    <ColumnsItem key="비율">
      <P2>방문비율</P2>
    </ColumnsItem>,
    <ColumnsItem key="재방문/신규">
      <P2>재방문/신규</P2>
    </ColumnsItem>,
  ];

  const worldMapColumns = [
    <WorldColumnsItem key="순위">
      <P2>순위</P2>
    </WorldColumnsItem>,
    <WorldColumnsItem key="국가">
      <P2>국가</P2>
    </WorldColumnsItem>,
    <WorldColumnsItem key="비율">
      <P2>방문비율</P2>
    </WorldColumnsItem>,
  ];

  const detailWorldColumns = [
    <WorldColumnsItem key="rank" $detail>
      <P2>순위</P2>
    </WorldColumnsItem>,
    <WorldColumnsItem key="ko" $detail>
      <P2>국가명</P2>
    </WorldColumnsItem>,
    <WorldColumnsItem key="en" $detail>
      <P2>영문 국가명</P2>
    </WorldColumnsItem>,
    <WorldColumnsItem key="uv" $detail>
      <P2>전체 방문자 수(UV)</P2>
    </WorldColumnsItem>,
    <WorldColumnsItem key="rate" $detail>
      <P2>방문비율</P2>
    </WorldColumnsItem>,
    <WorldColumnsItem key="comparison" $detail>
      <P2>재방문/신규</P2>
    </WorldColumnsItem>,
  ];

  const sumTotalUV = (
    arr:
      | NationalSessionCardProps['nationalData']
      | NationalSessionCardProps['globalData']
  ) => {
    let uvSum = 0;
    arr.forEach((element) => {
      uvSum += element.total;
    });

    return uvSum;
  };

  /* 
    호출 위치
    - 자세히 버튼 클릭했을 경우
    - 한눈에 보기 클릭했을 경우
  */
  const clickDetailButton = () => {
    setActiveDetail((prev) => {
      return !prev;
    });
  };

  const genTableData = () => {
    const data_temp: DefaultTableProps['data'] = [];
    if (nationalData.length > 0) {
      const totalUV = sumTotalUV(nationalData);
      nationalData.forEach((element, idx) => {
        data_temp.push({
          id: `${idx}`,
          node: [
            <DataItem key="rank">
              <P2>{idx + 1}</P2>
            </DataItem>,
            <DataItem key="city">
              <P2>{element.local_variant_ko}</P2>
            </DataItem>,
            <DataItem key="totaluv">
              <P2>{element.total.toLocaleString()}</P2>
            </DataItem>,
            <DataItem key="rate">
              <P2>{((element.total / totalUV) * 100).toFixed(1)}%</P2>
            </DataItem>,
            <DataItem key="bar">
              <ComparisonBar
                columns={['재방문', '신규']}
                totalCount={element.total}
                firstVal={element.old}
                secVal={element.new}
              />
            </DataItem>,
          ],
        });
      });
    }
    setTableData(data_temp);
  };

  const genWorldTableData = () => {
    const data_temp: DefaultTableProps['data'] = [];
    let etcUV = 0;
    let sumRate = 0;
    let etcOld = 0;
    let etcNew = 0;

    if (globalData.length > 0) {
      const totalUV = sumTotalUV(globalData);
      if (activeDetail) {
        globalData.forEach((element, idx) => {
          if (idx < 10) {
            sumRate += Number(((element.total / totalUV) * 100).toFixed(1));
            data_temp.push({
              id: `${idx}`,
              node: [
                <WorldDataItem key="rank" $detail>
                  <P2>{idx + 1}</P2>
                </WorldDataItem>,
                <WorldDataItem key="ko" $detail>
                  <P2>{element.country_name_ko}</P2>
                </WorldDataItem>,
                <WorldDataItem key="en" $detail>
                  <P2>{element.country_name_en}</P2>
                </WorldDataItem>,
                <WorldDataItem key="uv" $detail>
                  <P2>{element.total.toLocaleString()}</P2>
                </WorldDataItem>,
                <WorldDataItem key="rate" $detail>
                  <P2>{((element.total / totalUV) * 100).toFixed(1)}%</P2>
                </WorldDataItem>,
                <WorldDataItem key="comparison" $detail>
                  <ComparisonBar
                    columns={['재방문', '신규']}
                    totalCount={element.total}
                    firstVal={element.old}
                    secVal={element.new}
                  />
                </WorldDataItem>,
              ],
            });
          } else {
            etcUV += element.total;
            etcOld += element.old;
            etcNew += element.new;
          }
        });
        data_temp.push({
          id: '11',
          node: [
            <WorldDataItem key="rank" $detail>
              <P2>11</P2>
            </WorldDataItem>,
            <WorldDataItem key="ko" $detail>
              <P2>기타</P2>
            </WorldDataItem>,
            <WorldDataItem key="en" $detail>
              <P2>etc.</P2>
            </WorldDataItem>,
            <WorldDataItem key="uv" $detail>
              <P2>{etcUV}</P2>
            </WorldDataItem>,
            <WorldDataItem key="rate" $detail>
              <P2>{(100 - sumRate).toFixed(1)}%</P2>
            </WorldDataItem>,
            <WorldDataItem key="comparison" $detail>
              <ComparisonBar
                columns={['재방문', '신규']}
                totalCount={etcUV}
                firstVal={etcOld}
                secVal={etcNew}
              />
            </WorldDataItem>,
          ],
        });
      } else {
        globalData.forEach((element, idx) => {
          if (idx < 10) {
            data_temp.push({
              id: `${idx}`,
              node: [
                <WorldDataItem key="rank">
                  <P2>{idx + 1}</P2>
                </WorldDataItem>,
                <WorldDataItem key="country">
                  <P2>{element.country_name_ko}</P2>
                </WorldDataItem>,
                <WorldDataItem key="rate">
                  <P2>{((element.total / totalUV) * 100).toFixed(1)}%</P2>
                </WorldDataItem>,
              ],
            });
          }
        });
      }
    }
    setWorldTableData(data_temp);
  };

  const activeTabID = (id: number) => {
    setActiveID(id);
  };

  // national
  const clickNationalExcel = () => {
    // state
    const excelPayload: NationalSessionCardState['excelData'] = {};
    const totalUV = sumTotalUV(nationalData);
    excelPayload['0'] = [
      '순위',
      '시/도',
      '전체 방문자 수(UV)',
      '방문비율',
      '신규',
      '재방문',
    ];

    nationalData.forEach((element, index) => {
      excelPayload[(index + 1).toString()] = [
        (index + 1).toString(),
        element.local_variant_ko,
        element.total.toString(),
        ((element.total / totalUV) * 100).toFixed(1),
        element.new.toString(),
        element.old.toString(),
      ];
    });

    if (onClickNationalExcel) {
      onClickNationalExcel(excelPayload);
    }
  };

  // global
  const clickGlobalExcel = () => {
    // state
    const excelPayload: NationalSessionCardState['excelData'] = {};
    const totalUV = sumTotalUV(globalData);
    excelPayload['0'] = [
      '순위',
      '국가명',
      '국가명 (영문)',
      '전체 방문자 수(UV)',
      '방문비율',
      '신규',
      '재방문',
    ];

    globalData.forEach((element, index) => {
      excelPayload[(index + 1).toString()] = [
        (index + 1).toString(),
        element.country_name_ko,
        element.country_name_en,
        element.total.toString(),
        ((element.total / totalUV) * 100).toFixed(1),
        element.new.toString(),
        element.old.toString(),
      ];
    });

    if (onClickGlobalExcel) {
      onClickGlobalExcel(excelPayload);
    }
  };

  const printBody = () => {
    switch (activeID) {
      case 1:
        return (
          <CardBody>
            <LocalMapWrap>
              {nationalLoading ? (
                <Spinner />
              ) : (
                <LocalMapChart data={nationalData} setMax={setNationalMax} />
              )}
            </LocalMapWrap>
            <LocalMapWrap>
              <DefaultTable
                tableTitle="지역별 방문자 순위"
                isExcel
                scrollHeight={567}
                columns={localMapColumns}
                data={tableData}
                onClickExcel={clickNationalExcel}
              />
            </LocalMapWrap>
          </CardBody>
        );
      case 2:
        return activeDetail ? (
          <CardBody $isDetail={activeDetail}>
            <DetailWorldMapWrap className="wordl">
              <WorldMap data={globalData} isLoading={globalLoading} />
              <SimpleView size="detail" onClick={clickDetailButton}>
                <P3>한눈에보기</P3>
                <span className="ri-arrow-down-s-line"></span>
              </SimpleView>
            </DetailWorldMapWrap>
            <DetailWorldMapWrap>
              <DefaultTable
                tableTitle="국가별 방문자 순위"
                isExcel
                columns={detailWorldColumns}
                data={worldTableData}
                onClickExcel={clickGlobalExcel}
              />
            </DetailWorldMapWrap>
          </CardBody>
        ) : (
          <CardBody>
            <WorldMapWrap>
              <WorldMap data={globalData} isLoading={globalLoading} />
            </WorldMapWrap>
            <WorldMapWrap>
              <DefaultTable
                tableTitle="국가별 방문자 순위"
                isExcel
                columns={worldMapColumns}
                data={worldTableData}
                buttonText="자세히"
                onClickDetail={clickDetailButton}
                onClickExcel={clickGlobalExcel}
              />
            </WorldMapWrap>
          </CardBody>
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    if (nationalData.length > 0) {
      genTableData();
    }
  }, [nationalData]);

  useEffect(() => {
    if (globalData.length > 0) {
      genWorldTableData();
    }
  }, [globalData, activeDetail]);

  return (
    <Component>
      <Header>
        <TabGroup
          TabList={[
            {
              name: '국내 방문자 수 분석',
              id: 1,
            },
            {
              name: '국외 방문자 수 분석',
              id: 2,
            },
          ]}
          activeId={activeID}
          onClickTab={activeTabID}
        />
      </Header>
      {printBody()}
    </Component>
  );
};

export default NationalSessionCard;
