import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import { P1, P2, P3 } from '../../../Atoms/Typo';
import DefaultLink from '../../../Atoms/Link';
import Spinner from '../../../Molecule/Spinner';

const Component = styled(CardLayout)`
  border: 1px solid #6f749f;
  box-shadow: 0px 2px 3px #00000029;
  padding: 20px 0px;
  flex-direction: row;
`;

const ItemWrapper = styled.div`
  display: flex;
  width: 25%;
  /* flex: 1; */
  padding: 0px 25px;
  border-right: 1px solid #d7dbe6;
  :last-child {
    border-right: none;
  }
`;

const DescriptionWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;
`;

const Description = styled.div`
  display: flex;
  overflow: hidden;
  ${P3} {
    color: #767676;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const CountWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 20px;
`;

const Link = styled(DefaultLink)`
  display: flex;
  align-items: center;
  height: 20px;
  ${P2} {
    font-weight: bold;
  }
  span {
    color: #222222;
    font-size: 16px;
  }
`;

const UnLinkTitle = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  ${P2} {
    font-weight: bold;
  }
  span {
    color: #222222;
    font-size: 16px;
  }
`;

const Count = styled(P1)`
  font-size: 20px;
  font-weight: 800;
`;

const LogoWrap = styled.div`
  width: fit-content;
`;

const Logo = styled.div<{ $type: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 30px;

  span {
    color: white;
    font-size: 16px;
  }

  ${(props) => {
    switch (props.$type) {
      case 'user':
        return css`
          background-color: #0091ea;
        `;
      case 'session':
        return css`
          background-color: #00c0c8;
        `;
      case 'pv':
        return css`
          background-color: #576b9f;
        `;
      case 'time':
        return css`
          background-color: #2849d5;
        `;

      default:
        return css``;
    }
  }}
`;

const ItemDetail = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  width: 100%;
`;

const ValueWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* min-width: 75px; */
  height: 18px;
`;

const CompareIcon = styled.span<{ $id: string }>`
  position: relative;
  font-size: 17px;
  color: #aaaaaa;
  text-align: center;
  margin-left: 2px;
  :hover {
    cursor: pointer;
  }
`;

const Tooltip = styled.div<{ $id: string; $tooltipID: string }>`
  width: 300px;
  height: 60px;
  position: absolute;
  padding: 7px 13px;
  background-color: white;
  box-shadow: 0px 2px 4px #00000042;
  border: 1px solid #a7a7a7;
  border-radius: 30px;
  top: -60px;
  right: -20px;
  display: none;
  align-items: center;
  justify-content: center;
  ${(props) => {
    if (props.$id === props.$tooltipID) {
      return css`
        display: flex;
      `;
    }
    return css``;
  }}
  ${P3} {
    color: #767676;
  }
  /* &:after {
    position: absolute;
    content: '';
    bottom: 0px;
    right: 12px;
    transform: translate(0%, 100%);
    border-top: 8px solid white;
    border-bottom: 8px solid transparent;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  } */
`;

const CompareVal = styled.div<{ $rate: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  ${P3} {
    font-size: 15px;
    font-weight: bold;
  }

  ${(props) => {
    if (props.$rate > 0) {
      return css`
        ${P3} {
          color: #ff3b56;
        }
        .compare {
          border-bottom: 6px solid #ff3b56;
        }
      `;
    }
    if (props.$rate < 0) {
      return css`
        ${P3} {
          color: #2962ff;
        }
        .compare {
          border-bottom: 6px solid #2962ff;
          transform: rotate(180deg);
        }
      `;
    }
    if (props.$rate === 0) {
      return css`
        ${P3} {
          color: #6d6d6d;
        }
        .compare {
          display: none;
        }
      `;
    }
    return css``;
  }}
`;

const Triangle = styled.div`
  width: 0px;
  height: 0px;
  border-bottom: 6px solid red;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  margin-right: 3px;
`;

interface DashboardTotalCardState {
  totalData: {
    rate: number | null;
    count: number;
    compare: string | null;
  };
}

interface DashboardTotalCardProps {
  userLoading?: boolean;
  sessionLoading?: boolean;
  pvLoading?: boolean;
  timeLoading?: boolean;
  totalUser: DashboardTotalCardState['totalData'];
  session: DashboardTotalCardState['totalData'];
  pv: DashboardTotalCardState['totalData'];
  time: DashboardTotalCardState['totalData'];
}

const DashboardTotalCard = ({
  userLoading,
  sessionLoading,
  pvLoading,
  timeLoading,
  totalUser,
  session,
  pv,
  time,
}: DashboardTotalCardProps) => {
  // state
  const [compareBefore, setCompareBefore] = useState('');
  const [compareAfter, setCompareAfter] = useState('');
  const [tooltipID, setTooltipID] = useState('');

  const onEnterTitle = (id: string) => {
    setTooltipID(id);
  };
  const onLeaveTitle = () => {
    setTooltipID('');
  };

  const convertSec = (sec: number) => {
    const cvMin = Math.floor(sec / 60);
    const cvSec = Math.floor(sec % 60);

    if (cvMin === 0) {
      return `${cvSec}초`;
    }
    return `${cvMin}분 ${cvSec}초`;
  };

  const printCompare = (
    id: string,
    data: DashboardTotalCardState['totalData']
  ) => {
    if (data.rate) {
      return (
        <ValueWrap>
          <CompareVal $rate={data.rate}>
            <Triangle className="compare" />
            {data.rate === 0 ? <P3>-</P3> : <P3>{Math.abs(data.rate)}%</P3>}
          </CompareVal>
          <CompareIcon
            $id={id}
            className="ri-question-fill"
            onMouseEnter={() => {
              onEnterTitle(id);
            }}
            onMouseLeave={onLeaveTitle}
          >
            
            <Tooltip $id={id} $tooltipID={tooltipID}>
              <P3>
                {compareBefore}과 비교한
                <br />
                {compareAfter}의 수치입니다.
              </P3>
            </Tooltip>
          </CompareIcon>
        </ValueWrap>
      );
    }
    return null;
  };

  useEffect(() => {
    if (totalUser.compare) {
      setCompareBefore(totalUser.compare.split('/')[0]);
      setCompareAfter(totalUser.compare.split('/')[1]);
    }
  }, [totalUser]);

  return (
    <Component>
      <ItemWrapper>
        <LogoWrap>
          <Logo $type="user">
            <span className="ri-group-line"></span>
          </Logo>
        </LogoWrap>
        {userLoading ? (
          <Spinner />
        ) : (
          <ItemDetail>
            <DescriptionWrap>
              <Description>
                <P3>이렇게 많은 사람이 사용했어요.</P3>
              </Description>
              {printCompare('user', totalUser)}
            </DescriptionWrap>
            <CountWrap>
              <Link to="/sessionAnalytics/uniqueVisitor">
                <P2>전체 사용자 수</P2>
                <span className="ri-arrow-down-s-line"></span>
              </Link>
              <Count>{totalUser.count.toLocaleString()}</Count>
            </CountWrap>
          </ItemDetail>
        )}
      </ItemWrapper>
      <ItemWrapper>
        <LogoWrap>
          <Logo $type="session">
            <span className="ri-door-open-line"></span>
          </Logo>
        </LogoWrap>
        {sessionLoading ? (
          <Spinner />
        ) : (
          <ItemDetail>
            <DescriptionWrap>
              <Description>
                <P3>이만큼 방문했어요.</P3>
              </Description>
              {printCompare('session', session)}
            </DescriptionWrap>
            <CountWrap>
              <Link to="/sessionAnalytics/session">
                <P2>방문 횟수</P2>
                <span className="ri-arrow-down-s-line"></span>
              </Link>
              <Count>{session.count.toLocaleString()}</Count>
            </CountWrap>
          </ItemDetail>
        )}
      </ItemWrapper>
      <ItemWrapper>
        <LogoWrap>
          <Logo $type="pv">
            <span className="ri-stack-line"></span>
          </Logo>
        </LogoWrap>
        {pvLoading ? (
          <Spinner />
        ) : (
          <ItemDetail>
            <DescriptionWrap>
              <Description>
                <P3>이용할때 이만큼 보고 가요.</P3>
              </Description>
              {printCompare('pv', pv)}
            </DescriptionWrap>
            <CountWrap>
              <Link to="/sessionAnalytics/pageView">
                <P2>평균 페이지뷰</P2>
                <span className="ri-arrow-down-s-line"></span>
              </Link>
              <Count>{pv.count.toLocaleString()}</Count>
            </CountWrap>
          </ItemDetail>
        )}
      </ItemWrapper>
      <ItemWrapper>
        <LogoWrap>
          <Logo $type="time">
            <span className="ri-stack-line"></span>
          </Logo>
        </LogoWrap>
        {timeLoading ? (
          <Spinner />
        ) : (
          <ItemDetail>
            <DescriptionWrap>
              <Description>
                <P3>이만큼 머물렀어요.</P3>
              </Description>
              {printCompare('time', time)}
            </DescriptionWrap>
            <CountWrap>
              <UnLinkTitle>
                <P2>평균 이용 시간</P2>
              </UnLinkTitle>
              <Count>{convertSec(time.count)}</Count>
            </CountWrap>
          </ItemDetail>
        )}
      </ItemWrapper>
    </Component>
  );
};

export default DashboardTotalCard;
