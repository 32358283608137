import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { P2 } from '../../../Atoms/Typo';

const Component = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 0 40px;
`;

const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  span {
    color: #ff3b56;
  }
`;

const ExcelButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 32px;
  border-radius: 6px;
  border: 1px solid #c8c8c8;
  background-color: ${(props) => props.theme.colors.white};
  ${P2} {
    font-size: 15px;
  }
  span {
    font-size: 15px;
    color: ${(props) => props.theme.colors.grey900};
  }

  :hover {
    cursor: pointer;
  }
`;

const BodyLayout = styled.div`
  width: 100%;
  height: 500px;
  margin-top: 10px;
`;

const TableHeader = styled.div`
  display: flex;
  padding-right: 5px;
`;

const Cell = styled.div<{ $header?: boolean; $date?: string }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 245px;
  min-width: 144px;
  height: 44px;
  padding-right: 20px;
  border-right: 1px solid #dddddd;
  /* border-bottom: 1px solid #dddddd; */
  :first-child {
    padding-right: 0;
    justify-content: center;
  }
  :last-child {
    border-right: none;
  }

  ${(props) => {
    if (props.$header) {
      return css`
        border-top: 2px solid ${props.theme.colors.grey900};
        border-bottom: 1px solid ${props.theme.colors.grey900};
        ${P2} {
          font-weight: bold;
        }
      `;
    }
    if (props.$date === 'val') {
      return css`
        ${P2} {
          color: ${props.theme.colors.grey600};
        }
      `;
    }
    return css``;
  }}
`;

const TableBody = styled.div`
  height: 100%;
  overflow: overlay;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: hsla(0, 0%, 42%, 0.49);
    border-radius: 100px;
  }
`;

const Row = styled.div`
  display: flex;
  border-bottom: 1px solid #dddddd;
  :last-child {
    background-color: #f5f5f5;
    border-bottom: 1px solid #222222;
    ${P2} {
      font-weight: 600;
      color: #222222;
    }
  }
  :nth-last-child(2) {
    border-bottom: 1px solid #222222;
  }
`;

export interface TableChartState {
  header: {
    id: number;
    name: string;
  }[];
  bodyData: {
    date: string;
    total: number;
    old: number;
    oldRate: number | string;
    new: number;
    newRate: number | string;
  }[];
  excelData: {
    [key: string]: string[];
  };
}

interface TableChartProps {
  Data: {
    [key: string]: {
      total?: number;
      old: number;
      new: number;
      is_future?: boolean;
    };
  } | null;
  totalLabel?: string;
  oldLabel?: string;
  newLabel?: string;
  timeSort: string;
  onClickExcel?: (data: TableChartState['excelData']) => void;
}

const TableChart = ({
  Data,
  totalLabel,
  oldLabel,
  newLabel,
  timeSort,
  onClickExcel,
}: TableChartProps) => {
  const [total, setTotal] = useState(0);
  const [headerList, setHeaderList] = useState<TableChartState['header']>([]);
  const [rebuildArr, setRebuildArr] = useState<TableChartState['bodyData']>([]);

  useEffect(() => {
    const arr: {
      date: string;
      total: number;
      old: number;
      oldRate: number | string;
      new: number;
      newRate: number | string;
    }[] = [];
    let rateOld = 0;
    let rateNew = 0;
    let rebDate = '';
    let totalSum = 0;
    let oldSum = 0;
    let newSum = 0;

    if (Data) {
      setTotal(Object.keys(Data).length);

      Object.entries(Data).forEach((element) => {
        if (element[1].total) {
          rateOld = Math.floor((element[1].old / element[1].total) * 100);
          rateNew = Math.floor((element[1].new / element[1].total) * 100);
          switch (timeSort) {
            case 'day':
              rebDate = `${element[0].split(' ')[0]}`;
              break;
            case 'week':
              if (element[0].split(' ')[1] === '1') {
                rebDate = `${element[0].split(' ')[0].split('-')[1]}월 ${
                  element[0].split(' ')[1]
                }주차`;
              } else rebDate = `${element[0].split(' ')[1]}주차`;
              break;
            case 'month':
              if (element[0].split('-')[1] === '01') {
                rebDate = `${element[0].split('-')[0]}년 ${
                  element[0].split('-')[1].split(' ')[0]
                }월`;
              } else rebDate = `${element[0].split('-')[1].split(' ')[0]}월`;
              break;

            default:
              break;
          }
          totalSum += element[1].total;
          oldSum += element[1].old;
          newSum += element[1].new;

          arr.push({
            date: rebDate,
            total: element[1].total,
            old: element[1].old,
            oldRate: rateOld,
            new: element[1].new,
            newRate: rateNew,
          });
        }
      });
      arr.push({
        date: '합계',
        total: totalSum,
        old: oldSum,
        oldRate: '-',
        new: newSum,
        newRate: '-',
      });
      setRebuildArr(arr);
    }
  }, [Data, timeSort]);

  useEffect(() => {
    if (totalLabel && oldLabel && newLabel) {
      setHeaderList([
        {
          name: '날짜',
          id: 1,
        },
        {
          name: totalLabel,
          id: 2,
        },
        {
          name: oldLabel,
          id: 3,
        },
        {
          name: '비율',
          id: 4,
        },
        {
          name: newLabel,
          id: 5,
        },
        {
          name: '비율',
          id: 6,
        },
      ]);
    }
  }, [totalLabel, oldLabel, newLabel]);

  const rebuildExcel = () => {
    // state
    const excelPayload: TableChartState['excelData'] = {};
    const arr_temp: string[] = [];

    headerList.forEach((element) => {
      arr_temp.push(element.name);
    });

    excelPayload['0'] = arr_temp;

    rebuildArr.forEach((element, index) => {
      excelPayload[(index + 1).toString()] = [
        element.date,
        element.total.toString(),
        element.old.toString(),
        element.oldRate.toString(),
        element.new.toString(),
        element.newRate.toString(),
      ];
    });

    if (onClickExcel) {
      onClickExcel(excelPayload);
    }
  };

  return (
    <Component>
      <ChartHeader>
        <P2>
          Total.
          <span> {total}</span>
        </P2>
        <ExcelButton
          onClick={() => {
            rebuildExcel();
          }}
        >
          <P2>엑셀 다운로드 </P2>
          <span className="ri-download-line">&nbsp;</span>
        </ExcelButton>
      </ChartHeader>

      <BodyLayout>
        <TableHeader>
          {headerList.map((element) => {
            return (
              <Cell key={element.id} $header>
                <P2>{element.name}</P2>
              </Cell>
            );
          })}
        </TableHeader>
        <TableBody>
          {rebuildArr.map((element) => {
            return (
              <Row key={element.date}>
                <Cell>
                  <P2>{element.date}</P2>
                </Cell>
                <Cell $date="val">
                  <P2>{element.total.toLocaleString()}</P2>
                </Cell>
                <Cell $date="val">
                  <P2>{element.old.toLocaleString()}</P2>
                </Cell>
                <Cell $date="val">
                  {typeof element.oldRate === 'number' ? (
                    <P2>{element.oldRate}%</P2>
                  ) : (
                    <P2>{element.oldRate}</P2>
                  )}
                </Cell>
                <Cell $date="val">
                  <P2>{element.new.toLocaleString()}</P2>
                </Cell>
                <Cell $date="val">
                  {typeof element.newRate === 'number' ? (
                    <P2>{element.newRate}%</P2>
                  ) : (
                    <P2>{element.newRate}</P2>
                  )}
                </Cell>
              </Row>
            );
          })}
        </TableBody>
      </BodyLayout>
    </Component>
  );
};

export default TableChart;
