import { takeLatest } from 'redux-saga/effects';
import {
  apiReportAvgPV,
  apiReportDuration,
  apiReportSession,
  apiReportUV,
  apiReportInflowGlobal,
  apiReportInflowKeyword,
  apiReportInflowNational,
  apiReportInflowPath,
  apiReportClickRate,
  apiReportGrowthPV,
  apiReportPopularPage,
  apiReportMonth,
} from '../../api/report/api';

import createRequestSaga from '../createRequestSaga';
import {
  getReportAvgPVAsync,
  getReportDurationAsync,
  getReportSessionAsync,
  getReportUVAsync,
  getReportInflowGlobalAsync,
  getReportInflowKeywordAsync,
  getReportInflowNationalAsync,
  getReportInflowPathAsync,
  getReportClickRateAsync,
  getReportGrowthPVAsync,
  getReportPopularPageAsync,
  getReportMonthAsync,
  GET_REPORT_AVG_PV,
  GET_REPORT_DURATION,
  GET_REPORT_SESSION,
  GET_REPORT_UV,
  GET_REPORT_INFLOW_GLOBAL,
  GET_REPORT_INFLOW_KEYWORD,
  GET_REPORT_INFLOW_NATIONAL,
  GET_REPORT_INFLOW_PATH,
  GET_REPORT_CLICK_RATE,
  GET_REPORT_GROWTH_PV,
  GET_REPORT_POPULAR_PAGE,
  GET_REPORT_MONTH,
} from './actions';

const getReportUVSaga = createRequestSaga(getReportUVAsync, apiReportUV);

const getReportSessionSaga = createRequestSaga(
  getReportSessionAsync,
  apiReportSession
);

const getReportAvgPVSaga = createRequestSaga(
  getReportAvgPVAsync,
  apiReportAvgPV
);

const getReportDurationSaga = createRequestSaga(
  getReportDurationAsync,
  apiReportDuration
);

const getReportInflowNationalSaga = createRequestSaga(
  getReportInflowNationalAsync,
  apiReportInflowNational
);

const getReportInflowGlobalSaga = createRequestSaga(
  getReportInflowGlobalAsync,
  apiReportInflowGlobal
);

const getReportInflowPathSaga = createRequestSaga(
  getReportInflowPathAsync,
  apiReportInflowPath
);

const getReportInflowKeywordSaga = createRequestSaga(
  getReportInflowKeywordAsync,
  apiReportInflowKeyword
);

const getReportPopularPageSaga = createRequestSaga(
  getReportPopularPageAsync,
  apiReportPopularPage
);

const getReportGrowthPVSaga = createRequestSaga(
  getReportGrowthPVAsync,
  apiReportGrowthPV
);

const getReportClickRateSaga = createRequestSaga(
  getReportClickRateAsync,
  apiReportClickRate
);

const getReportMonthSaga = createRequestSaga(
  getReportMonthAsync,
  apiReportMonth
);

export function* reportSaga() {
  //
  yield takeLatest(GET_REPORT_UV, getReportUVSaga);
  yield takeLatest(GET_REPORT_SESSION, getReportSessionSaga);
  yield takeLatest(GET_REPORT_AVG_PV, getReportAvgPVSaga);
  yield takeLatest(GET_REPORT_DURATION, getReportDurationSaga);
  yield takeLatest(GET_REPORT_INFLOW_NATIONAL, getReportInflowNationalSaga);
  yield takeLatest(GET_REPORT_INFLOW_GLOBAL, getReportInflowGlobalSaga);
  yield takeLatest(GET_REPORT_INFLOW_PATH, getReportInflowPathSaga);
  yield takeLatest(GET_REPORT_INFLOW_KEYWORD, getReportInflowKeywordSaga);
  yield takeLatest(GET_REPORT_POPULAR_PAGE, getReportPopularPageSaga);
  yield takeLatest(GET_REPORT_GROWTH_PV, getReportGrowthPVSaga);
  yield takeLatest(GET_REPORT_CLICK_RATE, getReportClickRateSaga);
  yield takeLatest(GET_REPORT_MONTH, getReportMonthSaga);
}

export { reportSaga as default };
