/* eslint-disable no-nested-ternary */
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../modules';

// Private Public components
import PublicRoute from '../_PublicRoute';
import LoginPage from '../LoginPage';
import NotfoundPage from '../../components/NotfoundPage';

// Private components
import PrivateRoute from '../_PrivateRoute';
import Dashboard from '../Dashboard';

import ServiceUserAnalytics from '../ServiceAnalytics/UserAnalytics';
import ServicePageViewAnalytics from '../ServiceAnalytics/PageViewAnalytics';
import ServiceSessionAnalytics from '../ServiceAnalytics/SessionAnalytics';

import InflowPath from '../UserAnalytics/InflowPath';
import detail from '../UserAnalytics/InflowPath/detail';
import Contents from '../UserAnalytics/Contents';
import Region from '../UserAnalytics/Region';
import Environment from '../UserAnalytics/Environment';

import PageUXAnalysisList from '../PageAnalytics/PageList/PageUXAnalysis';
import UXAnalysis from '../PageAnalytics/UXAnalysis';
import PathPlot from '../PageAnalytics/PathPlot';

import AIVORYDashboard from '../AIVORYUsageAnalytics/Dashboard';
import SearchUsageAnalysis from '../AIVORYUsageAnalytics/SearchUsageAnalysis';
import PopularSearchAnalysis from '../AIVORYUsageAnalytics/PopularSearchAnalysis';
import ContentKeywordAnalysis from '../AIVORYUsageAnalytics/ContentKeywordAnalysis';

import Report from '../Report';
import Settings from '../Settings';
import Support from '../Support';
import MyPage from '../MyPage';
import ScriptGuide from '../ScriptGuide';

const env = process.env.NODE_ENV;
let isDevelopment = false;
if (env === 'development') {
  isDevelopment = true;
}
const Routes = () => {
  // redux
  const { isLogin, isExistsScript } = useSelector(
    (state: RootState) => state.authentication
  );

  return (
    <>
      {isLogin && localStorage.getItem('voda_tenant') ? (
        isExistsScript ? (
          <Switch>
            <PrivateRoute
              exact
              path="/"
              title="대시보드"
              Component={Dashboard}
            />

            <PrivateRoute
              exact
              path="/sessionAnalytics/uniqueVisitor"
              title="사용자 수 분석"
              Component={ServiceUserAnalytics}
            />
            <PrivateRoute
              exact
              path="/sessionAnalytics/pageView"
              title="페이지뷰 분석"
              Component={ServicePageViewAnalytics}
            />
            <PrivateRoute
              exact
              path="/sessionAnalytics/session"
              title="방문횟수(session) 분석"
              Component={ServiceSessionAnalytics}
            />

            <PrivateRoute
              exact
              path="/userAnalytics/inflowPath"
              title="유입경로 분석"
              Component={InflowPath}
            />
            <PrivateRoute
              exact
              path="/userAnalytics/inflowPath/detail"
              title="유입경로 분석"
              Component={detail}
            />
            <PrivateRoute
              exact
              path="/userAnalytics/contents"
              title="콘텐츠 분석"
              Component={Contents}
            />
            <PrivateRoute
              exact
              path="/userAnalytics/region"
              title="지역별 분석"
              Component={Region}
            />
            <PrivateRoute
              exact
              path="/userAnalytics/environment"
              title="사용 환경 분석"
              Component={Environment}
            />

            <PrivateRoute
              exact
              path="/pageAnalytics/uxAnalysisList"
              title="페이지 UX 분석"
              Component={PageUXAnalysisList}
            />
            <PrivateRoute
              exact
              path="/pageAnalytics/pathPlot"
              title="페이지 흐름 분석"
              Component={PathPlot}
            />
            <PrivateRoute
              exact
              path="/pageAnalytics/uxAnalysisList/uxAnalysis"
              title="페이지 UX 분석"
              Component={UXAnalysis}
            />

            {/* <PrivateRoute
            exact
            path="/aivoryUsageAnalytics"
            title="AIVORY 이용 평균 분석"
            Component={AIVORYDashboard}
          />
          <PrivateRoute
            exact
            path="/aivoryUsageAnalytics/searchKeyword"
            title="AIVORY 이용 평균 분석"
            Component={AIVORYDashboard}
          /> */}
            <PrivateRoute
              exact
              path="/aivoryUsageAnalytics"
              title="AIVORY 이용 분석"
              Component={AIVORYDashboard}
            />
            {/* <PrivateRoute
            exact
            path="/aivoryUsageAnalytics/searchUsageAnalysis"
            title="검색 이용 분석"
            Component={SearchUsageAnalysis}
          /> */}
            <PrivateRoute
              exact
              path="/aivoryUsageAnalytics/popularSearchAnalysis"
              title="인기검색어 분석"
              Component={PopularSearchAnalysis}
            />
            {/* <PrivateRoute
            exact
            path="/aivoryUsageAnalytics/contentKeywordAnalysis"
            title="콘텐츠 키워드 분석"
            Component={ContentKeywordAnalysis}
          /> */}

            <PrivateRoute
              exact
              path="/myPage"
              title="마이페이지"
              Component={MyPage}
            />

            <PrivateRoute
              exact
              path="/myPage/settings"
              title="설정"
              Component={Settings}
            />
            <PrivateRoute
              exact
              path="/report"
              title="월간 분석 리포트"
              Component={Report}
            />

            <PrivateRoute
              exact
              path="/support"
              title="고객센터"
              Component={Support}
            />

            <PrivateRoute exact path="*" title="404" Component={NotfoundPage} />
          </Switch>
        ) : (
          <PublicRoute exact path="*" component={ScriptGuide} />
        )
      ) : (
        <Switch>
          <PublicRoute exact path="*" component={LoginPage} />
          <Route component={NotfoundPage} />
        </Switch>
      )}
    </>
  );
};

export default Routes;
