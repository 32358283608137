import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Chart from 'react-apexcharts';
import ko from 'apexcharts/dist/locales/ko.json';
import { tooltip } from '../../../Atoms/ChartTooltip/MultiLineChartTooltip';
import { IconSMArrowLeft, IconSMArrowRight } from '../../../Atoms/Icon';

const Component = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  /* min-height: 400px; */
  padding-left: 52px;
  padding-right: 52px;
  .apexcharts-svg {
    overflow: initial;
  }
  ${tooltip};

  .apexcharts-bar-series {
    .apexcharts-series {
      transform: translate(-4px, 0%);
      &:last-child {
        transform: translate(4px, 0%);
      }
    }
  }
`;

const LeftZoomButton = styled.div<{
  $isEnd: boolean;
}>`
  position: absolute;
  left: 0;
  bottom: 12px;
  width: 12px;
  height: 16px;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;

    path {
      ${(props) => {
        if (props.$isEnd) {
          return css`
            display: none;
            fill: ${props.theme.colors.grey500};
          `;
        }
        return css`
          fill: ${props.theme.colors.grey900};
        `;
      }}
    }
  }
`;
const RightZoomButton = styled.div<{ $isEnd: boolean }>`
  position: absolute;
  right: 0;
  bottom: 12px;
  width: 12px;
  height: 16px;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;

    path {
      ${(props) => {
        if (props.$isEnd) {
          return css`
            display: none;
            fill: ${props.theme.colors.grey500};
          `;
        }
        return css`
          fill: ${props.theme.colors.grey900};
        `;
      }}
    }
  }
`;

interface MultiLineChartProps {
  Data: {
    [key: string]: {
      total?: number;
      old: number;
      new: number;
      is_future?: boolean;
      holiday?: boolean;
    };
  } | null;
  selectedTimeSort: string;

  lineLabel: string;
  bar1Label?: string;
  bar2Label?: string;
  isReport?: boolean;
  isDashboard?: boolean;
  notWeekly?: boolean;
  xLabels?: string;
}
interface MultiLineChartState {
  seriesType:
    | {
        name: string;
        type: string;
        data: {
          x: string;
          y: number;
          isFuture: boolean;
          isHoliday?: boolean;
        }[];
      }[]
    | null;
  dataValue: {
    total?: number;
    old: number;
    new: number;
    is_future?: boolean;
    holiday?: boolean;
  }[];
}

const MultiLineChart = ({
  Data,
  selectedTimeSort,

  lineLabel,
  bar1Label,
  bar2Label,
  isReport,
  isDashboard,
  notWeekly,
  xLabels,
}: MultiLineChartProps) => {
  const [stateStartIter, setStartIter] = useState(0);
  const [stateEndIter, setEndIter] = useState(-1);
  const [endIndex, setEndIndex] = useState(-1);

  const ChartRef = useRef<any>(null);
  const ConponentEl = useRef<HTMLDivElement | null>(null);

  const [rebuildSeries, setSeries] =
    useState<MultiLineChartState['seriesType']>(null);

  const markerColor = () => {
    if (isDashboard) {
      return '#222222';
    }
    return '#7851E7';
  };

  const lineColor = () => {
    if (isDashboard) {
      return '#C9C9C9';
    }
    return '#A6A6C7';
  };
  const options = {
    dataLabels: {
      enabled: false,
    },
    noData: {
      text: '데이터를 불러오지 못했습니다.',
      align: 'center' as const,
      verticalAlign: 'middle' as const,
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#424242',
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Pretendard',
      },
    },
    chart: {
      locales: [ko],
      defaultLocale: 'ko',
      animations: {
        enabled: true,
        easing: 'easeinout' as const,
        speed: 100,
        animateGradually: {
          enabled: false,
          delay: 0,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 200,
        },
      },

      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      events: {
        mouseMove(event: any, chartContext: any, config: any) {
          const apexcharts_tooltip = chartContext.el.querySelector(
            '.apexcharts-tooltip'
          );
          const canvasEl = chartContext.el.querySelector('.apexcharts-canvas');
          const marker =
            chartContext.el.getElementsByClassName('apexcharts-marker');
          const dataPointIndex =
            config.dataPointIndex === -1 ? 0 : config.dataPointIndex;
          if (apexcharts_tooltip && canvasEl && marker[dataPointIndex]) {
            const canvasTop =
              canvasEl.getBoundingClientRect().top + window.pageYOffset;

            const canvasLeft =
              canvasEl.getBoundingClientRect().left + window.pageXOffset;

            const width = canvasEl.clientWidth;

            const markerTop =
              marker[dataPointIndex].getBoundingClientRect().top +
              window.pageYOffset;
            const markerLeft =
              marker[dataPointIndex].getBoundingClientRect().left +
              window.pageXOffset;

            if (canvasLeft + width / 2 < markerLeft) {
              apexcharts_tooltip.style.top = `${markerTop - canvasTop + 5}px`;
              apexcharts_tooltip.style.left = `${
                markerLeft - canvasLeft - apexcharts_tooltip.clientWidth + 5
              }px`;
            } else {
              apexcharts_tooltip.style.top = `${markerTop - canvasTop + 5}px`;
              apexcharts_tooltip.style.left = `${
                markerLeft - canvasLeft + 5
              }px`;
            }
          }
        },
        updated(chartContext: any, config: any) {
          const seriesData = config.config.series[0].data;
          const seriesLineWrapper = chartContext.el
            .getElementsByClassName('apexcharts-line-series')
            .item(0);
          const seriesBarWrapper = chartContext.el
            .getElementsByClassName('apexcharts-bar-series')
            .item(0) as HTMLElement;
          const seriesTextWrapper =
            chartContext.el.getElementsByClassName('voda-xLabel');
          const seriesDayWeekWrapper = chartContext.el.getElementsByClassName(
            'voda-dayWeek-xLabel'
          );

          const coordinate: { cx: string; cy: string }[] = [];

          if (seriesTextWrapper) {
            seriesData.forEach((element: any, idx: number) => {
              if (element.isHoliday) {
                const holiday = seriesTextWrapper.item(idx) as HTMLElement;
                if (holiday) {
                  holiday.setAttribute('fill', 'red');
                }
              }
            });
          }

          if (seriesDayWeekWrapper.length > 0) {
            const Sat = seriesDayWeekWrapper.item(5) as HTMLElement;
            const Sun = seriesDayWeekWrapper.item(6) as HTMLElement;
            if (Sat && Sun) {
              Sat.setAttribute('fill', 'red');
              Sun.setAttribute('fill', 'red');
            }
          }

          if (seriesBarWrapper) {
            if (seriesBarWrapper.children.length > 1) {
              const bar1 = seriesBarWrapper.children.item(0)?.childNodes;
              const bar2 = seriesBarWrapper.children.item(1)?.childNodes;
              if (bar1 && bar2) {
                seriesData.forEach((element: any, index: number) => {
                  if (element.isFuture) {
                    const bar1path = bar1.item(index) as HTMLElement;
                    const bar2path = bar2.item(index) as HTMLElement;
                    bar1path.setAttribute('stroke-width', '1');
                    bar1path.setAttribute('stroke-width', '1');
                    bar1path.setAttribute('stroke', '#D5D5D5');
                    bar2path.setAttribute('stroke', '#6D6D6D');
                    bar1path.setAttribute('fill', '#D5D5D5');
                    bar2path.setAttribute('fill', '#6D6D6D');
                  }
                });
              }
            }
          }

          if (seriesLineWrapper) {
            const pathWrapper = seriesLineWrapper
              .getElementsByClassName('apexcharts-series')
              .item(0) as HTMLElement;
            if (pathWrapper) {
              pathWrapper.getElementsByTagName('path').item(0)?.remove();
            }

            const marker =
              chartContext.el.getElementsByClassName('apexcharts-marker');

            if (marker) {
              for (let index = 0; index < marker.length; index += 1) {
                const element = marker.item(index) as HTMLElement;

                if (
                  seriesData[index] &&
                  'isFuture' in seriesData[index] &&
                  seriesData[index].isFuture
                ) {
                  element.setAttribute(
                    'fill',
                    isReport ? '#b597f7' : '#000000'
                  );
                }
                // element.setAttribute('default-marker-size', '4');

                coordinate.push({
                  cx: element.getAttribute('cx') || '0',
                  cy: element.getAttribute('cy') || '0',
                });
              }
            }
          }
          if (coordinate.length > 0) {
            const pathWrapper = seriesLineWrapper
              .getElementsByClassName('apexcharts-series')
              .item(0) as HTMLElement;

            if (pathWrapper) {
              const markerWrapper = pathWrapper
                .getElementsByClassName('apexcharts-series-markers-wrap')
                .item(0);
              if (markerWrapper) {
                coordinate.forEach((element, index) => {
                  const path = document.createElementNS(
                    'http://www.w3.org/2000/svg',
                    'path'
                  );

                  path.setAttribute('fill', 'none');
                  path.setAttribute('stroke-width', '1');
                  path.setAttribute('stroke-opacity', '1');
                  if (seriesData[index] && seriesData[index].isFuture) {
                    path.setAttribute(
                      'stroke',
                      isReport ? '#b597f7' : '#D5D5D5'
                    );
                    path.setAttribute('stroke-dasharray', '6');
                  } else {
                    path.setAttribute('stroke', lineColor());
                  }

                  if (index !== 0) {
                    path.setAttribute(
                      'd',
                      `M ${coordinate[index - 1].cx} ${
                        coordinate[index - 1].cy
                      } L ${element.cx} ${element.cy}`
                    );
                    pathWrapper.insertBefore(path, markerWrapper);
                  }
                });
              }
            }
          }
        },
      },
    },

    legend: {
      show: false,
    },

    stroke: {
      width: 1,
    },

    markers: {
      size: 3,
      colors: [markerColor()],
      strokeColors: '#fff',
      strokeWidth: 1,
      strokeOpacity: 1,
      hover: {
        size: 8,
      },
    },
    colors: [markerColor(), '#3FECB7', '#1389FF'],
    tooltip: {
      enabled: true,
      followCursor: false,
      shared: true,
      intersect: false,
      fixed: {
        enabled: true,
        position: 'topLeft',
      },

      custom({
        series,
        seriesIndex,
        dataPointIndex,
        w,
      }: {
        series: any;
        seriesIndex: any;
        dataPointIndex: any;
        w: any;
      }) {
        if (series[0] && series[1] && series[2]) {
          const seriesData = w.config.series[0].data;

          const lineValue = series[0][dataPointIndex];
          const barValue1 = series[1][dataPointIndex];
          const barValue2 = series[2][dataPointIndex];
          let returnRate = 0;
          let newRate = 0;
          let result = '';
          if (lineValue !== 0) {
            returnRate = Math.round((barValue1 / lineValue) * 100);
            newRate = 100 - returnRate;
          }

          if (bar1Label !== undefined && bar2Label !== undefined) {
            if (seriesData[dataPointIndex].isFuture) {
              result = `<div class="tooltip">
              <div class="innerBox">
                <div class="title all">전체</div>
                <div class="value-all">${Number(
                  lineValue
                ).toLocaleString()}</div>
              </div>
              <div class="innerBox">
                <div class="title return">재방문</div>
                <div class="value-wrap">
                  <div class="value">${Number(barValue1).toLocaleString()}</div>
                  <div class="rate">(${returnRate}%)</div>
                </div>
              </div>
              <div class="innerBox">
                <div class="title new">신규</div>
                <div class="value-wrap">
                  <div class="value">${Number(barValue2).toLocaleString()}</div>
                  <div class="rate">(${newRate}%)</div>
                </div>
              </div>
            </div>`;
            } else {
              result = `<div class="tooltip">
              <div class="innerBox">
                <div class="title all">전체</div>
                <div class="value-all">${Number(
                  lineValue
                ).toLocaleString()}</div>
              </div>
              <div class="innerBox">
                <div class="title return">재방문</div>
                <div class="value-wrap">
                  <div class="value">${Number(barValue1).toLocaleString()}</div>
                  <div class="rate">(${returnRate}%)</div>
                </div>
              </div>
              <div class="innerBox">
                <div class="title new">신규</div>
                <div class="value-wrap">
                  <div class="value">${Number(barValue2).toLocaleString()}</div>
                  <div class="rate">(${newRate}%)</div>
                </div>
              </div>
            </div>`;
            }
          } else if (seriesData[dataPointIndex].isFuture) {
            result = `<div class="tooltip">
              <div class="innerBox">
                <div class="title">전체</div>
                <div class="value-all">${lineValue}</div>
              </div>
            </div>`;
          } else {
            result = `<div class="tooltip">
              <div class="innerBox">
                <div class="title">전체</div>
                <div class="value-all">${lineValue}</div>
              </div>
            </div>`;
          }
          return result;
        }
        if (series[0]) {
          const seriesData = w.config.series[0].data;
          const lineValue = series[0][dataPointIndex];
          if (seriesData[dataPointIndex].isFuture) {
            return `<div class="tooltip">
              <div class="innerBox">
                <div class="title">${lineLabel}(추정)</div>
                <div class="value">${lineValue}</div>
              </div>
            </div>`;
          }

          return `<div class="tooltip">
              <div class="innerBox">
                <div class="title">${lineLabel}</div>
                <div class="value">${lineValue}</div>
              </div>
            </div>`;
        }
        return ``;
      },
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        right: -15,
        left: -30,
      },
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        color: '#e0e0e0',
        width: '100%',
      },
      labels: {
        rotate: 0,

        style: {
          fontFamily: 'Pretendard',
          fontWeight: 400,
          fontSize: '14px',
          colors: '#757575',
        },
        formatter(value: string) {
          return ``;
        },
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      min: 0,
      max: 100,
    },
    fill: {
      colors: [markerColor(), '#3FECB7', '#1389FF'],
      type: ['gradient', 'solid', 'solid'],
      gradient: {
        shade: 'dark',
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        colorStops: [
          [
            {
              offset: 0,
              color: markerColor(),
              opacity: 1,
            },
            {
              offset: 18,
              color: markerColor(),
              opacity: 1,
            },
            {
              offset: 18,
              color: '#bdbdbd',
              opacity: 1,
            },
          ],
        ],
      },
      pattern: {
        style: 'slantedLines',
        width: 1,
        height: 10,
        strokeWidth: 1,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '10%',
        borderRadius: 0,
      },
    },
    annotations: {
      position: 'back',
    },
  };

  const [optionsState, setOptions] = useState(options);

  const tempSeries = [{ name: '', type: 'line', data: [] }];

  // 멀티차트 화살표 버튼 클릭 시 series data update를 위한 functino
  const updateSeries = (
    series: MultiLineChartState['seriesType'],
    startIter: number,
    endIter: number
  ) => {
    const { current } = ChartRef;

    if (series && current) {
      const seriesData0: {
        x: string;
        y: number;
        isFuture: boolean;
        isHoliday?: boolean;
      }[] = [];
      const seriesData1: {
        x: string;
        y: number;
        isFuture: boolean;
        isHoliday?: boolean;
      }[] = [];
      const seriesData2: {
        x: string;
        y: number;
        isFuture: boolean;
        isHoliday?: boolean;
      }[] = [];

      for (let index = startIter; index <= endIter; index += 1) {
        seriesData0.push(series[0].data[index]);
        if (bar1Label !== undefined && bar2Label !== undefined) {
          seriesData1.push(series[1].data[index]);
          seriesData2.push(series[2].data[index]);
        }
      }

      if (bar1Label !== undefined && bar2Label !== undefined) {
        current.chart.updateSeries(
          [
            {
              name: lineLabel,
              type: 'line',
              data: seriesData0,
            },
            {
              name: bar1Label,
              type: 'column',
              data: seriesData1,
            },
            {
              name: bar2Label,
              type: 'column',
              data: seriesData2,
            },
          ],
          true
        );
      } else {
        current.chart.updateSeries(
          [
            {
              name: lineLabel,
              type: 'line',
              data: seriesData0,
            },
          ],
          true
        );
      }
    }
  };

  // 리덕스 데이터 변경 시 호출 되는 function
  const updateOption = (
    series: MultiLineChartState['seriesType'],
    startIter: number,
    endIter: number,
    yAxisMin: number,
    yAxisMax: number,
    max: number,
    min: number,
    avg: number
  ) => {
    const { current } = ChartRef;
    let maxLength = 0;
    let ceilMax = '';
    let resultMax = 0;

    if (series && current) {
      const seriesData0: {
        x: string;
        y: number;
        isFuture: boolean;
        isHoliday?: boolean;
      }[] = [];
      const seriesData1: {
        x: string;
        y: number;
        isFuture: boolean;
        isHoliday?: boolean;
      }[] = [];
      const seriesData2: {
        x: string;
        y: number;
        isFuture: boolean;
        isHoliday?: boolean;
      }[] = [];

      for (let index = startIter; index <= endIter; index += 1) {
        seriesData0.push(series[0].data[index]);
        if (bar1Label !== undefined && bar2Label !== undefined) {
          seriesData1.push(series[1].data[index]);
          seriesData2.push(series[2].data[index]);
        }
      }

      const chartEl = current.chartRef.current as HTMLElement;
      const dataLength = seriesData0.length;
      const chartWidth =
        chartEl.getBoundingClientRect().right -
        chartEl.getBoundingClientRect().left;

      const barWidth = chartWidth / (dataLength * 2);
      const barWidthPercentage = 800 / barWidth;
      let updateTempSeries: {
        name: string;
        type: string;
        data: {
          x: string;
          y: number;
          isFuture: boolean;
          isHoliday?: boolean;
        }[];
      }[] = [];

      if (bar1Label !== undefined && bar2Label !== undefined) {
        updateTempSeries = [
          {
            name: lineLabel,
            type: 'line',
            data: seriesData0,
          },

          {
            name: bar1Label,
            type: 'column',
            data: seriesData1,
          },
          {
            name: bar2Label,
            type: 'column',
            data: seriesData2,
          },
        ];
      } else {
        updateTempSeries = [
          {
            name: lineLabel,
            type: 'line',
            data: seriesData0,
          },
        ];
      }

      if (max > 0) {
        maxLength = max.toString().length; // 2
        if (maxLength > 2) {
          ceilMax = `1${'0'.repeat(maxLength - 2)}`;
        } else ceilMax = `1${'0'.repeat(maxLength - 1)}`;

        resultMax = Math.ceil(max / Number(ceilMax)) * Number(ceilMax);
      }

      const tempOptions = {
        ...optionsState,
        series: updateTempSeries,

        yaxis: {
          ...optionsState.yaxis,
          min: yAxisMin - 0.01,
          max: yAxisMax + 1,
        },
        xaxis: {
          ...optionsState.xaxis,

          labels: {
            ...optionsState.xaxis.labels,
            style: {
              cssClass: xLabels,
            },
            formatter(value: string) {
              let month = '';
              let day = '';
              if (value) {
                switch (selectedTimeSort) {
                  case 'dayWeek':
                    return value;
                  case 'day':
                    if (value.split('-').length === 3) {
                      if (value.split('-').slice(1)[0][0] === '0') {
                        month = value.split('-').slice(1)[0].substring(1);
                      } else {
                        month = value.split('-').slice(1)[0].substring(0);
                      }
                      if (value.split('-').slice(1)[1][0] === '0') {
                        day = value.split('-').slice(1)[1].substring(1);
                      } else {
                        day = value.split('-').slice(1)[1].substring(0);
                      }
                    }
                    return `${month}.${day}`;
                  case 'week':
                    if (value.split(' ')[1] === '1') {
                      return `${value.split(' ')[0].split('-')[1]}월 ${
                        value.split(' ')[1]
                      }주차`;
                    }
                    return `${value.split(' ')[1]}주차`;
                  case 'month':
                    if (value.split('-')[1] === '01') {
                      return `${value.split('-')[0]}년 ${
                        value.split('-')[1].split(' ')[0]
                      }월`;
                    }
                    return `${value.split('-')[1].split(' ')[0]}월`;
                  default:
                    return value;
                }
              }
              return ``;
            },
          },
        },
        annotations: {
          ...optionsState.annotations,
          yaxis: [
            {
              y: resultMax,
              strokeDashArray: 0,
              borderColor: '#eeeeee',
              opacity: 1,
              width: '100%',
              offsetX: 0,
              label: {
                textAnchor: 'start',
                borderColor: 'transparent',
                borderWidth: 0,

                style: {
                  fontFamily: 'Pretendard',
                  fontWeight: 500,
                  background: 'transparent',
                  color: '#767676',
                  fontSize: '15px',
                },
                position: 'left',
                offsetX: -42,
                offsetY: 8,
                text: `${Math.floor(resultMax).toLocaleString()}`,
              },
            },
            {
              y: resultMax / 2,
              strokeDashArray: 0,
              borderColor: '#eeeeee',
              opacity: 1,
              width: '100%',
              offsetX: 0,
              label: {
                textAnchor: 'middle',
                borderColor: 'transparent',
                borderWidth: 0,

                style: {
                  fontFamily: 'Pretendard',
                  fontWeight: 500,
                  background: 'transparent',
                  color: '#767676',
                  fontSize: '15px',
                },
                position: 'left',
                offsetX: -25,
                offsetY: 8,
                text: `${Math.floor(resultMax / 2).toLocaleString()}`,
              },
            },
          ],
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: `${barWidthPercentage}%`,
            borderRadius: 0,
          },
        },
      };

      current.chart.updateOptions(tempOptions);
    }
  };

  // 리덕스 데이터 변경 시 호출 되는 function
  const rebuildData = () => {
    const { current } = ChartRef;
    if (Data && current) {
      const categoryArr = Object.keys(Data);
      const lineData: {
        x: string;
        y: number;
        isFuture: boolean;
        isHoliday?: boolean;
      }[] = [];
      const barData1: {
        x: string;
        y: number;
        isFuture: boolean;
        isHoliday?: boolean;
      }[] = [];
      const barData2: {
        x: string;
        y: number;
        isFuture: boolean;
        isHoliday?: boolean;
      }[] = [];

      if (selectedTimeSort === 'week') {
        categoryArr.sort((a, b) => {
          const a_arr = a.split(' ');
          const b_arr = b.split(' ');
          const a_date = new Date(a_arr[0]);
          const b_date = new Date(b_arr[0]);

          const case1 = Number(a_date) - Number(b_date);
          if (case1 === 0) {
            return Number(a_arr[1]) - Number(b_arr[1]);
          }
          return case1;
        });
      }

      categoryArr.forEach((element) => {
        const isFuture = Data[element].is_future || false;
        // const isFuture = false;

        lineData.push({
          x: element,
          // y: Data[element].total,
          y: Data[element].old + Data[element].new,
          isFuture,
          isHoliday: Data[element].holiday,
        });
        barData1.push({
          x: element,
          y: Data[element].old,
          isFuture,
          isHoliday: Data[element].holiday,
        });
        barData2.push({
          x: element,
          y: Data[element].new,
          isFuture,
          isHoliday: Data[element].holiday,
        });
      });

      let series: {
        name: string;
        type: string;
        data: {
          x: string;
          y: number;
          isFuture: boolean;
          isHoliday?: boolean;
        }[];
      }[] = [];

      if (bar1Label !== undefined && bar2Label !== undefined) {
        series = [
          { name: lineLabel, type: 'line', data: lineData },
          { name: bar1Label, type: 'column', data: barData1 },
          { name: bar2Label, type: 'column', data: barData2 },
        ];
      } else {
        series = [{ name: lineLabel, type: 'line', data: lineData }];
      }

      setSeries(series);
      setEndIndex(series[0].data.length - 1);

      let startIterTemp = 0;
      let endIterTemp = 0;

      if (series[0].data.length < 15) {
        setStartIter(0);
        setEndIter(series[0].data.length - 1);
        startIterTemp = 0;
        endIterTemp = series[0].data.length - 1;
      } else {
        setStartIter(series[0].data.length - 15);
        setEndIter(series[0].data.length - 1);
        startIterTemp = series[0].data.length - 15;
        endIterTemp = series[0].data.length - 1;
      }

      if (isDashboard) {
        if (series[0].data.length < 10) {
          setStartIter(0);
          setEndIter(series[0].data.length - 1);
          startIterTemp = 0;
          endIterTemp = series[0].data.length - 1;
        } else {
          setStartIter(series[0].data.length - 10);
          setEndIter(series[0].data.length - 1);
          startIterTemp = series[0].data.length - 10;
          endIterTemp = series[0].data.length - 1;
        }
      }

      const series0 = series[0].data;
      let sum = 0;
      let max = 0;
      let min = 0;
      let avg = 0;
      series0.forEach((element, index) => {
        sum += element.y;
        if (index === 0) {
          max = element.y;
          min = element.y;
        }
        if (element.y > max) {
          max = element.y;
        }

        if (element.y < min) {
          min = element.y;
        }
      });
      avg = Math.floor(sum / series0.length);

      let yAxisMin = 0;
      let yAxisMax = 1;
      series.forEach((element) => {
        element.data.forEach((dataElement) => {
          if (dataElement.y > yAxisMax) {
            yAxisMax = dataElement.y;
          }
          if (dataElement.y < yAxisMin) {
            yAxisMin = dataElement.y;
          }
        });
      });
      updateOption(
        series,
        startIterTemp,
        endIterTemp,
        yAxisMin,
        yAxisMax,
        max,
        min,
        avg
      );
    }
  };

  // 리덕스 데이터 변경 시 호출 되는 function
  useEffect(() => {
    if (Data) {
      rebuildData();
    }
  }, [Data]);

  const onClickNext = () => {
    const { current } = ChartRef;
    let startIterTemp = 0;
    let endIterTemp = 0;

    if (current && rebuildSeries) {
      const startIter = stateEndIter + 1;
      startIterTemp = stateEndIter + 1;
      if (startIter > rebuildSeries[0].data.length - 1) {
        setEndIter(stateEndIter);
        endIterTemp = stateEndIter;
      } else if (startIter + 14 > rebuildSeries[0].data.length - 1) {
        if (rebuildSeries[0].data.length - 15 > -1) {
          setStartIter(rebuildSeries[0].data.length - 15);
          startIterTemp = rebuildSeries[0].data.length - 15;
        } else {
          setStartIter(startIter);
          startIterTemp = startIter;
        }
        setEndIter(rebuildSeries[0].data.length - 1);
        endIterTemp = rebuildSeries[0].data.length - 1;
        updateSeries(rebuildSeries, startIterTemp, endIterTemp);
      } else {
        setStartIter(startIter);
        setEndIter(startIter + 14);
        startIterTemp = startIter;
        endIterTemp = startIter + 14;
        updateSeries(rebuildSeries, startIterTemp, endIterTemp);
      }

      if (isDashboard) {
        if (startIter > rebuildSeries[0].data.length - 1) {
          setEndIter(stateEndIter);
          endIterTemp = stateEndIter;
        } else if (startIter + 9 > rebuildSeries[0].data.length - 1) {
          if (rebuildSeries[0].data.length - 10 > -1) {
            setStartIter(rebuildSeries[0].data.length - 10);
            startIterTemp = rebuildSeries[0].data.length - 10;
          } else {
            setStartIter(startIter);
            startIterTemp = startIter;
          }
          setEndIter(rebuildSeries[0].data.length - 1);
          endIterTemp = rebuildSeries[0].data.length - 1;
          updateSeries(rebuildSeries, startIterTemp, endIterTemp);
        } else {
          setStartIter(startIter);
          setEndIter(startIter + 9);
          startIterTemp = startIter;
          endIterTemp = startIter + 9;
          updateSeries(rebuildSeries, startIterTemp, endIterTemp);
        }
      }
    }
  };

  const onClickPre = () => {
    const { current } = ChartRef;
    let startIterTemp = 0;
    let endIterTemp = 0;

    if (current && rebuildSeries) {
      const endIter = stateStartIter - 1;
      endIterTemp = stateStartIter - 1;
      if (endIter === -1) {
        setStartIter(stateStartIter);
        startIterTemp = stateStartIter;
      } else if (endIter - 14 < 0) {
        setStartIter(0);
        startIterTemp = 0;
        if (rebuildSeries[0].data.length - 1 > 14) {
          setEndIter(14);
          endIterTemp = 14;
        } else {
          setEndIter(endIter);
          endIterTemp = endIter;
        }
        updateSeries(rebuildSeries, startIterTemp, endIterTemp);
      } else {
        setStartIter(endIter - 14);
        setEndIter(endIter);
        startIterTemp = endIter - 14;
        endIterTemp = endIter;
        updateSeries(rebuildSeries, startIterTemp, endIterTemp);
      }

      if (isDashboard) {
        if (endIter === -1) {
          setStartIter(stateStartIter);
          startIterTemp = stateStartIter;
        } else if (endIter - 9 < 0) {
          setStartIter(0);
          startIterTemp = 0;
          if (rebuildSeries[0].data.length - 1 > 9) {
            setEndIter(9);
            endIterTemp = 9;
          } else {
            setEndIter(endIter);
            endIterTemp = endIter;
          }
          updateSeries(rebuildSeries, startIterTemp, endIterTemp);
        } else {
          setStartIter(endIter - 9);
          setEndIter(endIter);
          startIterTemp = endIter - 9;
          endIterTemp = endIter;
          updateSeries(rebuildSeries, startIterTemp, endIterTemp);
        }
      }
    }
  };

  return (
    <Component ref={ConponentEl}>
      <Chart
        type={bar1Label === undefined ? 'line' : 'bar'}
        series={tempSeries}
        options={optionsState}
        width="100%"
        height="100%"
        ref={ChartRef}
      />
      <LeftZoomButton
        onClick={() => {
          onClickPre();
        }}
        $isEnd={stateStartIter === 0}
      >
        <IconSMArrowLeft />
      </LeftZoomButton>
      <RightZoomButton
        onClick={() => {
          onClickNext();
        }}
        $isEnd={stateEndIter === endIndex}
      >
        <IconSMArrowRight />
      </RightZoomButton>
    </Component>
  );
};

export default MultiLineChart;
