import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { WeeklyUserCountPayload } from '../../../api/serviceAnalytics/userAnalysis/type';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import CardHeaderLayout from '../../Atoms/Layout/Card/CardHeaderLayout';

import HeatmapChart from '../../Molecule/Chart/HeatmapChart';
import Spinner from '../../Molecule/Spinner';

import DefaultCardHeading from '../../Molecule/Heading/Card/DefaultCardHeading';
import { P3 } from '../../Atoms/Typo';

const Component = styled(CardLayout)`
  height: 497px;
`;

const Body = styled(CardBodyLayout)`
  margin-top: 25px;
`;

const HeatmapLegend = styled.div`
  display: flex;
  align-items: center;
  width: 30%;
`;

const LegendSpectrum = styled.div`
  width: 100%;
  height: 15px;
  margin: 0 10px;
  background: transparent
    linear-gradient(90deg, #f9f9ff 0%, #c1c4fb 33%, #7e84f6 67%, #4c53f3 100%)
    0% 0% no-repeat padding-box;
`;

interface HeatmapChartCardState {
  heatmapData: {
    [key: string]: {
      [key: string]: {
        new: number;
        old: number;
      };
    };
  } | null;
}

export interface HeatmapChartCardProps {
  cardTitle: string;
  Data: WeeklyUserCountPayload | null;
  label1: string;
  label2: string;
  totalLabel: string;
  loading: boolean;
}

const HeatmapChartCard = ({
  cardTitle,
  Data,
  label1,
  label2,
  totalLabel,
  loading,
}: HeatmapChartCardProps) => {
  // state
  const [maxValue, setMaxValue] = useState(0);
  const [rebData, setRebData] =
    useState<HeatmapChartCardState['heatmapData']>(null);

  useEffect(() => {
    if (Data) {
      setRebData(Data.hourly);

      const yAxisCategory = Object.keys(Data.hourly);
      const xAxisCategory = Object.keys(Object.values(Data.hourly)[0]);
      let min = 0;
      let max = 0;

      yAxisCategory.forEach((element, index) => {
        xAxisCategory.forEach((xAxisElement, xAxisIndex) => {
          const y =
            Data.hourly[element][xAxisElement].new +
            Data.hourly[element][xAxisElement].old;
          if (index === 0 && xAxisIndex === 0) {
            min = y;
            max = y;
          } else {
            if (max < y) {
              max = y;
            }
            if (min > y) {
              min = y;
            }
          }
        });
      });

      setMaxValue(max);
    }
  }, [Data]);

  return (
    <Component>
      <CardHeaderLayout>
        <DefaultCardHeading title={cardTitle} />
        <HeatmapLegend>
          <P3>0</P3>
          <LegendSpectrum />
          <P3>{maxValue}</P3>
        </HeatmapLegend>
      </CardHeaderLayout>
      <Body>
        {loading ? (
          <Spinner />
        ) : (
          <HeatmapChart
            Data={rebData}
            label1={label1}
            label2={label2}
            totalLabel={totalLabel}
          />
        )}
      </Body>
    </Component>
  );
};

export default HeatmapChartCard;
