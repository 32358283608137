import { createReducer } from 'typesafe-actions';
import {
  GET_POPULAR_MENU,
  GET_POPULAR_MENU_SUCCESS,
  GET_POPULAR_MENU_ERROR,
  GET_POPULAR_CONTENT,
  GET_POPULAR_CONTENT_SUCCESS,
  GET_POPULAR_CONTENT_ERROR,
  GET_Total_CONTENT,
  GET_Total_CONTENT_SUCCESS,
  GET_Total_CONTENT_ERROR,
  SET_ERROR_NULL_CONTENTS,
} from './actions';
import { contentsAction, contentsState } from './types';

const initialState: contentsState = {
  isLoadingMenu: false,
  isLoadingcontent: false,
  isLoadingTotal: false,

  menuData: [],
  contentData: [],
  TotalData: [],

  contentsError: null,
};

const reducer = createReducer<contentsState, contentsAction>(initialState, {
  [GET_POPULAR_MENU]: (state) => ({
    ...state,
    isLoadingMenu: true,
    menuData: [],
    contentsError: null,
  }),
  [GET_POPULAR_MENU_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingMenu: false,
    menuData: action.payload.payload,
    contentsError: null,
  }),
  [GET_POPULAR_MENU_ERROR]: (state, action) => ({
    ...state,
    isLoadingMenu: false,
    menuData: [],
    contentsError: action.payload,
  }),

  [GET_POPULAR_CONTENT]: (state) => ({
    ...state,
    isLoadingcontent: true,
    contentData: [],
    contentsError: null,
  }),
  [GET_POPULAR_CONTENT_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingcontent: false,
    contentData: action.payload.payload,
    contentsError: null,
  }),
  [GET_POPULAR_CONTENT_ERROR]: (state, action) => ({
    ...state,
    isLoadingcontent: false,
    contentData: [],
    contentsError: action.payload,
  }),

  [GET_Total_CONTENT]: (state) => ({
    ...state,
    isLoadingTotal: true,
    TotalData: [],
    contentsError: null,
  }),
  [GET_Total_CONTENT_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingTotal: false,
    TotalData: action.payload.payload,
    contentsError: null,
  }),
  [GET_Total_CONTENT_ERROR]: (state, action) => ({
    ...state,
    isLoadingTotal: false,
    TotalData: [],
    contentsError: action.payload,
  }),

  [SET_ERROR_NULL_CONTENTS]: (state) => ({
    ...state,
    contentsError: null,
  }),
});

export default reducer;
