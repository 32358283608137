import React from 'react';
import styled, { css } from 'styled-components';

import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import CardBodyLayout from '../../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../../Atoms/Layout/Card/CardHeaderLayout';
import { H3, P2, P3 } from '../../../Atoms/Typo';
import Spinner from '../../../Molecule/Spinner';
import { GetInflowKeywordStatisticsResponseType } from '../../../../api/Dashboard/type';
import DataError from '../../../Atoms/DataError';
import DefaultLink from '../../../Atoms/Link';

const Component = styled(CardLayout)`
  height: 280px;
  padding: 25px;
`;

const HeaderLayout = styled(CardHeaderLayout)`
  ${H3} {
    font-size: 19px;
  }
`;

const BodyLayout = styled(CardBodyLayout)`
  flex-direction: row;
  padding-top: 20px;
`;

const KeywordWrapper = styled.div`
  width: 50%;
  :first-child {
    padding-right: 20px;
  }
  :last-child {
    padding-left: 20px;
  }
`;

const KeywordRank = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  :first-child {
    padding: 0;
  }
`;

const KeywordItem = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  ${P2} {
    @media (max-width: 1440px) {
      font-size: 14px;
    }
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Rank = styled.div<{ $lower?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  margin-right: 9px;
  border-radius: 4px;
  background-color: #1d2fc7;
  ${P3} {
    color: white;
  }
  ${(props) => {
    if (props.$lower) {
      return css`
        background-color: #eeeeee;
        ${P3} {
          color: #222222;
        }
      `;
    }
    return css``;
  }}
`;

const CompareVal = styled.div<{ $compare: number | null }>`
  display: flex;
  width: 27px;
  align-items: center;
  justify-content: center;
  ${P3} {
    font-size: 12px;
  }

  ${(props) => {
    if (props.$compare) {
      if (props.$compare > 0) {
        return css`
          ${P3} {
            color: #ff3b56;
          }
          .compare {
            border-bottom: 6px solid #ff3b56;
          }
        `;
      }
      if (props.$compare < 0) {
        return css`
          ${P3} {
            color: #2962ff;
          }
          .compare {
            border-bottom: 6px solid #2962ff;
            transform: rotate(180deg);
          }
        `;
      }
      if (props.$compare === 0) {
        return css`
          ${P3} {
            color: #6d6d6d;
          }
          .compare {
            display: none;
          }
        `;
      }
    }
    return css`
      ${P3} {
        color: #1d2fc7;
        font-weight: 600;
      }
      .compare {
        display: none;
      }
    `;
  }}
`;

const Triangle = styled.div`
  width: 0px;
  height: 0px;
  border-bottom: 6px solid red;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  margin-right: 3px;
`;

interface DashboardInflowCardProps {
  loading?: boolean;
  data: GetInflowKeywordStatisticsResponseType['payload'];
}

const DashboardInflowCard = ({ loading, data }: DashboardInflowCardProps) => {
  // state

  const printCompare = (rate: number | null) => {
    if (rate !== null) {
      if (rate === 0) {
        return <P3>-</P3>;
      }
      return <P3>{Math.abs(rate)}</P3>;
    }
    return <P3>NEW</P3>;
  };

  const printBody = () => {
    if (data.length > 0) {
      return (
        <BodyLayout>
          <KeywordWrapper>
            {data.slice(0, 5).map((element, idx) => {
              return (
                <KeywordRank key={element.name}>
                  <KeywordItem>
                    <Rank>
                      <P3>{idx + 1}</P3>
                    </Rank>
                    <P2>{element.name}</P2>
                  </KeywordItem>
                  <CompareVal $compare={element.compare}>
                    <Triangle className="compare" />
                    {printCompare(element.compare)}
                  </CompareVal>
                </KeywordRank>
              );
            })}
          </KeywordWrapper>

          <KeywordWrapper>
            {data.slice(5).map((element, idx) => {
              return (
                <KeywordRank key={element.name}>
                  <KeywordItem>
                    <Rank $lower>
                      <P3>{idx + 6}</P3>
                    </Rank>
                    <P2>{element.name}</P2>
                  </KeywordItem>
                  <CompareVal $compare={element.compare}>
                    <Triangle className="compare" />
                    {printCompare(element.compare)}
                  </CompareVal>
                </KeywordRank>
              );
            })}
          </KeywordWrapper>
        </BodyLayout>
      );
    }
    return <DataError />;
  };

  return (
    <Component>
      <HeaderLayout>
        <DefaultLink to="/userAnalytics/inflowPath/detail?search">
          <H3>
            유입검색어<span className="ri-arrow-down-s-line"></span>
          </H3>
        </DefaultLink>
      </HeaderLayout>
      {loading ? <Spinner /> : printBody()}
    </Component>
  );
};

export default DashboardInflowCard;
