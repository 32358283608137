import { createAsyncAction, createAction } from 'typesafe-actions';
import { AxiosErrorType } from '../../createRequestSaga';

import {
  GetPopularMenuParamsType,
  GetPopularMenuResponseType,
  GetPopularContentParamsType,
  GetPopularContentResponseType,
  GetTotalContentParamsType,
  GetTotalContentResponseType,
} from '../../../api/userAnalytics/contents/type';

// 메뉴 조회수 top5
export const GET_POPULAR_MENU =
  'userAnalytics/contents/GET_POPULAR_MENU' as const;
export const GET_POPULAR_MENU_SUCCESS =
  'userAnalytics/contents/GET_POPULAR_MENU_SUCCESS' as const;
export const GET_POPULAR_MENU_ERROR =
  'userAnalytics/contents/GET_POPULAR_MENU_ERROR' as const;

export const getPopularMenuAsync = createAsyncAction(
  GET_POPULAR_MENU,
  GET_POPULAR_MENU_SUCCESS,
  GET_POPULAR_MENU_ERROR
)<GetPopularMenuParamsType, GetPopularMenuResponseType, AxiosErrorType>();

// 게시글 조회수 top5
export const GET_POPULAR_CONTENT =
  'userAnalytics/contents/GET_POPULAR_CONTENT' as const;
export const GET_POPULAR_CONTENT_SUCCESS =
  'userAnalytics/contents/GET_POPULAR_CONTENT_SUCCESS' as const;
export const GET_POPULAR_CONTENT_ERROR =
  'userAnalytics/contents/GET_POPULAR_CONTENT_ERROR' as const;

export const getPopularContentAsync = createAsyncAction(
  GET_POPULAR_CONTENT,
  GET_POPULAR_CONTENT_SUCCESS,
  GET_POPULAR_CONTENT_ERROR
)<GetPopularContentParamsType, GetPopularContentResponseType, AxiosErrorType>();

// 전체 URL별 페이지뷰
export const GET_Total_CONTENT =
  'userAnalytics/contents/GET_Total_CONTENT' as const;
export const GET_Total_CONTENT_SUCCESS =
  'userAnalytics/contents/GET_Total_CONTENT_SUCCESS' as const;
export const GET_Total_CONTENT_ERROR =
  'userAnalytics/contents/GET_Total_CONTENT_ERROR' as const;

export const getTotalContentAsync = createAsyncAction(
  GET_Total_CONTENT,
  GET_Total_CONTENT_SUCCESS,
  GET_Total_CONTENT_ERROR
)<GetTotalContentParamsType, GetTotalContentResponseType, AxiosErrorType>();

// error type
export const SET_ERROR_NULL_CONTENTS =
  'serAnalytics/contents/SET_ERROR_NULL_CONTENTS';

export const setErrorNullInflowPath = createAction(SET_ERROR_NULL_CONTENTS)();
