import React, { ReactNodeArray, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as IconSMArrowRightSVG } from '../../../assets/icons/icon-sm-arrow-right.svg';
import { ReactComponent as IconSMArrowLeftSVG } from '../../../assets/icons/icon-sm-arrow-left.svg';
import Spinner from '../../Molecule/Spinner';

// icon
const IconSMArrowRight = styled(IconSMArrowRightSVG)``;
const IconSMArrowLeft = styled(IconSMArrowLeftSVG)``;

const componentVar = css`
  width: 100%;
  height: 558px;
  padding: 24px 24px 40px 24px;
  border-radius: 16px;
  background-color: white;
`;
const KeywordRankTableComponent = styled.div`
  ${componentVar}
  display: flex;
  flex-direction: column;
`;

const headerVar = css`
  width: 100%;
  height: 29px;
`;
const Header = styled.div`
  ${headerVar}
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const HeaderTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: ${(props) => props.theme.colors.grey800};
`;
const HeaderPageButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 12px;
`;

const HeaderPageButton = styled.button<{
  $pageNumber: number;
  $isShow: boolean;
}>`
  width: 7px;
  height: 11.3px;
  display: flex;
  border: none;
  background-color: transparent;
  cursor: pointer;
  ${(props) => {
    if (props.$pageNumber === 0) {
      return css`
        ${IconSMArrowLeft} {
          path {
            fill: ${props.theme.colors.grey400};
          }
        }
      `;
    }
    return css`
      ${IconSMArrowRight} {
        path {
          fill: ${props.theme.colors.grey400};
        }
      }
    `;
  }}
  ${IconSMArrowLeft} {
    width: 7px;
    height: 11.3px;
  }
  ${IconSMArrowRight} {
    width: 7px;
    height: 11.3px;
  }
  ${(props) => {
    if (props.$isShow) {
      return css``;
    }
    return css`
      display: none;
    `;
  }}
`;

const HeaderButtonSplit = styled.div<{ $isShow: boolean }>`
  width: 1px;
  height: 12px;
  margin-left: 8px;
  margin-right: 8px;
  background-color: ${(props) => props.theme.colors.grey300};
  ${(props) => {
    if (props.$isShow) {
      return css``;
    }
    return css`
      display: none;
    `;
  }}
`;

const HeaderSplit = styled.div`
  margin-bottom: 24px;
  margin-top: 24px;
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey200};
`;

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BodyItem = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

const BodyItemRank = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: ${(props) => props.theme.colors.grey800};
  width: 16px;
`;

const BodyItemText = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.colors.grey800};
  margin-left: 16px;
  margin-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
`;

const BodyItemText2 = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.colors.grey800};
  margin-left: 16px;
  margin-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* cursor: pointer; */
`;

const BodyItemCount = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.colors.grey600};
`;

const DataError = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: ${(props) => props.theme.colors.grey800};
`;

interface KeywordRankTableProps {
  data?: {
    word: string;
    count: number;
    value: number;
  }[];
  isLoading: boolean;
  title: string;
  clsName: string;
  onClickKeyword?: (word: string) => void;
}

const KeywordRankTable = ({
  data,
  isLoading,
  title,
  clsName,
  onClickKeyword,
}: KeywordRankTableProps) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [dataLength, setDataLength] = useState(0);

  const onClickPageButton = (id: number) => {
    setPageNumber(id);
  };

  const printTable = () => {
    const result: ReactNodeArray = [];
    if (data !== undefined) {
      if (data.length === 0) {
        result.push(<DataError>선택한 기간에 데이터가 없습니다.</DataError>);
        return result;
      }
      let iter = pageNumber * 10 + 10;
      if (iter > data.length) {
        iter = data.length;
      }
      for (let index = pageNumber * 10; index < iter; index += 1) {
        const element = data[index];

        switch (clsName) {
          case 'ContentKeyword':
            result.push(
              <BodyItem key={element.word + element.count}>
                <BodyItemRank>{index + 1}</BodyItemRank>
                <BodyItemText2>{element.word}</BodyItemText2>
                <BodyItemCount>
                  {element.count > 999 ? `(999+)` : `(${element.count})`}
                </BodyItemCount>
              </BodyItem>
            );
            break;
          case 'PopularSearch':
            result.push(
              <BodyItem key={element.word + element.count}>
                <BodyItemRank>{index + 1}</BodyItemRank>
                <BodyItemText
                  onClick={() => {
                    if (onClickKeyword) {
                      onClickKeyword(element.word);
                    }
                  }}
                >
                  {element.word}
                </BodyItemText>
                <BodyItemCount>
                  {element.count > 999 ? `(999+)` : `(${element.count})`}
                </BodyItemCount>
              </BodyItem>
            );
            break;
          default:
            break;
        }

        // origin
        // result.push(
        //   <BodyItem key={element.word + element.count}>
        //     <BodyItemRank>{index + 1}</BodyItemRank>
        //     <BodyItemText
        //     // onClick={() => {
        //     //   if (onClickKeyword) {
        //     //     onClickKeyword(element.word);
        //     //   }
        //     // }}
        //     >
        //       {element.word}
        //     </BodyItemText>
        //     <BodyItemCount>
        //       {element.count > 999 ? `(999+)` : `(${element.count})`}
        //     </BodyItemCount>
        //   </BodyItem>
        // );
      }
    } else {
      result.push(
        <DataError key="data_error">데이터를 불러오지 못했습니다.</DataError>
      );
    }

    return result;
  };

  useEffect(() => {
    if (data) {
      setDataLength(data.length);
    }
  }, [data]);

  return (
    <KeywordRankTableComponent>
      <Header>
        <HeaderTitle>{title}</HeaderTitle>
        <HeaderPageButtonWrapper>
          <HeaderPageButton
            $isShow={dataLength > 10}
            $pageNumber={pageNumber}
            onClick={() => {
              onClickPageButton(0);
            }}
          >
            <IconSMArrowLeft />
          </HeaderPageButton>
          <HeaderButtonSplit $isShow={dataLength > 10} />
          <HeaderPageButton
            $isShow={dataLength > 10}
            $pageNumber={pageNumber}
            onClick={() => {
              onClickPageButton(1);
            }}
          >
            <IconSMArrowRight />
          </HeaderPageButton>
        </HeaderPageButtonWrapper>
      </Header>
      <HeaderSplit />
      <Body>{isLoading ? <Spinner /> : printTable()}</Body>
    </KeywordRankTableComponent>
  );
};

export default KeywordRankTable;
