import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { RootState } from '../../../modules';
import {
  GetPopularContentParamsType,
  GetPopularMenuParamsType,
  GetTotalContentParamsType,
} from '../../../api/userAnalytics/contents/type';
import {
  getPopularContentAsync,
  getPopularMenuAsync,
  getTotalContentAsync,
} from '../../../modules/userAnalytics/contents';

import ContentsLayout from '../../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../../components/Atoms/Layout/Contents/ContentsItemLayout';
import Header from '../../../components/Organisms/Header';
import PopularMenuCard from '../../../components/Organisms/Card/PopularMenuCard';
import ContentsTableCard, {
  ContentsTableCardState,
} from '../../../components/Organisms/Card/ContentsTableCard';
import { GetExportStatisticsExcelParamType } from '../../../api/authentication/type';
import { getExportExcelAsync } from '../../../modules/authentication';

const Contents = () => {
  // redux
  const { startDate, endDate, isDatePickerToggle } = useSelector(
    (state: RootState) => state.datePicker
  );
  const {
    isLoadingMenu,
    isLoadingcontent,
    isLoadingTotal,
    menuData,
    contentData,
    TotalData,
  } = useSelector((state: RootState) => state.contents);

  const dispatch = useDispatch();

  const onGetPopularMenu = (info: GetPopularMenuParamsType) => {
    dispatch(getPopularMenuAsync.request(info));
  };

  const onGetPopularContent = (info: GetPopularContentParamsType) => {
    dispatch(getPopularContentAsync.request(info));
  };

  const onGetTotalContent = (info: GetTotalContentParamsType) => {
    dispatch(getTotalContentAsync.request(info));
  };

  const onGetExportExcel = (info: GetExportStatisticsExcelParamType) => {
    dispatch(getExportExcelAsync.request(info));
  };

  // excel
  const onClickExcelData = (data: ContentsTableCardState['excelData']) => {
    onGetExportExcel({
      time: `${moment(startDate)
        .format('YYYY-MM-DD')
        .replace(/-/g, '')}-${moment(endDate)
        .format('YYYY-MM-DD')
        .replace(/-/g, '')}`,
      type: 'content',
      payload: data,
    });
  };

  useEffect(() => {
    if (!isDatePickerToggle) {
      onGetPopularMenu({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
      onGetPopularContent({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
      onGetTotalContent({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
    }
  }, [isDatePickerToggle, startDate, endDate]);

  return (
    <ContentsLayout>
      <Header
        title="콘텐츠 분석"
        icon=""
        iconClass="ri-user-received-line"
        isDatePicker
      />
      <ContentBodyLayout>
        <ContentsItemLayout desktop={6}>
          <PopularMenuCard
            cardTitle="메뉴 조회수"
            type="menu"
            isLoading={isLoadingMenu}
            data={menuData}
          />
        </ContentsItemLayout>

        <ContentsItemLayout desktop={6}>
          <PopularMenuCard
            cardTitle="게시글 조회수"
            type="content"
            isLoading={isLoadingcontent}
            data={contentData}
          />
        </ContentsItemLayout>

        <ContentsItemLayout desktop={12}>
          <ContentsTableCard
            title="전체 URL별 페이지뷰"
            loading={isLoadingTotal}
            data={TotalData}
            onClickExcel={onClickExcelData}
          />
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default Contents;
