import React from 'react';
import styled from 'styled-components';
import { H1 } from '../../../Atoms/Typo';

const Component = styled.div`
  span {
    margin-right: 8px;
    font-weight: 500;
  }
`;

interface ContentsHeadingProps {
  className?: string;
  title: string;
  iconClass?: string;
  icon?: string;
}

const ContentsHeading = ({
  title,
  className,
  iconClass,
  icon,
}: ContentsHeadingProps) => {
  return (
    <Component className={className}>
      <H1>
        <span className={iconClass}>{icon}</span>
        {title}
      </H1>
    </Component>
  );
};

export default ContentsHeading;
