import { takeLatest } from 'redux-saga/effects';
import createRequestSaga from '../../createRequestSaga';
import {
  apiGetDetailSearch,
  apiGetDetailSocial,
  apiGetInflowPath,
  apiGetInflowPathCount,
  apiGetRecommendInflow,
  apiGetSearchInflow,
  apiGetSocialInflow,
  apiGetTotalInflowCount,
  apiGetTotalKeyword,
} from '../../../api/userAnalytics/inflowPath/api';
import {
  GET_DETAIL_SEARCH,
  GET_DETAIL_SOCIAL,
  GET_INFLOW_PATH,
  GET_INFLOW_PATH_COUNT,
  GET_RECOMMEND_INFLOW,
  GET_SEARCH_INFLOW,
  GET_SOCIAL_INFLOW,
  GET_TOTAL_INFLOW_COUNT,
  GET_TOTAL_KEYWORD,
  GetDetailSearchAsync,
  GetDetailSocialAsync,
  GetRecommendInflowAsync,
  GetTotalKeywordAsync,
  getInflowPathAsync,
  getInflowPathCountAsync,
  getSearchInflowAsync,
  getSocialInflowAsync,
  getTotalInflowCountAsync,
} from './actions';

const GetTotalInflowSaga = createRequestSaga(
  getTotalInflowCountAsync,
  apiGetTotalInflowCount
);

const getInflowPathSaga = createRequestSaga(
  getInflowPathAsync,
  apiGetInflowPath
);
const getInflowPathCountSaga = createRequestSaga(
  getInflowPathCountAsync,
  apiGetInflowPathCount
);
const getSearchInflowSaga = createRequestSaga(
  getSearchInflowAsync,
  apiGetSearchInflow
);
const getSocialInflowSaga = createRequestSaga(
  getSocialInflowAsync,
  apiGetSocialInflow
);
const getRecommendInflowSaga = createRequestSaga(
  GetRecommendInflowAsync,
  apiGetRecommendInflow
);
const getDetailSearchSaga = createRequestSaga(
  GetDetailSearchAsync,
  apiGetDetailSearch
);
const getTotalKeywordSaga = createRequestSaga(
  GetTotalKeywordAsync,
  apiGetTotalKeyword
);
const getDetailSocialSaga = createRequestSaga(
  GetDetailSocialAsync,
  apiGetDetailSocial
);

export function* inflowPathSaga() {
  yield takeLatest(GET_TOTAL_INFLOW_COUNT, GetTotalInflowSaga);
  yield takeLatest(GET_INFLOW_PATH, getInflowPathSaga);
  yield takeLatest(GET_INFLOW_PATH_COUNT, getInflowPathCountSaga);
  yield takeLatest(GET_SEARCH_INFLOW, getSearchInflowSaga);
  yield takeLatest(GET_SOCIAL_INFLOW, getSocialInflowSaga);
  yield takeLatest(GET_RECOMMEND_INFLOW, getRecommendInflowSaga);
  yield takeLatest(GET_DETAIL_SEARCH, getDetailSearchSaga);
  yield takeLatest(GET_DETAIL_SOCIAL, getDetailSocialSaga);
  yield takeLatest(GET_TOTAL_KEYWORD, getTotalKeywordSaga);
}

export { inflowPathSaga as default };
