import { createAsyncAction, createAction } from 'typesafe-actions';
import { AxiosErrorType } from '../createRequestSaga';
import {
  GetTotalUserStatisticsParamType,
  GetTotalUserStatisticsResponseType,
  GetPvPerSessionStatisticsParamType,
  GetPvPerSessionStatisticsResponseType,
  GetTotalSessionStatisticsParamType,
  GetTotalSessionStatisticsResponseType,
  GetUsageMeanStatisticsParamType,
  GetUsageMeanStatisticsResponseType,
  GetUserVisitStatisticsParamType,
  GetUserVisitStatisticsResponseType,
  GetHourlyUserStatisticsParamType,
  GetHourlyUserStatisticsResponseType,
  GetInflowKeywordStatisticsParamType,
  GetInflowKeywordStatisticsResponseType,
  GetPopularPageStatisticsParamType,
  GetPopularPageStatisticsResponseType,
  GetUserEnvironmentStatisticsParamType,
  GetUserEnvironmentStatisticsResponseType,
  GetInflowPathStatisticsParamType,
  GetInflowPathStatisticsResponseType,
} from '../../api/Dashboard/type';

export const GET_TOTAL_USER_STATISTICS =
  'dashboard/GET_TOTAL_USER_STATISTICS' as const;
export const GET_TOTAL_USER_STATISTICS_SUCCESS =
  'dashboard/GET_TOTAL_USER_STATISTICS_SUCCESS' as const;
export const GET_TOTAL_USER_STATISTICS_ERROR =
  'dashboard/GET_TOTAL_USER_STATISTICS_ERROR' as const;

export const GET_PV_PER_SESSION_STATISTICS =
  'dashboard/GET_PV_PER_SESSION_STATISTICS' as const;
export const GET_PV_PER_SESSION_STATISTICS_SUCCESS =
  'dashboard/GET_PV_PER_SESSION_STATISTICS_SUCCESS' as const;
export const GET_PV_PER_SESSION_STATISTICS_ERROR =
  'dashboard/GET_PV_PER_SESSION_STATISTICS_ERROR' as const;

export const GET_TOTAL_SESSION_STATISTICS =
  'dashboard/GET_TOTAL_SESSION_STATISTICS' as const;
export const GET_TOTAL_SESSION_STATISTICS_SUCCESS =
  'dashboard/GET_TOTAL_SESSION_STATISTICS_SUCCESS' as const;
export const GET_TOTAL_SESSION_STATISTICS_ERROR =
  'dashboard/GET_TOTAL_SESSION_STATISTICS_ERROR' as const;

export const GET_USAGE_MEAN_STATISTICS =
  'dashboard/GET_USAGE_MEAN_STATISTICS' as const;
export const GET_USAGE_MEAN_STATISTICS_SUCCESS =
  'dashboard/GET_USAGE_MEAN_STATISTICS_SUCCESS' as const;
export const GET_USAGE_MEAN_STATISTICS_ERROR =
  'dashboard/GET_USAGE_MEAN_STATISTICS_ERROR' as const;

export const GET_USER_VISIT_STATISTICS =
  'dashboard/GET_USER_VISIT_STATISTICS' as const;
export const GET_USER_VISIT_STATISTICS_SUCCESS =
  'dashboard/GET_USER_VISIT_STATISTICS_SUCCESS' as const;
export const GET_USER_VISIT_STATISTICS_ERROR =
  'dashboard/GET_USER_VISIT_STATISTICS_ERROR' as const;

export const GET_HOURLY_USER_STATISTIC =
  'dashboard/GET_HOURLY_USER_STATISTIC' as const;
export const GET_HOURLY_USER_STATISTIC_SUCCESS =
  'dashboard/GET_HOURLY_USER_STATISTIC_SUCCESS' as const;
export const GET_HOURLY_USER_STATISTIC_ERROR =
  'dashboard/GET_HOURLY_USER_STATISTIC_ERROR' as const;

export const GET_INFLOW_KEYWORD_STATISTICS =
  'dashboard/GET_INFLOW_KEYWORD_STATISTICS' as const;
export const GET_INFLOW_KEYWORD_STATISTICS_SUCCESS =
  'dashboard/GET_INFLOW_KEYWORD_STATISTICS_SUCCESS' as const;
export const GET_INFLOW_KEYWORD_STATISTICS_ERROR =
  'dashboard/GET_INFLOW_KEYWORD_STATISTICS_ERROR' as const;

export const GET_POPULAR_PAGE_STATISTICS =
  'dashboard/GET_POPULAR_PAGE_STATISTICS' as const;
export const GET_POPULAR_PAGE_STATISTICS_SUCCESS =
  'dashboard/GET_POPULAR_PAGE_STATISTICS_SUCCESS' as const;
export const GET_POPULAR_PAGE_STATISTICS_ERROR =
  'dashboard/GET_POPULAR_PAGE_STATISTICS_ERROR' as const;

export const GET_USER_ENVIRONMENT_STATISTICS =
  'dashboard/GET_USER_ENVIRONMENT_STATISTICS' as const;
export const GET_USER_ENVIRONMENT_STATISTICS_SUCCESS =
  'dashboard/GET_USER_ENVIRONMENT_STATISTICS_SUCCESS' as const;
export const GET_USER_ENVIRONMENT_STATISTICS_ERROR =
  'dashboard/GET_USER_ENVIRONMENT_STATISTICS_ERROR' as const;

export const GET_INFLOW_PATH_STATISTICS =
  'dashboard/GET_INFLOW_PATH_STATISTICS' as const;
export const GET_INFLOW_PATH_STATISTICS_SUCCESS =
  'dashboard/GET_INFLOW_PATH_STATISTICS_SUCCESS' as const;
export const GET_INFLOW_PATH_STATISTICS_ERROR =
  'dashboard/GET_INFLOW_PATH_STATISTICS_ERROR' as const;

export const SET_ERROR_NULL_DASHBAORD = 'dashboard/SET_ERROR_NULL_DASHBAORD';

export const getTotalUserStatisticsAsync = createAsyncAction(
  GET_TOTAL_USER_STATISTICS,
  GET_TOTAL_USER_STATISTICS_SUCCESS,
  GET_TOTAL_USER_STATISTICS_ERROR
)<
  GetTotalUserStatisticsParamType,
  GetTotalUserStatisticsResponseType,
  AxiosErrorType
>();

export const getPvPerSessionAsync = createAsyncAction(
  GET_PV_PER_SESSION_STATISTICS,
  GET_PV_PER_SESSION_STATISTICS_SUCCESS,
  GET_PV_PER_SESSION_STATISTICS_ERROR
)<
  GetPvPerSessionStatisticsParamType,
  GetPvPerSessionStatisticsResponseType,
  AxiosErrorType
>();

export const getTotalSessionStatisticsAsync = createAsyncAction(
  GET_TOTAL_SESSION_STATISTICS,
  GET_TOTAL_SESSION_STATISTICS_SUCCESS,
  GET_TOTAL_SESSION_STATISTICS_ERROR
)<
  GetTotalSessionStatisticsParamType,
  GetTotalSessionStatisticsResponseType,
  AxiosErrorType
>();

export const getUsageMeanStatisticsAsync = createAsyncAction(
  GET_USAGE_MEAN_STATISTICS,
  GET_USAGE_MEAN_STATISTICS_SUCCESS,
  GET_USAGE_MEAN_STATISTICS_ERROR
)<
  GetUsageMeanStatisticsParamType,
  GetUsageMeanStatisticsResponseType,
  AxiosErrorType
>();

export const getUserVisitStatisticsAsync = createAsyncAction(
  GET_USER_VISIT_STATISTICS,
  GET_USER_VISIT_STATISTICS_SUCCESS,
  GET_USER_VISIT_STATISTICS_ERROR
)<
  GetUserVisitStatisticsParamType,
  GetUserVisitStatisticsResponseType,
  AxiosErrorType
>();

export const getHourlyUserStatisticsAsync = createAsyncAction(
  GET_HOURLY_USER_STATISTIC,
  GET_HOURLY_USER_STATISTIC_SUCCESS,
  GET_HOURLY_USER_STATISTIC_ERROR
)<
  GetHourlyUserStatisticsParamType,
  GetHourlyUserStatisticsResponseType,
  AxiosErrorType
>();

export const getInflowKeywordStatisticsAsync = createAsyncAction(
  GET_INFLOW_KEYWORD_STATISTICS,
  GET_INFLOW_KEYWORD_STATISTICS_SUCCESS,
  GET_INFLOW_KEYWORD_STATISTICS_ERROR
)<
  GetInflowKeywordStatisticsParamType,
  GetInflowKeywordStatisticsResponseType,
  AxiosErrorType
>();

export const getPopularPageStatisticsAsync = createAsyncAction(
  GET_POPULAR_PAGE_STATISTICS,
  GET_POPULAR_PAGE_STATISTICS_SUCCESS,
  GET_POPULAR_PAGE_STATISTICS_ERROR
)<
  GetPopularPageStatisticsParamType,
  GetPopularPageStatisticsResponseType,
  AxiosErrorType
>();

export const getUserEnvironmentStatisticsAsync = createAsyncAction(
  GET_USER_ENVIRONMENT_STATISTICS,
  GET_USER_ENVIRONMENT_STATISTICS_SUCCESS,
  GET_USER_ENVIRONMENT_STATISTICS_ERROR
)<
  GetUserEnvironmentStatisticsParamType,
  GetUserEnvironmentStatisticsResponseType,
  AxiosErrorType
>();

export const getInflowPathStatisticsAsync = createAsyncAction(
  GET_INFLOW_PATH_STATISTICS,
  GET_INFLOW_PATH_STATISTICS_SUCCESS,
  GET_INFLOW_PATH_STATISTICS_ERROR
)<
  GetInflowPathStatisticsParamType,
  GetInflowPathStatisticsResponseType,
  AxiosErrorType
>();

export const setErrorNullDashboard = createAction(SET_ERROR_NULL_DASHBAORD)();
