import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Chart } from 'react-google-charts';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import {
  GoogleChartControl,
  GoogleChartWrapper,
  GoogleViz,
  ReactGoogleChartProps,
} from 'react-google-charts/dist/types';
import Spinner from '../../Spinner';
import Tooltip from '../Tooltip/LocalMapChartTooltip';

const WorldMapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 650px;
`;
const LegendWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 20px;
`;

const LegendBar = styled.div`
  background-image: linear-gradient(90deg, #d9e6fd 0%, #2962ff 100%);
  width: 400px;
  height: 10px;
  margin: 8px 10px;
`;

const LegendValueVariable = css`
  font-weight: 500;
  font-size: 15px;
  color: ${(props) => props.theme.colors.grey900};
`;

const LegendValue = styled.div`
  ${LegendValueVariable}
`;

const DataError = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: ${(props) => props.theme.colors.grey800};
`;

const options = {
  legend: 'none',
  backGroundColor: { stroke: '#000000' },
  colorAxis: { minValue: 0, colors: ['#E2EFFF', '#9CC1FA'] },
  datalessRegionColor: '#E2EFFF',
  magnifyingGlass: { enable: true, zoomFactor: 7.5 },
  tooltip: {
    trigger: 'none',
  },
};

interface WorldMapProps {
  data: {
    code: string;
    total: number;
    old: number;
    new: number;
    country_name_en: string;
    country_name_ko: string;
  }[];
  isLoading: boolean;
}

const WorldMap = ({ data, isLoading }: WorldMapProps) => {
  const ChartEl = useRef<HTMLDivElement | null>(null);
  const [geoCode, setGeoCode] = useState<string | null>(null);
  const [tooltipInfo, setTooltipInfo] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });
  const [isTooltipShow, setIsTooltipShow] = useState(false);
  let lastEvent: null | any = null;
  const [max, setMax] = useState(0);
  const [series, setSeries] = useState<(string | number)[][]>([
    ['Country', '전체 사용자 수'],
  ]);

  useEffect(() => {
    if (data.length > 0) {
      let maxTemp = 0;
      const tempSereis: (string | number)[][] = [['Country', '전체 사용자 수']];
      data.forEach((element) => {
        if (maxTemp < element.total) {
          maxTemp = element.total;
        }
        tempSereis.push([`${element.code}`, element.total]);
      });
      setMax(maxTemp);
      setSeries(tempSereis);
    }
  }, [data]);

  useEffect(() => {
    const { current } = ChartEl;

    if (current) {
      let previosFill = '';
      current.addEventListener('click', (e) => {
        lastEvent = e;
      });
      current.addEventListener(
        'mousemove',
        _.throttle((e) => {
          lastEvent = e;
          const event = document.createEvent('MouseEvent');
          event.initEvent('click', true, true);
          e.target.dispatchEvent(event);

          const targetArr = Object.keys(e.target.attributes);

          if (targetArr.length > 3) {
            const fill = e.target.attributes[targetArr[3]];

            // console.log('fill: ', e.target);
            // const targetDiv = e.target as HTMLDivElement;
            // targetDiv.style.stroke = 'red';

            if (fill.value === '#ffffff' || fill.value !== previosFill) {
              setGeoCode(null);
            }
            previosFill = fill.value;
          }

          let x = 0;
          let y = 0;
          if (e.pageX) {
            x = e.pageX;
            y = e.pageY;
          } else if (e.clientX) {
            let offsetX;
            let offsetY = 0;
            if (document.documentElement.scrollLeft) {
              offsetX = document.documentElement.scrollLeft;
              offsetY = document.documentElement.scrollTop;
            } else if (document.body) {
              offsetX = document.body.scrollLeft;
              offsetY = document.body.scrollTop;
            }

            x = e.clientX + offsetX;
            y = e.clientY + offsetY;
          }
          setTooltipInfo({
            top: y - current.getBoundingClientRect().top,
            left: x - current.getBoundingClientRect().left,
          });
        }, 0)
      );
    }
  }, [ChartEl.current]);

  useEffect(() => {
    if (geoCode) {
      setIsTooltipShow(true);
    } else {
      setIsTooltipShow(false);
    }
  }, [geoCode]);

  const ChartSelectCallback = (eventCallbackArgs: {
    chartWrapper: GoogleChartWrapper;
    controlWrapper?: GoogleChartControl | undefined;
    props: ReactGoogleChartProps;
    google: GoogleViz;
    eventArgs: any;
  }) => {
    const chart = eventCallbackArgs.chartWrapper.getChart();
    const selection = chart.getSelection();
    if (selection.length === 0) return;
    if (series.length > 0) {
      const region = series[selection[0].row + 1][0];
      setGeoCode(region as string);
    }
  };

  const printTooltipMemo = useMemo(() => {
    if (isTooltipShow) {
      let total = 0;
      let old = 0;
      let new_user = 0;
      let kor_name = '';
      if (data.length > 0) {
        data.some((element) => {
          if (element.code === geoCode) {
            total = element.total;
            old = element.old;
            new_user = element.new;
            kor_name = element.country_name_ko;
            return true;
          }
          return false;
        });
      }

      return (
        <Tooltip
          isShow={isTooltipShow}
          newUser={new_user}
          old={old}
          total={total}
          title={kor_name}
          top={tooltipInfo.top}
          left={tooltipInfo.left}
        />
      );
    }
    return null;
  }, [isTooltipShow, tooltipInfo]);

  const printChart = useMemo(() => {
    if (series.length > 1) {
      return (
        <Chart
          chartEvents={[
            {
              eventName: 'select',
              callback: ChartSelectCallback,
            },
          ]}
          chartType="GeoChart"
          width="100%"
          height="100%"
          data={series}
          options={options}
        />
      );
    }
    return <DataError>데이터를 불러오지 못했습니다.</DataError>;
  }, [series]);
  return (
    <WorldMapWrapper ref={ChartEl}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <LegendWrapper>
            <LegendValue>0</LegendValue>
            <LegendBar />
            <LegendValue>{max.toLocaleString()}</LegendValue>
          </LegendWrapper>
          {printChart}
          {printTooltipMemo}
        </>
      )}
    </WorldMapWrapper>
  );
};

export default WorldMap;
