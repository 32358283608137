import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import produce from 'immer';
import axios from 'axios';

import { useSelector } from 'react-redux';
import { RootState } from '../../modules';

import ContentsLayout from '../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../components/Atoms/Layout/Contents/ContentsItemLayout';

import { H3, P1, P3 } from '../../components/Atoms/Typo';
import CardLayout from '../../components/Atoms/Layout/Card/CardLayout';
import DefaultLink from '../../components/Atoms/Link';

const PageHeader = styled.div`
  width: 100%;
  display: flex;
  padding-top: 45px;
  margin-bottom: 30px;
`;

const SettingsIcon = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 15px;
  margin-left: 10px;
  background-color: #dde1eb;
  text-align: center;
  span {
    font-size: 20px;
    font-weight: 400;
    color: #222222;
  }
  cursor: pointer;
`;

const InformCard = styled.div`
  width: 100%;
  height: 70px;
  border-radius: 14px;
  background-color: #6050dd;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PlanWrapper = styled.div`
  display: flex;
  ${P1} {
    color: white;
    span {
      color: #f99e32;
      font-weight: 600;
    }
  }
`;

const DetailWrapper = styled.div`
  display: flex;
`;

const Title = styled(P3)`
  font-size: 15px;
  color: #ffffff;
  font-weight: 500;
  margin-right: 10px;
  opacity: 0.8;
  padding-left: 20px;
`;

const ValueText = styled(P3)`
  font-size: 15px;
  color: #ffffff;
  opacity: 0.8;
  :first-child {
    color: black;
  }
`;

const QuestionCard = styled(CardLayout)`
  min-height: 700px;
  padding: 40px;
  flex-direction: row;
`;

const CardTitleWrap = styled.div`
  width: 25%;
`;

const CardBodyWrap = styled.div`
  width: 75%;
  padding: 0 20px;
`;

const ContactForm = styled.form`
  position: relative;
  .contact-id {
    display: none;
  }
`;

const ContactTitle = styled(P1)`
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 10px;
  :first-child {
    margin-top: 0;
  }
  span {
    font-size: 15px;
    color: ${(props) => props.theme.colors.blue};
    font-weight: 400;
  }
`;

const ConTactInput = styled.input<{ $status: string }>`
  padding: 0 16px;
  width: 100%;
  height: 40px;
  font-weight: 400;
  font-size: 16px;
  color: ${(props) => props.theme.colors.grey700};
  border-radius: 5px;
  border: solid 1px ${(props) => props.theme.colors.grey500};
  :focus {
    border: 2px solid #6050dd;
    outline: none;
  }

  ${(props) => {
    if (props.$status === 'error') {
      return css`
        border: 2px solid ${props.theme.colors.danger5} !important;
      `;
    }
    return css``;
  }}
`;

const ContactTextArea = styled.textarea<{ $status: string }>`
  width: 100%;
  height: 300px;
  padding: 10px 16px;
  font-weight: 400;
  font-size: 16px;
  color: ${(props) => props.theme.colors.grey700};
  border-radius: 5px;
  border: solid 1px ${(props) => props.theme.colors.grey500};
  :focus {
    border: 2px solid #6050dd;
    outline: none;
  }

  ${(props) => {
    if (props.$status === 'error') {
      return css`
        border: 2px solid ${props.theme.colors.danger5} !important;
      `;
    }
    return css``;
  }}
`;

const ConfirmButton = styled.button<{ $active: boolean }>`
  width: 100px;
  height: 40px;
  margin-top: 15px;
  background-color: ${(props) => props.theme.colors.grey200};
  color: ${(props) => props.theme.colors.grey700};
  border-radius: 8px;
  border: none;
  font-size: 15px;
  cursor: not-allowed;

  ${(props) => {
    if (props.$active) {
      return css`
        cursor: pointer;
        color: #ffffff;
        background-color: #6050dd;
      `;
    }
    return css``;
  }}
`;

const Iframe = styled.iframe`
  display: none;
`;

export const Toast = styled.div<{ isSendOk: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${P3} {
    color: white;
  }

  position: absolute;
  background-color: #333333;
  border-radius: 8px;

  right: 50%;
  bottom: 0%;
  transform: translate(50%, 25%);
  transition: opacity 0.8s cubic-bezier(0, 0.55, 0.45, 1);

  //mobile
  width: 290px;
  height: 72px;

  ${(props) => {
    if (props.isSendOk) {
      return css`
        opacity: 0.8;
      `;
    }
    return css`
      opacity: 0;
    `;
  }}
`;

const MyPage = () => {
  // state
  const [license, setLicense] = useState('');
  const [remainPV, setRemainPV] = useState(0);
  const [remainDate, setRemainDate] = useState(0);
  const [inputState, setInputState] = useState<{
    [key: string]: string;
  }>({
    name: '',
    inquiry: '',
    email: '',
    id: '',
  });

  const [inputValidation, setInputValidation] = useState<{
    [key: string]: 'default' | 'error';
  }>({
    name: 'default',
    inquiry: 'default',
    email: 'default',
  });
  const [isAllCurrcnt, setIsAllCurrect] = useState(false);
  const [isSendOk, setIsSendOk] = useState(false);

  // redux
  const { user_name, pvUsageData } = useSelector(
    (state: RootState) => state.authentication
  );

  // slack webHook
  const WebHooksToSlack = async () => {
    const options = {
      attachments: [
        {
          fallback: 'VODA 관리자페이지를 통한 문의',
          color: '#36a64f',
          fields: [
            {
              title: '문의 계정',
              value: inputState.id,
              short: false,
            },
            {
              title: '제목',
              value: inputState.name,
              short: false,
            },
            {
              title: '이메일',
              value: inputState.email,
              short: false,
            },
            {
              title: '문의내용',
              value: inputState.inquiry,
              short: false,
            },
          ],
        },
      ],
    };

    return axios({
      method: 'post',
      url: `https://hooks.slack.com/services/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`,
      timeout: 60000,
      data: JSON.stringify(options),
    }).then(() => {
      setIsSendOk(true);
      setTimeout(() => {
        setIsSendOk(false);
      }, 2000);
    });
  };

  const validationCheckInput = (value: string): 'default' | 'error' => {
    const blank_pattern = /([^ ])/g;

    if (value !== '' && blank_pattern.test(value)) {
      return 'default';
    }

    return 'error';
  };

  const validationCheckEmail = (value: string): 'default' | 'error' => {
    const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (pattern.test(value)) {
      return 'default';
    }

    return 'error';
  };

  const onChangeInput = (
    value: string,
    inputType: 'name' | 'email' | 'inquiry'
  ) => {
    setInputState(
      produce((draft) => {
        draft[inputType] = value;
      })
    );

    if (inputType === 'name') {
      setInputValidation(
        produce((draft) => {
          draft[inputType] = validationCheckInput(value);
        })
      );
    } else if (inputType === 'email') {
      setInputValidation(
        produce((draft) => {
          draft[inputType] = validationCheckEmail(value);
        })
      );
    } else {
      setInputValidation(
        produce((draft) => {
          draft[inputType] = validationCheckInput(value);
        })
      );
    }
  };

  const onClickContactButton = () => {
    WebHooksToSlack();
  };

  useEffect(() => {
    const key_arr = Object.keys(inputValidation);
    const correctArr: boolean[] = [];

    key_arr.forEach((element) => {
      if (
        inputValidation[element] === 'default' &&
        inputState[element].length > 0
      ) {
        correctArr.push(true);
      } else {
        correctArr.push(false);
      }
    });

    setIsAllCurrect(correctArr.every((value) => value === true));
  }, [inputValidation, inputState]);

  useEffect(() => {
    if (pvUsageData) {
      setLicense(pvUsageData.license);
      setRemainPV(pvUsageData.remain_pv);
      setRemainDate(pvUsageData.remain_date);
    }
  }, [pvUsageData]);

  useEffect(() => {
    const item = 'id';
    setInputState(
      produce((draft) => {
        draft[item] = user_name;
      })
    );
  }, [user_name]);

  return (
    <ContentsLayout>
      <PageHeader>
        <H3>{user_name}</H3>
        <DefaultLink to="/myPage/settings">
          <SettingsIcon>
            <span className="ri-settings-3-line"></span>
          </SettingsIcon>
        </DefaultLink>
      </PageHeader>
      <ContentBodyLayout>
        <ContentsItemLayout desktop={12}>
          <InformCard>
            <PlanWrapper>
              <P1>
                <span>{license}</span> 플랜을 이용중입니다.
              </P1>
            </PlanWrapper>
            <DetailWrapper>
              <Title>남은기간</Title>
              <ValueText>{remainDate.toLocaleString()}일</ValueText>
              <Title>이용가능PV</Title>
              <ValueText>{remainPV.toLocaleString()}PV</ValueText>
            </DetailWrapper>
          </InformCard>
        </ContentsItemLayout>

        <ContentsItemLayout desktop={12}>
          <QuestionCard>
            <CardTitleWrap>
              <H3>문의하기</H3>
            </CardTitleWrap>
            <CardBodyWrap>
              <ContactForm
                className="gform"
                method="post"
                action="https://script.google.com/macros/s/AKfycbw7-fIElFhWzFj0IaPEj2X2ilNQ4MRwe0Ha3W8gBHaqVJ8u16FJLvCh1jbcZYTBp_Up/exec"
                target="frAttachFiles"
              >
                <ContactTitle>
                  제목 <span>(필수)</span>
                </ContactTitle>
                <ConTactInput
                  $status={inputValidation.name}
                  name="name"
                  placeholder="제목을 입력해 주세요."
                  onChange={(event) => {
                    onChangeInput(event.target.value, 'name');
                  }}
                />
                <ContactTitle>
                  내용 <span>(필수)</span>
                </ContactTitle>
                <ContactTextArea
                  $status={inputValidation.inquiry}
                  name="text"
                  placeholder="내용을 입력해 주세요."
                  onChange={(event) => {
                    onChangeInput(event.target.value, 'inquiry');
                  }}
                />
                <ContactTitle>
                  이메일주소 <span>(필수)</span>
                </ContactTitle>
                <ConTactInput
                  $status={inputValidation.email}
                  name="email"
                  placeholder="이메일 아이디를 입력해 주세요."
                  onChange={(event) => {
                    onChangeInput(event.target.value, 'email');
                  }}
                />
                <input
                  className="contact-id"
                  name="id"
                  value={inputState.id}
                  readOnly
                />
                <ConfirmButton
                  type="submit"
                  onClick={onClickContactButton}
                  $active={isAllCurrcnt}
                  disabled={!isAllCurrcnt}
                >
                  문의하기
                </ConfirmButton>
                <Toast isSendOk={isSendOk}>
                  <P3>보내주신 문의사항이 성공적으로 접수되었습니다.</P3>
                </Toast>
              </ContactForm>
              <Iframe name="frAttachFiles" />
            </CardBodyWrap>
          </QuestionCard>
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default MyPage;
