import { createAsyncAction, createAction } from 'typesafe-actions';
import { AxiosErrorType } from '../../createRequestSaga';
import {
  GetInflowPathParamType,
  GetInflowPathResponseType,
  GetInflowPathCountParamType,
  GetInflowPathCountResponseType,
  GetSearchInflowParamType,
  GetSearchInflowResponseType,
  GetSocialInflowParamType,
  GetSocialInflowResponseType,
  GetRecommendInflowParamType,
  GetRecommendInflowResponseType,
  GetDetailSearchParamType,
  GetDetailSearchResponseType,
  GetDetailSocialParamType,
  GetDetailSocialResponseType,
  GetTotalKeywordParamType,
  GetTotalKeywordResponseType,
  GetTotalInflowCountParamType,
  GetTotalInflowCountResponseType,
} from '../../../api/userAnalytics/inflowPath/type';

// 전체 유입 수
export const GET_TOTAL_INFLOW_COUNT =
  'userAnalytics/inflowPath/GET_TOTAL_INFLOW_COUNT' as const;
export const GET_TOTAL_INFLOW_COUNT_SUCCESS =
  'userAnalytics/inflowPath/GET_TOTAL_INFLOW_COUNT_SUCCESS' as const;
export const GET_TOTAL_INFLOW_COUNT_ERROR =
  'userAnalytics/inflowPath/GET_TOTAL_INFLOW_COUNT_ERROR' as const;

export const getTotalInflowCountAsync = createAsyncAction(
  GET_TOTAL_INFLOW_COUNT,
  GET_TOTAL_INFLOW_COUNT_SUCCESS,
  GET_TOTAL_INFLOW_COUNT_ERROR
)<
  GetTotalInflowCountParamType,
  GetTotalInflowCountResponseType,
  AxiosErrorType
>();

// 유입 경로
export const GET_INFLOW_PATH =
  'userAnalytics/inflowPath/GET_INFLOW_PATH' as const;
export const GET_INFLOW_PATH_SUCCESS =
  'userAnalytics/inflowPath/GET_INFLOW_PATH_SUCCESS' as const;
export const GET_INFLOW_PATH_ERROR =
  'userAnalytics/inflowPath/GET_INFLOW_PATH_ERROR' as const;

export const getInflowPathAsync = createAsyncAction(
  GET_INFLOW_PATH,
  GET_INFLOW_PATH_SUCCESS,
  GET_INFLOW_PATH_ERROR
)<GetInflowPathParamType, GetInflowPathResponseType, AxiosErrorType>();

// 일별 유입 경로
export const GET_INFLOW_PATH_COUNT =
  'userAnalytics/inflowPath/GET_INFLOW_PATH_COUNT' as const;
export const GET_INFLOW_PATH_COUNT_SUCCESS =
  'userAnalytics/inflowPath/GET_INFLOW_PATH_COUNT_SUCCESS' as const;
export const GET_INFLOW_PATH_COUNT_ERROR =
  'userAnalytics/inflowPath/GET_INFLOW_PATH_COUNT_ERROR' as const;

export const getInflowPathCountAsync = createAsyncAction(
  GET_INFLOW_PATH_COUNT,
  GET_INFLOW_PATH_COUNT_SUCCESS,
  GET_INFLOW_PATH_COUNT_ERROR
)<
  GetInflowPathCountParamType,
  GetInflowPathCountResponseType,
  AxiosErrorType
>();

// 검색 유입
export const GET_SEARCH_INFLOW =
  'userAnalytics/inflowPath/GET_SEARCH_INFLOW' as const;
export const GET_SEARCH_INFLOW_SUCCESS =
  'userAnalytics/inflowPath/GET_SEARCH_INFLOW_SUCCESS' as const;
export const GET_SEARCH_INFLOW_ERROR =
  'userAnalytics/inflowPath/GET_SEARCH_INFLOW_ERROR' as const;

export const getSearchInflowAsync = createAsyncAction(
  GET_SEARCH_INFLOW,
  GET_SEARCH_INFLOW_SUCCESS,
  GET_SEARCH_INFLOW_ERROR
)<GetSearchInflowParamType, GetSearchInflowResponseType, AxiosErrorType>();

// 소셜 유입
export const GET_SOCIAL_INFLOW =
  'userAnalytics/inflowPath/GET_SOCIAL_INFLOW' as const;
export const GET_SOCIAL_INFLOW_SUCCESS =
  'userAnalytics/inflowPath/GET_SOCIAL_INFLOW_SUCCESS' as const;
export const GET_SOCIAL_INFLOW_ERROR =
  'userAnalytics/inflowPath/GET_SOCIAL_INFLOW_ERROR' as const;

export const getSocialInflowAsync = createAsyncAction(
  GET_SOCIAL_INFLOW,
  GET_SOCIAL_INFLOW_SUCCESS,
  GET_SOCIAL_INFLOW_ERROR
)<GetSocialInflowParamType, GetSocialInflowResponseType, AxiosErrorType>();

// 추천 유입
export const GET_RECOMMEND_INFLOW =
  'userAnalytics/inflowPath/GET_RECOMMEND_INFLOW' as const;
export const GET_RECOMMEND_INFLOW_SUCCESS =
  'userAnalytics/inflowPath/GET_RECOMMEND_INFLOW_SUCCESS' as const;
export const GET_RECOMMEND_INFLOW_ERROR =
  'userAnalytics/inflowPath/GET_RECOMMEND_INFLOW_ERROR' as const;

export const GetRecommendInflowAsync = createAsyncAction(
  GET_RECOMMEND_INFLOW,
  GET_RECOMMEND_INFLOW_SUCCESS,
  GET_RECOMMEND_INFLOW_ERROR
)<
  GetRecommendInflowParamType,
  GetRecommendInflowResponseType,
  AxiosErrorType
>();

// detail searcj
export const GET_DETAIL_SEARCH =
  'userAnalytics/inflowPath/GET_DETAIL_SEARCH' as const;
export const GET_DETAIL_SEARCH_SUCCESS =
  'userAnalytics/inflowPath/GET_DETAIL_SEARCH_SUCCESS' as const;
export const GET_DETAIL_SEARCH_ERROR =
  'userAnalytics/inflowPath/GET_DETAIL_SEARCH_ERROR' as const;

export const GetDetailSearchAsync = createAsyncAction(
  GET_DETAIL_SEARCH,
  GET_DETAIL_SEARCH_SUCCESS,
  GET_DETAIL_SEARCH_ERROR
)<GetDetailSearchParamType, GetDetailSearchResponseType, AxiosErrorType>();

// search rank
export const GET_TOTAL_KEYWORD =
  'userAnalytics/inflowPath/GET_TOTAL_KEYWORD' as const;
export const GET_TOTAL_KEYWORD_SUCCESS =
  'userAnalytics/inflowPath/GET_TOTAL_KEYWORD_SUCCESS' as const;
export const GET_TOTAL_KEYWORD_ERROR =
  'userAnalytics/inflowPath/GET_TOTAL_KEYWORD_ERROR' as const;

export const GetTotalKeywordAsync = createAsyncAction(
  GET_TOTAL_KEYWORD,
  GET_TOTAL_KEYWORD_SUCCESS,
  GET_TOTAL_KEYWORD_ERROR
)<GetTotalKeywordParamType, GetTotalKeywordResponseType, AxiosErrorType>();

// detail social
export const GET_DETAIL_SOCIAL =
  'userAnalytics/inflowPath/GET_DETAIL_SOCIAL' as const;
export const GET_DETAIL_SOCIAL_SUCCESS =
  'userAnalytics/inflowPath/GET_DETAIL_SOCIAL_SUCCESS' as const;
export const GET_DETAIL_SOCIAL_ERROR =
  'userAnalytics/inflowPath/GET_DETAIL_SOCIAL_ERROR' as const;

export const GetDetailSocialAsync = createAsyncAction(
  GET_DETAIL_SOCIAL,
  GET_DETAIL_SOCIAL_SUCCESS,
  GET_DETAIL_SOCIAL_ERROR
)<GetDetailSocialParamType, GetDetailSocialResponseType, AxiosErrorType>();

// error type
export const SET_ERROR_NULL_INFLOW_PATH =
  'serAnalytics/inflowPath/SET_ERROR_NULL_INFLOW_PATH';

export const setErrorNullInflowPath = createAction(
  SET_ERROR_NULL_INFLOW_PATH
)();
