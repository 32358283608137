import { css } from 'styled-components';

export const tooltip = css`
  .apexcharts-tooltip {
    min-width: 170px;
    z-index: 999;
    border-radius: 15px;
    border: solid 1px ${(props) => props.theme.colors.grey700};
  }
  .tooltip {
    padding: 15px 14px;
    border-radius: 15px;
    z-index: 999;
    .innerBox {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:nth-child(1) {
        border-bottom: 1px solid ${(props) => props.theme.colors.grey200};
        margin-bottom: 10px;
      }

      .title {
        font-family: Pretendard;
        font-weight: 600;
        font-size: 15px;
        margin-right: 8px;
        color: ${(props) => props.theme.colors.grey500};
      }

      .all {
        margin-right: 50px;
        color: ${(props) => props.theme.colors.purple7};
      }
      .return {
        color: #009f6f;
      }
      .new {
        color: ${(props) => props.theme.colors.blue};
      }

      .value-all {
        font-family: Pretendard;
        font-weight: 800;
        font-size: 15px;
        margin-right: 4px;
        color: ${(props) => props.theme.colors.grey900};
      }
      .value {
        font-family: Pretendard;
        font-weight: 600;
        font-size: 15px;
        margin-right: 4px;
        color: ${(props) => props.theme.colors.grey900};
      }
      .rate {
        font-family: Pretendard;
        font-weight: 500;
        font-size: 15px;
        color: ${(props) => props.theme.colors.grey600};
      }

      .value-wrap {
        display: flex;
        margin-left: 10px;
      }
    }
  }
`;

export { tooltip as default };
