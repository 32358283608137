import React from 'react';
import styled from 'styled-components';

const Component = styled.div`
  position: relative;
  height: 220px;
  overflow: hidden;
  border-radius: 14px 14px 0 0;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 0%;
`;

interface PageThumbnailImageProps {
  img_src: string;
}

const PageThumbnailImage = ({ img_src }: PageThumbnailImageProps) => {
  return (
    <Component>
      <Image src={img_src} alt="" />
    </Component>
  );
};

export default PageThumbnailImage;
