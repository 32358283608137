import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Chart from 'react-apexcharts';
import { P1 } from '../../../Atoms/Typo';
import DataError from '../../../Atoms/DataError';

const Component = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;

  .apexcharts-pie-series {
    path {
      filter: none;
    }
    filter: none;
  }
`;

const ChartDataLabelWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ChartDataLabelTitle = styled.div`
  ${P1} {
    font-size: 17px;
    color: ${(props) => props.theme.colors.grey900};
    font-weight: bold;
  } ;
`;

const Split = styled.div`
  width: 100%;
  height: 1px;
  background-color: #cecece;
  margin-bottom: 9px;
`;

const ChartDataLabelRate = styled.div`
  ${P1} {
    color: #7851e7;
    font-weight: bold;
  }
`;

interface DashboardDonutProps {
  data: {
    value: string;
    rate: number;
  }[];
}

const DashboardDonut = ({ data }: DashboardDonutProps) => {
  const [seriesState, setSeries] = useState<number[]>([]);

  const options = {
    chart: {
      type: 'donut' as const,
      width: '100%',
    },
    colors: ['#7851E7', '#EFEFEF'],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 3,
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        expandOnClick: false, // Disable click-to-detach for other slices

        donut: {
          size: '83.4%',
        },
      },
    },
    grid: {
      padding: {
        top: -10,
        right: -10,
        bottom: -10,
        left: -10,
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  const printChart = useMemo(() => {
    if (seriesState.length > 0) {
      return (
        <Chart
          type="donut"
          series={seriesState}
          options={options}
          // width="100%"
          // height="100%"
        />
      );
    }
    return <DataError />;
  }, [seriesState]);

  useEffect(() => {
    if (data.length > 0) {
      const seriesTemp: number[] = [];
      data.some((element) => {
        seriesTemp.push(element.rate, 100 - element.rate);
        return false;
      });
      setSeries(seriesTemp);
    }
  }, [data]);

  return (
    <Component>
      {printChart}
      <ChartDataLabelWrapper>
        <ChartDataLabelTitle>
          <P1>{data[0].value}</P1>
        </ChartDataLabelTitle>
        <Split />
        <ChartDataLabelRate>
          <P1>{data[0].rate}%</P1>
        </ChartDataLabelRate>
      </ChartDataLabelWrapper>
    </Component>
  );
};

export default DashboardDonut;
