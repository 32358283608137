import React from 'react';
import styled, { css } from 'styled-components';
import Select from 'react-select';

const Component = styled(Select)<{ $state?: number; $type?: string }>`
  ${(props) => {
    switch (props.$type) {
      case 'state':
        return css`
          display: flex;
          width: 100%;
        `;
      case 'page':
        return css`
          width: 180px;
          color: ${props.theme.colors.black};
        `;
      default:
        return css`
          width: 100%;
          margin-right: 10px;
          color: ${props.theme.colors.black};
        `;
    }
  }}

  .Select__option {
    border-bottom: 1px solid #d9d9d9;
    :last-child {
      border-bottom: none;
    }
  }

  .Select__control {
    height: 40px;
    cursor: pointer;
    border-radius: 8px;
    border: none;

    ${(props) => {
      switch (props.$type) {
        case 'state':
          return css`
            font-size: 12px;
          `;
        case 'page':
          return css`
            font-size: 15px;
          `;
        default:
          return css`
            font-size: 14px;
          `;
      }
    }}
  }

  .Select__control:hover {
    border-color: #a1a1a1;
  }

  .Select__control--is-focused {
    box-shadow: 0 0 0 0px black;
    outline: none;
  }

  .Select__input-container {
    input {
      caret-color: transparent;
    }
  }

  .Select__value-container {
    padding-right: 0px;
  }
  .Select__indicator-separator {
    display: none;
  }

  .Select__menu {
    border: 1px solid #000000
      ${(props) => {
        switch (props.$type) {
          case 'state':
            return css`
              font-size: 12px;
              color: #3c3d3e;
            `;
          case 'page':
            return css`
              font-size: 14px;
              color: ${props.theme.colors.black};
            `;
          default:
            return css`
              font-size: 15px;
              color: ${props.theme.colors.black};
            `;
        }
      }};
  }

  .Select__dropdown-indicator {
    padding-left: 0px;
    color: black;
  }

  .Select__single-value {
    color: #767676;
  }
`;

interface SelectBoxProps {
  state?: number;
  type?: string;
  option: {
    value: string | number;
    label: string;
  }[];
  defaultValue?: {
    value: string | number;
    label: string;
  };
  value?: {
    value: string | number;
    label: string;
  };
  disabled?: boolean;
  placeholder?: string;
  onChange?: (changeValue: any) => void;
}

const customStyles = {
  // control: (provided: any) => ({
  //   ...provided,
  //   color: '#767676',
  // }),
  menu: (provided: any) => ({
    ...provided,
    border: '1px solid #000000',
  }),
  option: (provided: any, state: { isSelected: any }) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#F5F5F5' : 'white', // Set the background color of selected and non-selected options
    color: state.isSelected ? 'black' : '#767676',
    '&:hover': {
      backgroundColor: '#F5F5F5', // Set the background color when hovering over an option
    },
    borderColor: 'black',
  }),
};

const SelectBox = ({
  state,
  type,
  option,
  defaultValue,
  value,
  disabled,
  placeholder,
  onChange,
}: SelectBoxProps) => {
  return (
    <Component
      classNamePrefix="Select"
      $state={state}
      $type={type}
      options={option}
      defaultValue={defaultValue}
      value={value}
      isDisabled={disabled}
      onChange={onChange}
      placeholder={placeholder}
      styles={customStyles}
    />
  );
};

export default SelectBox;
