import React from 'react';
import styled from 'styled-components';
import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import DefaultLink from '../../Atoms/Link';

import PageThumbnailCardHeading from '../../Molecule/Heading/Card/PageThumbnailCardHeading';
import PageThumbnailImage from '../../Molecule/Thumbnail/PageThumbnailImage';

const Component = styled(CardLayout)`
  height: 100%;
  padding: 0;
`;

const Link = styled(DefaultLink)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SplitLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d7dbe6;
`;

interface PageThumbnailCardProps {
  page_id: number;
  simple_url: string;
  page_name: string;
  pv_count: number;
  to: string;
}

const PageThumbnailCard = ({
  page_id,
  simple_url,
  page_name,
  pv_count,
  to,
}: PageThumbnailCardProps) => {
  return (
    <Component>
      <Link to={to}>
        <PageThumbnailImage
          img_src={`https://voda-media.nerdfactory.ai/${localStorage.getItem(
            'voda_tenant'
          )}/auto/${page_id}/${encodeURIComponent('page_screenshot')}`}
        />
        <SplitLine />
      </Link>
      <PageThumbnailCardHeading
        title={page_name}
        url={simple_url}
        pvCount={pv_count}
      />
    </Component>
  );
};

export default PageThumbnailCard;
