import React from 'react';
import styled, { css } from 'styled-components';
import Spinner from '../Spinner';

const ModalBackBlur = styled.div<{ $isModalOpen?: boolean }>`
  position: fixed;
  background-color: black;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;

  ${(props) => {
    if (props.$isModalOpen) {
      return css``;
    }
    return css`
      display: none;
    `;
  }}
  z-index: 400;
`;

const ModalWrapper = styled.div<{ $isModalOpen?: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 50%;
  width: 80px;
  height: 80px;
  background-color: white;
  /* background-color: ${(props) => props.theme.colors.grey700}; */
  border-radius: 25px;
  ${(props) => {
    if (props.$isModalOpen) {
      return css``;
    }
    return css`
      display: none;
    `;
  }}
  z-index: 500;
  overflow: hidden;
`;
const ModalBody = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: ${(props) => props.theme.colors.grey800};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

interface LoadingModalProps {
  isOpen?: boolean;
}

const LoadingModal = ({ isOpen }: LoadingModalProps) => {
  return (
    <>
      <ModalBackBlur $isModalOpen={isOpen} />
      <ModalWrapper $isModalOpen={isOpen}>
        <ModalBody>
          <Spinner />
        </ModalBody>
      </ModalWrapper>
    </>
  );
};

export default LoadingModal;
