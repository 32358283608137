import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Logo from '../../../assets/images/logo-voda.png';
import ContentsLayout from '../../Atoms/Layout/Contents/ContentsLayout';
import { P1, P2, P3, P4 } from '../../Atoms/Typo';
import { IconCheck, IconPin } from '../../Atoms/Icon';
import { RootState } from '../../../modules';
import {
  getCheckScriptAsync,
  setToggleCheck,
} from '../../../modules/authentication';
import LoadingModal from '../../Molecule/Modal/LoadingModal';
import DefaultModal from '../../UIComponents/Modal/DefaultModal';

const Component = styled(ContentsLayout)`
  width: 100%;
  height: 100%;
  background-color: #f1f3f8;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GuideCard = styled.div`
  width: 950px;
  height: 700px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 100px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  ${P1} {
    margin-left: 10px;
    font-size: 25px;
    color: #00c0c8;
  }
`;

const InformWrapper = styled.div`
  width: 100%;
`;

const InformTitle = styled(P2)`
  margin-bottom: 10px;
`;

const InformBody = styled.div`
  display: flex;
  div {
    width: 50%;
  }
  .right {
    display: flex;
    svg {
      min-width: 15px;
      min-height: 15px;
      margin-top: 5px;
      margin-right: 5px;
    }
  }
  ${P2} {
    color: ${(props) => props.theme.colors.primary};
    margin-bottom: 10px;
    font-size: 15px;
  }
`;

const CodeLayout = styled.div`
  display: flex;
  width: 100%;
  height: 260px;
  padding: 5px 10px;
  margin-top: 10px;
  background-color: #f1f3f8;
  border-radius: 5px;
  overflow: scroll;
`;

const CodeLineNumber = styled.div`
  min-width: 25px;
  height: 100%;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CodeBody = styled.div`
  margin-left: 5px;
`;

const CodeScript = styled.pre`
  font-size: 12px;
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  button {
    width: 60px;
    height: 30px;
    border: 1px solid ${(props) => props.theme.colors.grey500};
    border-radius: 8px;
    background-color: white;
    cursor: pointer;
    :last-child {
      background-color: ${(props) => props.theme.colors.primary};
      border: none;
      margin-left: 12px;
      ${P3} {
        color: white;
      }
    }
  }
`;

const RowDiv = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 100px;
    margin-right: 5px;
  }
`;

const ScriptGuideTemplate = () => {
  const [errMessage, setErrMessage] = useState('');
  const [openErrModal, setOpenErrModal] = useState(false);

  const dispatch = useDispatch();
  const { userId, userTenant, isExistsScript, isloadingCheckScript, isCheck } =
    useSelector((state: RootState) => state.authentication);
  const textRef = useRef<HTMLPreElement>(null);

  const handleCopyClipBoard = async (text?: string | null) => {
    if (text) {
      try {
        await navigator.clipboard.writeText(text);
        alert('클립보드에 복사되었습니다.');
      } catch (e) {
        alert('복사에 실패하였습니다');
      }
    }
  };

  const checkScriptButton = () => {
    dispatch(getCheckScriptAsync.request({ user_id: userId }));
  };

  // 스크립트 코드 설치 확인됐을때 isCheck 값 초기화
  useEffect(() => {
    if (isExistsScript) {
      setToggleCheck();
    }
  }, [isExistsScript]);

  useEffect(() => {
    if (isCheck) {
      if (!isExistsScript) {
        setErrMessage('스크립트 코드를 설치해주세요.');
        setOpenErrModal(true);
      }
    }
  }, [isCheck]);

  return (
    <Component>
      <LoadingModal isOpen={isloadingCheckScript} />
      <DefaultModal
        isOpen={openErrModal}
        message={errMessage}
        handleClose={() => {
          setOpenErrModal(false);
        }}
      />
      <GuideCard>
        <Header>
          <RowDiv>
            <img src={Logo} alt="" />
            <P2>에 오신걸 환영합니다!</P2>
          </RowDiv>
          <RowDiv>
            <IconCheck />
            <P1>스크립트 코드를 설치해주세요.</P1>
          </RowDiv>
        </Header>
        <InformWrapper>
          <InformTitle>VODA 코드 설치 안내</InformTitle>
          <InformBody>
            <div>
              <P2>1. 분석도구 VODA Agent 적용 요청 부탁드립니다.</P2>
              <P2>
                {'2. <head> 태그 최상단에 아래 스크립트 코드 추가해 주세요.'}
              </P2>
            </div>
            <div className="right">
              <IconPin />
              <P3>
                데이터분석은 스크립트 코드 설치가 완료되어야 가능합니다.
                <br />
                코드 설치 후 확인 버튼을 클릭해주세요.
              </P3>
            </div>
          </InformBody>
        </InformWrapper>
        <CodeLayout>
          <CodeLineNumber>
            <P4>1</P4>
            <P4>2</P4>
            <P4>3</P4>
            <P4>4</P4>
            <P4>5</P4>
            <P4>6</P4>
            <P4>7</P4>
            <P4>8</P4>
            <P4>9</P4>
            <P4>10</P4>
            <P4>11</P4>
            <P4>12</P4>
            <P4>13</P4>
          </CodeLineNumber>
          <CodeBody>
            <CodeScript
              ref={textRef}
            >{`<script type="text/javascript">\n      (function (a, i, v, o, r, y) {\n        a.__aivoryClient__ = {\n          load: function (src) {\n            var b = i.createElement("script");\n            b.src = src; b.async = true; b.type = "text/javascript";\n            i.getElementsByTagName("head")[0].appendChild(b);\n          },\n          ten: o,\n          rootDomain: r,\n        }; a.__aivoryClient__.load(v);\n      })(window, document, "//cdn.nerdfactory.ai/voda/agent/${userId}/voda-agent.js",'${userTenant}','','');\n    </script>`}</CodeScript>
          </CodeBody>
        </CodeLayout>
        <ButtonWrapper>
          <button
            type="button"
            onClick={() => handleCopyClipBoard(textRef.current?.textContent)}
          >
            <P3>복사</P3>
          </button>
          <button type="button" onClick={checkScriptButton}>
            <P3>확인</P3>
          </button>
        </ButtonWrapper>
      </GuideCard>
    </Component>
  );
};

export default ScriptGuideTemplate;
