import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import DefaultLink from '../../Atoms/Link';

// Molecule
import NavDropDownButton from '../../Molecule/NavDropDownButton';
import Timer from '../../Molecule/Timer';
import Modal from '../../Molecule/Modal';
import DefaultNavLink from '../../Atoms/NavLink';
import { P2, P3 } from '../../Atoms/Typo';
import { IconNavigation, IconNavFooter } from '../../Atoms/Icon';
import Logo from '../../../assets/images/logo-voda.png';

const Component = styled.div`
  position: relative;
  float: left;
  display: flex;
  flex-direction: column;
  width: 15%;
  /* width: 290px; */
  height: 100vh;
  background-color: #f1f3f8;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 40px 50px;

  img {
    width: 153px;
  }
`;

const MainMenuWrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.grey300};
    border-radius: 4px;
  }
`;

const SingleMenuButton = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;

  a {
    display: flex;
    align-items: center;
    padding-left: 50px;
    height: 100%;
    width: 100%;
    svg {
      margin-right: 8px;
      width: 16px;
      height: 16px;
    }
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 0 40px 50px;
  .voda_profile_link {
    margin-top: 8px;
  }
  .voda_timer {
    margin-left: 8px;
  }
`;

const TimerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${P3} {
    font-size: 15px;
    color: ${(props) => props.theme.colors.grey600};
  }
`;

const UserInformWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UserName = styled.div`
  display: flex;
  align-items: center;
  ${P2} {
    font-size: 15px;
    font-weight: bold;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  .icon-logout {
    margin-left: 5px;
  }
`;

function getCookie(cookieName: string) {
  let cookieValue = null;
  if (document.cookie) {
    const array = document.cookie.split(`${escape(cookieName)}=`);
    if (array.length >= 2) {
      const arraySub = array[1].split(';');
      cookieValue = unescape(arraySub[0]);
    }
  }
  return cookieValue;
}

const deleteCookie = (
  sKey: string,
  sDomain: string | null,
  sPath: string | null
) => {
  if (getCookie(sKey)) {
    document.cookie = `${sKey}=${sPath ? `;path=${sPath}` : ''}${
      sDomain ? `;domain=${sDomain}` : ''
    };expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }
};

interface NavigationProps extends RouteComponentProps {
  userName: string;
  isAIVORYAvailable: boolean;
  onSetLogout: () => void;
  onGetCheckSession: () => void;
}

const Navigation = ({
  location,
  userName,
  isAIVORYAvailable,
  onSetLogout,
  onGetCheckSession,
}: NavigationProps) => {
  let interval: ReturnType<typeof setTimeout> | null = null;
  const sessionInitTime = 3600;
  let sessionSecond = sessionInitTime;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] =
    useState('정상적으로 로그아웃되었습니다.');
  const [activeMenuId, setActiveMenuId] = useState(-1);
  const [timer, setTimer] = useState('59:59');

  const onClickLogout = () => {
    onSetLogout();
  };

  const TimerInterval = () => {
    const pad = (n: number, width: number) => {
      const numberToStr = `${n}`;
      return numberToStr.length >= width
        ? numberToStr
        : new Array(width - numberToStr.length + 1).join('0') + numberToStr;
    };

    interval = setInterval(() => {
      if (sessionSecond >= 0) {
        const voda_session_valid_time = sessionStorage.getItem(
          'voda_session_valid_time'
        );
        if (voda_session_valid_time && voda_session_valid_time !== null) {
          const currentTime = new Date();
          const validTime = new Date(voda_session_valid_time);

          const elapsedMsec = Math.floor(
            (validTime.getTime() - currentTime.getTime()) / 1000
          );

          const min = Math.floor(elapsedMsec / 60);
          const sec = elapsedMsec % 60;

          const minStr = pad(min, 2);
          const secStr = pad(sec, 2);
          setTimer(`${minStr}:${secStr}`);
          sessionSecond = elapsedMsec;

          if (elapsedMsec <= 1) {
            setModalMessage(
              '로그인 유지 시간이 만료되었습니다.\n다시 로그인 해주세요.'
            );

            // setIsModalOpen(true);

            if (interval) {
              clearInterval(interval);
            }
            localStorage.removeItem('voda_tenant');
            deleteCookie('sessionid', '.nerdfactory.ai', '/');
            deleteCookie('csrftoken', '.nerdfactory.ai', '/');
            onGetCheckSession();
          }
        }
      }
    }, 1000);
  };

  const ModalClose = () => {
    setIsModalOpen(false);
    onSetLogout();
  };

  useEffect(() => {
    const pathArr = location.pathname.split('/');

    switch (pathArr[1]) {
      case '':
        setActiveMenuId(0);
        break;
      case 'sessionAnalytics':
        setActiveMenuId(1);
        break;
      case 'userAnalytics':
        setActiveMenuId(2);
        break;
      case 'pageAnalytics':
        setActiveMenuId(3);
        break;
      case 'aivoryUsageAnalytics':
        setActiveMenuId(4);
        break;
      case 'report':
        setActiveMenuId(5);
        break;
      default:
        setActiveMenuId(-1);
        break;
    }
  }, [location]);

  useEffect(() => {
    const expire = new Date();
    expire.setMinutes(expire.getMinutes() + 60);
    sessionStorage.setItem('voda_session_valid_time', expire.toUTCString());
    axios.interceptors.response.use(
      (response) => {
        sessionSecond = sessionInitTime;
        const expireNew = new Date();
        expireNew.setMinutes(expireNew.getMinutes() + 60);
        sessionStorage.setItem(
          'voda_session_valid_time',
          expireNew.toUTCString()
        );
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    TimerInterval();
    return () => {
      if (interval !== null) {
        clearInterval(interval);
        sessionSecond = sessionInitTime;
      }
      sessionStorage.setItem('voda_session_valid_time', '');
      setIsModalOpen(false);
    };
  }, []);

  return (
    <Component>
      <Modal
        isOpen={isModalOpen}
        message={modalMessage}
        handleClose={ModalClose}
      />
      <DefaultLink to="/">
        <LogoWrapper>
          <img src={Logo} alt="" />
        </LogoWrapper>
      </DefaultLink>
      <MainMenuWrapper>
        <SingleMenuButton>
          <DefaultNavLink key="/" to="/" title="대시보드">
            <IconNavigation
              className={activeMenuId === 0 ? 'focus' : 'default'}
            >
              <span className="ri-home-5-line"></span>
            </IconNavigation>
          </DefaultNavLink>
        </SingleMenuButton>

        <NavDropDownButton
          menuId={1}
          Icon={IconNavigation}
          initIsActive={activeMenuId === 1}
          title="사용자 방문 분석"
          subMenu={[
            {
              to: '/sessionAnalytics/uniqueVisitor',
              title: '사용자 수 분석',
            },
            {
              to: '/sessionAnalytics/pageView',
              title: '페이지뷰 수 분석',
            },
            {
              to: '/sessionAnalytics/session',
              title: '방문 횟수 분석',
            },
          ]}
          activeId={activeMenuId}
        />
        <NavDropDownButton
          menuId={2}
          Icon={IconNavigation}
          initIsActive={activeMenuId === 2}
          title="사용자 분석"
          subMenu={[
            {
              to: '/userAnalytics/inflowPath',
              title: '유입경로 분석',
            },
            {
              to: '/userAnalytics/contents',
              title: '콘텐츠 분석',
            },
            {
              to: '/userAnalytics/region',
              title: '지역별 분석',
            },
            {
              to: '/userAnalytics/environment',
              title: '사용 환경 분석',
            },
          ]}
          activeId={activeMenuId}
        />
        <NavDropDownButton
          menuId={3}
          Icon={IconNavigation}
          initIsActive={activeMenuId === 3}
          title="페이지 상세 분석"
          subMenu={[
            {
              to: '/pageAnalytics/uxAnalysisList',
              title: '페이지 UX 분석',
              exact: false,
            },
            {
              to: '/pageAnalytics/pathPlot',
              title: '페이지 흐름 분석',
              exact: false,
            },
          ]}
          activeId={activeMenuId}
        />
        {isAIVORYAvailable ? (
          <NavDropDownButton
            menuId={4}
            Icon={IconNavigation}
            initIsActive={activeMenuId === 4}
            title="AIVORY 이용 분석"
            subMenu={[
              {
                to: '/aivoryUsageAnalytics',
                title: 'AIVORY 이용 분석',
              },
              // {
              //   to: '/aivoryUsageAnalytics/searchUsageAnalysis',
              //   title: '검색 이용 분석',
              // },
              {
                to: '/aivoryUsageAnalytics/popularSearchAnalysis',
                title: '인기검색어 분석',
              },
              // {
              //   to: '/aivoryUsageAnalytics/contentKeywordAnalysis',
              //   title: '콘텐츠 키워드 분석',
              // },
            ]}
            // subMenu={[
            //   {
            //     to: '/aivoryUsageAnalytics',
            //     title: 'AIVORY 이용 평균 분석',
            //   },
            //   {
            //     to: '/aivoryUsageAnalytics/searchKeyword',
            //     title: '검색 이용 분석',
            //   },
            // ]}
            activeId={activeMenuId}
          />
        ) : null}

        <SingleMenuButton>
          <DefaultNavLink key="/report" to="/report" title="월간 분석 리포트">
            <IconNavigation
              className={activeMenuId === 5 ? 'focus' : 'default'}
            >
              <span className="ri-bar-chart-box-line"></span>
            </IconNavigation>
          </DefaultNavLink>
        </SingleMenuButton>
      </MainMenuWrapper>

      <Footer>
        <UserInformWrapper>
          <UserName>
            <P2>{userName}</P2>
          </UserName>
          <IconWrapper>
            <DefaultLink to="/myPage">
              <IconNavFooter className="icon-mypage">
                <span className="ri-user-line"></span>
              </IconNavFooter>
            </DefaultLink>
            <IconNavFooter className="icon-logout" onClick={onClickLogout}>
              <span className="ri-logout-box-r-line"></span>
            </IconNavFooter>
          </IconWrapper>
        </UserInformWrapper>
        <TimerWrapper>
          <P3>로그인 만료 시간</P3>
          <Timer time={timer} />
        </TimerWrapper>
      </Footer>
    </Component>
  );
};

export default withRouter(Navigation);
