import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import BubbleChart from '../../Molecule/Chart/BubbleChart';
import CardHeaderLayout from '../../Atoms/Layout/Card/CardHeaderLayout';
import { H3, P2 } from '../../Atoms/Typo';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import DefaultTable, { DefaultTableProps } from '../../Molecule/DefaultTable';

const Component = styled(CardLayout)`
  height: 100%;
  width: 100%;
`;

const CardBody = styled(CardBodyLayout)`
  flex-direction: row;
`;

const BubbleWrapper = styled.div`
  width: 100%;
  padding-right: 20px;
`;

const TableWrapper = styled.div`
  width: 100%;
  padding-top: 20px;
`;

const ColumnsItem = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #dddddd;
  height: 47px;
  :first-child {
    width: 22%;
  }
  :nth-child(2) {
    width: 28%;
  }
  :last-child {
    border-right: none;
  }
  ${P2} {
    font-weight: bold;
  }
`;

const DataItem = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #dddddd;
  padding: 0px 20px;
  height: 47px;

  :first-child {
    width: 22%;
  }
  :nth-child(2) {
    width: 28%;
  }

  :nth-child(3) {
    justify-content: flex-end;
  }
  :nth-child(4) {
    justify-content: flex-end;
    border-right: none;
  }

  ${P2} {
    font-weight: 400;
  }

  .resolution-x {
    width: 40px;
    text-align: end;
  }

  .resolution-y {
    width: 40px;
    text-align: start;
  }

  .center {
    margin: 0 3px;
  }
`;

interface ResolutionCardProps {
  data: {
    [resolution: string]: {
      [device: string]: {
        uv: number;
        rate: number;
      };
    };
  } | null;
}

const ResolutionCard = ({ data }: ResolutionCardProps) => {
  const [tableData, setTableData] = useState<DefaultTableProps['data']>([]);
  const [maxWidth, setMaxWidth] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);

  const columns = [
    <ColumnsItem key="device">
      <P2>기기</P2>
    </ColumnsItem>,
    <ColumnsItem key="resolution">
      <P2>해상도</P2>
    </ColumnsItem>,
    <ColumnsItem key="uv">
      <P2>UV</P2>
    </ColumnsItem>,
    <ColumnsItem key="rate">
      <P2>비율</P2>
    </ColumnsItem>,
  ];

  const buildSeries = () => {
    const widthArr: number[] = [];
    const heightArr: number[] = [];
    let width_temp = 0;
    let height_temp = 0;

    if (data) {
      const keyArr = Object.keys(data);
      keyArr.forEach((element) => {
        // element = '1920 x 1080'
        const temp = element.split('x');
        widthArr.push(Number(temp[0]));
        heightArr.push(Number(temp[1]));
      });

      width_temp = Math.max(...widthArr);
      height_temp = Math.max(...heightArr);
      setMaxWidth(Math.ceil(width_temp / 1000) * 1000);
      setMaxHeight(Math.ceil(height_temp / 1000) * 1000);
    }
  };

  const genTableData = () => {
    const data_temp: DefaultTableProps['data'] = [];
    const temp: {
      device: string;
      resolutions: string;
      uv: number;
      rate: number;
    }[] = [];

    if (data) {
      const entriesArr = Object.entries(data);

      entriesArr.forEach(([resolution, device]) => {
        const deviceArr = Object.keys(device);
        const valueArr = Object.values(device);

        deviceArr.forEach((element, idx) => {
          temp.push({
            device: element,
            resolutions: resolution,
            uv: valueArr[idx].uv,
            rate: valueArr[idx].rate,
          });
        });
      });
      temp
        .sort((a, b) => b.uv - a.uv)
        .forEach((element, idx) => {
          const resolutionArr = element.resolutions.split('x');
          data_temp.push({
            id: `${idx}`,
            node: [
              <DataItem key="device">
                <P2>{element.device}</P2>
              </DataItem>,
              <DataItem key="resolution">
                <P2 className="resolution-x">{resolutionArr[0]}</P2>
                <P2 className="center">x</P2>
                <P2 className="resolution-y">{resolutionArr[1]}</P2>
              </DataItem>,
              <DataItem key="uv">
                <P2>{element.uv.toLocaleString()}</P2>
              </DataItem>,
              <DataItem key="rate">
                <P2>{element.rate}%</P2>
              </DataItem>,
            ],
          });
        });
      setTableData(data_temp);
    }
  };

  useEffect(() => {
    genTableData();
    buildSeries();
  }, [data]);

  return (
    <Component>
      <CardHeaderLayout>
        <H3>화면 해상도</H3>
      </CardHeaderLayout>
      <CardBody>
        <BubbleWrapper className="wrap">
          <BubbleChart data={data} xaxisMax={maxWidth} yaxisMax={maxHeight} />
        </BubbleWrapper>
        <TableWrapper className="wrap2">
          <DefaultTable columns={columns} data={tableData} scrollHeight={300} />
        </TableWrapper>
      </CardBody>
    </Component>
  );
};

export default ResolutionCard;
