import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { P1, P2, P3 } from '../../Atoms/Typo';
import DefaultLink from '../../Atoms/Link';

const Tab = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-right: 10px;
  border-radius: 14px 14px 0 0;
  border: 1px solid #d7dbe6;
  border-bottom: none;
  background-color: #e8ebf2;
  margin-bottom: -1px;
  padding: 20px 30px;

  :hover {
    cursor: pointer;
  }
`;

const Component = styled.div<{ $id: number }>`
  display: flex;
  width: 70%;
  border: none;
  .inflow-tab {
    ${(props) => {
      if (props.$id) {
        return css`
          :nth-child(${props.$id}) {
            ${Tab} {
              background-color: white;
              z-index: 1;
            }
          }
        `;
      }
      return css``;
    }}
  }
  .region-tab {
    ${(props) => {
      if (props.$id) {
        return css`
          :nth-child(${props.$id}) {
            div {
              background-color: white;
              border-bottom: white;
              z-index: 1;
            }
          }
        `;
      }
      return css``;
    }}
  }
`;

const Div = styled.div`
  display: flex;
  width: 100%;
`;

const Link = styled(DefaultLink)`
  width: 100%;
`;

const TabName = styled.div`
  display: flex;
  align-items: center;
  ${P1} {
    font-weight: bold;
  }
`;

const TabData = styled.div`
  display: flex;
  align-items: baseline;
  ${P3} {
    color: ${(props) => props.theme.colors.grey600};
  }
`;

const CompareIcon = styled.span<{ $id: number }>`
  position: relative;
  font-size: 17px;
  color: #aaaaaa;
  text-align: center;
  margin-left: 2px;
  :hover {
    cursor: pointer;
    ${(props) => {
      if (props.$id) {
        return css`
          .tab${props.$id} {
            display: flex;
          }
        `;
      }
      return css``;
    }}
  }
`;

const Tooltip = styled.div`
  display: none;
  width: 250px;
  /* height: 60px; */
  position: absolute;
  padding: 10px 10px;
  background-color: white;
  box-shadow: 0px 2px 4px #00000042;
  border: 1px solid #a7a7a7;
  border-radius: 25px;
  top: 0px;
  left: 0px;
  align-items: center;
  justify-content: center;
  transform: translate(0%, -100%);
  z-index: 2 !important;
  ${P3} {
    color: #767676;
    word-break: keep-all;
  }
`;

interface TabGroupProps {
  TabList: {
    name: string;
    link?: string;
    id: number;
    data?: number;
  }[];
  activeId: number;
  onClickTab?: (id: number) => void;
}

const TabGroup = ({ TabList, activeId, onClickTab }: TabGroupProps) => {
  const [description] = useState([
    '브라우저 검색을 통해 유입된 세션입니다.',
    '소셜 네트워크를 통해 유입된 세션입니다.',
    '소셜 네트워크를 제외한 다른 사이트 혹은 특정 링크를 통해 유입된 세션입니다.',
  ]);
  return (
    <Component $id={activeId} className="Tab">
      {TabList.map((element, idx) => {
        if (element.link) {
          return (
            <Link className="inflow-tab" key={element.id} to={element.link}>
              <Tab>
                <TabName>
                  <P1>{element.name}</P1>
                  <CompareIcon $id={element.id} className="ri-question-fill">
                    
                    <Tooltip className={`tab${element.id}`}>
                      <P3>{description[idx]}</P3>
                    </Tooltip>
                  </CompareIcon>
                </TabName>
                {element.data ? (
                  <TabData>
                    <P2>{element.data.toLocaleString()}</P2>
                    <P3>세션</P3>
                  </TabData>
                ) : null}
              </Tab>
            </Link>
          );
        }
        if (onClickTab) {
          return (
            <Div className="region-tab" key={element.id}>
              <Tab onClick={() => onClickTab(element.id)}>
                <P1>{element.name}</P1>
                {element.data ? (
                  <TabData>
                    <P2>{element.data.toLocaleString()}</P2>
                    <P3>세션</P3>
                  </TabData>
                ) : null}
              </Tab>
            </Div>
          );
        }
        return null;
      })}
    </Component>
  );
};

export default TabGroup;
