import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { RootState } from '../../../modules';

import {
  GetTotalUserCountParamType,
  GetWeeklyUserCountParamType,
} from '../../../api/serviceAnalytics/userAnalysis/type';
import {
  getTotalUserCountAsync,
  getWeeklyUserCountAsync,
} from '../../../modules/serviceAnalytics/userAnalysis';

import ServiceAnalysisTemplate from '../../../components/Templates/ServiceAnalysisTemplate';
import { TableChartState } from '../../../components/Molecule/Chart/TableChart';
import { GetExportStatisticsExcelParamType } from '../../../api/authentication/type';
import { getExportExcelAsync } from '../../../modules/authentication';

const ServiceUserAnalytics = () => {
  // redux
  const { isGetTUCLoading, isGetWUCLoading, TUCData, WUCData } = useSelector(
    (state: RootState) => state.serviceUserAnalysis
  );
  const { startDate, endDate, isDatePickerToggle } = useSelector(
    (state: RootState) => state.datePicker
  );
  const dispatch = useDispatch();
  const onGetTotalUserCount = (info: GetTotalUserCountParamType) => {
    dispatch(getTotalUserCountAsync.request(info));
  };
  const onGetWeeklyUserCount = (info: GetWeeklyUserCountParamType) => {
    dispatch(getWeeklyUserCountAsync.request(info));
  };
  const onGetExportExcel = (info: GetExportStatisticsExcelParamType) => {
    dispatch(getExportExcelAsync.request(info));
  };

  // state
  const [selectedTimeSort, setSelectedTimeSort] = useState('day');
  // const [selectedUserSort, setSelectedUserSort] = useState('all');

  useEffect(() => {
    if (!isDatePickerToggle) {
      onGetTotalUserCount({
        unit: selectedTimeSort,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
      onGetWeeklyUserCount({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
    }
  }, [isDatePickerToggle, startDate, endDate]);

  const onChangeFliter = (timeSort: string) => {
    setSelectedTimeSort(timeSort);
    onGetTotalUserCount({
      unit: timeSort,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
    });
  };

  const onClickExcel = (data: TableChartState['excelData']) => {
    onGetExportExcel({
      time: `${moment(startDate)
        .format('YYYY-MM-DD')
        .replace(/-/g, '')}-${moment(endDate)
        .format('YYYY-MM-DD')
        .replace(/-/g, '')}`,
      type: 'user',
      payload: data,
    });
  };

  return (
    <ServiceAnalysisTemplate
      headerTitle="사용자 수 분석"
      icon=""
      iconClass="ri-user-received-line"
      multiChartHeader="사용자 수"
      dayWeekChartHeader="요일별 평균 사용자 수"
      heatmapChartHeader="시간대별 사용자 수"
      multiChartData={{
        Data: TUCData || null,
        isLoading: isGetTUCLoading,
        onChangeFliter,
        lineLabel: '전체 사용자 수',
        bar1Label: '재방문 사용자 수',
        bar2Label: '신규 사용자 수',
        legendData: [
          {
            title: '전체 사용자 수',
            status: 'all',
          },
          {
            title: '재방문 사용자 수',
            status: 'return',
          },
          {
            title: '신규 사용자 수',
            status: 'new',
          },
          {
            title: '전체 사용자 수(추정)',
            status: 'all_future',
          },
          {
            title: '재방문 사용자 수(추정)',
            status: 'return_future',
          },
          {
            title: '신규 사용자 수(추정)',
            status: 'new_future',
          },
        ],
      }}
      dayWeekMultiChartData={{
        Data: WUCData?.weekday || null,
        isLoading: isGetWUCLoading,
        lineLabel: '전체 사용자 수',
        bar1Label: '재방문 사용자 수',
        bar2Label: '신규 사용자 수',
        legendData: [
          {
            title: '전체 사용자 수',
            status: 'all',
          },
          {
            title: '재방문 사용자 수',
            status: 'return',
          },
          {
            title: '신규 사용자 수',
            status: 'new',
          },
        ],
      }}
      heatmapChartData={{
        Data: WUCData,
        loading: isGetWUCLoading,
        totalLabel: '전체',
        label1: '재방문',
        label2: '신규',
      }}
      onClickExcel={onClickExcel}
    />
  );
};

export default ServiceUserAnalytics;
