import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Header from '../../components/Organisms/Header';
import ServiceActivationReportCard from '../../components/Organisms/Card/Report/ServiceActivationReportCard';
import NewVSReturnReportCard from '../../components/Organisms/Card/Report/NewVSReturnReportCard';
import PopularPageReportCard from '../../components/Organisms/Card/Report/PopularPageReportCard';
import SearchWordReportCard from '../../components/Organisms/Card/Report/SearchWordReportCard';
import ExternalReportCard from '../../components/Organisms/Card/Report/ExternalReportCard';
import LocalReportCard from '../../components/Organisms/Card/Report/LocalReportCard';
import SimilarVSReportCard from '../../components/Organisms/Card/Report/SimilarVSReportCard';
import ForecastReportCard from '../../components/Organisms/Card/Report/ForecastReportCard';
import { RootState } from '../../modules';
import {
  getReportUVAsync,
  getReportAvgPVAsync,
  getReportDurationAsync,
  getReportSessionAsync,
  getReportInflowNationalAsync,
  getReportInflowGlobalAsync,
  getReportInflowKeywordAsync,
  getReportInflowPathAsync,
  getReportClickRateAsync,
  getReportGrowthPVAsync,
  getReportPopularPageAsync,
  getReportMonthAsync,
} from '../../modules/report';

import PageUsabilityClickRateReportCard from '../../components/Organisms/Card/Report/PageUsabilityClickRateReportCard';
import PageUsabilityPageDurationReportCard from '../../components/Organisms/Card/Report/PageUsabilityPageDurationReportCard';
import ReferrerReportCard from '../../components/Organisms/Card/Report/ReferrerReportCard';
import BotReportCard from '../../components/Organisms/Card/Report/BotReportCard';
import ContentsLayout from '../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../components/Atoms/Layout/Contents/ContentsItemLayout';

import NoDataCard from '../../components/Organisms/Card/NoDataCard';
import ReportHeaderCard from '../../components/Organisms/Card/Report/ReportHeaderCard';
import ServiceActivationCard from '../../components/Organisms/Card/Report/ServiceActivationCard';
import UserInflowReportCard from '../../components/Organisms/Card/Report/UserInflowReportCard';
import ReportPopularPageCard from '../../components/Organisms/Card/Report/ReportPopularPageCard';
import {
  GetReportClickRateParamType,
  GetReportParamType,
} from '../../api/report/type';

const Report = () => {
  const el = useRef<HTMLDivElement>(null);
  const [elementId, setElementId] = useState('');

  const {
    isLoadingDuration,
    isLoadingPV,
    isLoadingSession,
    isLoadingUV,
    isLoadingGlobal,
    isLoadingKeyword,
    isLoadingNational,
    isLoadingPath,
    isLoadingClickRate,
    isLoadingGrowthPV,
    isLoadingpopular,

    ReportDutraionData,
    ReportPVData,
    ReportSessionData,
    ReportUVData,
    ReportGlobalData,
    ReportKeywordData,
    ReportNationalData,
    ReportPathData,
    ReportClickRateData,
    ReportGrowthPVData,
    ReportPopularPageData,
    ReportMonthData,
  } = useSelector((state: RootState) => state.report);

  const dispatch = useDispatch();

  const onGetReportUV = (info: GetReportParamType) => {
    dispatch(getReportUVAsync.request(info));
  };

  const onGetReportSession = (info: GetReportParamType) => {
    dispatch(getReportSessionAsync.request(info));
  };

  const onGetReportAvgPV = (info: GetReportParamType) => {
    dispatch(getReportAvgPVAsync.request(info));
  };

  const onGetReportDuration = (info: GetReportParamType) => {
    dispatch(getReportDurationAsync.request(info));
  };

  const onGetReportInflowNational = (info: GetReportParamType) => {
    dispatch(getReportInflowNationalAsync.request(info));
  };

  const onGetReportInflowGlobal = (info: GetReportParamType) => {
    dispatch(getReportInflowGlobalAsync.request(info));
  };

  const onGetReportInflowPath = (info: GetReportParamType) => {
    dispatch(getReportInflowPathAsync.request(info));
  };

  const onGetReportInflowKeyword = (info: GetReportParamType) => {
    dispatch(getReportInflowKeywordAsync.request(info));
  };

  const onGetReportPopularPage = (info: GetReportParamType) => {
    dispatch(getReportPopularPageAsync.request(info));
  };

  const onGetReportGrowthPV = (info: GetReportParamType) => {
    dispatch(getReportGrowthPVAsync.request(info));
  };

  const onGetReportClickRate = (info: GetReportClickRateParamType) => {
    dispatch(getReportClickRateAsync.request(info));
  };

  const onGetReportMonth = () => {
    dispatch(getReportMonthAsync.request());
  };

  useEffect(() => {
    onGetReportMonth();
  }, []);

  const onSetReportMonth = (reportMonth?: string) => {
    const info = {
      month: reportMonth,
    };
    onGetReportUV(info);
    onGetReportSession(info);
    onGetReportAvgPV(info);
    onGetReportDuration(info);
    onGetReportInflowNational(info);
    onGetReportInflowGlobal(info);
    onGetReportInflowPath(info);
    onGetReportInflowKeyword(info);
    onGetReportPopularPage(info);
    onGetReportGrowthPV(info);
    onGetReportClickRate(info);
  };

  useEffect(() => {
    const { current } = el;
    if (current && current.offsetParent) {
      setElementId(current.offsetParent.id);
    }
  }, [el.current]);

  useEffect(() => {
    onSetReportMonth();
  }, []);

  return (
    <ContentsLayout className="report" ContentsLayoutRef={el}>
      <Header
        title="월간 분석 리포트"
        icon=""
        iconClass="ri-ser-received-line"
        isDatePicker={false}
      />
      <ContentBodyLayout>
        <ContentsItemLayout desktop={12}>
          <ReportHeaderCard
            elIdForPdf={elementId}
            monthList={ReportMonthData}
            setReportMonth={onSetReportMonth}
          />
        </ContentsItemLayout>

        <ContentsItemLayout desktop={12}>
          <ServiceActivationCard
            uvLoading={isLoadingDuration}
            sessionLoading={isLoadingPV}
            pvLoading={isLoadingSession}
            dtLoading={isLoadingUV}
            uvData={ReportUVData}
            sessionData={ReportSessionData}
            pvData={ReportPVData}
            dtData={ReportDutraionData}
          />
        </ContentsItemLayout>

        <ContentsItemLayout desktop={12}>
          <UserInflowReportCard
            nationalLoading={isLoadingNational}
            globalLoading={isLoadingGlobal}
            pathLoading={isLoadingPath}
            keywordLoading={isLoadingKeyword}
            nationalData={ReportNationalData}
            globalData={ReportGlobalData}
            pathData={ReportPathData}
            keywordData={ReportKeywordData}
          />
        </ContentsItemLayout>

        <ContentsItemLayout desktop={12}>
          <ReportPopularPageCard
            popularLoading={isLoadingpopular}
            growthLoading={isLoadingGrowthPV}
            rateLoading={isLoadingClickRate}
            popularData={ReportPopularPageData}
            growthData={ReportGrowthPVData}
            rateData={ReportClickRateData}
          />
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
    // ) : (
    //   <ContentsLayout>
    //     <Header
    //       title={`${user_name}님, 아직 데이터를 모으고 있습니다!`}
    //       isDatePicker={false}
    //     />
    //     <ContentBodyLayout>
    //       <ContentsItemLayout desktop={12}>
    //         <NoDataCard
    //           header="아직 데이터를 모으고 있습니다."
    //           bodyText="서비스 시작일부터 약 30일 후 데이터를 볼 수 있습니다."
    //         />
    //       </ContentsItemLayout>
    //     </ContentBodyLayout>
    //   </ContentsLayout>
  );
};

export default Report;
