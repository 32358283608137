import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  GetScrollEventsResponseType,
  UserUsabilityDatum,
} from '../../../../api/pageAnalytics/uxAnalytics/scroll/type';
import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import UXHeatmapBarChart from '../../../Molecule/Chart/UXHeatmapBarChart';
import FullPageScreenShot from '../../../Molecule/FullPageScreenShot';
import ScrollHeatmapTooltip, {
  ScrollHeatmapTooltipProps,
} from '../../../Molecule/Tooltip/ScrollHeatmapTooltip';
import ScrollHeatmapCrossHair from '../../../Molecule/ScrollHeatmapCrossHair';
import ScrollHeatmapLayer from '../../../Molecule/HeatmapLayer/ScrollHeatmapLayer';
import UXHeatmapLineChart from '../../../Molecule/Chart/UXHeatmapLineChart';
import Spinner from '../../../Molecule/Spinner';
import OpacitySlider from '../../../Atoms/Slider';
import { P3 } from '../../../Atoms/Typo';

const PageScreenWrap = styled.div`
  width: 100%;
  border-radius: 14px;
  background-color: #f5f5f5;
`;

const ScreenWrapHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 70px;
  border-radius: 14px 14px 0 0;
  background-color: #eeeeee;
  padding: 0 30px;
  ${P3} {
    font-size: 15px;
    font-weight: 600;
    margin-right: 10px;
    height: 20px;
  }
`;

const ScreenBody = styled.div`
  position: relative;
  width: 100%;
  padding: 30px 30px 30px 60px;
`;

const LabelWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 24px;
  top: 31px;
  left: 27px;
  ${P3} {
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    line-height: 1.2;
  }
  .top {
    color: #ff3b56;
  }
  .bottom {
    color: #2962ff;
  }
`;

const LabelTitle = styled(P3)`
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  line-height: 1.2;
  margin-bottom: 20px;
`;

const Spectrum = styled.div`
  width: 10px;
  height: 160px;
  background: transparent linear-gradient(180deg, #ff3b56 0%, #2962ff 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  margin: 9px 0;
`;

const Component = styled(CardLayout)`
  padding: 0px;
  position: relative;
  border-radius: 0;
`;

export interface ScrollHeatmapCardProps {
  pageId: string;
  data: GetScrollEventsResponseType['payload'] | null;
  isLoading: boolean;
}

const ScrollHeatmapCard = ({
  pageId,
  data,
  isLoading,
}: ScrollHeatmapCardProps) => {
  const ComponentEl = useRef<HTMLDivElement>(null);
  const SliderEl = useRef<HTMLDivElement>(null);
  const CrossHairEl = useRef<HTMLDivElement>(null);
  const TooltipEl = useRef<HTMLDivElement>(null);
  const fullPageScreenShotEl = useRef<HTMLImageElement>(null);
  const [isImgLoad, setIsImgLoad] = useState(false);
  const [imgSize, setImgSize] = useState({ width: 0, height: 0 });
  const [mouseoverTooltipInfo, setMouseoverTooltipInfo] = useState<
    ScrollHeatmapTooltipProps['data']
  >([]);
  const [isTooltipOverHalf, setIsTooltipOverHalf] = useState(false);
  const [barCnt, setBarCnt] = useState(1);
  const [pageHeight, setpageHeight] = useState(1);
  const [gradientHeight, setGradientHeight] = useState(0);
  const [gradientWidth, setGradientWidth] = useState(0);
  const [barHeight, setBarHeight] = useState(0);
  const [lastBarHeight, setLastBarHeight] = useState(0);
  const [averageHeightToRenderPixel, setAverageHeightToRenderPixel] =
    useState(0);
  const [isTooltip, setIsTooltip] = useState(false);
  const [heatmapOpacity, setHeatmapOpacity] = useState(0.6);

  const averageHeight = 900;

  const handleImageLoaded = () => {
    setIsImgLoad(true);
  };

  const handleImageLoadStart = () => {
    setIsImgLoad(false);
  };

  const onMouseMove = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    dataLength: number,
    element: UserUsabilityDatum,
    index: number
  ) => {
    const { current } = ComponentEl;
    const crossHairElCurrent = CrossHairEl.current;
    const TooltipElCurrent = TooltipEl.current;
    if (current && crossHairElCurrent && TooltipElCurrent && isTooltip) {
      setMouseoverTooltipInfo([
        {
          id: 0,
          title: '평균 주목 시간',
          value: `${element.avg_attention_time}초`,
        },
        {
          id: 1,
          title: '주목 비율',
          value: `${element.attention_rate}%`,
        },
        {
          id: 2,
          title: '사용자 도달 비율',
          value: `${element.view_rate}%`,
        },
      ]);

      const componentTop =
        current.getBoundingClientRect().top + window.pageYOffset;

      if (index > dataLength / 2) {
        setIsTooltipOverHalf(true);
        TooltipElCurrent.style.top = `${
          event.pageY - componentTop - TooltipElCurrent.offsetHeight - 12
        }px`;
      } else {
        setIsTooltipOverHalf(false);
        TooltipElCurrent.style.top = `${event.pageY - componentTop + 12}px`;
      }

      crossHairElCurrent.style.top = `${event.pageY - componentTop}px`;
    }
  };

  const printTooltip = () => {
    return (
      <ScrollHeatmapTooltip
        tooltip_ref={TooltipEl}
        data={mouseoverTooltipInfo}
        isTooltipOverHalf={isTooltipOverHalf}
        isShow={isTooltip}
      />
    );
  };

  const handleOpacity = (val: number) => {
    setHeatmapOpacity(val / 10);
  };

  // 2
  useEffect(() => {
    const barHeightTemp = (gradientHeight * 100) / pageHeight;
    setBarHeight(barHeightTemp);

    setAverageHeightToRenderPixel(
      Math.round((gradientHeight * averageHeight) / pageHeight)
    );

    if (gradientHeight % barHeightTemp !== 0) {
      setLastBarHeight(gradientHeight % barHeightTemp);
    } else {
      setLastBarHeight(barHeightTemp);
    }
  }, [gradientHeight, gradientWidth, pageHeight, data]);

  // 1
  useEffect(() => {
    if (data) {
      setBarCnt(Math.ceil(data.height / 100));
      setpageHeight(data.height);
    }
  }, [data]);

  // 1
  useEffect(() => {
    const { current } = ComponentEl;
    if (current && isImgLoad) {
      setGradientHeight(current.getBoundingClientRect().height);
      setGradientWidth(current.getBoundingClientRect().width);

      current.addEventListener('mouseenter', () => {
        setIsTooltip(true);
      });
      current.addEventListener('mouseleave', () => {
        setIsTooltip(false);
      });
    }
  }, [isImgLoad, ComponentEl.current]);

  useEffect(() => {
    const { current } = SliderEl;
    if (current) {
      current.addEventListener('mouseenter', () => {
        setIsTooltip(false);
      });
      current.addEventListener('mouseleave', () => {
        setIsTooltip(true);
      });
    }
  }, [SliderEl.current]);

  return (
    <PageScreenWrap>
      <ScreenWrapHeader>
        <P3>불투명도</P3>
        <OpacitySlider
          type="scroll"
          defaultVal={6}
          opacityVal={handleOpacity}
        />
      </ScreenWrapHeader>
      {isLoading ? (
        <Spinner />
      ) : (
        <ScreenBody>
          <LabelWrapper>
            <LabelTitle>도달비율(%)</LabelTitle>
            <P3 className="top">100</P3>
            <Spectrum />
            <P3 className="bottom">0</P3>
          </LabelWrapper>
          <Component card_ref={ComponentEl}>
            <FullPageScreenShot
              img_src={`https://voda-media.nerdfactory.ai/${localStorage.getItem(
                'voda_tenant'
              )}/auto/${pageId}/${encodeURIComponent('page_screenshot')}`}
              imgRef={fullPageScreenShotEl}
              onLoad={handleImageLoaded}
              onLoadStart={handleImageLoadStart}
            />
            <ScrollHeatmapLayer
              data={data}
              barHeight={barHeight}
              isImgLoad={isImgLoad}
              gradientWidth={gradientWidth}
              opacity={heatmapOpacity}
            />
            <UXHeatmapLineChart
              data={data}
              barHeight={barHeight}
              gradientWidth={gradientWidth}
            />
            <UXHeatmapBarChart
              data={data}
              barHeight={barHeight}
              lastBarHeight={lastBarHeight}
              barCnt={barCnt}
              onMouseMove={onMouseMove}
            />
            {printTooltip()}
            <ScrollHeatmapCrossHair
              crossHairRef={CrossHairEl}
              isShow={isTooltip}
            />
          </Component>
        </ScreenBody>
      )}
    </PageScreenWrap>
  );
};

export default ScrollHeatmapCard;
