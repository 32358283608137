import React, { useState, useEffect, useRef, ReactNodeArray } from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import FunnelCard from '../../../Molecule/FunnelCard/FunnelCard';

import { ReactComponent as IconArrowFunnel } from '../../../../assets/icons/icon-arrow-funnel.svg';

const IconArrow = styled(IconArrowFunnel)<{ $isVisible: boolean }>`
  display: flex;
  path {
    fill: #000000;
  }
  ${(props) => {
    if (props.$isVisible) {
      return css`
        visibility: visible;
      `;
    }
    return css`
      visibility: hidden;
    `;
  }}
`;

const Component = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 14px;
  background-color: #f5f5f5;
  padding: 50px;
`;

const Wrapper = styled.div<{ $screen?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  ${(props) => {
    if (props.$screen) {
      return css`
        width: 30%;
      `;
    }
    return css`
      width: 35%;
    `;
  }}
`;

const FunnelCardWrapper = styled.div<{ $inflow?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;

  ${(props) => {
    if (props.$inflow) {
      return css`
        align-items: flex-start;
      `;
    }
    return css``;
  }}
`;

const DataError = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: ${(props) => props.theme.colors.grey800};
`;

const InflowLineWrapper = styled.svg<{
  $width: number;
  $height: number;
  $isUpper: boolean;
}>`
  position: absolute;
  left: 0px;
  transform: translate(-100%);

  ${(props) => {
    if (props.$height < 0) {
      return css`
        width: ${props.$width}px;
        height: ${-props.$height}px;
      `;
    }
    return css`
      width: ${props.$width}px;
      height: ${props.$height}px;
    `;
  }}
  ${(props) => {
    if (props.$isUpper) {
      return css`
        bottom: 50%;
      `;
    }
    return css`
      top: 50%;
    `;
  }}
`;

const InflowStraightLineWrapper = styled.div<{ $width: number }>`
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translate(-100%, -50%);
  height: 2px;
  background-color: ${(props) => props.theme.colors.black};

  ${(props) => {
    if (props.$width) {
      return css`
        width: ${props.$width}px;
      `;
    }
    return css``;
  }};
`;

const ConversionStraightLineWrapper = styled.div<{
  $width: number;
  $isExit?: boolean;
}>`
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translate(100%, -50%);
  height: 2px;
  background-color: #000000;

  ${(props) => {
    if (props.$isExit) {
      return css`
        background-color: ${props.theme.colors.danger5};
      `;
    }
    return css``;
  }}

  ${(props) => {
    if (props.$width) {
      return css`
        width: ${props.$width}px;
      `;
    }
    return css``;
  }};
`;

const ConversionLineWrapper = styled.svg<{
  $width: number;
  $height: number;
  $isUpper: boolean;
}>`
  position: absolute;
  right: 0px;
  transform: translate(100%);

  ${(props) => {
    if (props.$height < 0) {
      return css`
        width: ${props.$width}px;
        height: ${-props.$height}px;
      `;
    }
    return css`
      width: ${props.$width}px;
      height: ${props.$height}px;
    `;
  }}
  ${(props) => {
    if (props.$isUpper) {
      return css`
        bottom: 50%;
      `;
    }
    return css`
      top: 50%;
    `;
  }}
`;

const ThumbnailWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  /* background-color: white; */
  width: 100%;
  /* height: 486px; */
  /* justify-content: center; */
  /* Desktop */
`;

const InflowArrow = styled.div`
  position: absolute;
  top: 50%;
  width: 7px;
  height: 8px;
  left: -4px;
  z-index: 40;
  transform: translate(-100%, -50%);
  ${IconArrow} {
    width: 7px;
    height: 8px;
    /* width: 16px;
    height: 24px; */
  }
`;

const ThumbnailImageBox = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: gray;
  margin-bottom: 16px;
  overflow: hidden;
  height: 200px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 0%;
  }
  /* @media ${(props) => props.theme.device.$desktop} {
    height: 194px;
  }

  
  @media ${(props) => props.theme.device.$bigscreen} {
    height: 291px;
  } */
`;

const ThumbnailTitle = styled.div`
  font-weight: 600;
  color: ${(props) => props.theme.colors.grey900};
  font-size: 20px;
  line-height: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  margin-bottom: 8px;
`;

const ThumbnailURL = styled.div`
  width: 100%;
  font-weight: 500;
  color: #767676;
  font-size: 15px;
  line-height: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 12px;
`;

interface UserFlowCardStates {
  dataList: {
    url: string;
    uv: number;
    rate: number;
  }[];
  lineInfo: {
    width: number;
    height: number;
    isUpper: boolean;
    isExit?: boolean;
  }[];
}

interface UserFlowCardProps {
  pageId: string;
  pageName: string;
  pageLocation: string;
  flowData: {
    page: string;
    cnt: number;
    rate: number;
  }[];
  conversionData: {
    page: string;
    cnt: number;
    rate: number;
  }[];
}

const UserFlowCard = ({
  pageId,
  pageName,
  pageLocation,
  flowData,
  conversionData,
}: UserFlowCardProps) => {
  // state
  const [windowWidth, setWindowWidth] = useState(0);
  const [lastPageNumber, setLastPageNumber] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [inflowETCInfo, setInflowETCInfo] = useState({ uv: 0, rate: 0 });
  const [conversionETCInfo, setConversionETCInfo] = useState({
    uv: 0,
    rate: 0,
  });
  const [inflowDataList, setInflowDataList] = useState<
    UserFlowCardStates['dataList']
  >([]);
  const [conversionDataList, setConversionDataList] = useState<
    UserFlowCardStates['dataList']
  >([]);
  const [inflowETCData, setInfolowETCData] = useState<
    UserFlowCardStates['dataList']
  >([]);
  const [conversionETCData, setConversionETCData] = useState<
    UserFlowCardStates['dataList']
  >([]);
  const [ETCPageData, setETCPageData] = useState<
    UserFlowCardStates['dataList']
  >([]);
  const [exitDataList, setExitDataList] = useState<
    UserFlowCardStates['dataList']
  >([]);

  const [isOpenModal, setIsOpenModal] = useState({
    inflow: false,
    conversion: false,
  });
  const inflowArrowEl = useRef<HTMLDivElement | null>(null);
  const ThumbnailWrapperEl = useRef<HTMLDivElement | null>(null);
  const inflowFunnelCardWrapperEl = useRef<HTMLDivElement | null>(null);
  const conversionFunnelCardWrapperEl = useRef<HTMLDivElement | null>(null);
  const [inflowLineInfo, setInflowLineInfo] = useState<
    UserFlowCardStates['lineInfo']
  >([]);
  const [conversionLineInfo, setConversionLineInfo] = useState<
    UserFlowCardStates['lineInfo']
  >([]);

  const generateTablePageData = (cardType: string) => {
    let selectData: UserFlowCardStates['dataList'] = [];
    const tempData: UserFlowCardStates['dataList'] = [];
    switch (cardType) {
      case 'inflow':
        selectData = inflowETCData;
        break;
      case 'conversion':
        selectData = conversionETCData;
        break;
      default:
        break;
    }

    for (
      let index = (pageNumber - 1) * 10;
      index <= pageNumber * 10 - 1;
      index += 1
    ) {
      if (index > selectData.length - 1) {
        break;
      }
      const element = selectData[index];
      tempData.push(element);
    }
    setETCPageData(tempData);
  };

  const onClickCloseHandle = () => {
    setIsOpenModal({
      inflow: false,
      conversion: false,
    });
    setPageNumber(1);
  };
  const onClickOpenHandle = (cardType: string) => {
    switch (cardType) {
      case 'inflow':
        setLastPageNumber(Math.ceil(inflowETCData.length / 10));
        generateTablePageData(cardType);
        setIsOpenModal({
          inflow: true,
          conversion: false,
        });
        break;
      case 'conversion':
        setLastPageNumber(Math.ceil(conversionETCData.length / 10));
        generateTablePageData(cardType);
        setIsOpenModal({
          inflow: false,
          conversion: true,
        });
        break;
      default:
        break;
    }
  };

  const printFunnelCard = (cardType: string) => {
    const result: ReactNodeArray = [];

    switch (cardType) {
      case 'inflow':
        if (inflowDataList.length === 0) {
          result.push(
            <DataError key="inflow_data_error">
              조회한 기간에 유입 데이터가 없습니다.
            </DataError>
          );
        }
        inflowDataList.forEach((element) => {
          result.push(
            <FunnelCard
              onClickOpenHandle={onClickOpenHandle}
              className={`${cardType}FunnelCard`}
              url={element.url}
              count={element.uv}
              rate={element.rate}
              cardType={cardType}
              key={element.url + element.uv}
            />
          );
        });
        if (inflowETCData.length > 0) {
          result.push(
            <FunnelCard
              onClickOpenHandle={onClickOpenHandle}
              className={`${cardType}FunnelCard`}
              url="ETC"
              count={inflowETCInfo.uv}
              rate={inflowETCInfo.rate}
              cardType={cardType}
              key="inflow_etc"
            />
          );
        }
        break;
      case 'conversion':
        if (conversionDataList.length === 0 && exitDataList.length === 0) {
          result.push(
            <DataError key="conversion_data_error">
              조회한 기간에 전환 데이터가 없습니다.
            </DataError>
          );
        }
        conversionDataList.forEach((element) => {
          result.push(
            <FunnelCard
              onClickOpenHandle={onClickOpenHandle}
              className={`${cardType}FunnelCard`}
              url={element.url}
              count={element.uv}
              rate={element.rate}
              cardType={cardType}
              key={element.url + element.uv}
            />
          );
        });
        if (conversionETCData.length > 0) {
          result.push(
            <FunnelCard
              onClickOpenHandle={onClickOpenHandle}
              className={`${cardType}FunnelCard`}
              url="ETC"
              count={conversionETCInfo.uv}
              rate={conversionETCInfo.rate}
              cardType={cardType}
              key="conversion_etc"
            />
          );
        }
        if (exitDataList.length > 0) {
          exitDataList.forEach((element) => {
            result.push(
              <FunnelCard
                onClickOpenHandle={onClickOpenHandle}
                className="exitFunnelCard"
                url="이탈"
                count={element.uv}
                rate={element.rate}
                cardType="exit"
                key="conversion_exit"
              />
            );
          });
        }

        break;
      default:
        break;
    }

    if (result.length > 0) {
      return result;
    }
    return null;
  };

  const generateConversionLine = () => {
    const testData: UserFlowCardStates['lineInfo'] = [];

    const ThumbnailWrapperElInfo = {
      element_x: 0,
      element_y: 0,
      element_width: 0,
      element_height: 0,
    };

    if (ThumbnailWrapperEl) {
      const { current } = ThumbnailWrapperEl;

      if (current) {
        ThumbnailWrapperElInfo.element_x =
          window.pageXOffset + current.getBoundingClientRect().left;
        ThumbnailWrapperElInfo.element_y =
          window.pageYOffset + current.getBoundingClientRect().top;
        ThumbnailWrapperElInfo.element_width = current.offsetWidth;
        ThumbnailWrapperElInfo.element_height = current.offsetHeight;
      }
    }

    const conversionLineElArr = document.getElementsByClassName(
      'conversionFunnelCard'
    );

    const exitLineElArr = document.getElementsByClassName('exitFunnelCard');

    if (conversionLineElArr) {
      for (let index = 0; index < conversionLineElArr.length; index += 1) {
        const element = conversionLineElArr.item(index);
        if (element) {
          const element_x =
            window.pageXOffset + element.getBoundingClientRect().left;
          const element_y =
            window.pageYOffset + element.getBoundingClientRect().top;
          const element_height = (element as HTMLElement).offsetHeight;

          const endX = element_x;
          const endY = element_y + element_height / 2;
          const height =
            ThumbnailWrapperElInfo.element_y +
            ThumbnailWrapperElInfo.element_height / 2 -
            endY;
          const width =
            endX -
            (ThumbnailWrapperElInfo.element_x +
              ThumbnailWrapperElInfo.element_width);

          testData.push({
            width,
            height,
            isUpper: height > 0,
          });
        }
      }
      setConversionLineInfo(testData);
    }

    if (exitLineElArr) {
      for (let index = 0; index < exitLineElArr.length; index += 1) {
        const element = exitLineElArr.item(index);
        if (element) {
          const element_x =
            window.pageXOffset + element.getBoundingClientRect().left;
          const element_y =
            window.pageYOffset + element.getBoundingClientRect().top;
          const element_height = (element as HTMLElement).offsetHeight;

          const endX = element_x;
          const endY = element_y + element_height / 2;
          const height =
            ThumbnailWrapperElInfo.element_y +
            ThumbnailWrapperElInfo.element_height / 2 -
            endY;
          const width =
            endX -
            (ThumbnailWrapperElInfo.element_x +
              ThumbnailWrapperElInfo.element_width);

          testData.push({
            width,
            height,
            isExit: true,
            isUpper: height > 0,
          });
        }
      }
      setConversionLineInfo(testData);
    }
  };

  const generateInflowLine = () => {
    const testData: UserFlowCardStates['lineInfo'] = [];

    const arrowInfo = {
      element_x: 0,
      element_y: 0,
      element_width: 0,
      element_height: 0,
    };

    if (inflowArrowEl) {
      const { current } = inflowArrowEl;
      if (current) {
        arrowInfo.element_x =
          window.pageXOffset + current.getBoundingClientRect().left;
        arrowInfo.element_y =
          window.pageYOffset + current.getBoundingClientRect().top;
        arrowInfo.element_width = current.offsetWidth;
        arrowInfo.element_height = current.offsetHeight;
      }
    }
    const inflowFunnelArr = document.getElementsByClassName('inflowFunnelCard');
    if (inflowFunnelArr) {
      for (let index = 0; index < inflowFunnelArr.length; index += 1) {
        const element = inflowFunnelArr.item(index);
        if (element) {
          const element_x =
            window.pageXOffset + element.getBoundingClientRect().left;
          const element_y =
            window.pageYOffset + element.getBoundingClientRect().top;
          const element_width = (element as HTMLElement).offsetWidth;
          const element_height = (element as HTMLElement).offsetHeight;
          const startX = element_x + element_width;
          const startY = element_y + element_height / 2;
          const height =
            arrowInfo.element_y + arrowInfo.element_height / 2 - startY;

          testData.push({
            width: arrowInfo.element_x - startX,
            height,
            isUpper: height > 0,
          });
        }
      }
      setInflowLineInfo(testData);
    }
  };

  const printInflowLine = () => {
    const result: ReactNodeArray = [];

    if (inflowLineInfo.length > 0) {
      inflowLineInfo.forEach((element) => {
        if (element.height === 0) {
          result.push(
            <InflowStraightLineWrapper
              $width={element.width}
              key={element.height + element.width}
            />
          );
        } else {
          result.push(
            <InflowLineWrapper
              className="voda_inflow_line"
              $width={element.width}
              $height={element.height}
              $isUpper={element.isUpper}
              key={JSON.stringify(element)}
            >
              {element.isUpper ? (
                <path
                  d={`M0 0 C ${element.width / 2} 0, ${element.width / 2} ${
                    element.height
                  }, ${element.width} ${element.height}`}
                  fill="none"
                  stroke="#000000"
                  strokeWidth="1px"
                />
              ) : (
                <path
                  d={`M0 ${-element.height} 
                C ${element.width / 2} ${-element.height}, ${
                    element.width / 2
                  } 0 ${element.width} 0`}
                  fill="none"
                  stroke="#000000"
                  strokeWidth="1px"
                />
              )}
            </InflowLineWrapper>
          );
        }
      });
    }

    if (result.length > 0) {
      return result;
    }
    return null;
  };

  const printConversionLine = () => {
    const result: ReactNodeArray = [];
    if (conversionLineInfo.length > 0) {
      conversionLineInfo.forEach((element) => {
        if (element.height === 0) {
          if (element.isExit) {
            result.push(
              <ConversionStraightLineWrapper
                className="voda_conversion_line"
                $width={element.width}
                $isExit={element.isExit}
                key={JSON.stringify(element)}
              />
            );
          } else {
            result.push(
              <ConversionStraightLineWrapper
                className="voda_conversion_line"
                $width={element.width}
                key={JSON.stringify(element)}
              />
            );
          }
        } else {
          result.push(
            <ConversionLineWrapper
              className="voda_conversion_line"
              $width={element.width}
              $height={element.height}
              $isUpper={element.isUpper}
              key={JSON.stringify(element)}
            >
              {element.isUpper ? (
                <path
                  d={`M0 ${element.height} 
                C ${element.width / 2} ${element.height}, ${
                    element.width / 2
                  } 0, 
                ${element.width} 0`}
                  fill="none"
                  stroke="#000000"
                  //   stroke={element.isExit ? '#f16464' : '#fdb67d'}
                  strokeWidth="1px"
                />
              ) : (
                <path
                  d={`M0 0 
                C ${element.width / 2} 0, ${
                    element.width / 2
                  } ${-element.height}
                ${element.width} ${-element.height}`}
                  fill="none"
                  stroke="#000000"
                  //   stroke={element.isExit ? '#f16464' : '#fdb67d'}
                  strokeWidth="1px"
                />
              )}
            </ConversionLineWrapper>
          );
        }
      });
    }
    if (result.length > 0) {
      return result;
    }
    return null;
  };

  const onChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setPageNumber(page);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const inflowETCDataTemp: UserFlowCardStates['dataList'] = [];
    const inflowDataTemp: UserFlowCardStates['dataList'] = [];

    let inflowUVSum = 0;
    let inflowRateSum = 0;

    flowData.forEach((element, index) => {
      if (index > 3) {
        inflowUVSum += element.cnt;
        inflowRateSum += element.rate;
        inflowETCDataTemp.push({
          url: element.page,
          uv: element.cnt,
          rate: element.rate,
        });
      } else {
        inflowDataTemp.push({
          url: element.page,
          uv: element.cnt,
          rate: element.rate,
        });
      }
    });

    setInflowETCInfo({
      uv: inflowUVSum,
      rate: inflowRateSum,
    });

    setInflowDataList(inflowDataTemp);
    setInfolowETCData(inflowETCDataTemp);
  }, [flowData]);

  useEffect(() => {
    const conversionETCDataTemp: UserFlowCardStates['dataList'] = [];
    const conversionDataTemp: UserFlowCardStates['dataList'] = [];
    const exitDataTemp: UserFlowCardStates['dataList'] = [];

    let conversionUVSum = 0;
    let conversionRateSum = 0;

    conversionData.forEach((element, index) => {
      if (element.page === 'exit') {
        exitDataTemp.push({
          url: element.page,
          uv: element.cnt,
          rate: element.rate,
        });
      } else if (index > 3) {
        conversionUVSum += element.cnt;
        conversionRateSum += element.rate;
        conversionETCDataTemp.push({
          url: element.page,
          uv: element.cnt,
          rate: element.rate,
        });
      } else {
        conversionDataTemp.push({
          url: element.page,
          uv: element.cnt,
          rate: element.rate,
        });
      }
    });
    setExitDataList(exitDataTemp);
    setConversionETCInfo({
      uv: conversionUVSum,
      rate: conversionRateSum,
    });
    setConversionDataList(conversionDataTemp);
    setConversionETCData(conversionETCDataTemp);
  }, [conversionData]);

  useEffect(() => {
    generateInflowLine();
  }, [inflowFunnelCardWrapperEl.current, inflowDataList, windowWidth]);

  useEffect(() => {
    generateConversionLine();
  }, [conversionFunnelCardWrapperEl.current, conversionDataList, windowWidth]);

  useEffect(() => {
    if (isOpenModal.inflow) {
      generateTablePageData('inflow');
    }
    if (isOpenModal.conversion) {
      generateTablePageData('conversion');
    }
  }, [pageNumber]);

  useEffect(() => {
    window.addEventListener(
      'resize',
      _.debounce((event) => {
        setWindowWidth(window.innerWidth);
      }, 1000)
    );
  }, []);

  return (
    <Component>
      {/* 유입 Wrapper */}
      <Wrapper>
        <FunnelCardWrapper ref={inflowFunnelCardWrapperEl} $inflow>
          {printFunnelCard('inflow')}
        </FunnelCardWrapper>
      </Wrapper>

      {/* 스크린샷 Wrapper */}
      <Wrapper $screen className="screen">
        <ThumbnailWrapper ref={ThumbnailWrapperEl}>
          <InflowArrow ref={inflowArrowEl}>
            <IconArrow $isVisible={inflowLineInfo.length > 0} />
            {printInflowLine()}
          </InflowArrow>
          {printConversionLine()}
          <ThumbnailTitle>{pageName}</ThumbnailTitle>
          <ThumbnailURL>{pageLocation}</ThumbnailURL>
          <ThumbnailImageBox>
            <img
              src={`https://voda-media.nerdfactory.ai/${localStorage.getItem(
                'voda_tenant'
              )}/auto/${pageId}/${encodeURIComponent('page_screenshot')}`}
              alt="..."
            />
          </ThumbnailImageBox>
        </ThumbnailWrapper>
      </Wrapper>

      {/* 전환 Wrapper */}
      <Wrapper>
        <FunnelCardWrapper ref={conversionFunnelCardWrapperEl}>
          {printFunnelCard('conversion')}
        </FunnelCardWrapper>
      </Wrapper>
    </Component>
  );
};

export default UserFlowCard;
