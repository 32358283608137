import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Chart from 'react-apexcharts';
import DataError from '../../../Atoms/DataError';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

interface BubbleChartProps {
  data: {
    [resolution: string]: {
      [device: string]: {
        uv: number;
        rate: number;
      };
    };
  } | null;
  xaxisMax: number;
  yaxisMax: number;
}

const BubbleChart = ({ data, xaxisMax, yaxisMax }: BubbleChartProps) => {
  const [series, setSeries] = useState<
    {
      data: number[][];
    }[]
  >([]);

  const options = {
    chart: {
      type: 'bubble' as const,
      height: 300,
      //   width: 700,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1.0,
    },
    grid: {
      borderColor: '#dddddd',
      row: {
        colors: ['#F1F3F8'],
        // opacity: 0.5
      },
      column: {
        colors: ['#F1F3F8'],
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      min: 0,
      max: xaxisMax,
      tickAmount: xaxisMax / 500,
      labels: {
        style: {
          colors: '#767676',
          fontSize: '15px',
          fontFamily: 'Pretendard',
        },
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        color: '#000000',
      },
    },
    yaxis: {
      min: 0,
      max: yaxisMax,
      tickAmount: yaxisMax / 500,
      labels: {
        style: {
          colors: '#767676',
          fontSize: '15px',
          fontFamily: 'Pretendard',
        },
      },
      axisBorder: {
        show: true,
        color: '#000000',
      },
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      bubble: {
        zScaling: true,
        minBubbleRadius: 5,
        maxBubbleRadius: undefined,
      },
    },
  };

  // rebuuld
  const rebuildData = () => {
    const data_temp: number[][] = [];

    if (data) {
      let sum = 0;
      const entriesArr = Object.entries(data);

      entriesArr.forEach(([resolution, device]) => {
        Object.values(device).forEach((el) => {
          sum += el.rate;
        });
        data_temp.push([
          Number(resolution.split('x')[0]),
          Number(resolution.split('x')[1]),
          sum,
        ]);

        sum = 0;
      });

      setSeries([{ data: data_temp }]);
    }
  };

  useEffect(() => {
    rebuildData();
  }, [data]);

  const printChart = useMemo(() => {
    if (series.length > 0) {
      return (
        <Chart
          type="bubble"
          options={options}
          series={series}
          width="100%"
          height="100%"
        />
      );
    }
    return <DataError />;
  }, [series]);

  return <Component>{printChart}</Component>;
};

export default BubbleChart;
