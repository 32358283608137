import axios from 'axios';
import config from '../../config-api.json';
import {
  GetNationalDeviceEnvStaticsParamType,
  GetNationalDeviceEnvStaticsResponseType,
  GetNationalOSEnvStaticsParamType,
  GetNationalOSEnvStaticsResponseType,
  GetNationalBrowserEnvStaticsParamType,
  GetNationalBrowserEnvStaticsResponseType,
  GetNationalResolutionStaticsParamType,
  GetNationalResolutionResponseType,
} from './type';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

export async function apiGetNationalDeviceEnvStatics(
  info: GetNationalDeviceEnvStaticsParamType
) {
  const response = await axios.get<GetNationalDeviceEnvStaticsResponseType>(
    `${apiInfo.api_url}/national_device_env_statics/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetNationalOSEnvStatics(
  info: GetNationalOSEnvStaticsParamType
) {
  const response = await axios.get<GetNationalOSEnvStaticsResponseType>(
    `${apiInfo.api_url}/national_os_env_statics/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetNationalBrowserEnvStatics(
  info: GetNationalBrowserEnvStaticsParamType
) {
  const response = await axios.get<GetNationalBrowserEnvStaticsResponseType>(
    `${apiInfo.api_url}/national_browser_env_statics/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// resolution
export async function apiGetNationalResolutionStatics(
  info: GetNationalResolutionStaticsParamType
) {
  const response = await axios.get<GetNationalResolutionResponseType>(
    `${apiInfo.api_url}/national_resolution_env_statics/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
