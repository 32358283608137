import { takeLatest } from 'redux-saga/effects';
import {
  apiGetNationalSessionEnvStatistics,
  apiGetGlobalSessionEnvStatistics,
} from '../../../api/userAnalytics/region/api';

import {
  getNationalSessionEnvStatisticsAsync,
  getGlobalSessionEnvStatisticsAsync,
  GET_NATIONAL_SESSION_ENV_STATISTICS,
  GET_GLOBAL_SESSION_ENV_STATISTICS,
} from './actions';
import createRequestSaga from '../../createRequestSaga';

const getNationalSessionEnvStatisticsSaga = createRequestSaga(
  getNationalSessionEnvStatisticsAsync,
  apiGetNationalSessionEnvStatistics
);

const getGlobalSessionEnvStatisticsSaga = createRequestSaga(
  getGlobalSessionEnvStatisticsAsync,
  apiGetGlobalSessionEnvStatistics
);

export function* regionSaga() {
  yield takeLatest(
    GET_NATIONAL_SESSION_ENV_STATISTICS,
    getNationalSessionEnvStatisticsSaga
  );
  yield takeLatest(
    GET_GLOBAL_SESSION_ENV_STATISTICS,
    getGlobalSessionEnvStatisticsSaga
  );
}

export { regionSaga as default };
