import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import CardHeaderLayout from '../../Atoms/Layout/Card/CardHeaderLayout';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';

import Filter from '../../Molecule/Chart/Filter';
import MultiLineChart from '../../Molecule/Chart/MultiLineChart';
import LegendTitleAllUser from '../../Molecule/Chart/Legend';
import Spinner from '../../Molecule/Spinner';
import DefaultCardHeading from '../../Molecule/Heading/Card/DefaultCardHeading';
import TableChart, { TableChartState } from '../../Molecule/Chart/TableChart';

const visibleAni = keyframes`
  0% {
    height: 380px;
  }
  100% {
    height: 700px;
  }
`;

const closeAni = keyframes`
  0% {
    height: 700px;
  }
  100% {
    height: 380px;
  }
`;

const Component = styled(CardLayout)<{ $isTable: string }>`
  height: 380px;
  box-sizing: border-box;
  padding: 30px 0 0;

  ${(props) => {
    if (props.$isTable === 'table') {
      return css`
        animation: ${visibleAni} 0.5s ease-in-out forwards;
      `;
    }
    if (props.$isTable === 'chart') {
      return css`
        animation: ${closeAni} 0.5s ease-in-out forwards;
      `;
    }
    return css`
      height: 380px;
    `;
  }}
`;

const HeaderLayout = styled(CardHeaderLayout)`
  padding: 0 30px;
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CardHeaderTitle = styled(DefaultCardHeading)`
  margin-bottom: 8px;
`;

const Group = styled.div`
  display: flex;
`;

const ChartFormFilter = styled.div`
  display: flex;
`;

const FormButton = styled.div<{ $active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 6px;
  background-color: ${(props) => props.theme.colors.grey100};
  :first-child {
    margin-right: 5px;
  }
  span {
    font-size: 18px;
    color: ${(props) => props.theme.colors.grey600};
  }
  :hover {
    cursor: pointer;
  }

  ${(props) => {
    if (props.$active) {
      return css`
        background-color: ${props.theme.colors.grey900};
        span {
          color: ${props.theme.colors.white};
        }
      `;
    }
    return css``;
  }}
`;

const CardBody = styled(CardBodyLayout)`
  padding: 0 30px;
`;

const LegendWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 40px;
  background-color: ${(props) => props.theme.colors.grey100};
  border-radius: 0 0 14px 14px;
`;

const LegendItem = styled(LegendTitleAllUser)`
  margin-right: 16px;
`;

export interface MultiLineChartCardProps {
  isDayWeekChart?: boolean;
  legendData: {
    title: string;
    status:
      | 'all'
      | 'return'
      | 'new'
      | 'all_future'
      | 'return_future'
      | 'new_future';
  }[];
  Data: {
    [key: string]: {
      total?: number;
      old: number;
      new: number;
      is_future?: boolean;
    };
  } | null;
  isLoading: boolean;
  lineLabel: string;
  bar1Label?: string;
  bar2Label?: string;
  headerTitle?: string;
  notWeekly?: boolean;
  xLabels?: string;
  onChangeFliter?: (timeSort: string) => void;
  onClickExcel?: (data: TableChartState['excelData']) => void;
}

const MultiLineChartCard = ({
  isDayWeekChart,
  legendData,
  Data,
  isLoading,
  lineLabel,
  bar1Label,
  bar2Label,
  headerTitle,
  notWeekly,
  xLabels,
  onChangeFliter,
  onClickExcel,
}: MultiLineChartCardProps) => {
  const [selectedTime, setSelectedTime] = useState('day');
  const [chartForm, setChartForm] = useState('');
  const [animationEnd, setAnimationEnd] = useState(true);

  const onClickChartForm = (type: string) => {
    if (type !== chartForm) {
      if (!(chartForm === '' && type === 'chart')) {
        setChartForm(type);
        setAnimationEnd(false);
      }
    }
  };

  const onClickButtonGroup = (id: number, timeSort: string) => {
    if (onChangeFliter) {
      onChangeFliter(timeSort);
      setSelectedTime(timeSort);
    }
  };

  const endAnimation = () => {
    setAnimationEnd(true);
  };

  const printCardBody = () => {
    if (animationEnd) {
      switch (chartForm) {
        case 'table':
          return (
            <CardBody className="card-body">
              {isLoading ? (
                <Spinner />
              ) : (
                <TableChart
                  Data={Data}
                  totalLabel={lineLabel}
                  oldLabel={bar1Label}
                  newLabel={bar2Label}
                  timeSort={selectedTime}
                  onClickExcel={onClickExcel}
                />
              )}
            </CardBody>
          );

        default:
          return (
            <>
              <CardBody className="card-body">
                {isLoading ? (
                  <Spinner />
                ) : (
                  <MultiLineChart
                    Data={Data}
                    selectedTimeSort={selectedTime}
                    lineLabel={lineLabel}
                    bar1Label={bar1Label}
                    bar2Label={bar2Label}
                    notWeekly={notWeekly}
                    xLabels={xLabels}
                  />
                )}
              </CardBody>
              <LegendWrapper>
                {legendData.map((element) => {
                  return (
                    <LegendItem
                      key={element.title}
                      title={element.title}
                      status={element.status}
                    />
                  );
                })}
              </LegendWrapper>
            </>
          );
      }
    }
    return null;
  };

  useEffect(() => {
    if (isDayWeekChart) {
      setSelectedTime('dayWeek');
    }
  }, [isDayWeekChart]);

  return (
    <Component
      $isTable={chartForm}
      onAniEnd={() => {
        endAnimation();
      }}
    >
      <HeaderLayout>
        <CardHeader>
          {headerTitle ? <CardHeaderTitle title={headerTitle} /> : null}
        </CardHeader>
        {isDayWeekChart ? null : (
          <Group>
            <Filter
              buttonGroup={[
                {
                  id: 0,
                  timeSort: 'day',
                  title: '일별',
                },
                {
                  id: 1,
                  timeSort: 'week',
                  title: '주별',
                },
                {
                  id: 2,
                  timeSort: 'month',
                  title: '월별',
                },
              ]}
              onClick={onClickButtonGroup}
            />
            <ChartFormFilter>
              <FormButton
                $active={chartForm === 'chart' || chartForm === ''}
                onClick={() => {
                  onClickChartForm('chart');
                }}
              >
                <span className="ri-list-check"></span>
              </FormButton>
              <FormButton
                $active={chartForm === 'table'}
                onClick={() => {
                  onClickChartForm('table');
                }}
              >
                <span className="ri-list-check"></span>
              </FormButton>
            </ChartFormFilter>
          </Group>
        )}
      </HeaderLayout>

      {printCardBody()}
    </Component>
  );
};

export default MultiLineChartCard;
