import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { RootState } from '../../../modules';

import {
  GetPageViewCountParamType,
  GetRankPageViewCountParamType,
  GetWeeklyPageViewCountParamType,
} from '../../../api/serviceAnalytics/pageviewAnalysis/type';
import {
  getPageViewCountAsync,
  getRankPageViewCountAsync,
  getWeeklyPageViewCountAsync,
} from '../../../modules/serviceAnalytics/pageViewAnalysis';

import ServiceAnalysisTemplate from '../../../components/Templates/ServiceAnalysisTemplate';
import { GetExportStatisticsExcelParamType } from '../../../api/authentication/type';
import { getExportExcelAsync } from '../../../modules/authentication';
import { TableChartState } from '../../../components/Molecule/Chart/TableChart';

const ServiceUserAnalytics = () => {
  // redux
  const { PVCData, WPVCData, isGetPVCLoading, isGetWPVCLoading } = useSelector(
    (state: RootState) => state.servicePageViewAnalysis
  );

  const { startDate, endDate, isDatePickerToggle } = useSelector(
    (state: RootState) => state.datePicker
  );

  const dispatch = useDispatch();
  const onGetPageViewCount = (info: GetPageViewCountParamType) => {
    dispatch(getPageViewCountAsync.request(info));
  };
  const onGetWeeklyPageViewCount = (info: GetWeeklyPageViewCountParamType) => {
    dispatch(getWeeklyPageViewCountAsync.request(info));
  };
  const onGetRankPageViewCount = (info: GetRankPageViewCountParamType) => {
    dispatch(getRankPageViewCountAsync.request(info));
  };
  const onGetExportExcel = (info: GetExportStatisticsExcelParamType) => {
    dispatch(getExportExcelAsync.request(info));
  };

  // state
  const [selectedTimeSort, setSelectedTimeSort] = useState('day');
  // const [selectedUserSort, setSelectedUserSort] = useState('all');

  const onClickExcel = (data: TableChartState['excelData']) => {
    onGetExportExcel({
      time: `${moment(startDate)
        .format('YYYY-MM-DD')
        .replace(/-/g, '')}-${moment(endDate)
        .format('YYYY-MM-DD')
        .replace(/-/g, '')}`,
      type: 'user',
      payload: data,
    });
  };

  useEffect(() => {
    if (!isDatePickerToggle) {
      onGetPageViewCount({
        unit: selectedTimeSort,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
      onGetWeeklyPageViewCount({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
      onGetRankPageViewCount({
        ordering: 'asc',
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
    }
  }, [isDatePickerToggle, startDate, endDate]);

  const onChangeFliter = (timeSort: string) => {
    setSelectedTimeSort(timeSort);
    onGetPageViewCount({
      unit: timeSort,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
    });
  };

  const total_label = '전체 페이지뷰 수';
  const label1 = '재방문 페이지뷰 수';
  const label2 = '신규 페이지뷰 수';

  return (
    <ServiceAnalysisTemplate
      headerTitle="페이지뷰 수 분석"
      icon=""
      iconClass="ri-user-received-line"
      multiChartHeader="페이지뷰 수"
      dayWeekChartHeader="요일별 페이지뷰 수"
      heatmapChartHeader="시간대별 페이지뷰 수"
      multiChartData={{
        Data: PVCData || null,
        isLoading: isGetPVCLoading,
        onChangeFliter,
        lineLabel: total_label,
        bar1Label: label1,
        bar2Label: label2,
        legendData: [
          {
            title: total_label,
            status: 'all',
          },
          {
            title: label1,
            status: 'return',
          },
          {
            title: label2,
            status: 'new',
          },
          {
            title: `${total_label}(추정)`,
            status: 'all_future',
          },
          {
            title: `${label1}(추정)`,
            status: 'return_future',
          },
          {
            title: `${label2}(추정)`,
            status: 'new_future',
          },
        ],
      }}
      dayWeekMultiChartData={{
        Data: WPVCData?.weekday || null,
        isLoading: isGetWPVCLoading,
        lineLabel: '전체 페이지뷰 수',
        bar1Label: '재방문 페이지뷰 수',
        bar2Label: '신규 페이지뷰 수',
        legendData: [
          {
            title: '전체 페이지뷰 수',
            status: 'all',
          },
          {
            title: '재방문 페이지뷰 수',
            status: 'return',
          },
          {
            title: '신규 페이지뷰 수',
            status: 'new',
          },
        ],
      }}
      heatmapChartData={{
        Data: WPVCData,
        loading: isGetWPVCLoading,
        totalLabel: total_label,
        label1,
        label2,
      }}
      onClickExcel={onClickExcel}
    />
  );
};

export default ServiceUserAnalytics;
