import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import CardBodyLayout from '../../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../../Atoms/Layout/Card/CardHeaderLayout';
import { H3, P3 } from '../../../Atoms/Typo';
import Spinner from '../../../Molecule/Spinner';
import HeatmapChart from '../../../Molecule/Chart/HeatmapChart';
import DefaultLink from '../../../Atoms/Link';

const Component = styled(CardLayout)`
  height: 410px;
  padding: 25px 0 0;
`;

const HeaderLayout = styled(CardHeaderLayout)`
  padding: 0 30px;
  ${H3} {
    font-size: 19px;
  }
`;

const CardBody = styled(CardBodyLayout)`
  padding: 0 30px;
`;

const LegendWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: ${(props) => props.theme.colors.grey100};
  border-radius: 0 0 10px 10px;
`;

const LegendSpectrum = styled.div`
  width: 60%;
  height: 15px;
  margin: 0 10px;
  background: transparent
    linear-gradient(90deg, #f9f9ff 0%, #c1c4fb 33%, #7e84f6 67%, #4c53f3 100%)
    0% 0% no-repeat padding-box;
`;

interface DashboardHeatMapCardProps {
  loading?: boolean;
  data: {
    [key: string]: {
      [key: string]: {
        new: number;
        old: number;
      };
    };
  } | null;
}

const DashboardHeatMapCard = ({ loading, data }: DashboardHeatMapCardProps) => {
  // state
  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    if (data) {
      const yAxisCategory = Object.keys(data);
      const xAxisCategory = Object.keys(Object.values(data)[0]);
      let min = 0;
      let max = 0;

      yAxisCategory.forEach((element, index) => {
        xAxisCategory.forEach((xAxisElement, xAxisIndex) => {
          const y =
            data[element][xAxisElement].new + data[element][xAxisElement].old;
          if (index === 0 && xAxisIndex === 0) {
            min = y;
            max = y;
          } else {
            if (max < y) {
              max = y;
            }
            if (min > y) {
              min = y;
            }
          }
        });
      });

      setMaxValue(max);
    }
  }, [data]);

  return (
    <Component>
      <HeaderLayout>
        <DefaultLink to="/sessionAnalytics/uniqueVisitor">
          <H3>
            시간대별 사용자<span className="ri-arrow-down-s-line"></span>
          </H3>
        </DefaultLink>
      </HeaderLayout>
      <CardBody>
        {loading ? (
          <Spinner />
        ) : (
          <HeatmapChart
            dashboard
            Data={data}
            totalLabel="전체"
            label1="재방문"
            label2="신규"
          />
        )}
      </CardBody>
      <LegendWrapper>
        <P3>0</P3>
        <LegendSpectrum />
        <P3>{maxValue}</P3>
      </LegendWrapper>
    </Component>
  );
};

export default DashboardHeatMapCard;
