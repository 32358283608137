import styled, { css } from 'styled-components';

import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';

import { P1, P2, P3 } from '../../Atoms/Typo';
import { Button } from '../../Atoms/Button';

export const Component = styled(CardLayout)`
  height: 100%;
  border: none;
  padding: 0;
  background-color: #f1f3f8;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  height: 60px;
`;

export const CardBody = styled(CardBodyLayout)<{ $isDetail?: boolean }>`
  display: flex;
  flex-direction: row;
  border-radius: 0 14px 14px 14px;
  border: 1px solid #d7dbe6;
  background-color: white;
  padding: 0px;

  ${(props) => {
    if (props.$isDetail) {
      return css`
        flex-direction: column;
      `;
    }
    return css``;
  }}
`;

export const LocalMapWrap = styled.div`
  padding: 30px;
  :nth-child(1) {
    width: 30%;
    padding-right: 15px;
  }
  :nth-child(2) {
    width: 70%;
    padding-left: 15px;
    .table-header {
      font-weight: 600;
      font-size: 18px;
    }
  }
`;

export const WorldMapWrap = styled.div`
  padding: 30px;
  :nth-child(1) {
    width: 75%;
    padding-right: 15px;
  }
  :nth-child(2) {
    width: 25%;
    background-color: #fcfcfc;
    padding-left: 15px;
    border-left: 1px solid #d7dbe6;
    border-radius: 0 14px 14px 0;
    .table-header {
      font-weight: 600;
      font-size: 18px;
    }
  }
`;

export const DetailWorldMapWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 30px;
  :first-child {
    padding-bottom: 0;
  }
  .table-header {
    font-weight: 600;
    font-size: 18px;
  }
`;

export const SimpleView = styled(Button)`
  position: absolute;
  width: 120px;
  height: 33px;
  bottom: 30px;
  right: 30px;
  ${P3} {
    color: #767676;
  }
`;

export const ColumnsItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #dddddd;
  height: 47px;
  :last-child {
    border-right: none;
  }
  ${P2} {
    font-weight: bold;
  }
  :nth-child(1) {
    width: 8%;
  }
  :nth-child(2) {
    width: 14%;
  }
  :nth-child(3) {
    width: 22%;
  }
  :nth-child(4) {
    width: 9%;
  }
  :nth-child(5) {
    width: 47%;
  }
`;

export const DataItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #dddddd;
  padding: 15px 20px;
  :nth-child(1) {
    width: 8%;
    ${P2} {
      font-weight: bold;
    }
  }
  :nth-child(2) {
    width: 14%;
    padding: 0;
  }
  :nth-child(3) {
    width: 22%;
    justify-content: flex-end;
    padding-right: 20px;
  }
  :nth-child(4) {
    width: 9%;
  }
  :nth-child(5) {
    width: 47%;
    height: 100%;
    padding: 15px 20px;
    border-right: none;
  }
`;

export const WorldColumnsItem = styled.div<{ $detail?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #dddddd;
  height: 45px;
  ${P2} {
    font-weight: bold;
  }
  :nth-child(1) {
    width: 20%;
  }
  :nth-child(2) {
    width: 50%;
  }
  :nth-child(3) {
    width: 30%;
    border-right: none;
  }
  ${(props) => {
    if (props.$detail) {
      return css`
        :nth-child(1) {
          width: 6%;
        }
        :nth-child(2) {
          width: 17%;
        }
        :nth-child(3) {
          width: 20%;
          border-right: 1px solid #dddddd;
        }
        :nth-child(4) {
          width: 15%;
        }
        :nth-child(5) {
          width: 10%;
        }
        :nth-child(6) {
          width: 32%;
          border-right: none;
        }
      `;
    }
    return css``;
  }}
`;

export const WorldDataItem = styled.div<{ $detail?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #dddddd;
  padding: 15px 20px;
  :nth-child(1) {
    width: 20%;
    ${P2} {
      font-weight: bold;
    }
  }
  :nth-child(2) {
    width: 50%;
    justify-content: flex-start;
  }
  :nth-child(3) {
    width: 30%;
    padding-right: 20px;
    border-right: none;
  }

  ${(props) => {
    if (props.$detail) {
      return css`
        :nth-child(1) {
          width: 6%;
        }
        :nth-child(2) {
          width: 17%;
          justify-content: flex-start;
        }
        :nth-child(3) {
          width: 20%;
          justify-content: flex-start;
          border-right: 1px solid #dddddd;
        }
        :nth-child(4) {
          width: 15%;
          justify-content: flex-end;
        }
        :nth-child(5) {
          width: 10%;
          justify-content: flex-end;
        }
        :nth-child(6) {
          width: 32%;
          height: 100%;
          padding: 15px 20px;
          border-right: none;
        }
      `;
    }
    return css``;
  }}
`;
