import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { RootState } from '../../../modules';

import {
  GetNationalDeviceEnvStaticsParamType,
  GetNationalBrowserEnvStaticsParamType,
  GetNationalOSEnvStaticsParamType,
  GetNationalResolutionStaticsParamType,
} from '../../../api/userAnalytics/environment/type';
import {
  getNationalDeviceEnvStaticsAsync,
  getNationalBrowserEnvStaticsAsync,
  getNationalOSEnvStaticsAsync,
  getNationalResolutionStaticsAsync,
} from '../../../modules/userAnalytics/environment/actions';

import ContentsLayout from '../../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../../components/Atoms/Layout/Contents/ContentsItemLayout';
import Header from '../../../components/Organisms/Header';
import EnvironmentDonutCard from '../../../components/Organisms/Card/EnvironmentDonutCard';
import ResolutionCard from '../../../components/Organisms/Card/ResolutionCard';

const Environment = () => {
  // redux
  const dispatch = useDispatch();
  const { startDate, endDate, isDatePickerToggle } = useSelector(
    (state: RootState) => state.datePicker
  );
  const {
    isLoadingNationalDevice,
    isLoadingNationalBrowser,
    isLoadingNationalOS,
    isLoadingResolution,
    nationalOSEnvData,
    nationalBrowserEnvData,
    nationalDeviceEnvData,
    resolutionData,
  } = useSelector((state: RootState) => state.environment);

  const onGetNationalDeviceEnvStatistics = (
    info: GetNationalDeviceEnvStaticsParamType
  ) => {
    dispatch(getNationalDeviceEnvStaticsAsync.request(info));
  };

  const onGetNationalOSEnvStatistics = (
    info: GetNationalOSEnvStaticsParamType
  ) => {
    dispatch(getNationalOSEnvStaticsAsync.request(info));
  };

  const onGetNationalBrowserEnvStatistics = (
    info: GetNationalBrowserEnvStaticsParamType
  ) => {
    dispatch(getNationalBrowserEnvStaticsAsync.request(info));
  };

  const onGetNationalResolutionStatics = (
    info: GetNationalResolutionStaticsParamType
  ) => {
    dispatch(getNationalResolutionStaticsAsync.request(info));
  };

  useEffect(() => {
    if (!isDatePickerToggle) {
      onGetNationalDeviceEnvStatistics({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
      onGetNationalOSEnvStatistics({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
      onGetNationalBrowserEnvStatistics({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
      onGetNationalResolutionStatics({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
    }
  }, [isDatePickerToggle, startDate, endDate]);

  return (
    <ContentsLayout>
      <Header
        title="사용환경 분석"
        icon=""
        iconClass="ri-user-received-line"
        isDatePicker
      />
      <ContentBodyLayout>
        <ContentsItemLayout desktop={4}>
          <EnvironmentDonutCard
            isLoading={isLoadingNationalDevice}
            title="디바이스"
            data={nationalDeviceEnvData}
            colorList={[
              '#05ABBE',
              '#007DA4',
              '#13B89C',
              '#C4A37D',
              '#C8BEB4',
              '#8B8B8B',
            ]}
          />
        </ContentsItemLayout>

        <ContentsItemLayout desktop={4}>
          <EnvironmentDonutCard
            isLoading={isLoadingNationalOS}
            title="운영체제"
            data={nationalOSEnvData}
            colorList={[
              '#7851E7',
              '#4F3892',
              '#D269A5',
              '#DEC2E1',
              '#E2E2E2',
              '#8B8B8B',
            ]}
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={4}>
          <EnvironmentDonutCard
            isLoading={isLoadingNationalBrowser}
            title="브라우저"
            data={nationalBrowserEnvData}
            colorList={[
              '#F99E32',
              '#EC5C1C',
              '#F3BE41',
              '#FFEAA5',
              '#E2E2E2',
              '#8B8B8B',
            ]}
          />
        </ContentsItemLayout>

        <ContentsItemLayout desktop={12}>
          <ResolutionCard data={resolutionData} />
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default Environment;
