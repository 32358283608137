import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  GetReportParamType,
  GetReportUVResponseType,
  GetReportSessionResponseType,
  GetReportAvgPVResponseType,
  GetReportDurationResponseType,
  GetReportInflowGlobalResponseType,
  GetReportInflowKeywordResponseType,
  GetReportInflowNationalResponseType,
  GetReportInflowPathResponseType,
  GetReportClickRateResponseType,
  GetReportGrowthPVResponseType,
  GetReportPopularPageResponseType,
  GetReportClickRateParamType,
  GetReportMonthResponseType,
} from '../../api/report/type';
import { AxiosErrorType } from '../createRequestSaga';

export const GET_REPORT_UV = 'report/GET_REPORT_UV' as const;
export const GET_REPORT_UV_SUCCESS = 'report/GET_REPORT_UV_SUCCESS' as const;
export const GET_REPORT_UV_ERROR = 'report/GET_REPORT_UV_ERROR' as const;

export const GET_REPORT_SESSION = 'report/GET_REPORT_SESSION' as const;
export const GET_REPORT_SESSION_SUCCESS =
  'report/GET_REPORT_SESSION_SUCCESS' as const;
export const GET_REPORT_SESSION_ERROR =
  'report/GET_REPORT_SESSION_ERROR' as const;

export const GET_REPORT_AVG_PV = 'report/GET_REPORT_AVG_PV' as const;
export const GET_REPORT_AVG_PV_SUCCESS =
  'report/GET_REPORT_AVG_PV_SUCCESS' as const;
export const GET_REPORT_AVG_PV_ERROR =
  'report/GET_REPORT_AVG_PV_ERROR' as const;

export const GET_REPORT_DURATION = 'report/GET_REPORT_DURATION' as const;
export const GET_REPORT_DURATION_SUCCESS =
  'report/GET_REPORT_DURATION_SUCCESS' as const;
export const GET_REPORT_DURATION_ERROR =
  'report/GET_REPORT_DURATION_ERROR' as const;

export const GET_REPORT_INFLOW_NATIONAL =
  'report/GET_REPORT_INFLOW_NATIONAL' as const;
export const GET_REPORT_INFLOW_NATIONAL_SUCCESS =
  'report/GET_REPORT_INFLOW_NATIONAL_SUCCESS' as const;
export const GET_REPORT_INFLOW_NATIONAL_ERROR =
  'report/GET_REPORT_INFLOW_NATIONAL_ERROR' as const;

export const GET_REPORT_INFLOW_GLOBAL =
  'report/GET_REPORT_INFLOW_GLOBAL' as const;
export const GET_REPORT_INFLOW_GLOBAL_SUCCESS =
  'report/GET_REPORT_INFLOW_GLOBAL_SUCCESS' as const;
export const GET_REPORT_INFLOW_GLOBAL_ERROR =
  'report/GET_REPORT_INFLOW_GLOBAL_ERROR' as const;

export const GET_REPORT_INFLOW_PATH = 'report/GET_REPORT_INFLOW_PATH' as const;
export const GET_REPORT_INFLOW_PATH_SUCCESS =
  'report/GET_REPORT_INFLOW_PATH_SUCCESS' as const;
export const GET_REPORT_INFLOW_PATH_ERROR =
  'report/GET_REPORT_INFLOW_PATH_ERROR' as const;

export const GET_REPORT_INFLOW_KEYWORD =
  'report/GET_REPORT_INFLOW_KEYWORD' as const;
export const GET_REPORT_INFLOW_KEYWORD_SUCCESS =
  'report/GET_REPORT_INFLOW_KEYWORD_SUCCESS' as const;
export const GET_REPORT_INFLOW_KEYWORD_ERROR =
  'report/GET_REPORT_INFLOW_KEYWORD_ERROR' as const;

export const GET_REPORT_POPULAR_PAGE =
  'report/GET_REPORT_POPULAR_PAGE' as const;
export const GET_REPORT_POPULAR_PAGE_SUCCESS =
  'report/GET_REPORT_POPULAR_PAGE_SUCCESS' as const;
export const GET_REPORT_POPULAR_PAGE_ERROR =
  'report/GET_REPORT_POPULAR_PAGE_ERROR' as const;

export const GET_REPORT_GROWTH_PV = 'report/GET_REPORT_GROWTH_PV' as const;
export const GET_REPORT_GROWTH_PV_SUCCESS =
  'report/GET_REPORT_GROWTH_PV_SUCCESS' as const;
export const GET_REPORT_GROWTH_PV_ERROR =
  'report/GET_REPORT_GROWTH_PV_ERROR' as const;

export const GET_REPORT_CLICK_RATE = 'report/GET_REPORT_CLICK_RATE' as const;
export const GET_REPORT_CLICK_RATE_SUCCESS =
  'report/GET_REPORT_CLICK_RATE_SUCCESS' as const;
export const GET_REPORT_CLICK_RATE_ERROR =
  'report/GET_REPORT_CLICK_RATE_ERROR' as const;

export const GET_REPORT_MONTH = 'report/GET_REPORT_MONTH' as const;
export const GET_REPORT_MONTH_SUCCESS =
  'report/GET_REPORT_MONTH_SUCCESS' as const;
export const GET_REPORT_MONTH_ERROR = 'report/GET_REPORT_MONTH_ERROR' as const;

export const SET_ERROR_NULL = 'report/SET_ERROR_NULL' as const;

export const getReportUVAsync = createAsyncAction(
  GET_REPORT_UV,
  GET_REPORT_UV_SUCCESS,
  GET_REPORT_UV_ERROR
)<GetReportParamType, GetReportUVResponseType, AxiosErrorType>();

export const getReportSessionAsync = createAsyncAction(
  GET_REPORT_SESSION,
  GET_REPORT_SESSION_SUCCESS,
  GET_REPORT_SESSION_ERROR
)<GetReportParamType, GetReportSessionResponseType, AxiosErrorType>();

export const getReportAvgPVAsync = createAsyncAction(
  GET_REPORT_AVG_PV,
  GET_REPORT_AVG_PV_SUCCESS,
  GET_REPORT_AVG_PV_ERROR
)<GetReportParamType, GetReportAvgPVResponseType, AxiosErrorType>();

export const getReportDurationAsync = createAsyncAction(
  GET_REPORT_DURATION,
  GET_REPORT_DURATION_SUCCESS,
  GET_REPORT_DURATION_ERROR
)<GetReportParamType, GetReportDurationResponseType, AxiosErrorType>();

export const getReportInflowNationalAsync = createAsyncAction(
  GET_REPORT_INFLOW_NATIONAL,
  GET_REPORT_INFLOW_NATIONAL_SUCCESS,
  GET_REPORT_INFLOW_NATIONAL_ERROR
)<GetReportParamType, GetReportInflowNationalResponseType, AxiosErrorType>();

export const getReportInflowGlobalAsync = createAsyncAction(
  GET_REPORT_INFLOW_GLOBAL,
  GET_REPORT_INFLOW_GLOBAL_SUCCESS,
  GET_REPORT_INFLOW_GLOBAL_ERROR
)<GetReportParamType, GetReportInflowGlobalResponseType, AxiosErrorType>();

export const getReportInflowPathAsync = createAsyncAction(
  GET_REPORT_INFLOW_PATH,
  GET_REPORT_INFLOW_PATH_SUCCESS,
  GET_REPORT_INFLOW_PATH_ERROR
)<GetReportParamType, GetReportInflowPathResponseType, AxiosErrorType>();

export const getReportInflowKeywordAsync = createAsyncAction(
  GET_REPORT_INFLOW_KEYWORD,
  GET_REPORT_INFLOW_KEYWORD_SUCCESS,
  GET_REPORT_INFLOW_KEYWORD_ERROR
)<GetReportParamType, GetReportInflowKeywordResponseType, AxiosErrorType>();

export const getReportPopularPageAsync = createAsyncAction(
  GET_REPORT_POPULAR_PAGE,
  GET_REPORT_POPULAR_PAGE_SUCCESS,
  GET_REPORT_POPULAR_PAGE_ERROR
)<GetReportParamType, GetReportPopularPageResponseType, AxiosErrorType>();

export const getReportGrowthPVAsync = createAsyncAction(
  GET_REPORT_GROWTH_PV,
  GET_REPORT_GROWTH_PV_SUCCESS,
  GET_REPORT_GROWTH_PV_ERROR
)<GetReportParamType, GetReportGrowthPVResponseType, AxiosErrorType>();

export const getReportClickRateAsync = createAsyncAction(
  GET_REPORT_CLICK_RATE,
  GET_REPORT_CLICK_RATE_SUCCESS,
  GET_REPORT_CLICK_RATE_ERROR
)<
  GetReportClickRateParamType,
  GetReportClickRateResponseType,
  AxiosErrorType
>();

export const getReportMonthAsync = createAsyncAction(
  GET_REPORT_MONTH,
  GET_REPORT_MONTH_SUCCESS,
  GET_REPORT_MONTH_ERROR
)<void, GetReportMonthResponseType, AxiosErrorType>();

export const setErrorNull = createAction(SET_ERROR_NULL)();
