import React, { ReactNodeArray } from 'react';
import styled from 'styled-components';

import { GetScrollEventsResponseType } from '../../../../api/pageAnalytics/uxAnalytics/scroll/type';
import { P1 } from '../../../Atoms/Typo';
import Spinner from '../../../Molecule/Spinner';

import PageScreenCard from './PageScreenCard';

const Component = styled.div`
  width: 100%;
`;

const Header = styled.div`
  margin-bottom: 20px;
  ${P1} {
    font-weight: 600;
  }
`;

const ItemList = styled.div`
  display: flex;
  width: 100%;
`;

const Body = styled.div``;

const ColumnRankTable = styled.div`
  flex: 1;
`;

interface MouseScrollRankCardProps {
  data: GetScrollEventsResponseType['payload'] | null;
  pageId: string;
  isLoading: boolean;
  activeDataType: number;
}

const MouseScrollRankCard = ({
  data,
  pageId,
  isLoading,
  activeDataType,
}: MouseScrollRankCardProps) => {
  const printData = () => {
    const result: ReactNodeArray = [];
    if (data) {
      data.attention_section.forEach((element, index) => {
        const rank_data = [
          {
            id: 0,
            title: '평균 주목 시간',
            value: `${element.avg_attention_time || 0}초`,
          },
          {
            id: 1,
            title: '주목 비율',
            value: `${element.attention_rate}%`,
          },
        ];

        const Item = (
          <PageScreenCard
            img_src={`https://voda-media.nerdfactory.ai/${localStorage.getItem(
              'voda_tenant'
            )}/auto/${pageId}/${encodeURIComponent('page_screenshot')}`}
            rank={index + 1}
            data={rank_data}
            pageHeight={data.height}
            section_cnt={data.section_cnt}
            index={element.index}
            key={element.index}
          />
        );
        result.push(Item);
      });
    }

    return (
      <ItemList key="mouse_scroll_rank">
        <ColumnRankTable>{result}</ColumnRankTable>
      </ItemList>
    );
  };

  const printHeading = () => {
    let title = '';
    switch (activeDataType) {
      case 0:
        title = '클릭 순위';
        break;
      case 1:
        title = '주목 화면 순위';
        break;

      default:
        break;
    }
    return title;
  };

  return (
    <Component>
      <Header>
        <P1>{printHeading()}</P1>
      </Header>

      <Body>{isLoading ? <Spinner /> : printData()}</Body>
    </Component>
  );
};

export default MouseScrollRankCard;
