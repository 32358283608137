import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import CardBodyLayout from '../../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../../Atoms/Layout/Card/CardHeaderLayout';
import { H3, P2, P3 } from '../../../Atoms/Typo';
import Spinner from '../../../Molecule/Spinner';
import { GetInflowPathStatisticsResponseType } from '../../../../api/Dashboard/type';
import DounutChart from '../../../Molecule/Chart/DonutChart';
import DefaultLink from '../../../Atoms/Link';

const Component = styled(CardLayout)`
  height: 280px;
  padding: 25px;
`;

const HeaderLayout = styled(CardHeaderLayout)`
  ${H3} {
    font-size: 19px;
  }
`;

const BodyLayout = styled(CardBodyLayout)`
  flex-direction: row;
  height: 100%;
`;

const LegendWrapper = styled.div`
  min-width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LegendItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  :last-child {
    margin-bottom: 0;
  }
`;

const Rank = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: #eeeeee;
  text-align: center;
  margin-right: 10px;
`;

const TitleWrap = styled.div`
  display: flex;
`;

const Rate = styled(P2)<{ $idx: string }>`
  font-weight: bold;
  ${(props) => {
    switch (props.$idx) {
      case '직접유입':
        return css`
          color: #00258d;
        `;
      case '검색유입':
        return css`
          color: #2962ff;
        `;
      case '추천유입':
        return css`
          color: #7851e7;
        `;
      case '소셜유입':
        return css`
          color: #0dae9d;
        `;

      default:
        return null;
    }
  }}
`;

const ChartWrapper = styled.div<{ $idx?: number }>`
  .apexcharts-datalabels {
    ${(props) => {
      if (props.$idx) {
        return css`
          :nth-child(${props.$idx + 1}) {
            rect {
              fill: #ff3b56;
              stroke: #ff3b56;
            }
            text {
              fill: #fff;
            }
          }
        `;
      }
      return css``;
    }}
  }
`;

interface DashboardPathCardState {
  listData: {
    name: string;
    cnt: number;
    rate: number;
  }[];
}

interface DashboardPathCardProps {
  loading?: boolean;
  data: GetInflowPathStatisticsResponseType['payload'];
}

const DashboardPathCard = ({ loading, data }: DashboardPathCardProps) => {
  // state
  const [rebData, setRebData] = useState<DashboardPathCardState['listData']>(
    []
  );
  const [legendData, setLegendData] = useState<
    DashboardPathCardState['listData']
  >([]);
  const [maxIndex, setMaxIndex] = useState<number>();

  useEffect(() => {
    const cntArr: number[] = [];

    if (data.length > 0) {
      const updatedArr = data.map((item) => ({
        ...item,
        cnt: item.rate,
      }));

      setRebData(updatedArr);

      // DataLabel 활성화를 위한 최대값 idx 구하는 로직
      updatedArr.forEach((element) => {
        cntArr.push(element.rate);
      });
      const max = Math.max(...cntArr);
      cntArr.forEach((element, idx) => {
        if (element === max) {
          setMaxIndex(idx + cntArr.length);
        }
      });

      // legend data rebuild
      setLegendData([...updatedArr].sort((a, b) => b.rate - a.rate));
    }
  }, [data]);

  return (
    <Component>
      <HeaderLayout>
        <DefaultLink to="/userAnalytics/inflowPath">
          <H3>
            유입 경로<span className="ri-arrow-down-s-line"></span>
          </H3>
        </DefaultLink>
      </HeaderLayout>
      {loading ? (
        <Spinner />
      ) : (
        <BodyLayout>
          <LegendWrapper>
            {legendData.map((element, idx) => {
              return (
                <LegendItem key={element.name}>
                  <TitleWrap>
                    <Rank>
                      <P3>{idx + 1}</P3>
                    </Rank>
                    <P2>{element.name}</P2>
                  </TitleWrap>
                  <Rate $idx={element.name}>{element.rate}%</Rate>
                </LegendItem>
              );
            })}
          </LegendWrapper>
          <ChartWrapper $idx={maxIndex}>
            <DounutChart
              data={rebData}
              colorList={['#00258D', '#2962FF', '#3FECB7', '#7851E7']}
              isDashboard
            />
          </ChartWrapper>
        </BodyLayout>
      )}
    </Component>
  );
};

export default DashboardPathCard;
