import { takeLatest } from 'redux-saga/effects';
import createRequestSaga from '../../createRequestSaga';

import {
  apiGetNationalDeviceEnvStatics,
  apiGetNationalBrowserEnvStatics,
  apiGetNationalOSEnvStatics,
  apiGetNationalResolutionStatics,
} from '../../../api/userAnalytics/environment/api';

import {
  GET_NATIONAL_BROWSER_ENV_STATICS,
  GET_NATIONAL_DEVICE_ENV_STATICS,
  GET_NATIONAL_OS_ENV_STATICS,
  GET_NATIONAL_RESOLUTION,
  getNationalBrowserEnvStaticsAsync,
  getNationalDeviceEnvStaticsAsync,
  getNationalOSEnvStaticsAsync,
  getNationalResolutionStaticsAsync,
} from './actions';

const getNationalDeviceEnvStaticsSaga = createRequestSaga(
  getNationalDeviceEnvStaticsAsync,
  apiGetNationalDeviceEnvStatics
);

const getNationalOSEnvStaticsSaga = createRequestSaga(
  getNationalOSEnvStaticsAsync,
  apiGetNationalOSEnvStatics
);

const getNationalBrowserEnvStaticsSaga = createRequestSaga(
  getNationalBrowserEnvStaticsAsync,
  apiGetNationalBrowserEnvStatics
);

const getNationalResolutionStaticsSaga = createRequestSaga(
  getNationalResolutionStaticsAsync,
  apiGetNationalResolutionStatics
);

export function* environmentSaga() {
  yield takeLatest(
    GET_NATIONAL_DEVICE_ENV_STATICS,
    getNationalDeviceEnvStaticsSaga
  );
  yield takeLatest(GET_NATIONAL_OS_ENV_STATICS, getNationalOSEnvStaticsSaga);
  yield takeLatest(
    GET_NATIONAL_BROWSER_ENV_STATICS,
    getNationalBrowserEnvStaticsSaga
  );
  yield takeLatest(GET_NATIONAL_RESOLUTION, getNationalResolutionStaticsSaga);
}

export { environmentSaga as default };
