import { createReducer } from 'typesafe-actions';
import {
  GET_INFLOW_PATH,
  GET_INFLOW_PATH_SUCCESS,
  GET_INFLOW_PATH_ERROR,
  GET_INFLOW_PATH_COUNT,
  GET_INFLOW_PATH_COUNT_SUCCESS,
  GET_INFLOW_PATH_COUNT_ERROR,
  SET_ERROR_NULL_INFLOW_PATH,
  GET_SEARCH_INFLOW,
  GET_SEARCH_INFLOW_SUCCESS,
  GET_SEARCH_INFLOW_ERROR,
  GET_SOCIAL_INFLOW,
  GET_SOCIAL_INFLOW_SUCCESS,
  GET_SOCIAL_INFLOW_ERROR,
  GET_RECOMMEND_INFLOW,
  GET_RECOMMEND_INFLOW_SUCCESS,
  GET_RECOMMEND_INFLOW_ERROR,
  GET_DETAIL_SEARCH,
  GET_DETAIL_SEARCH_SUCCESS,
  GET_DETAIL_SEARCH_ERROR,
  GET_DETAIL_SOCIAL,
  GET_DETAIL_SOCIAL_SUCCESS,
  GET_DETAIL_SOCIAL_ERROR,
  GET_TOTAL_KEYWORD,
  GET_TOTAL_KEYWORD_SUCCESS,
  GET_TOTAL_KEYWORD_ERROR,
  GET_TOTAL_INFLOW_COUNT,
  GET_TOTAL_INFLOW_COUNT_SUCCESS,
  GET_TOTAL_INFLOW_COUNT_ERROR,
} from './actions';
import { inflowPathAction, inflowPathState } from './types';

const initialState: inflowPathState = {
  isLoadingTotal: false,
  isLoadingInflow: false,
  isLoadingInflowCount: false,
  isLoadingSearch: false,
  isLoadingSocial: false,
  isLoadingRecommend: false,
  isLoadingDetailSearch: false,
  isLoadingTotalKeyword: false,
  isLoadingDetailSocial: false,

  totalInflowData: null,
  inflowPathData: [],
  inflowPathCntData: null,
  inflowError: null,
  searchInflowData: [],
  socialInflowData: [],
  recommendInflowData: [],
  detailSearchData: [],
  totalKeywordData: [],
  detailSocialData: [],
};

const reducer = createReducer<inflowPathState, inflowPathAction>(initialState, {
  [GET_TOTAL_INFLOW_COUNT]: (state) => ({
    ...state,
    isLoadingInflowCount: true,
    totalInflowData: null,
    inflowError: null,
  }),
  [GET_TOTAL_INFLOW_COUNT_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingInflowCount: false,
    totalInflowData: action.payload.payload,
  }),
  [GET_TOTAL_INFLOW_COUNT_ERROR]: (state, action) => ({
    ...state,
    isLoadingInflowCount: false,
    totalInflowData: null,
    inflowError: action.payload,
  }),

  [GET_INFLOW_PATH]: (state) => ({
    ...state,
    isLoadingInflow: true,
    inflowPathData: [],
    inflowError: null,
  }),
  [GET_INFLOW_PATH_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingInflow: false,
    inflowPathData: action.payload.payload,
  }),
  [GET_INFLOW_PATH_ERROR]: (state, action) => ({
    ...state,
    isLoadingInflow: false,
    inflowPathData: [],
    inflowError: action.payload,
  }),

  [GET_INFLOW_PATH_COUNT]: (state) => ({
    ...state,
    isLoadingInflowCount: true,
    inflowPathCntData: null,
    inflowError: null,
  }),
  [GET_INFLOW_PATH_COUNT_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingInflowCount: false,
    inflowPathCntData: action.payload.payload,
  }),
  [GET_INFLOW_PATH_COUNT_ERROR]: (state, action) => ({
    ...state,
    isLoadingInflowCount: false,
    inflowPathCntData: null,
    inflowError: action.payload,
  }),

  [GET_SEARCH_INFLOW]: (state) => ({
    ...state,
    isLoadingSearch: true,
    searchInflowData: [],
    inflowError: null,
  }),
  [GET_SEARCH_INFLOW_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingSearch: false,
    searchInflowData: action.payload.payload,
  }),
  [GET_SEARCH_INFLOW_ERROR]: (state, action) => ({
    ...state,
    isLoadingSearch: false,
    searchInflowData: [],
    inflowError: action.payload,
  }),

  [GET_SOCIAL_INFLOW]: (state) => ({
    ...state,
    isLoadingSocial: true,
    socialInflowData: [],
    inflowError: null,
  }),
  [GET_SOCIAL_INFLOW_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingSocial: false,
    socialInflowData: action.payload.payload,
  }),
  [GET_SOCIAL_INFLOW_ERROR]: (state, action) => ({
    ...state,
    isLoadingSocial: false,
    socialInflowData: [],
    inflowError: action.payload,
  }),

  [GET_RECOMMEND_INFLOW]: (state) => ({
    ...state,
    isLoadingRecommend: true,
    recommendInflowData: [],
    inflowError: null,
  }),
  [GET_RECOMMEND_INFLOW_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingRecommend: false,
    recommendInflowData: action.payload.payload,
  }),
  [GET_RECOMMEND_INFLOW_ERROR]: (state, action) => ({
    ...state,
    isLoadingRecommend: false,
    recommendInflowData: [],
    inflowError: action.payload,
  }),

  [GET_DETAIL_SEARCH]: (state) => ({
    ...state,
    isLoadingDetailSearch: true,
    detailSearchData: [],
    inflowError: null,
  }),
  [GET_DETAIL_SEARCH_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingDetailSearch: false,
    detailSearchData: action.payload.payload,
  }),
  [GET_DETAIL_SEARCH_ERROR]: (state, action) => ({
    ...state,
    isLoadingDetailSearch: false,
    detailSearchData: [],
    inflowError: action.payload,
  }),

  [GET_TOTAL_KEYWORD]: (state) => ({
    ...state,
    isLoadingTotalKeyword: true,
    totalKeywordData: [],
    inflowError: null,
  }),
  [GET_TOTAL_KEYWORD_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingTotalKeyword: false,
    totalKeywordData: action.payload.payload,
  }),
  [GET_TOTAL_KEYWORD_ERROR]: (state, action) => ({
    ...state,
    isLoadingTotalKeyword: false,
    totalKeywordData: [],
    inflowError: action.payload,
  }),

  [GET_DETAIL_SOCIAL]: (state) => ({
    ...state,
    isLoadingDetailSocial: true,
    detailSocialData: [],
    inflowError: null,
  }),
  [GET_DETAIL_SOCIAL_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingDetailSocial: false,
    detailSocialData: action.payload.payload,
  }),
  [GET_DETAIL_SOCIAL_ERROR]: (state, action) => ({
    ...state,
    isLoadingDetailSocial: false,
    detailSocialData: [],
    inflowError: action.payload,
  }),

  [SET_ERROR_NULL_INFLOW_PATH]: (state) => ({
    ...state,
    inflowError: null,
  }),
});

export default reducer;
