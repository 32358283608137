import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import { P1, P2, P3 } from '../../../Atoms/Typo';
import ServiceIcon from '../../../../assets/icons/icon-report.png';
import UpIcon from '../../../../assets/icons/icon-report-up.png';
import DownIcon from '../../../../assets/icons/icon-report-down.png';
import {
  GetReportAvgPVResponseType,
  GetReportDurationResponseType,
  GetReportSessionResponseType,
  GetReportUVResponseType,
} from '../../../../api/report/type';
import BarChart from '../../../Molecule/Chart/BarChart';
import Spinner from '../../../Molecule/Spinner';
import DefaultLink from '../../../Atoms/Link';
import DataError from '../../../Atoms/DataError';

const Component = styled(CardLayout)`
  padding: 0;
  flex-direction: row;
  border-radius: 14px;
  height: 600px;
  width: 100%;
`;

const InnerGrid = styled.div`
  display: flex;
  flex-direction: column;
  width: 37%;
  padding: 40px;
  justify-content: space-around;
  :nth-child(1) {
    width: 26%;
    padding-right: 10px;
    border-right: 1px solid #d7dbe6;
    border-radius: 14px 0px 0px 14px;
    background-color: #fcfcfc;
    justify-content: space-between;
  }
  img {
    width: 44px;
    height: 82px;
  }
`;

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(P1)`
  font-size: 20px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 25px;
`;

const Summary = styled.div`
  width: 100%;
  ${P3} {
    color: #767676;
  }
`;

const LinkButton = styled.button`
  border: none;
  width: 155px;
  height: 38px;
  background-color: #ffffff;
  border: 1px solid #cfcfcf;
  border-radius: 23px;
  color: #767676;
  font-size: 15px;
  :hover {
    cursor: pointer;
  }
`;

const GridWrapper = styled.div`
  width: 100%;
  height: 225px;
  display: flex;
  flex-direction: column;
`;

const WrapperBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const ChartSummary = styled.div`
  width: 35%;
`;

const GridTitle = styled(P2)`
  font-size: 15px;
  font-weight: 600;
  text-decoration: underline;
`;

const Value = styled(P1)`
  font-size: 22px;
  color: #7851e7;
  font-weight: bold;
  padding: 10px 0 15px;
`;

const CompareItem = styled.div`
  display: flex;
  align-items: center;
  ${P3} {
    font-size: 15px;
    color: #767676;
  }
  :first-child {
    margin-bottom: 10px;
  }
  img {
    width: 10px;
    height: 9px;
    margin-left: 4px;
  }
`;

const ChartGrid = styled.div`
  width: 65%;
  padding-top: 10px;
`;

interface ServiceActivationCardState {
  rebuildData: {
    [key: string]: number | boolean;
    isHighlight: boolean;
  }[];
}

interface ServiceActivationCardProps {
  uvLoading: boolean;
  sessionLoading: boolean;
  pvLoading: boolean;
  dtLoading: boolean;
  uvData: GetReportUVResponseType['payload'] | null;
  sessionData: GetReportSessionResponseType['payload'] | null;
  pvData: GetReportAvgPVResponseType['payload'] | null;
  dtData: GetReportDurationResponseType['payload'] | null;
}

const ServiceActivationCard = ({
  uvLoading,
  sessionLoading,
  pvLoading,
  dtLoading,
  uvData,
  sessionData,
  pvData,
  dtData,
}: ServiceActivationCardProps) => {
  // state
  // uv
  const [compareUV, setCompareUV] = useState(0);
  const [compareUVRate, setCompareUVRate] = useState(0);
  const [compareUVTwoMonthRate, setCompareUVTwoMonthRate] = useState(0);
  const [countUV, setCountUV] = useState(0);
  const [rebuildUVData, setRebuildUVData] = useState<
    ServiceActivationCardState['rebuildData']
  >([]);
  // pv
  const [comparePV, setComparePV] = useState(0);
  const [comparePVRate, setComparePVRate] = useState(0);
  const [comparePVTwoMonthRate, setComparePVTwoMonthRate] = useState(0);
  const [countPV, setCountPV] = useState(0);
  const [rebuildPVData, setRebuildPVData] = useState<
    ServiceActivationCardState['rebuildData']
  >([]);
  // session
  const [compareSessionRate, setCompareSessionRate] = useState(0);
  const [compareSessionTwoMonthRate, setCompareSessionTwoMonthRate] =
    useState(0);
  const [countSession, setCountSession] = useState(0);
  const [rebuildSessionData, setRebuildSessionData] = useState<
    ServiceActivationCardState['rebuildData']
  >([]);
  // duration
  const [compareDT, setCompareDT] = useState('');
  const [compareDTRate, setCompareDTRate] = useState(0);
  const [compareDTTwoMonthRate, setCompareDTTwoMonthRate] = useState(0);
  const [countDT, setCountDT] = useState(0);
  const [rebuildDTData, setRebuildDTData] = useState<
    ServiceActivationCardState['rebuildData']
  >([]);

  const [monthList, setMonthList] = useState<string[]>([]);

  const convertSec = (sec: number) => {
    const cvMin = Math.floor(sec / 60);
    const cvSec = Math.floor(sec % 60);

    if (cvMin === 0) {
      return `${Math.abs(cvSec)}초`;
    }
    return `${Math.abs(cvMin)}분 ${Math.abs(cvSec)}초`;
  };

  // 사용자수
  useEffect(() => {
    const rebuildData: ServiceActivationCardState['rebuildData'] = [];
    if (uvData && Object.keys(uvData).length > 0) {
      const month_obj = uvData.month_count;
      const month_arr = Object.keys(month_obj);
      const month_list: string[] = [];

      let max_uv = 0;

      setCompareUV(uvData.last_month_compare);
      setCompareUVRate(uvData.last_month_compare_rate);
      setCompareUVTwoMonthRate(uvData.two_month_ago_compare_rate);
      setCountUV(uvData.count);

      month_arr.forEach((element, index) => {
        if (max_uv < month_obj[element]) {
          max_uv = month_obj[element];
        }

        month_list.push(`${element.split('-')[1]}월`);
        rebuildData.push({
          [`${element.split('-')[1]}월`]: month_obj[element],
          isHighlight: index === 2,
        });
      });

      setMonthList(month_list);
    }
    setRebuildUVData(rebuildData);
  }, [uvData]);

  // 평균 페이지뷰
  useEffect(() => {
    const rebuildData: ServiceActivationCardState['rebuildData'] = [];
    if (pvData && Object.keys(pvData).length > 0) {
      const month_obj = pvData.month_avg_pv;
      const month_arr = Object.keys(month_obj);
      const month_list: string[] = [];

      let max_uv = 0;

      setComparePV(pvData.last_month_compare);
      setComparePVRate(pvData.last_month_compare_rate);
      setComparePVTwoMonthRate(pvData.two_month_ago_compare_rate);
      setCountPV(pvData.count);

      month_arr.forEach((element, index) => {
        if (max_uv < month_obj[element]) {
          max_uv = month_obj[element];
        }

        month_list.push(`${element.split('-')[1]}월`);
        rebuildData.push({
          [`${element.split('-')[1]}월`]: month_obj[element],
          isHighlight: index === 2,
        });
      });
    }
    setRebuildPVData(rebuildData);
  }, [pvData]);

  // 방문 횟수
  useEffect(() => {
    const rebuildData: ServiceActivationCardState['rebuildData'] = [];
    if (sessionData && Object.keys(sessionData).length > 0) {
      const month_obj = sessionData.month_count;
      const month_arr = Object.keys(month_obj);
      const month_list: string[] = [];

      let max_uv = 0;

      setCompareSessionRate(sessionData.last_month_compare_rate);
      setCompareSessionTwoMonthRate(sessionData.two_month_ago_compare_rate);
      setCountSession(sessionData.count);

      month_arr.forEach((element, index) => {
        if (max_uv < month_obj[element]) {
          max_uv = month_obj[element];
        }

        month_list.push(`${element.split('-')[1]}월`);
        rebuildData.push({
          [`${element.split('-')[1]}월`]: month_obj[element],
          isHighlight: index === 2,
        });
      });
    }
    setRebuildSessionData(rebuildData);
  }, [sessionData]);

  // 평균 이용 시간
  useEffect(() => {
    const rebuildData: ServiceActivationCardState['rebuildData'] = [];
    if (dtData && Object.keys(dtData).length > 0) {
      const month_obj = dtData.duration_time_list;
      const month_arr = Object.keys(month_obj);
      const month_list: string[] = [];

      let max_uv = 0;

      if (dtData.last_month_compare_rate > 0) {
        setCompareDT(
          ` ${convertSec(dtData.last_month_compare)}(+${
            dtData.last_month_compare_rate
          }%) 길게 머물렀어요.`
        );
      } else {
        setCompareDT(
          ` ${convertSec(dtData.last_month_compare)}(${
            dtData.last_month_compare_rate
          }%) 짧게 머물렀어요.`
        );
      }

      setCompareDTRate(dtData.last_month_compare_rate);
      setCompareDTTwoMonthRate(dtData.two_month_ago_compare_rate);
      setCountDT(dtData.count);

      month_arr.forEach((element, index) => {
        if (max_uv < month_obj[element]) {
          max_uv = month_obj[element];
        }

        month_list.push(`${element.split('-')[1]}월`);
        rebuildData.push({
          [`${element.split('-')[1]}월`]: month_obj[element],
          isHighlight: index === 2,
        });
      });
    }
    setRebuildDTData(rebuildData);
  }, [dtData]);

  return (
    <Component>
      <InnerGrid>
        <SummaryWrapper>
          <img alt="" src={ServiceIcon} />
          <Title>서비스가 얼마나 활성화됐나요?</Title>
          <Summary>
            <P3>
              지난 달보다
              {compareUV > 0
                ? ` ${compareUV.toLocaleString()}명(+${compareUVRate}%) 더 많이 `
                : ` ${Math.abs(
                    compareUV
                  ).toLocaleString()}명(${compareUVRate}%) 적게 `}
              방문하고
            </P3>
            <P3>
              평균
              {comparePV > 0
                ? ` ${comparePV.toLocaleString()}(+${comparePVRate}%) 많은 `
                : ` ${Math.abs(
                    comparePV
                  ).toLocaleString()}(${comparePVRate}%) 적은 `}
              페이지를 이용했어요.
            </P3>
            <P3>
              지난 달보다 평균
              {compareDT}
            </P3>
          </Summary>
        </SummaryWrapper>
        <DefaultLink to="/sessionAnalytics/uniqueVisitor">
          <LinkButton>
            사용자 방문 분석 <span className="ri-arrow-down-s-line"></span>
          </LinkButton>
        </DefaultLink>
      </InnerGrid>

      <InnerGrid>
        {uvLoading ? (
          <Spinner />
        ) : (
          <GridWrapper>
            <GridTitle>사용자 수</GridTitle>
            {rebuildUVData.length > 0 ? (
              <WrapperBody>
                <ChartSummary>
                  <Value>{countUV.toLocaleString()}</Value>
                  {compareUVTwoMonthRate > 0 ? (
                    <CompareItem>
                      <P3>
                        {monthList[0]} 대비 {compareUVTwoMonthRate}%
                      </P3>
                      <img src={UpIcon} alt="" />
                    </CompareItem>
                  ) : (
                    <CompareItem>
                      <P3>
                        {monthList[0]} 대비 {Math.abs(compareUVTwoMonthRate)}%
                      </P3>
                      <img src={DownIcon} alt="" />
                    </CompareItem>
                  )}
                  {compareUVRate > 0 ? (
                    <CompareItem>
                      <P3>
                        {monthList[1]} 대비 {compareUVRate}%
                      </P3>
                      <img src={UpIcon} alt="" />
                    </CompareItem>
                  ) : (
                    <CompareItem>
                      <P3>
                        {monthList[1]} 대비 {Math.abs(compareUVRate)}%
                      </P3>
                      <img src={DownIcon} alt="" />
                    </CompareItem>
                  )}
                </ChartSummary>
                <ChartGrid>
                  <BarChart data={rebuildUVData} isLabelInChart={false} />
                </ChartGrid>
              </WrapperBody>
            ) : (
              <DataError />
            )}
          </GridWrapper>
        )}
        {pvLoading ? (
          <Spinner />
        ) : (
          <GridWrapper>
            <GridTitle>평균 페이지뷰</GridTitle>
            {rebuildPVData.length > 0 ? (
              <WrapperBody>
                <ChartSummary>
                  <Value>{countPV.toLocaleString()}</Value>
                  {comparePVTwoMonthRate > 0 ? (
                    <CompareItem>
                      <P3>
                        {monthList[0]} 대비 {comparePVTwoMonthRate}%
                      </P3>
                      <img src={UpIcon} alt="" />
                    </CompareItem>
                  ) : (
                    <CompareItem>
                      <P3>
                        {monthList[0]} 대비 {Math.abs(comparePVTwoMonthRate)}%
                      </P3>
                      <img src={DownIcon} alt="" />
                    </CompareItem>
                  )}
                  {comparePVRate > 0 ? (
                    <CompareItem>
                      <P3>
                        {monthList[1]} 대비 {comparePVRate}%
                      </P3>
                      <img src={UpIcon} alt="" />
                    </CompareItem>
                  ) : (
                    <CompareItem>
                      <P3>
                        {monthList[1]} 대비 {Math.abs(comparePVRate)}%
                      </P3>
                      <img src={DownIcon} alt="" />
                    </CompareItem>
                  )}
                </ChartSummary>
                <ChartGrid>
                  <BarChart data={rebuildPVData} isLabelInChart={false} />
                </ChartGrid>
              </WrapperBody>
            ) : (
              <DataError />
            )}
          </GridWrapper>
        )}
      </InnerGrid>

      <InnerGrid>
        {sessionLoading ? (
          <Spinner />
        ) : (
          <GridWrapper>
            <GridTitle>방문 횟수</GridTitle>
            {rebuildSessionData.length > 0 ? (
              <WrapperBody>
                <ChartSummary>
                  <Value>{countSession.toLocaleString()}</Value>
                  {compareSessionTwoMonthRate > 0 ? (
                    <CompareItem>
                      <P3>
                        {monthList[0]} 대비 {compareSessionTwoMonthRate}%
                      </P3>
                      <img src={UpIcon} alt="" />
                    </CompareItem>
                  ) : (
                    <CompareItem>
                      <P3>
                        {monthList[0]} 대비{' '}
                        {Math.abs(compareSessionTwoMonthRate)}%
                      </P3>
                      <img src={DownIcon} alt="" />
                    </CompareItem>
                  )}
                  {compareSessionRate > 0 ? (
                    <CompareItem>
                      <P3>
                        {monthList[1]} 대비 {compareSessionRate}%
                      </P3>
                      <img src={UpIcon} alt="" />
                    </CompareItem>
                  ) : (
                    <CompareItem>
                      <P3>
                        {monthList[1]} 대비 {Math.abs(compareSessionRate)}%
                      </P3>
                      <img src={DownIcon} alt="" />
                    </CompareItem>
                  )}
                </ChartSummary>
                <ChartGrid>
                  <BarChart data={rebuildSessionData} isLabelInChart={false} />
                </ChartGrid>
              </WrapperBody>
            ) : (
              <DataError addText="23년 7월부터 제공되는 카드입니다." />
            )}
          </GridWrapper>
        )}
        {dtLoading ? (
          <Spinner />
        ) : (
          <GridWrapper>
            <GridTitle>평균 이용 시간</GridTitle>
            {rebuildDTData.length > 0 ? (
              <WrapperBody>
                <ChartSummary>
                  <Value>{convertSec(countDT)}</Value>
                  {compareDTTwoMonthRate > 0 ? (
                    <CompareItem>
                      <P3>
                        {monthList[0]} 대비 {compareDTTwoMonthRate}%
                      </P3>
                      <img src={UpIcon} alt="" />
                    </CompareItem>
                  ) : (
                    <CompareItem>
                      <P3>
                        {monthList[0]} 대비 {Math.abs(compareDTTwoMonthRate)}%
                      </P3>
                      <img src={DownIcon} alt="" />
                    </CompareItem>
                  )}
                  {compareDTRate > 0 ? (
                    <CompareItem>
                      <P3>
                        {monthList[1]} 대비 {compareDTRate}%
                      </P3>
                      <img src={UpIcon} alt="" />
                    </CompareItem>
                  ) : (
                    <CompareItem>
                      <P3>
                        {monthList[1]} 대비 {Math.abs(compareDTRate)}%
                      </P3>
                      <img src={DownIcon} alt="" />
                    </CompareItem>
                  )}
                </ChartSummary>
                <ChartGrid>
                  <BarChart
                    data={rebuildDTData}
                    isLabelInChart={false}
                    isTime
                  />
                </ChartGrid>
              </WrapperBody>
            ) : (
              <DataError />
            )}
          </GridWrapper>
        )}
      </InnerGrid>
    </Component>
  );
};

export default ServiceActivationCard;
