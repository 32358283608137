import React from 'react';
import styled, { css } from 'styled-components';
import { P3 } from '../../../Atoms/Typo';

const Component = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  :last-child {
    margin-bottom: 0;
  }
`;

const Dot = styled.div<{ $color: string }>`
  min-width: 8px;
  height: 8px;
  background-color: black;

  ${(props) => {
    if (props.$color) {
      return css`
        background-color: ${props.$color};
      `;
    }
    return css``;
  }}
`;

const Title = styled(P3)`
  font-size: 15px;
  color: #767676;
  margin-left: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface LegendProps {
  data: {
    [key: string]: string | number;
    cnt: number;
    rate: number;
  }[];
  colorList: string[];
}

const Legend = ({ data, colorList }: LegendProps) => {
  return (
    <Component>
      {data.map((element, index) => {
        return (
          <Item key={element.name}>
            <Dot $color={colorList[index]} />
            <Title>{element.name}</Title>
          </Item>
        );
      })}
    </Component>
  );
};

export default Legend;
