import React from 'react';
import styled from 'styled-components';
import { P3 } from '../../Atoms/Typo';
import { pathPlotInflow } from '../../../api/pageAnalytics/pathPlot/type';

const Component = styled.div`
  width: 100%;
  height: 90px;
  padding: 15px 10px;
  border: 1px solid #d7dbe6;
  background-color: white;
  border-radius: 6px;
  margin-bottom: 20px;
  :last-child {
    margin-bottom: 0;
  }
`;

const InflowTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
`;

const InflowItemTitle = styled.div`
  overflow: hidden;
  P {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const InflowCountWrapper = styled.div`
  display: flex;
`;

const InflowSessionCount = styled.div`
  display: flex;
  align-items: baseline;
  span {
    font-size: 15px;
    color: #222222;
  }
  ${P3} {
    font-size: 15px;
    font-weight: 600;
  }
`;

interface PathPlotInflowItemProps {
  data: pathPlotInflow;
}

const PathPlotInflowItem = ({ data }: PathPlotInflowItemProps) => {
  return (
    <Component className="inflowItemCard">
      <InflowTitleWrapper>
        <InflowItemTitle>
          <P3>{data.name}</P3>
        </InflowItemTitle>
        <P3>{data.device}</P3>
      </InflowTitleWrapper>
      <InflowCountWrapper>
        <InflowSessionCount>
          <span className="ri-user-received-line"> </span>
          <P3>{data.cnt.toLocaleString()}</P3>
          <P3>&nbsp;({data.rate}%)</P3>
        </InflowSessionCount>
      </InflowCountWrapper>
    </Component>
  );
};

export default PathPlotInflowItem;
