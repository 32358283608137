import axios from 'axios';
import config from '../config-api.json';
import {
  GetReportParamType,
  GetReportAvgPVResponseType,
  GetReportClickRateParamType,
  GetReportClickRateResponseType,
  GetReportDurationResponseType,
  GetReportGrowthPVResponseType,
  GetReportInflowGlobalResponseType,
  GetReportInflowKeywordResponseType,
  GetReportInflowNationalResponseType,
  GetReportInflowPathResponseType,
  GetReportMonthResponseType,
  GetReportPopularPageResponseType,
  GetReportSessionResponseType,
  GetReportUVResponseType,
} from './type';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

// 서비스 활성화 카드 - 사용자 수
export async function apiReportUV(info: GetReportParamType) {
  const response = await axios.get<GetReportUVResponseType>(
    `${apiInfo.api_url}/report_uv/`,
    {
      params: {
        month: info.month,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 서비스 활성화 카드 - 방문 횟수
export async function apiReportSession(info: GetReportParamType) {
  const response = await axios.get<GetReportSessionResponseType>(
    `${apiInfo.api_url}/report_session/`,
    {
      params: {
        month: info.month,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 서비스 활성화 카드 - 평균 페이지뷰
export async function apiReportAvgPV(info: GetReportParamType) {
  const response = await axios.get<GetReportAvgPVResponseType>(
    `${apiInfo.api_url}/report_avg_pv/`,
    {
      params: {
        month: info.month,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 서비스 활성화 카드 - 평균 이용시간
export async function apiReportDuration(info: GetReportParamType) {
  const response = await axios.get<GetReportDurationResponseType>(
    `${apiInfo.api_url}/report_dt/`,
    {
      params: {
        month: info.month,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 유입 카드 - 국내 유입
export async function apiReportInflowNational(info: GetReportParamType) {
  const response = await axios.get<GetReportInflowNationalResponseType>(
    `${apiInfo.api_url}/report_inflow_national_top/`,
    {
      params: {
        month: info.month,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 유입 카드 - 국외 유입
export async function apiReportInflowGlobal(info: GetReportParamType) {
  const response = await axios.get<GetReportInflowGlobalResponseType>(
    `${apiInfo.api_url}/report_inflow_global_top/`,
    {
      params: {
        month: info.month,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 유입 카드 - 유입 경로
export async function apiReportInflowPath(info: GetReportParamType) {
  const response = await axios.get<GetReportInflowPathResponseType>(
    `${apiInfo.api_url}/report_inflow_path/`,
    {
      params: {
        month: info.month,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 유입 카드 - 유입 검색어
export async function apiReportInflowKeyword(info: GetReportParamType) {
  const response = await axios.get<GetReportInflowKeywordResponseType>(
    `${apiInfo.api_url}/report_inflow_keyword/`,
    {
      params: {
        month: info.month,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 유입 카드 - 가장 많이 본 페이지
export async function apiReportPopularPage(info: GetReportParamType) {
  const response = await axios.get<GetReportPopularPageResponseType>(
    `${apiInfo.api_url}/report_popular_page/`,
    {
      params: {
        month: info.month,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 유입 카드 - 인기 급상승 페이지
export async function apiReportGrowthPV(info: GetReportParamType) {
  const response = await axios.get<GetReportGrowthPVResponseType>(
    `${apiInfo.api_url}/report_growth_pv/`,
    {
      params: {
        month: info.month,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 유입 카드 - 유효클릭 비율
export async function apiReportClickRate(info: GetReportClickRateParamType) {
  const response = await axios.get<GetReportClickRateResponseType>(
    `${apiInfo.api_url}/report_click_rate/`,
    {
      params: {
        month: info.month,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 전월호
export async function apiReportMonth() {
  const response = await axios.get<GetReportMonthResponseType>(
    `${apiInfo.api_url}/report_month/`,
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
