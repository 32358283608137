import axios from 'axios';
import config from '../../config-api.json';
import {
  GetPathPlotParamType,
  GetPathPlotResponseType,
  GetPathInflowChildrenParamType,
  GetPathInflowChildrenResponseType,
  GetPathInflowParamType,
  GetPathInflowResponseType,
} from './type';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

export async function apiGetPathPlot(info: GetPathPlotParamType) {
  const response = await axios.get<GetPathPlotResponseType>(
    `${apiInfo.api_url}/path_plot/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
        user_created: info.user_created,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetPathInflowChildren(
  info: GetPathInflowChildrenParamType
) {
  const response = await axios.post<GetPathInflowChildrenResponseType>(
    `${apiInfo.api_url}/path_plot_children/`,
    {
      start_date: info.start_date,
      end_date: info.end_date,
      url: info.url,
      children_path: info.children_path,
      seq: info.seq,
      through_count: info.through_count,
    },
    {
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetPathInflow(info: GetPathInflowParamType) {
  const response = await axios.get<GetPathInflowResponseType>(
    `${apiInfo.api_url}/path_inflow/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
        user_created: info.user_created,
        url: info.url,
        session_count: info.session_count,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
