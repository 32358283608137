import { createReducer } from 'typesafe-actions';
import {
  GET_NATIONAL_SESSION_ENV_STATISTICS,
  GET_NATIONAL_SESSION_ENV_STATISTICS_SUCCESS,
  GET_NATIONAL_SESSION_ENV_STATISTICS_ERROR,
  GET_GLOBAL_SESSION_ENV_STATISTICS,
  GET_GLOBAL_SESSION_ENV_STATISTICS_SUCCESS,
  GET_GLOBAL_SESSION_ENV_STATISTICS_ERROR,
  SET_ERROR_NULL_SERVICE_ENV,
} from './actions';
import { environmentAnalysisAction, environmentAnalysisState } from './types';

const initialState: environmentAnalysisState = {
  isLoadingNational: false,
  isLoadingGlobal: false,

  nationalSessionEnvData: [],
  globalSessionEnvData: [],

  environmentAnalysisError: null,
};

const reducer = createReducer<
  environmentAnalysisState,
  environmentAnalysisAction
>(initialState, {
  [GET_NATIONAL_SESSION_ENV_STATISTICS]: (state) => ({
    ...state,
    isLoadingNational: true,
    nationalSessionEnvData: [],
    environmentAnalysisError: null,
  }),
  [GET_NATIONAL_SESSION_ENV_STATISTICS_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingNational: false,
    nationalSessionEnvData: action.payload.payload,
  }),
  [GET_NATIONAL_SESSION_ENV_STATISTICS_ERROR]: (state, action) => ({
    ...state,
    isLoadingNational: false,
    nationalSessionEnvData: [],
    environmentAnalysisError: action.payload,
  }),

  [GET_GLOBAL_SESSION_ENV_STATISTICS]: (state) => ({
    ...state,
    isLoadingGlobal: true,
    globalSessionEnvData: [],
    environmentAnalysisError: null,
  }),
  [GET_GLOBAL_SESSION_ENV_STATISTICS_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingGlobal: false,
    globalSessionEnvData: action.payload.payload,
  }),
  [GET_GLOBAL_SESSION_ENV_STATISTICS_ERROR]: (state, action) => ({
    ...state,
    isLoadingGlobal: false,
    globalSessionEnvData: [],
    environmentAnalysisError: action.payload,
  }),

  [SET_ERROR_NULL_SERVICE_ENV]: (state) => ({
    ...state,
    environmentAnalysisError: null,
  }),
});

export default reducer;
