import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import { P1, P3 } from '../../../Atoms/Typo';
import { IconPDF } from '../../../Atoms/Icon';
import SelectBox from '../../../Atoms/SelectBox';

const Component = styled(CardLayout)`
  background-color: #6050dd;
  height: 70px;
  border-radius: 14px;
  padding: 15px 20px;
`;

const CardInner = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  display: flex;
  margin-left: 20px;
  ${P1} {
    color: white;
  }
  .month-title {
    color: #f99e32;
    font-weight: 600;
  }
`;

const FunctionWrapper = styled.div`
  display: flex;
`;

const PDFButton = styled.button`
  border: none;
  width: 180px;
  height: 40px;
  background: white;
  border-radius: 8px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  ${P3} {
    font-size: 15px;
    color: #767676;
    height: 20px;
  }
  :hover {
    cursor: pointer;
  }
`;

interface ReportHeaderCardProps {
  elIdForPdf: string;
  monthList: string[];
  setReportMonth: (reportMonth: string) => void;
}

const ReportHeaderCard = ({
  elIdForPdf,
  monthList,
  setReportMonth,
}: ReportHeaderCardProps) => {
  // state
  const [month, setMonth] = useState(0);
  const [elementId, setElementId] = useState('');
  const [monthArr, setMonthArr] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);

  // for pdf
  const convertPdf = (imageFile: string) => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF('p', 'mm', 'a4');

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    doc.addImage(imageFile, 'JPEG', 0, 0, pageWidth, pageHeight);

    doc.save('voda_report.pdf');
  };

  const createImgFile = async (elId: string) => {
    const element = document.querySelector(`#${elId}`) as HTMLElement;
    const canvas = await html2canvas(element, {
      windowWidth: element.scrollWidth,
      windowHeight: element.scrollHeight,
      useCORS: true,
    });
    const imageFile = canvas.toDataURL('image/png', 1.0);

    convertPdf(imageFile);
  };

  const onClickPdfButton = () => {
    createImgFile(elementId);
  };

  const stateChange = useCallback(
    (inputValue: { value: string; label: string }) => {
      setReportMonth(inputValue.value);
      setMonth(Number(inputValue.value.split('-')[1]));
    },
    []
  );

  useEffect(() => {
    const today = new Date();

    let month_temp = today.getMonth();
    if (month_temp === 0) {
      month_temp += 12;
    }

    setMonth(month_temp);
  }, []);

  useEffect(() => {
    if (elIdForPdf) {
      setElementId(elIdForPdf);
    }
  }, [elIdForPdf]);

  useEffect(() => {
    const data_temp: {
      value: string;
      label: string;
    }[] = [];

    if (monthList.length > 0) {
      monthList.forEach((element) => {
        data_temp.push({
          value: element,
          label: `${element.split('-')[0]}년 ${element.split('-')[1]}월`,
        });
      });
      setMonthArr(data_temp);
    }
  }, [monthList]);

  return (
    <Component>
      <CardInner>
        <Title>
          <P1 className="month-title">{month}월 월간 분석 리포트</P1>
          <P1>가 도착했습니다.</P1>
        </Title>
        <FunctionWrapper>
          <SelectBox
            type="page"
            option={monthArr}
            onChange={stateChange}
            placeholder="전월호 보기"
          />
          <PDFButton onClick={onClickPdfButton}>
            <P3>PDF 다운로드</P3>
            <IconPDF />
          </PDFButton>
        </FunctionWrapper>
      </CardInner>
    </Component>
  );
};

export default ReportHeaderCard;
