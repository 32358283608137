import { createAsyncAction, createAction } from 'typesafe-actions';
import { AxiosErrorType } from '../../createRequestSaga';
import {
  GetPathPlotParamType,
  GetPathPlotResponseType,
  GetPathInflowChildrenParamType,
  GetPathInflowChildrenResponseType,
  GetPathInflowParamType,
  GetPathInflowResponseType,
} from '../../../api/pageAnalytics/pathPlot/type';

export const GET_PATH_PLOT = 'pageAnalytics/GET_PATH_PLOT' as const;
export const GET_PATH_PLOT_SUCCESS =
  'pageAnalytics/GET_PATH_PLOT_SUCCESS' as const;
export const GET_PATH_PLOT_ERROR = 'pageAnalytics/GET_PATH_PLOT_ERROR' as const;

export const GET_PATH_INFLOW_CHILDREN =
  'pageAnalytics/GET_PATH_INFLOW_CHILDREN' as const;
export const GET_PATH_INFLOW_CHILDREN_SUCCESS =
  'pageAnalytics/GET_PATH_INFLOW_CHILDREN_SUCCESS' as const;
export const GET_PATH_INFLOW_CHILDREN_ERROR =
  'pageAnalytics/GET_PATH_INFLOW_CHILDREN_ERROR' as const;

export const GET_PATH_INFLOW = 'pageAnalytics/GET_PATH_INFLOW' as const;
export const GET_PATH_INFLOW_SUCCESS =
  'pageAnalytics/GET_PATH_INFLOW_SUCCESS' as const;
export const GET_PATH_INFLOW_ERROR =
  'pageAnalytics/GET_PATH_INFLOW_ERROR' as const;

export const SET_ERROR_NULL_PATHPLOT = 'pageAnalytics/SET_ERROR_NULL_PATHPLOT';

export const getPathPlotAsync = createAsyncAction(
  GET_PATH_PLOT,
  GET_PATH_PLOT_SUCCESS,
  GET_PATH_PLOT_ERROR
)<GetPathPlotParamType, GetPathPlotResponseType, AxiosErrorType>();

export const getPathInflowChildrenAsync = createAsyncAction(
  GET_PATH_INFLOW_CHILDREN,
  GET_PATH_INFLOW_CHILDREN_SUCCESS,
  GET_PATH_INFLOW_CHILDREN_ERROR
)<
  GetPathInflowChildrenParamType,
  GetPathInflowChildrenResponseType,
  AxiosErrorType
>();

export const getPathInflowAsync = createAsyncAction(
  GET_PATH_INFLOW,
  GET_PATH_INFLOW_SUCCESS,
  GET_PATH_INFLOW_ERROR
)<GetPathInflowParamType, GetPathInflowResponseType, AxiosErrorType>();

export const setErrorNullPathPlot = createAction(SET_ERROR_NULL_PATHPLOT)();
