import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { RootState } from '../../../modules';

import ContentsLayout from '../../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../../components/Atoms/Layout/Contents/ContentsItemLayout';
import Header from '../../../components/Organisms/Header';
import {
  GetPathInflowChildrenParamType,
  GetPathInflowParamType,
  GetPathPlotParamType,
} from '../../../api/pageAnalytics/pathPlot/type';
import {
  getPathInflowAsync,
  getPathInflowChildrenAsync,
  getPathPlotAsync,
} from '../../../modules/PageAnalytics/PathPlot';
import UxPathPlotCard from '../../../components/Organisms/Card/UxPathPlotCard';

const PathPlot = () => {
  // redux
  const { startDate, endDate, isDatePickerToggle } = useSelector(
    (state: RootState) => state.datePicker
  );
  const {
    isLoadingPathPlot,
    isLoadingPathChildren,
    isloadingPathInflow,
    pathPlotData,
    pathChildrenData,
    pathInflowData,
  } = useSelector((state: RootState) => state.pathPlot);

  const dispatch = useDispatch();

  const onGetPathPlot = (info: GetPathPlotParamType) => {
    dispatch(getPathPlotAsync.request(info));
  };

  const onGetPathInflowChildren = (info: GetPathInflowChildrenParamType) => {
    dispatch(getPathInflowChildrenAsync.request(info));
  };

  const onGetPathInflow = (info: GetPathInflowParamType) => {
    dispatch(getPathInflowAsync.request(info));
  };

  const onClickChildren = (
    children_url: string,
    path: string[],
    sequence: string,
    throunCnt: number
  ) => {
    onGetPathInflowChildren({
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
      url: children_url,
      children_path: path,
      seq: sequence,
      through_count: throunCnt,
    });
  };

  const onClickStartItem = (startUrl: string, count: number) => {
    onGetPathInflow({
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
      url: startUrl,
      session_count: count,
      user_created: null,
    });
  };

  useEffect(() => {
    if (!isDatePickerToggle) {
      onGetPathPlot({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        user_created: null,
      });
    }
  }, [isDatePickerToggle, startDate, endDate]);

  return (
    <ContentsLayout>
      <Header
        title="페이지 흐름 분석"
        icon=""
        iconClass="ri-user-received-line"
        isDatePicker
      />
      <ContentBodyLayout>
        <ContentsItemLayout desktop={12}>
          <UxPathPlotCard
            loading={isLoadingPathPlot}
            childrenLoading={isLoadingPathChildren}
            inflowLoading={isloadingPathInflow}
            pathPlotData={pathPlotData}
            childrenData={pathChildrenData}
            inflowData={pathInflowData}
            onClickChildren={onClickChildren}
            onClickStartInfo={onClickStartItem}
          />
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default PathPlot;
