import { createAsyncAction, createAction } from 'typesafe-actions';
import { AxiosErrorType } from '../createRequestSaga';
import {
  GetCheckSessionResponseType,
  GetIsAIVORYAvailableResponseType,
  GetPVUsageResponseType,
  GetVodaHealthResponseType,
  GetVodaSessionHealthParamType,
  GetVodaSessionHealthResponseType,
  SetLoginParamType,
  SetLoginResponseType,
  SetLogoutResponseType,
  GetExportStatisticsExcelParamType,
  GetExportStatisticsExcelResponseType,
  GetCheckScriptResponseType,
  GetCheckScriptParamType,
} from '../../api/authentication/type';

export const GET_SESSION = 'authentication/GET_SESSION' as const;
export const GET_SESSION_SUCCESS =
  'authentication/GET_SESSION_SUCCESS' as const;
export const GET_SESSION_ERROR = 'authentication/GET_SESSION_ERROR' as const;

export const SET_LOGIN = 'authentication/SET_LOGIN' as const;
export const SET_LOGIN_SUCCESS = 'authentication/SET_LOGIN_SUCCESS' as const;
export const SET_LOGIN_ERROR = 'authentication/SET_LOGIN_ERROR' as const;

export const GET_CHECK_SESSION = 'authentication/GET_CHECK_SESSION' as const;
export const GET_CHECK_SESSION_SUCCESS =
  'authentication/GET_CHECK_SESSION_SUCCESS' as const;
export const GET_CHECK_SESSION_ERROR =
  'authentication/GET_CHECK_SESSION_ERROR' as const;

export const SET_LOGOUT = 'authentication/SET_LOGOUT' as const;
export const SET_LOGOUT_SUCCESS = 'authentication/SET_LOGOUT_SUCCESS' as const;
export const SET_LOGOUT_ERROR = 'authentication/SET_LOGOUT_ERROR' as const;

export const GET_IS_AIVORY_AVAILABLE =
  'authentication/GET_IS_AIVORY_AVAILABLE' as const;
export const GET_IS_AIVORY_AVAILABLE_SUCCESS =
  'authentication/GET_IS_AIVORY_AVAILABLE_SUCCESS' as const;
export const GET_IS_AIVORY_AVAILABLE_ERROR =
  'authentication/GET_IS_AIVORY_AVAILABLE_ERROR' as const;

export const GET_VODA_HEALTH = 'authentication/GET_VODA_HEALTH' as const;
export const GET_VODA_HEALTH_SUCCESS =
  'authentication/GET_VODA_HEALTH_SUCCESS' as const;
export const GET_VODA_HEALTH_ERROR =
  'authentication/GET_VODA_HEALTH_ERROR' as const;

export const GET_VODA_SESSION_HEALTH =
  'authentication/GET_VODA_SESSION_HEALTH' as const;
export const GET_VODA_SESSION_HEALTH_SUCCESS =
  'authentication/GET_VODA_SESSION_HEALTH_SUCCESS' as const;
export const GET_VODA_SESSION_HEALTH_ERROR =
  'authentication/GET_VODA_SESSION_HEALTH_ERROR' as const;

export const GET_PV_USAGE = 'authentication/GET_PV_USAGE' as const;
export const GET_PV_USAGE_SUCCESS =
  'authentication/GET_PV_USAGE_SUCCESS' as const;
export const GET_PV_USAGE_ERROR = 'authentication/GET_PV_USAGE_ERROR' as const;

export const GET_EXPORT_EXCEL = 'authentication/GET_EXPORT_EXCEL' as const;
export const GET_EXPORT_EXCEL_SUCCESS =
  'authentication/GET_EXPORT_EXCEL_SUCCESS' as const;
export const GET_EXPORT_EXCEL_ERROR =
  'authentication/GET_EXPORT_EXCEL_ERROR' as const;

export const GET_CHECK_SCRIPT = 'authentication/GET_CHECK_SCRIPT' as const;
export const GET_CHECK_SCRIPT_SUCCESS =
  'authentication/GET_CHECK_SCRIPT_SUCCESS' as const;
export const GET_CHECK_SCRIPT_ERROR =
  'authentication/GET_CHECK_SCRIPT_ERROR' as const;

export const SET_TOGGLE_CHECK = 'authentication/SET_TOGGLE_CHECK' as const;

export const SET_ERROR_NULL_AUTH = 'authentication/SET_ERROR_NULL' as const;

export const REFRESH_SESSION_TIME =
  'authentication/REFRESH_SESSION_TIME' as const;

export const CHECK_COOKIE = 'authentication/CHECK_COOKIE' as const;

export const setLoginAsync = createAsyncAction(
  SET_LOGIN,
  SET_LOGIN_SUCCESS,
  SET_LOGIN_ERROR
)<SetLoginParamType, SetLoginResponseType, AxiosErrorType>();

export const getCheckSessionAsync = createAsyncAction(
  GET_CHECK_SESSION,
  GET_CHECK_SESSION_SUCCESS,
  GET_CHECK_SESSION_ERROR
)<void, GetCheckSessionResponseType, AxiosErrorType>();

export const setLogoutAsync = createAsyncAction(
  SET_LOGOUT,
  SET_LOGOUT_SUCCESS,
  SET_LOGOUT_ERROR
)<void, SetLogoutResponseType, AxiosErrorType>();

export const getIsAIVORYAvailableAsync = createAsyncAction(
  GET_IS_AIVORY_AVAILABLE,
  GET_IS_AIVORY_AVAILABLE_SUCCESS,
  GET_IS_AIVORY_AVAILABLE_ERROR
)<void, GetIsAIVORYAvailableResponseType, AxiosErrorType>();

export const getVodaHealthAsync = createAsyncAction(
  GET_VODA_HEALTH,
  GET_VODA_HEALTH_SUCCESS,
  GET_VODA_HEALTH_ERROR
)<void, GetVodaHealthResponseType, AxiosErrorType>();

export const getVodaSessionHealthAsync = createAsyncAction(
  GET_VODA_SESSION_HEALTH,
  GET_VODA_SESSION_HEALTH_SUCCESS,
  GET_VODA_SESSION_HEALTH_ERROR
)<
  GetVodaSessionHealthParamType,
  GetVodaSessionHealthResponseType,
  AxiosErrorType
>();

export const getPVUsageAsync = createAsyncAction(
  GET_PV_USAGE,
  GET_PV_USAGE_SUCCESS,
  GET_PV_USAGE_ERROR
)<void, GetPVUsageResponseType, AxiosErrorType>();

export const getExportExcelAsync = createAsyncAction(
  GET_EXPORT_EXCEL,
  GET_EXPORT_EXCEL_SUCCESS,
  GET_EXPORT_EXCEL_ERROR
)<
  GetExportStatisticsExcelParamType,
  GetExportStatisticsExcelResponseType,
  AxiosErrorType
>();

export const getCheckScriptAsync = createAsyncAction(
  GET_CHECK_SCRIPT,
  GET_CHECK_SCRIPT_SUCCESS,
  GET_CHECK_SCRIPT_ERROR
)<GetCheckScriptParamType, GetCheckScriptResponseType, AxiosErrorType>();

export const setToggleCheck = createAction(SET_TOGGLE_CHECK)();

export const setErrorNullAuth = createAction(SET_ERROR_NULL_AUTH)();

export const refreshSessionTime = createAction(REFRESH_SESSION_TIME)();

export const checkCookie = createAction(CHECK_COOKIE)<boolean>();
