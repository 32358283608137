import axios from 'axios';
import config from '../config-api.json';
import {
  GetTotalUserStatisticsParamType,
  GetTotalUserStatisticsResponseType,
  GetTotalSessionStatisticsParamType,
  GetTotalSessionStatisticsResponseType,
  GetPvPerSessionStatisticsParamType,
  GetPvPerSessionStatisticsResponseType,
  GetUsageMeanStatisticsParamType,
  GetUsageMeanStatisticsResponseType,
  GetUserVisitStatisticsParamType,
  GetUserVisitStatisticsResponseType,
  GetHourlyUserStatisticsParamType,
  GetHourlyUserStatisticsResponseType,
  GetInflowKeywordStatisticsParamType,
  GetInflowKeywordStatisticsResponseType,
  GetPopularPageStatisticsParamType,
  GetPopularPageStatisticsResponseType,
  GetUserEnvironmentStatisticsParamType,
  GetUserEnvironmentStatisticsResponseType,
  GetInflowPathStatisticsParamType,
  GetInflowPathStatisticsResponseType,
} from './type';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

export async function apiGetTotalUserStatistics(
  info: GetTotalUserStatisticsParamType
) {
  const response = await axios.get<GetTotalUserStatisticsResponseType>(
    `${apiInfo.api_url}/total_user_statistics/`,
    {
      params: {
        unit: info.unit,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetTotalSessionStatistics(
  info: GetTotalSessionStatisticsParamType
) {
  const response = await axios.get<GetTotalSessionStatisticsResponseType>(
    `${apiInfo.api_url}/total_session_statistics/`,
    {
      params: {
        unit: info.unit,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetPvPerSessionStatistics(
  info: GetPvPerSessionStatisticsParamType
) {
  const response = await axios.get<GetPvPerSessionStatisticsResponseType>(
    `${apiInfo.api_url}/pv_per_session_statistics/`,
    {
      params: {
        unit: info.unit,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

export async function apiGetUsageMeanStatistics(
  info: GetUsageMeanStatisticsParamType
) {
  const response = await axios.get<GetUsageMeanStatisticsResponseType>(
    `${apiInfo.api_url}/usage_mean_statistics/`,
    {
      params: {
        unit: info.unit,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 사용자 방문 분석
export async function apiGetUserVisitStatistics(
  info: GetUserVisitStatisticsParamType
) {
  const response = await axios.get<GetUserVisitStatisticsResponseType>(
    `${apiInfo.api_url}/user_visit_statistics/`,
    {
      params: {
        unit: info.unit,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 시간대별 분석
export async function apiGetHourlyUserStatistics(
  info: GetHourlyUserStatisticsParamType
) {
  const response = await axios.get<GetHourlyUserStatisticsResponseType>(
    `${apiInfo.api_url}/hourly_user_statistics/`,
    {
      params: {
        unit: info.unit,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 유입검색어
export async function apiGetInflowKeywordStatistics(
  info: GetInflowKeywordStatisticsParamType
) {
  const response = await axios.get<GetInflowKeywordStatisticsResponseType>(
    `${apiInfo.api_url}/inflow_keyword_statistics/`,
    {
      params: {
        unit: info.unit,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 많이 본 페이지
export async function apiGetPopularPageStatistics(
  info: GetPopularPageStatisticsParamType
) {
  const response = await axios.get<GetPopularPageStatisticsResponseType>(
    `${apiInfo.api_url}/popular_page_statistics/`,
    {
      params: {
        unit: info.unit,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 사용자 환경 분석
export async function apiGetUserEnvironmentStatistics(
  info: GetUserEnvironmentStatisticsParamType
) {
  const response = await axios.get<GetUserEnvironmentStatisticsResponseType>(
    `${apiInfo.api_url}/user_environment_statistics/`,
    {
      params: {
        unit: info.unit,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 유입 경로 분석
export async function apiGetInflowPathStatistics(
  info: GetInflowPathStatisticsParamType
) {
  const response = await axios.get<GetInflowPathStatisticsResponseType>(
    `${apiInfo.api_url}/inflow_path_statistics/`,
    {
      params: {
        unit: info.unit,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
