import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Chart } from 'react-google-charts';
import styled from 'styled-components';
import _ from 'lodash';
import {
  GoogleChartControl,
  GoogleChartWrapper,
  GoogleViz,
  ReactGoogleChartProps,
} from 'react-google-charts/dist/types';

import DataError from '../../../Atoms/DataError';

import Tooltip from '../Tooltip/LocalMapChartTooltip';

const Component = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  svg {
    transform: scale(1.7);
  }
  path {
    stroke: #93bcf9;
  }
`;

interface LocalMapChartProps {
  data: {
    total: number;
    old: number;
    new: number;
    local_variant_en: string;
    local_variant_ko: string;
  }[];
  setMax: React.Dispatch<React.SetStateAction<number>>;
}

const LocalMapChart = ({ data, setMax }: LocalMapChartProps) => {
  const options = {
    region: 'KR',
    displayMode: 'regions',
    resolution: 'provinces',
    legend: 'none',
    colorAxis: { minValue: 0, colors: ['#CBE0FC', '#9CC1FA'] },
    datalessRegionColor: '#FFFFFF',
    magnifyingGlass: { enable: true, zoomFactor: 7.5 },
    tooltip: {
      trigger: 'none',
    },
    // keepAspectRatio: false,
    // width: 700,
    // height: 620,
  };

  const datalessOption = {
    region: 'KR',
    displayMode: 'regions',
    resolution: 'provinces',
    legend: 'none',
    colorAxis: { minValue: 0, colors: ['#e8dcfd'] },
    datalessRegionColor: '#FFFFFF',
    magnifyingGlass: { enable: true, zoomFactor: 7.5 },
    tooltip: {
      trigger: 'none',
    },
  };

  const ChartEl = useRef<HTMLDivElement | null>(null);
  const [geoCode, setGeoCode] = useState<string | null>(null);
  const [tooltipInfo, setTooltipInfo] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });
  const [isTooltipShow, setIsTooltipShow] = useState(false);
  let lastEvent: null | any = null;
  const [series, setSeries] = useState<(string | number)[][]>([
    ['City', '전체 사용자 수'],
  ]);
  const [emptyData, setEmptyData] = useState(false); // 빈 데이터 체크

  useEffect(() => {
    if (data.length > 0) {
      let maxTemp = 0;
      const tempSereis: (string | number)[][] = [['City', '전체 사용자 수']];
      data.forEach((element) => {
        if (maxTemp < element.total) {
          maxTemp = element.total;
          setEmptyData(false);
        }
        if (maxTemp === 0) {
          maxTemp = 100;
          setEmptyData(true);
        }
        tempSereis.push([`${element.local_variant_en}`, element.total]);
      });
      setMax(maxTemp);
      setSeries(tempSereis);
    }
  }, [data]);

  useEffect(() => {
    const { current } = ChartEl;

    if (current) {
      let previosFill = '';
      current.addEventListener('click', (e) => {
        lastEvent = e;
      });
      current.addEventListener(
        'mousemove',
        _.throttle((e) => {
          lastEvent = e;
          const event = document.createEvent('MouseEvent');
          event.initEvent('click', true, true);
          e.target.dispatchEvent(event);

          const targetArr = Object.keys(e.target.attributes);

          if (targetArr.length > 3) {
            const fill = e.target.attributes[targetArr[3]];

            if (fill.value === '#ffffff' || fill.value !== previosFill) {
              setGeoCode(null);
            }
            previosFill = fill.value;
          }

          let x = 0;
          let y = 0;
          if (e.pageX) {
            x = e.pageX;
            y = e.pageY;
          } else if (e.clientX) {
            let offsetX = 0;
            let offsetY = 0;
            if (document.documentElement.scrollLeft) {
              offsetX = document.documentElement.scrollLeft;
              offsetY = document.documentElement.scrollTop;
            } else if (document.body) {
              offsetX = document.body.scrollLeft;
              offsetY = document.body.scrollTop;
            }

            x = e.clientX + offsetX;
            y = e.clientY + offsetY;
          }
          setTooltipInfo({
            top: y - current.getBoundingClientRect().top,
            left: x - current.getBoundingClientRect().left,
          });
        }, 0)
      );
    }
  }, [ChartEl.current]);

  useEffect(() => {
    if (geoCode) {
      setIsTooltipShow(true);
    } else {
      setIsTooltipShow(false);
    }
  }, [geoCode]);

  const ChartSelectCallback = (eventCallbackArgs: {
    chartWrapper: GoogleChartWrapper;
    controlWrapper?: GoogleChartControl | undefined;
    props: ReactGoogleChartProps;
    google: GoogleViz;
    eventArgs: any;
  }) => {
    const chart = eventCallbackArgs.chartWrapper.getChart();
    const selection = chart.getSelection();
    if (selection.length === 0) return;
    if (series.length > 0) {
      const region = series[selection[0].row + 1][0];
      setGeoCode(region as string);
    }
  };

  const printTooltipMemo = useMemo(() => {
    if (isTooltipShow) {
      let total = 0;
      let old = 0;
      let new_user = 0;
      let kor_name = '';
      if (data.length > 0) {
        data.some((element) => {
          if (element.local_variant_en === geoCode) {
            total = element.total;
            old = element.old;
            new_user = element.new;
            kor_name = element.local_variant_ko;
            return true;
          }
          return false;
        });
      }

      return (
        <Tooltip
          isShow={isTooltipShow}
          newUser={new_user}
          old={old}
          total={total}
          title={kor_name}
          top={tooltipInfo.top}
          left={tooltipInfo.left}
        />
      );
    }
    return null;
  }, [isTooltipShow, tooltipInfo]);

  const printChart = useMemo(() => {
    if (series.length > 1 && !emptyData) {
      return (
        <Chart
          chartEvents={[
            {
              eventName: 'select',
              callback: ChartSelectCallback,
            },
          ]}
          chartType="GeoChart"
          width="100%"
          height="100%"
          data={series}
          options={options}
        />
      );
    }
    if (series.length > 1 && emptyData) {
      return (
        <Chart
          chartEvents={[
            {
              eventName: 'select',
              callback: ChartSelectCallback,
            },
          ]}
          chartType="GeoChart"
          width="100%"
          height="100%"
          data={series}
          options={datalessOption}
        />
      );
    }
    return <DataError />;
  }, [series]);

  return (
    <Component ref={ChartEl}>
      {printChart}
      {printTooltipMemo}
    </Component>
  );
};

export default LocalMapChart;
