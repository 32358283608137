import { createReducer } from 'typesafe-actions';
import {
  GET_NATIONAL_DEVICE_ENV_STATICS,
  GET_NATIONAL_DEVICE_ENV_STATICS_SUCCESS,
  GET_NATIONAL_DEVICE_ENV_STATICS_ERROR,
  GET_NATIONAL_OS_ENV_STATICS,
  GET_NATIONAL_OS_ENV_STATICS_SUCCESS,
  GET_NATIONAL_OS_ENV_STATICS_ERROR,
  GET_NATIONAL_BROWSER_ENV_STATICS,
  GET_NATIONAL_BROWSER_ENV_STATICS_SUCCESS,
  GET_NATIONAL_BROWSER_ENV_STATICS_ERROR,
  GET_NATIONAL_RESOLUTION,
  GET_NATIONAL_RESOLUTION_SUCCESS,
  GET_NATIONAL_RESOLUTION_ERROR,
  SET_ERROR_NULL_SERVICE_ENV,
} from './actions';
import { environmentAction, environmentState } from './types';

const initialState: environmentState = {
  isLoadingNationalDevice: false,
  isLoadingNationalOS: false,
  isLoadingNationalBrowser: false,
  isLoadingResolution: false,

  nationalDeviceEnvData: [],
  nationalOSEnvData: [],
  nationalBrowserEnvData: [],
  resolutionData: null,

  environmentAnalysisError: null,
};

const reducer = createReducer<environmentState, environmentAction>(
  initialState,
  {
    [GET_NATIONAL_DEVICE_ENV_STATICS]: (state) => ({
      ...state,
      isLoadingNationalDevice: true,
      nationalDeviceEnvData: [],
      environmentAnalysisError: null,
    }),
    [GET_NATIONAL_DEVICE_ENV_STATICS_SUCCESS]: (state, action) => ({
      ...state,
      isLoadingNationalDevice: false,
      nationalDeviceEnvData: action.payload.payload,
    }),
    [GET_NATIONAL_DEVICE_ENV_STATICS_ERROR]: (state, action) => ({
      ...state,
      isLoadingNationalDevice: false,
      nationalDeviceEnvData: [],
      environmentAnalysisError: action.payload,
    }),

    [GET_NATIONAL_OS_ENV_STATICS]: (state) => ({
      ...state,
      isLoadingNationalOS: true,
      nationalOSEnvData: [],
      environmentAnalysisError: null,
    }),
    [GET_NATIONAL_OS_ENV_STATICS_SUCCESS]: (state, action) => ({
      ...state,
      isLoadingNationalOS: false,
      nationalOSEnvData: action.payload.payload,
    }),
    [GET_NATIONAL_OS_ENV_STATICS_ERROR]: (state, action) => ({
      ...state,
      isLoadingNationalOS: false,
      nationalOSEnvData: [],
      environmentAnalysisError: action.payload,
    }),

    [GET_NATIONAL_BROWSER_ENV_STATICS]: (state) => ({
      ...state,
      isLoadingNationalBrowser: true,
      nationalBrowserEnvData: [],
      environmentAnalysisError: null,
    }),
    [GET_NATIONAL_BROWSER_ENV_STATICS_SUCCESS]: (state, action) => ({
      ...state,
      isLoadingNationalBrowser: false,
      nationalBrowserEnvData: action.payload.payload,
    }),
    [GET_NATIONAL_BROWSER_ENV_STATICS_ERROR]: (state, action) => ({
      ...state,
      isLoadingNationalBrowser: false,
      nationalBrowserEnvData: [],
      environmentAnalysisError: action.payload,
    }),

    [GET_NATIONAL_RESOLUTION]: (state) => ({
      ...state,
      isLoadingResolution: true,
      resolutionData: null,
      environmentAnalysisError: null,
    }),
    [GET_NATIONAL_RESOLUTION_SUCCESS]: (state, action) => ({
      ...state,
      isLoadingResolution: false,
      resolutionData: action.payload.payload,
    }),
    [GET_NATIONAL_RESOLUTION_ERROR]: (state, action) => ({
      ...state,
      isLoadingResolution: false,
      resolutionData: null,
      environmentAnalysisError: action.payload,
    }),

    [SET_ERROR_NULL_SERVICE_ENV]: (state) => ({
      ...state,
      environmentAnalysisError: null,
    }),
  }
);

export default reducer;
