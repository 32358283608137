import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import DashboardTemplate from '../../components/Templates/Dashboard';
import { RootState } from '../../modules';
import {
  GetHourlyUserStatisticsParamType,
  GetInflowKeywordStatisticsParamType,
  GetPvPerSessionStatisticsParamType,
  GetTotalSessionStatisticsParamType,
  GetTotalUserStatisticsParamType,
  GetUsageMeanStatisticsParamType,
  GetUserVisitStatisticsParamType,
  GetPopularPageStatisticsParamType,
  GetUserEnvironmentStatisticsParamType,
  GetInflowPathStatisticsParamType,
} from '../../api/Dashboard/type';
import {
  getHourlyUserStatisticsAsync,
  getInflowKeywordStatisticsAsync,
  getPvPerSessionAsync,
  getTotalSessionStatisticsAsync,
  getTotalUserStatisticsAsync,
  getUsageMeanStatisticsAsync,
  getUserVisitStatisticsAsync,
  getPopularPageStatisticsAsync,
  getUserEnvironmentStatisticsAsync,
  getInflowPathStatisticsAsync,
} from '../../modules/dashboard';

const Dashboard = () => {
  // redux
  const {
    isLoadingTotalUser,
    isLoadingTotalSession,
    isLoadingPV,
    isLoadingUsage,
    isLoadingVisit,
    isLoadingHourly,
    isLoadingInflow,
    isLoadingPage,
    isLoadingEnv,
    isLoadingPath,
    totalUserData,
    totalSessionData,
    pvData,
    usageData,
    VSD,
    HSD,
    ISD,
    PSD,
    ESD,
    pathData,
  } = useSelector((state: RootState) => state.dashboard);

  const dispatch = useDispatch();

  // state
  const [activeTabID, setActiveTabID] = useState(0);
  const [activeDurationName, setActiveDurationName] = useState('day');

  const onGetTotalUserStatistics = (info: GetTotalUserStatisticsParamType) => {
    dispatch(getTotalUserStatisticsAsync.request(info));
  };

  const onGetTotalSessionStatistics = (
    info: GetTotalSessionStatisticsParamType
  ) => {
    dispatch(getTotalSessionStatisticsAsync.request(info));
  };

  const onGetPvPerSessionStatistics = (
    info: GetPvPerSessionStatisticsParamType
  ) => {
    dispatch(getPvPerSessionAsync.request(info));
  };

  const onGetUsageMeanStatistics = (info: GetUsageMeanStatisticsParamType) => {
    dispatch(getUsageMeanStatisticsAsync.request(info));
  };

  const onGetUserVisitStatistics = (info: GetUserVisitStatisticsParamType) => {
    dispatch(getUserVisitStatisticsAsync.request(info));
  };

  const onGetHourlyUserStatistics = (
    info: GetHourlyUserStatisticsParamType
  ) => {
    dispatch(getHourlyUserStatisticsAsync.request(info));
  };

  const onGetInflowKeywordStatistics = (
    info: GetInflowKeywordStatisticsParamType
  ) => {
    dispatch(getInflowKeywordStatisticsAsync.request(info));
  };

  const onGetPopularPageStatistics = (
    info: GetPopularPageStatisticsParamType
  ) => {
    dispatch(getPopularPageStatisticsAsync.request(info));
  };

  const onGetUserEnvStatistics = (
    info: GetUserEnvironmentStatisticsParamType
  ) => {
    dispatch(getUserEnvironmentStatisticsAsync.request(info));
  };

  const onGetInflowPathStatistics = (
    info: GetInflowPathStatisticsParamType
  ) => {
    dispatch(getInflowPathStatisticsAsync.request(info));
  };

  const onGetDataDashboard = (unit: string) => {
    const info = {
      unit,
    };
    onGetTotalUserStatistics(info);
    onGetTotalSessionStatistics(info);
    onGetPvPerSessionStatistics(info);
    onGetUsageMeanStatistics(info);
    onGetUserVisitStatistics(info);
    onGetHourlyUserStatistics(info);
    onGetInflowKeywordStatistics(info);
    onGetPopularPageStatistics(info);
    onGetUserEnvStatistics(info);
    onGetInflowPathStatistics(info);
  };

  const onClickTab = (id: number) => {
    setActiveTabID(id);
    switch (id) {
      case 0:
        onGetDataDashboard('day');
        setActiveDurationName('day');
        break;
      case 1:
        onGetDataDashboard('week');
        setActiveDurationName('week');
        break;
      case 2:
        onGetDataDashboard('month');
        setActiveDurationName('month');
        break;
      default:
        onGetDataDashboard('day');
        setActiveDurationName('day');
        break;
    }
  };

  useEffect(() => {
    onGetDataDashboard('day');
  }, []);

  return (
    <DashboardTemplate
      userLoading={isLoadingTotalUser}
      sessionLoading={isLoadingTotalSession}
      pvLoading={isLoadingPV}
      timeLoading={isLoadingUsage}
      hourlyLoading={isLoadingHourly}
      inflowLoading={isLoadingInflow}
      pageLoading={isLoadingPage}
      envLoading={isLoadingEnv}
      pathLoading={isLoadingPath}
      totalUser={totalUserData}
      session={totalSessionData}
      pageView={pvData}
      time={usageData}
      multiChartData={{
        Data: VSD || null,
        isLoading: isLoadingVisit,
        lineLabel: '전체',
        bar1Label: '재방문',
        bar2Label: '신규',
        legendData: [
          {
            title: '전체',
            status: 'dashboard-all',
          },
          {
            title: '재방문',
            status: 'return',
          },
          {
            title: '신규',
            status: 'new',
          },
        ],
      }}
      hourlyData={HSD}
      inflowData={ISD}
      pageData={PSD}
      envData={ESD}
      pathData={pathData}
      activeDurationName={activeDurationName}
      activeTabID={activeTabID}
      onClickButtonGroup={onClickTab}
    />
  );
};

export default Dashboard;
