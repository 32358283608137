import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { RootState } from '../../../modules';

import {
  GetInflowPathCountParamType,
  GetInflowPathParamType,
  GetRecommendInflowParamType,
  GetSearchInflowParamType,
  GetSocialInflowParamType,
} from '../../../api/userAnalytics/inflowPath/type';
import {
  GetRecommendInflowAsync,
  getInflowPathAsync,
  getInflowPathCountAsync,
  getSearchInflowAsync,
  getSocialInflowAsync,
} from '../../../modules/userAnalytics/inflowPath';

import ContentsLayout from '../../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../../components/Atoms/Layout/Contents/ContentsItemLayout';
import Header from '../../../components/Organisms/Header';
import InflowPathCard from '../../../components/Organisms/Card/InflowPathCard';
import InflowTableCard from '../../../components/Organisms/Card/InflowTableCard';

const InflowPath = () => {
  // redux
  const { startDate, endDate, isDatePickerToggle } = useSelector(
    (state: RootState) => state.datePicker
  );
  const {
    isLoadingInflow,
    isLoadingInflowCount,
    isLoadingSearch,
    isLoadingSocial,
    isLoadingRecommend,
    inflowPathData,
    inflowPathCntData,
    searchInflowData,
    socialInflowData,
    recommendInflowData,
  } = useSelector((state: RootState) => state.inflowPath);

  const dispatch = useDispatch();

  const onGetInflowPath = (info: GetInflowPathParamType) => {
    dispatch(getInflowPathAsync.request(info));
  };

  const onGetInflowPathCount = (info: GetInflowPathCountParamType) => {
    dispatch(getInflowPathCountAsync.request(info));
  };

  const onGetSearchInflow = (info: GetSearchInflowParamType) => {
    dispatch(getSearchInflowAsync.request(info));
  };

  const onGetSocialInflow = (info: GetSocialInflowParamType) => {
    dispatch(getSocialInflowAsync.request(info));
  };

  const onGetRecommendInflow = (info: GetRecommendInflowParamType) => {
    dispatch(GetRecommendInflowAsync.request(info));
  };

  useEffect(() => {
    if (!isDatePickerToggle) {
      onGetInflowPathCount({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
      onGetInflowPath({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
      onGetSearchInflow({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
      onGetSocialInflow({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
      onGetRecommendInflow({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
    }
  }, [isDatePickerToggle, startDate, endDate]);

  return (
    <ContentsLayout>
      <Header
        title="유입 경로 분석"
        icon=""
        iconClass="ri-user-received-line"
        isDatePicker
      />
      <ContentBodyLayout>
        <ContentsItemLayout desktop={12}>
          <InflowPathCard
            isDonutloading={isLoadingInflow}
            isAreaLoading={isLoadingInflowCount}
            MultiAreaData={inflowPathCntData}
            DonutData={inflowPathData}
            legendLabel={[
              { title: '직접유입' },
              { title: '검색유입' },
              { title: '소셜유입' },
              { title: '추천유입' },
            ]}
            label1="직접유입"
            label2="검색유입"
            label3="소셜유입"
            label4="추천유입"
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={4}>
          <InflowTableCard
            isloading={isLoadingSearch}
            data={searchInflowData}
            cardTitle="검색유입"
            cardType="search"
            description="브라우저 검색을 통해 유입된 세션입니다."
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={4}>
          <InflowTableCard
            isloading={isLoadingSocial}
            data={socialInflowData}
            cardTitle="소셜유입(SNS)"
            cardType="social"
            description="소셜 네트워크를 통해 유입된 세션입니다."
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={4}>
          <InflowTableCard
            isloading={isLoadingRecommend}
            data={recommendInflowData}
            cardTitle="추천유입"
            cardType="recommend"
            description="소셜 네트워크를 제외한 다른 사이트 혹은 특정 링크를 통해 유입된 세션입니다."
          />
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default InflowPath;
