import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import { P2 } from '../../Atoms/Typo';
import DefaultTable, { DefaultTableProps } from '../../Molecule/DefaultTable';
import ComparisonBar from '../../Molecule/ComparisonBar';
import { GetTotalContentResponseType } from '../../../api/userAnalytics/contents/type';

const Component = styled(CardLayout)`
  /* height: 405px; */
`;

const URLLink = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  padding-right: 20px;
  ${P2} {
    color: ${(props) => props.theme.colors.grey600};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    font-size: 15px;
    color: ${(props) => props.theme.colors.grey600};
    padding-left: 5px;
    :hover {
      cursor: pointer;
    }
  }
`;

const ColumnsItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #dddddd;
  height: 47px;
  :last-child {
    border-right: none;
  }
  ${P2} {
    font-weight: bold;
  }
  :nth-child(1) {
    width: 8%;
  }
  :nth-child(2) {
    width: 47%;
  }
  :nth-child(3) {
    width: 13%;
  }
  :nth-child(4) {
    width: 32%;
  }
`;

const DataItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #dddddd;
  padding: 15px 20px;

  :nth-child(1) {
    width: 8%;
    ${P2} {
      font-weight: bold;
    }
  }
  :nth-child(2) {
    flex-direction: column;
    width: 47%;
    align-items: flex-start;
    padding: 0 20px;
    overflow: hidden;
    ${P2} {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  :nth-child(3) {
    width: 13%;
    justify-content: flex-end;
    padding-right: 20px;
  }
  :nth-child(4) {
    width: 32%;
    padding: 10px 20px;
    border-right: none;
  }
`;

const Contents = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  .menu {
    font-weight: 600;
    margin-right: 6px;
  }
`;

export interface ContentsTableCardState {
  excelData: {
    [key: string]: string[];
  };
}

interface ContentsTableCardProps {
  title: string;
  loading?: boolean;
  data: GetTotalContentResponseType['payload'];
  onClickExcel?: (data: ContentsTableCardState['excelData']) => void;
}

const ContentsTableCard = ({
  title,
  loading,
  data,
  onClickExcel,
}: ContentsTableCardProps) => {
  const [tableData, setTableData] = useState<DefaultTableProps['data']>([]);

  const columns = [
    <ColumnsItem key="순위">
      <P2>순위</P2>
    </ColumnsItem>,
    <ColumnsItem key="URL">
      <P2>URL</P2>
    </ColumnsItem>,
    <ColumnsItem key="pv">
      <P2>PV</P2>
    </ColumnsItem>,
    <ColumnsItem key="재방문/신규">
      <P2>재방문/신규</P2>
    </ColumnsItem>,
  ];

  const onClickLink = (url: string) => {
    window.open(url, '_blank', 'noopener, noreferrer');
  };

  const rebuildExcel = () => {
    // state
    const excelPayload: ContentsTableCardState['excelData'] = {};
    excelPayload['0'] = [
      '순위',
      '콘텐츠',
      '메뉴',
      'URL',
      'PV',
      '신규',
      '재방문',
    ];

    data.forEach((element, index) => {
      excelPayload[(index + 1).toString()] = [
        (index + 1).toString(),
        element.content ? element.content : 'null',
        element.menu,
        element.url,
        element.cnt.toString(),
        element.new.toString(),
        element.old.toString(),
      ];
    });

    if (onClickExcel) {
      onClickExcel(excelPayload);
    }
  };

  const genTableData = () => {
    const data_temp: DefaultTableProps['data'] = [];
    if (data.length > 0) {
      data.forEach((element, idx) => {
        data_temp.push({
          id: `${idx}`,
          node: [
            <DataItem key="rank">
              <P2>{idx + 1}</P2>
            </DataItem>,
            <DataItem key="url">
              {element.content ? (
                <Contents>
                  <P2 className="menu">[{element.menu}]</P2>
                  <P2>{element.content}</P2>
                </Contents>
              ) : (
                <P2>{element.menu}</P2>
              )}

              <URLLink>
                <P2 className="page-url">{element.url}</P2>
                <span
                  className="ri-external-link-line"
                  onClick={() => {
                    onClickLink(element.url);
                  }}
                >
                  
                </span>
              </URLLink>
            </DataItem>,
            <DataItem key="pv">
              <P2>{element.cnt.toLocaleString()}</P2>
            </DataItem>,
            <DataItem key="bar">
              <ComparisonBar
                columns={['재방문', '신규']}
                totalCount={element.old + element.new}
                firstVal={element.old}
                secVal={element.new}
                isPV
              />
            </DataItem>,
          ],
        });
      });
    }
    setTableData(data_temp);
  };

  useEffect(() => {
    genTableData();
  }, [data]);

  return (
    <Component>
      <CardBodyLayout>
        <DefaultTable
          tableTitle={title}
          isExcel
          isLoading={loading}
          columns={columns}
          data={tableData}
          buttonText="더보기"
          onClickExcel={rebuildExcel}
        />
      </CardBodyLayout>
    </Component>
  );
};

export default ContentsTableCard;
