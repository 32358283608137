import React, { ReactNodeArray } from 'react';
import styled from 'styled-components';

import { PageElementDataPayload } from '../../../../api/pageAnalytics/uxAnalytics/type';
import { P1 } from '../../../Atoms/Typo';
import { MouseHeatmapTooltipType } from '../../../Molecule/Tooltip/MouseHeatmapTooltip';
import ElementCard from './ElementCard';
import Spinner from '../../../Molecule/Spinner';

const Component = styled.div`
  width: 100%;
`;

const Header = styled.div`
  margin-bottom: 20px;
  ${P1} {
    font-weight: 600;
  }
`;

const ItemList = styled.div`
  display: flex;
  width: 100%;
`;

const Body = styled.div``;

const ColumnRankTable = styled.div`
  flex: 1;
`;

interface MouseEventRankCardProps {
  elementData: PageElementDataPayload[];
  pageId: string;
  clickedElementPath: string;
  activeDataType: number;
  isLoading: boolean;
  setClickedElementPath: React.Dispatch<React.SetStateAction<string>>;
  setClickTooltipInfo: React.Dispatch<
    React.SetStateAction<MouseHeatmapTooltipType | null>
  >;
}

const MouseEventRankCard = ({
  elementData,
  pageId,
  clickedElementPath,
  activeDataType,
  isLoading,
  setClickedElementPath,
  setClickTooltipInfo,
}: MouseEventRankCardProps) => {
  const printData = () => {
    const result: ReactNodeArray = [];
    elementData.forEach((element, index) => {
      let data: {
        id: number;
        title: string;
        value: string;
      }[] = [];

      let toolipData: MouseHeatmapTooltipType | null = null;

      if (activeDataType === 0) {
        data = [
          {
            id: 0,
            title: '클릭 횟수',
            value: `${element.cnt || 0}회`,
          },
          {
            id: 1,
            title: '클릭 비율',
            value: `${element.rate}%`,
          },
        ];
        toolipData = {
          data: [
            {
              id: 0,
              title: '클릭 순위',
              value: `${element.rank}위`,
            },
            {
              id: 1,
              title: '클릭 횟수',
              value: `${element.cnt || 0}회`,
            },
            {
              id: 2,
              title: '클릭 비율',
              value: `${element.rate}%`,
            },
          ],
          img_src: `https://voda-media.nerdfactory.ai/${localStorage.getItem(
            'voda_tenant'
          )}/auto/${pageId}/${encodeURIComponent(element.element_path)}`,
          isOpenTooltip: true,
          elementX: element.x,
          elementY: element.y,
          elementHeight: element.height,
          elementWidth: element.width,
        };
      }

      const Item = (
        <ElementCard
          img_src={`https://voda-media.nerdfactory.ai/${localStorage.getItem(
            'voda_tenant'
          )}/auto/${pageId}/${encodeURIComponent(element.element_path)}`}
          rank={index + 1}
          data={data}
          isClicked={element.element_path === clickedElementPath}
          onClickImage={() => {
            setClickedElementPath(element.element_path);
            setClickTooltipInfo(toolipData);
          }}
          key={element.element_path}
        />
      );
      result.push(Item);
    });

    return (
      <ItemList key="rank_card">
        <ColumnRankTable>{result}</ColumnRankTable>
      </ItemList>
    );
  };

  const printHeading = () => {
    let title = '';
    switch (activeDataType) {
      case 0:
        title = '클릭 순위';
        break;
      case 1:
        title = '주목 화면 순위';
        break;

      default:
        break;
    }
    return title;
  };

  return (
    <Component>
      <Header>
        <P1>{printHeading()}</P1>
      </Header>

      <Body>{isLoading ? <Spinner /> : printData()}</Body>
    </Component>
  );
};

export default MouseEventRankCard;
