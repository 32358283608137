import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../Atoms/Layout/Card/CardHeaderLayout';
import DefaultCardHeading from '../../Molecule/Heading/Card/DefaultCardHeading';
import Spinner from '../../Molecule/Spinner';
import { P2, P3 } from '../../Atoms/Typo';
import DataError from '../../Atoms/DataError';

const Component = styled(CardLayout)`
  height: 335px;
`;

const CardHeader = styled(CardHeaderLayout)<{ $type: string }>`
  display: flex;
  justify-content: flex-start;
  /* margin-bottom: 15px; */
  padding-bottom: 15px;

  ${(props) => {
    switch (props.$type) {
      case 'menu':
        return css`
          border-bottom: 2px solid #6050dd;
        `;
      case 'content':
        return css`
          border-bottom: 2px solid #f99e32;
        `;

      default:
        return css``;
    }
  }}

  span {
    ${(props) => {
      switch (props.$type) {
        case 'menu':
          return css`
            color: #6050dd;
          `;
        case 'content':
          return css`
            color: #f99e32;
          `;

        default:
          return css``;
      }
    }}
  }
`;

const ItemList = styled.div`
  display: flex;
  flex-direction: column;
`;

const Item = styled.div<{ $type: string }>`
  display: flex;
  justify-content: space-between;
  height: 45px;
  padding: 12px 13px 12px 20px;
  border-bottom: 1px solid #dddddd;
`;

const ItemTitle = styled.div`
  display: flex;
  overflow: hidden;
  padding-right: 20px;
  span {
    font-size: 15px;
    color: ${(props) => props.theme.colors.grey600};
    padding-left: 5px;
    :hover {
      cursor: pointer;
    }
  }
`;

const Rank = styled(P2)<{ $type: string }>`
  font-weight: bold;
  margin-right: 20px;
  ${(props) => {
    switch (props.$type) {
      case 'menu':
        return css`
          color: #6050dd;
        `;
      case 'content':
        return css`
          color: #f99e32;
        `;

      default:
        return css``;
    }
  }}
`;

const ListName = styled.div`
  display: flex;
  overflow: hidden;

  ${P2} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Value = styled.div`
  display: flex;
  align-items: baseline;
  ${P3} {
    color: ${(props) => props.theme.colors.grey600};
  }
`;

interface PopularMenuCardProps {
  isLoading?: boolean;
  cardTitle: string;
  type: string;
  data: {
    menu: string;
    content?: string;
    cnt: number;
    url: string;
  }[];
}

const PopularMenuCard = ({
  isLoading,
  cardTitle,
  type,
  data,
}: PopularMenuCardProps) => {
  const [menuData, setMenuData] = useState<
    {
      menu: string;
      cnt: number;
      url: string;
    }[]
  >([]);
  const [contentData, setContentData] = useState<
    {
      menu: string;
      content: string;
      cnt: number;
      url: string;
    }[]
  >([]);

  const onClickLink = (url: string) => {
    window.open(url, '_blank', 'noopener, noreferrer');
  };

  const printList = () => {
    // print list
    switch (type) {
      case 'menu':
        return menuData.length > 0 ? (
          menuData.map((element, idx) => {
            return (
              <Item key={element.menu} $type="menu">
                <ItemTitle>
                  <Rank $type="menu">{idx + 1}</Rank>
                  <ListName>
                    <P2>{element.menu}</P2>
                  </ListName>
                  <span
                    className="ri-external-link-line"
                    onClick={() => {
                      onClickLink(element.url);
                    }}
                  >
                    
                  </span>
                </ItemTitle>
                <Value>
                  <P2>{element.cnt.toLocaleString()}</P2>
                  <P3>PV</P3>
                </Value>
              </Item>
            );
          })
        ) : (
          <DataError />
        );

      case 'content':
        return contentData.length > 0 ? (
          contentData.map((element, idx) => {
            return (
              <Item key={element.menu} $type="content">
                <ItemTitle>
                  <Rank $type="content">{idx + 1}</Rank>
                  <ListName>
                    <P2>{element.content}</P2>
                  </ListName>
                  <span
                    className="ri-external-link-line"
                    onClick={() => {
                      onClickLink(element.url);
                    }}
                  >
                    
                  </span>
                </ItemTitle>
                <Value>
                  <P2>{element.cnt.toLocaleString()}</P2>
                  <P3>PV</P3>
                </Value>
              </Item>
            );
          })
        ) : (
          <DataError />
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    const tempMenuArr: {
      menu: string;
      cnt: number;
      url: string;
    }[] = [];
    const tempContentArr: {
      menu: string;
      content: string;
      cnt: number;
      url: string;
    }[] = [];
    if (data.length > 0) {
      data.forEach((element) => {
        if (element.content) {
          // content list
          tempContentArr.push({
            menu: element.menu,
            content: element.content,
            cnt: element.cnt,
            url: element.url,
          });
        } else {
          // menu list
          tempMenuArr.push({
            menu: element.menu,
            cnt: element.cnt,
            url: element.url,
          });
        }
      });
      setMenuData(tempMenuArr);
      setContentData(tempContentArr);
    }
    return () => {
      setMenuData([]);
      setContentData([]);
    };
  }, [data]);

  return (
    <Component>
      <CardHeader $type={type}>
        <DefaultCardHeading title={cardTitle}>
          <span> TOP5</span>
        </DefaultCardHeading>
      </CardHeader>
      {isLoading ? (
        <Spinner />
      ) : (
        <CardBodyLayout>
          <ItemList>{printList()}</ItemList>
        </CardBodyLayout>
      )}
    </Component>
  );
};

export default PopularMenuCard;
