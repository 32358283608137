import React from 'react';
import styled, { css } from 'styled-components';
import { P2 } from '../../Atoms/Typo';
import DefaultLink from '../../Atoms/Link';

const Component = styled.div<{ $header?: boolean }>`
  display: flex;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  border: 1px solid #d7dbe6;
  background-color: white;

  :hover {
    border: 1px solid #767676;
    cursor: pointer;
  }
`;

const Link = styled(DefaultLink)`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
  :last-child {
    margin-bottom: 0;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  overflow: hidden;
  border-right: 1px solid #ececec;
  :last-child {
    border-right: none;
  }

  ${P2} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  :nth-child(1) {
    width: 25%;
  }
  :nth-child(2) {
    width: 30%;
    justify-content: flex-start;
    ${P2} {
      color: #767676;
      font-size: 15px;
    }
  }
  :nth-child(3) {
    width: 15%;
  }
  :nth-child(4) {
    width: 15%;
  }
  :nth-child(5) {
    width: 15%;
  }
`;

interface PageListProps {
  isHeader?: boolean;
  title: string;
  url: string;
  pv: number;
  time: string;
  click: number;
  link: string;
}

const PageList = ({
  isHeader,
  title,
  url,
  pv,
  time,
  click,
  link,
}: PageListProps) => {
  return (
    <Link to={link}>
      <Component $header={isHeader}>
        <Item>
          <P2>{title}</P2>
        </Item>
        <Item>
          <P2>{url}</P2>
        </Item>
        <Item>
          <P2>{pv.toLocaleString()}PV</P2>
        </Item>
        <Item>
          <P2>{time}</P2>
        </Item>
        <Item>
          <P2>{click}회</P2>
        </Item>
      </Component>
    </Link>
  );
};

export default PageList;
