import { createAsyncAction, createAction } from 'typesafe-actions';
import { AxiosErrorType } from '../../createRequestSaga';
import {
  GetNationalDeviceEnvStaticsParamType,
  GetNationalDeviceEnvStaticsResponseType,
  GetNationalOSEnvStaticsParamType,
  GetNationalOSEnvStaticsResponseType,
  GetNationalBrowserEnvStaticsParamType,
  GetNationalBrowserEnvStaticsResponseType,
  GetNationalResolutionStaticsParamType,
  GetNationalResolutionResponseType,
} from '../../../api/userAnalytics/environment/type';

export const GET_NATIONAL_DEVICE_ENV_STATICS =
  'userAnalytics/environment/GET_NATIONAL_DEVICE_ENV_STATICS' as const;
export const GET_NATIONAL_DEVICE_ENV_STATICS_SUCCESS =
  'userAnalytics/environment/GET_NATIONAL_DEVICE_ENV_STATICS_SUCCESS' as const;
export const GET_NATIONAL_DEVICE_ENV_STATICS_ERROR =
  'userAnalytics/environment/GET_NATIONAL_DEVICE_ENV_STATICS_ERROR' as const;

export const GET_NATIONAL_OS_ENV_STATICS =
  'userAnalytics/environment/GET_NATIONAL_OS_ENV_STATICS' as const;
export const GET_NATIONAL_OS_ENV_STATICS_SUCCESS =
  'userAnalytics/environment/GET_NATIONAL_OS_ENV_STATICS_SUCCESS' as const;
export const GET_NATIONAL_OS_ENV_STATICS_ERROR =
  'userAnalytics/environment/GET_NATIONAL_OS_ENV_STATICS_ERROR' as const;

export const GET_NATIONAL_BROWSER_ENV_STATICS =
  'userAnalytics/environment/GET_NATIONAL_BROWSER_ENV_STATICS' as const;
export const GET_NATIONAL_BROWSER_ENV_STATICS_SUCCESS =
  'userAnalytics/environment/GET_NATIONAL_BROWSER_ENV_STATICS_SUCCESS' as const;
export const GET_NATIONAL_BROWSER_ENV_STATICS_ERROR =
  'userAnalytics/environment/GET_NATIONAL_BROWSER_ENV_STATICS_ERROR' as const;

export const GET_NATIONAL_RESOLUTION =
  'userAnalytics/environment/GET_NATIONAL_RESOLUTION' as const;
export const GET_NATIONAL_RESOLUTION_SUCCESS =
  'userAnalytics/environment/GET_NATIONAL_RESOLUTION_SUCCESS' as const;
export const GET_NATIONAL_RESOLUTION_ERROR =
  'userAnalytics/environment/GET_NATIONAL_RESOLUTION_ERROR' as const;

export const SET_ERROR_NULL_SERVICE_ENV =
  'userAnalytics/environment/SET_ERROR_NULL_SERVICE_ENV';

export const getNationalDeviceEnvStaticsAsync = createAsyncAction(
  GET_NATIONAL_DEVICE_ENV_STATICS,
  GET_NATIONAL_DEVICE_ENV_STATICS_SUCCESS,
  GET_NATIONAL_DEVICE_ENV_STATICS_ERROR
)<
  GetNationalDeviceEnvStaticsParamType,
  GetNationalDeviceEnvStaticsResponseType,
  AxiosErrorType
>();

export const getNationalOSEnvStaticsAsync = createAsyncAction(
  GET_NATIONAL_OS_ENV_STATICS,
  GET_NATIONAL_OS_ENV_STATICS_SUCCESS,
  GET_NATIONAL_OS_ENV_STATICS_ERROR
)<
  GetNationalOSEnvStaticsParamType,
  GetNationalOSEnvStaticsResponseType,
  AxiosErrorType
>();

export const getNationalBrowserEnvStaticsAsync = createAsyncAction(
  GET_NATIONAL_BROWSER_ENV_STATICS,
  GET_NATIONAL_BROWSER_ENV_STATICS_SUCCESS,
  GET_NATIONAL_BROWSER_ENV_STATICS_ERROR
)<
  GetNationalBrowserEnvStaticsParamType,
  GetNationalBrowserEnvStaticsResponseType,
  AxiosErrorType
>();

export const getNationalResolutionStaticsAsync = createAsyncAction(
  GET_NATIONAL_RESOLUTION,
  GET_NATIONAL_RESOLUTION_SUCCESS,
  GET_NATIONAL_RESOLUTION_ERROR
)<
  GetNationalResolutionStaticsParamType,
  GetNationalResolutionResponseType,
  AxiosErrorType
>();

export const setErrorNullServiceEnv = createAction(
  SET_ERROR_NULL_SERVICE_ENV
)();
