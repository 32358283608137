import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';

import TabGroup from '../../Molecule/TabGroup';
import { P1, P3 } from '../../Atoms/Typo';
import InflowTable from '../../Molecule/InflowTable';
import Spinner from '../../Molecule/Spinner';
import KeywordRank from '../../Molecule/KeywordRank';
import { GetTotalInflowCountResponseType } from '../../../api/userAnalytics/inflowPath/type';
import DefaultLink from '../../Atoms/Link';

const Component = styled(CardLayout)`
  height: 740px;
  border: none;
  padding: 0;
  background-color: #f1f3f8;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  height: 60px;
`;

const CardBody = styled(CardBodyLayout)<{ $cardType: string }>`
  display: flex;
  flex-direction: row;
  border-radius: 0 14px 14px 14px;
  border: 1px solid #d7dbe6;
  background-color: white;

  .body-wrap {
    ${(props) => {
      switch (props.$cardType) {
        case 'search':
          return css`
            :nth-child(1) {
              padding-left: 30px;
              width: 30%;
              margin-right: 20px;
            }
            :nth-child(2) {
              width: 47%;
              margin-right: 10px;
            }
            :nth-child(3) {
              width: 23%;
              padding-left: 30px;
              padding-right: 15px;
              border-radius: 0 14px 14px 0;
              border-left: 1px solid #d7dbe6;
              background-color: #fcfcfc;
            }
          `;
        case 'social':
          return css`
            :nth-child(1) {
              padding-left: 30px;
              width: 30%;
              padding-right: 15px;
            }
            :nth-child(2) {
              width: 70%;
              padding-left: 15px;
              padding-right: 30px;
            }
          `;
        case 'recommend':
          return css`
            :nth-child(1) {
              padding-left: 30px;
              padding-right: 30px;
              width: 100%;
            }
          `;

        default:
          return css``;
      }
    }}
  }
`;

const BodyWrap = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: column;
`;

const BodyHeader = styled.div`
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  ${P1} {
    font-weight: 600;
  }
  ${P3} {
    margin-left: 8px;
    color: #767676;
  }
`;

const LinkButton = styled.button`
  border: none;
  width: 158px;
  height: 38px;
  border-radius: 23px;
  background-color: #ffffff;
  border: 1px solid #cfcfcf;
  color: #767676;
  font-size: 15px;
  :hover {
    cursor: pointer;
  }
`;

interface InflowDetailCardProps {
  pageType: string;
  isLoadingSearch?: boolean;
  isLoadingDetail?: boolean;
  isLoadingSearchLank?: boolean;
  isLoadingSocial?: boolean;
  isLoadingSocialDetail?: boolean;
  isLoadingRecommend?: boolean;
  tabData: GetTotalInflowCountResponseType['payload'] | null;
  pathData: {
    name: string;
    device: string;
    cnt: number;
    rate: number;
  }[];
  detailData: {
    name: string;
    cnt: number;
    rate: number;
  }[];
  rankingData: {
    name: string;
    cnt: number;
    compare: number;
  }[];
  socialData: {
    name: string;
    device: string;
    cnt: number;
    rate: number;
  }[];
  SocialDetailData: {
    name: string;
    cnt: number;
    rate: number;
  }[];
  recommendData: {
    name: string;
    cnt: number;
    rate: number;
  }[];
  activeTabID: number;
  activeSearchID: number;
  activeSocialID: number;
  setDetailPath: (name: string, device: string, id: number) => void;
  setDetailSocial: (name: string, device: string, id: number) => void;
}

const InflowDetailCard = ({
  pageType,
  isLoadingSearch,
  isLoadingDetail,
  isLoadingSearchLank,
  isLoadingSocial,
  isLoadingSocialDetail,
  isLoadingRecommend,
  tabData,
  pathData,
  detailData,
  rankingData,
  socialData,
  SocialDetailData,
  recommendData,
  activeTabID,
  activeSearchID,
  activeSocialID,
  setDetailPath,
  setDetailSocial,
}: InflowDetailCardProps) => {
  //   const [activeTabID, setActiveTabID] = useState(1);
  const [totalSearch, setTotalSearch] = useState(0);
  const [totalSocial, setTotalSocial] = useState(0);
  const [totalRecommend, setTotalRecommend] = useState(0);

  const clickPath = (name: string, device: string, id: number) => {
    setDetailPath(name, device, id);
  };

  const clickSocialPath = (name: string, device: string, id: number) => {
    setDetailSocial(name, device, id);
  };

  const printBody = () => {
    // pageType에 따라 Card Body 변경
    switch (pageType) {
      case 'search':
        return (
          <CardBody $cardType="search">
            <BodyWrap className="body-wrap">
              <BodyHeader>
                <P1>유입경로</P1>
              </BodyHeader>
              {isLoadingSearch ? (
                <Spinner />
              ) : (
                <InflowTable
                  data={pathData}
                  cardType="search"
                  onClickPath={clickPath}
                  dataType="path"
                  actID={activeSearchID}
                />
              )}
            </BodyWrap>
            <BodyWrap className="body-wrap">
              <BodyHeader>
                <P1>상세 유입 검색어</P1>
                <P3>
                  Google, Bing의 경우 상세 유입 검색어를 지원하지 않습니다.
                </P3>
              </BodyHeader>
              {isLoadingSearch || isLoadingDetail ? (
                <Spinner />
              ) : (
                <InflowTable
                  data={detailData}
                  cardType="search"
                  onClickPath={clickPath}
                  dataType="detail"
                />
              )}
            </BodyWrap>
            <BodyWrap className="body-wrap">
              <BodyHeader>
                <P1>유입검색어 통합 순위</P1>
              </BodyHeader>
              {isLoadingSearchLank ? (
                <Spinner />
              ) : (
                <KeywordRank data={rankingData} />
              )}
            </BodyWrap>
          </CardBody>
        );
      case 'social':
        return (
          <CardBody $cardType="social">
            <BodyWrap className="body-wrap">
              <BodyHeader>
                <P1>유입경로</P1>
              </BodyHeader>
              {isLoadingSocial ? (
                <Spinner />
              ) : (
                <InflowTable
                  data={socialData}
                  cardType="social"
                  onClickPath={clickSocialPath}
                  dataType="path"
                  actID={activeSocialID}
                />
              )}
            </BodyWrap>
            <BodyWrap className="body-wrap">
              <BodyHeader>
                <P1>상세 유입 콘텐츠</P1>
              </BodyHeader>
              {isLoadingSocial || isLoadingSocialDetail ? (
                <Spinner />
              ) : (
                <InflowTable
                  data={SocialDetailData}
                  cardType="social"
                  dataType="detail"
                />
              )}
            </BodyWrap>
          </CardBody>
        );
      case 'recommend':
        return (
          <CardBody $cardType="recommend">
            <BodyWrap className="body-wrap">
              <BodyHeader>
                <P1>유입 경로</P1>
              </BodyHeader>
              {isLoadingRecommend ? (
                <Spinner />
              ) : (
                <InflowTable
                  data={recommendData}
                  cardType="recommend"
                  dataType="detail"
                />
              )}
            </BodyWrap>
          </CardBody>
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    if (tabData) {
      setTotalSearch(tabData.search);
      setTotalSocial(tabData.social);
      setTotalRecommend(tabData.recommend);
    }
  }, [tabData]);

  return (
    <Component>
      <Header>
        <TabGroup
          TabList={[
            {
              name: '검색유입',
              link: '/userAnalytics/inflowPath/detail?search',
              id: 1,
              data: totalSearch,
            },
            {
              name: '소셜유입(SNS)',
              link: '/userAnalytics/inflowPath/detail?social',
              id: 2,
              data: totalSocial,
            },
            {
              name: '추천유입',
              link: '/userAnalytics/inflowPath/detail?recommend',
              id: 3,
              data: totalRecommend,
            },
          ]}
          activeId={activeTabID}
        />

        <DefaultLink to="/userAnalytics/inflowPath">
          <LinkButton className="ux-analytics">
            <span className="ri-arrow-down-s-line"></span> 유입경로분석 메인
          </LinkButton>
        </DefaultLink>
      </Header>
      {printBody()}
    </Component>
  );
};

export default InflowDetailCard;
