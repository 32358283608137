import { takeLatest } from 'redux-saga/effects';
import createRequestSaga from '../../createRequestSaga';
import {
  apiGetPopularMenu,
  apiGetPopularContent,
  apiGetTotalContent,
} from '../../../api/userAnalytics/contents/api';
import {
  GET_POPULAR_MENU,
  GET_POPULAR_CONTENT,
  GET_Total_CONTENT,
  getPopularMenuAsync,
  getPopularContentAsync,
  getTotalContentAsync,
} from './actions';

const GetPopularMenuSaga = createRequestSaga(
  getPopularMenuAsync,
  apiGetPopularMenu
);

const GetPopularContentSaga = createRequestSaga(
  getPopularContentAsync,
  apiGetPopularContent
);

const GetTotalContentSaga = createRequestSaga(
  getTotalContentAsync,
  apiGetTotalContent
);

export function* contentsSaga() {
  yield takeLatest(GET_POPULAR_MENU, GetPopularMenuSaga);
  yield takeLatest(GET_POPULAR_CONTENT, GetPopularContentSaga);
  yield takeLatest(GET_Total_CONTENT, GetTotalContentSaga);
}

export { contentsSaga as default };
