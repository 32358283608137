import React from 'react';
import styled, { css } from 'styled-components';
import { P3 } from '../../../Atoms/Typo';

interface TooltipWrapperProps {
  $top: number;
  $left: number;
  $isShow: boolean;
}
const TooltipWrapperVariable = css`
  background-color: white;
  border: 1px solid #6b6b6b;
  border-radius: 4px;
  padding-top: 10px;
`;

const Component = styled.div<TooltipWrapperProps>`
  ${TooltipWrapperVariable}
  position: absolute;
  z-index: 999;
  width: 166px;
  height: 130px;
  display: none;
  flex-direction: column;
  border-radius: 18px;
  background-color: #f3f3f3;
  box-shadow: 2px 2px 6px #00000030;

  ${P3} {
    font-size: 15px;
  }

  ${(props) => {
    if (props.$isShow) {
      return css`
        display: flex;
      `;
    }
    return css``;
  }}
  ${(props) => {
    if (props.$left && props.$top) {
      return css`
        top: ${props.$top - 122}px;
        left: ${props.$left - 82}px;
      `;
    }
    return css``;
  }}
`;

const TooltipTitleWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  ${P3} {
    height: 18px;
    font-weight: 600;
  }
`;

const TooltipBodyWrap = styled.div`
  height: 100%;
  background-color: white;
  padding: 7px 14px 0;
  border-radius: 0 0 18px 18px;
`;

const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 18px;
`;

const ItemTitle = styled(P3)<{ $type: string }>`
  font-weight: 600;
  ${(props) => {
    switch (props.$type) {
      case 'total':
        return css`
          color: #7851e7;
        `;
      case 'old':
        return css`
          color: #009f6f;
        `;
      case 'new':
        return css`
          color: #1389ff;
        `;

      default:
        return css``;
    }
  }}
`;

const ItemValue = styled.div<{ $type?: string }>`
  display: flex;

  ${P3} {
    ${(props) => {
      switch (props.$type) {
        case 'total':
          return css`
            font-weight: 800;
          `;
        default:
          return css``;
      }
    }}
  }
`;

const ValueRate = styled(P3)`
  color: #767676;
  margin-left: 3px;
`;

const Split = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e2e2e2;
  margin-top: 4px;
  margin-bottom: 10px;
`;

interface TooltipProps {
  isShow: boolean;
  top: number;
  left: number;
  title: string;
  total: number;
  old: number;
  newUser: number;
}

const Tooltip = ({
  isShow,
  top,
  left,
  title,
  total,
  old,
  newUser,
}: TooltipProps) => {
  return (
    <Component $isShow={isShow} $top={top} $left={left}>
      <TooltipTitleWrap>
        <P3>{title}</P3>
      </TooltipTitleWrap>
      <TooltipBodyWrap>
        <ItemWrapper>
          <ItemTitle $type="total">전체</ItemTitle>
          <ItemValue $type="total">
            <P3>{total.toLocaleString()}</P3>
          </ItemValue>
        </ItemWrapper>
        <Split />
        <ItemWrapper>
          <ItemTitle $type="old">재방문</ItemTitle>
          <ItemValue>
            <P3>{old.toLocaleString()}</P3>
            {total ? (
              <ValueRate>({Math.ceil((old / total) * 100)}%)</ValueRate>
            ) : null}
          </ItemValue>
        </ItemWrapper>
        <ItemWrapper>
          <ItemTitle $type="new">신규</ItemTitle>
          <ItemValue>
            <P3>{newUser.toLocaleString()}</P3>
            {total ? (
              <ValueRate>({Math.floor((newUser / total) * 100)}%)</ValueRate>
            ) : null}
          </ItemValue>
        </ItemWrapper>
      </TooltipBodyWrap>
    </Component>
  );
};

export default Tooltip;
