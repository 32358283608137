import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import heatmapjs from 'heatmap.js';

const Component = styled.div<{
  $width: number;
  $height: number;
  $opacity: number;
}>`
  position: absolute !important;
  left: 0;
  top: 0;
  ${(props) => {
    if (props.$opacity) {
      return css`
        opacity: ${props.$opacity};
      `;
    }
    return css``;
  }}
  ${(props) => {
    if (props.$width && props.$height) {
      return css`
        width: ${props.$width}px;
        height: ${props.$height}px;
      `;
    }
    return css``;
  }}
`;

interface HeatmapLayerProps {
  width: number;
  height: number;
  data?: {
    x: number;
    y: number;
    value: number;
    // radius: number;
  }[];
  max?: number;
  opacity: number;
}

const HeatmapLayer = ({
  width,
  height,
  data,
  max,
  opacity,
}: HeatmapLayerProps) => {
  const heatmapCanvasEl = useRef<HTMLDivElement>(null);
  const [ins, setIns] = useState<heatmapjs.Heatmap<'value', 'x', 'y'> | null>(
    null
  );

  const makeHeatmap = (instance: heatmapjs.Heatmap<'value', 'x', 'y'>) => {
    if (data && data.length > 0 && max) {
      try {
        instance.setData({
          data,
          max: max + 5,
          min: 0,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (ins && data && data.length > 0) {
      makeHeatmap(ins);
    }
  }, [ins, data]);

  useEffect(() => {
    const { current } = heatmapCanvasEl;

    if (current && ins === null && width !== 0 && height !== 0) {
      setIns(
        heatmapjs.create({
          container: current,
          radius: 30,
        })
      );
    }
  }, [heatmapCanvasEl.current, width, height]);

  return (
    <Component
      id="heatmapContainer"
      key="heatmapContainer"
      $width={width}
      $height={height}
      ref={heatmapCanvasEl}
      $opacity={opacity}
    />
  );
};

export default HeatmapLayer;
