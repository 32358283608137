import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import Spinner from '../../Molecule/Spinner';
import {
  GetPathInflowChildrenResponseType,
  GetPathInflowResponseType,
  GetPathPlotResponseType,
  PathPlotStartFlow,
  PathPlotpayload,
} from '../../../api/pageAnalytics/pathPlot/type';
import { P2, P3 } from '../../Atoms/Typo';
import PathPlotGrid from '../../Molecule/PathPlotGird';
import LoadingModal from '../../Molecule/Modal/LoadingModal';

const Inform = styled.div`
  margin-bottom: 10px;
  ${P3} {
    color: ${(props) => props.theme.colors.grey600};
    span {
      margin-right: 5px;
    }
  }
`;

const Compoenent = styled(CardLayout)`
  display: flex;
  flex-direction: row;
  padding: 0;
  padding-bottom: 1px;
  height: 820px;
  border-radius: 0;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const CanvasGrid = styled.div<{ $height: number }>`
  flex: 1;
  display: flex;
  overflow: scroll;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    to right,
    white,
    white 25%,
    #f5f5f5 25%,
    #f5f5f5 50%
  );
`;

const NoDataWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${P3} {
    text-align: center;
  }
`;

const NextGrid = styled.div`
  width: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #f5f5f5;
  ${P2} {
    font-weight: 600;
  }
`;

const NextButton = styled.button`
  width: 44px;
  height: 44px;
  border-radius: 20px;
  border: 1px solid #dedede;
  background-color: #ffffff;
  span {
    font-size: 20px;
    color: #222222;
  }
  :hover {
    cursor: pointer;
  }
`;

interface UxPathPlotCardState {
  flowArr: {
    id: number;
    node: React.ReactNode;
  }[];
  startFlowData: PathPlotpayload['start_flow'];
  inflowData: PathPlotStartFlow['inflow'];
  sendData: {
    url: string;
    path: string[];
    seq: string;
    through_count: number;
  };
  clickImageInfo: {
    element_x: number;
    element_y: number;
    element_width: number;
    element_height: number;
  };
}

interface UxPathPlotCardProps {
  loading?: boolean;
  childrenLoading?: boolean;
  inflowLoading?: boolean;
  pathPlotData: GetPathPlotResponseType['payload'] | null;
  childrenData: GetPathInflowChildrenResponseType['payload'];
  inflowData: GetPathInflowResponseType['payload'];
  onClickChildren: (
    url: string,
    children: string[],
    seq: string,
    through_count: number
  ) => void;
  onClickStartInfo: (url: string, count: number) => void;
}

const UxPathPlotCard = ({
  loading,
  childrenLoading,
  inflowLoading,
  pathPlotData,
  childrenData,
  inflowData,
  onClickChildren,
  onClickStartInfo,
}: UxPathPlotCardProps) => {
  // state
  // 늘어날 FlowGrid number, 이걸로 <CanvasGrid> 내부 아이탬들 map으로 보여주자
  const [girdCount, setGridCount] = useState(3);
  const [girdNode, setGridNode] = useState<UxPathPlotCardState['flowArr']>([]);
  const [startItemIndex, setStartItemIndex] = useState(0);
  const [actFirstFlowID, setActFirstFlowID] = useState(0);
  const [componentHeight, setComponentHeight] = useState(0);

  const componentEl = useRef<HTMLDivElement | null>(null);

  const [nextSendData, setNextSendData] =
    useState<UxPathPlotCardState['sendData']>();

  const clickNext = () => {
    if (nextSendData) {
      setGridCount(girdCount + 1);
      // setClickItemInfo(prevSelectImageInfo(nextSendData.seq));
      onClickChildren(
        nextSendData.url,
        nextSendData.path,
        nextSendData.seq,
        nextSendData.through_count
      );
    }
  };

  // 시작 그리드 클릭
  const onClickStartGrid = (itemId: number, url: string, count: number) => {
    setStartItemIndex(itemId);
    setGridCount(3); // 그리드 3칸으로 set
    onClickStartInfo(url, count);
    setNextSendData(undefined);
  };

  // 흐름1 클릭
  const onClickFirstFlow = (
    url: string,
    path: string[],
    id: number,
    seq: string,
    through_count: number
  ) => {
    setGridCount(Number(seq) + 3); // 그리드 4칸으로 set
    onClickChildren(url, path, seq, through_count);
    // setClickItemInfo(info);
    setActFirstFlowID(id);
  };

  // 흐름2 부터
  const onClickFlow = (
    url: string,
    path: string[],
    id: number,
    seq: string,
    through_count: number
  ) => {
    setGridCount(Number(seq) + 3); // 그리드 setting
    onClickChildren(url, path, seq, through_count);
    // next를 눌렀을때와 동일하게 값을 일치시키기 위해 setting
    setNextSendData({
      url,
      path,
      seq,
      through_count,
    });
  };

  const rebuildData = (data: GetPathPlotResponseType['payload']) => {
    const gridArr: UxPathPlotCardState['flowArr'] = [];

    if (data.start_flow.length > 0) {
      if (girdNode.length > 0 && girdCount > 4) {
        gridArr.push(...girdNode);
        gridArr.splice(girdCount - 1);

        if (childrenData.length > 0) {
          gridArr.push({
            id: girdCount - 1,
            node: (
              <PathPlotGrid
                key={girdCount - 1}
                title={`흐름${girdCount - 2}`}
                flowClassName={`flow${girdCount - 2}`}
                type="flow"
                childrenData={childrenData}
                onClickChildren={onClickFlow}
              />
            ),
          });
        }
      } else {
        for (let index = 0; index < girdCount; index += 1) {
          if (index === 0) {
            if (inflowData.length > 0) {
              gridArr.push({
                id: index,
                node: (
                  <PathPlotGrid
                    key={index}
                    title="유입"
                    type="inflow"
                    data={inflowData}
                  />
                ),
              });
            } else {
              gridArr.push({
                id: index,
                node: (
                  <PathPlotGrid
                    key={index}
                    title="유입"
                    type="inflow"
                    data={data.start_flow[0].inflow}
                  />
                ),
              });
            }
          } else if (index === 1) {
            gridArr.push({
              id: index,
              node: (
                <PathPlotGrid
                  key={index}
                  title="시작"
                  flowClassName="start"
                  type="start"
                  data={data.start_flow}
                  inflowData={inflowData}
                  firstFlowID={actFirstFlowID}
                  onClickStartItem={onClickStartGrid}
                />
              ),
            });
          } else if (index === 2) {
            gridArr.push({
              id: index,
              node: (
                <PathPlotGrid
                  key={index}
                  title={`흐름${index - 1}`}
                  flowClassName={`flow${index - 1}`}
                  type="flow"
                  childrenData={data.start_flow[startItemIndex].children_path}
                  onClickChildren={onClickFirstFlow}
                />
              ),
            });
          } else if (childrenData.length > 0) {
            gridArr.push({
              id: girdCount - 1,
              node: (
                <PathPlotGrid
                  key={girdCount - 1}
                  title={`흐름${girdCount - 2}`}
                  flowClassName={`flow${girdCount - 2}`}
                  type="flow"
                  childrenData={childrenData}
                  onClickChildren={onClickFlow}
                />
              ),
            });
          }
        }
      }
    }
    setGridNode(gridArr);
  };

  useEffect(() => {
    if (pathPlotData) {
      rebuildData(pathPlotData);
    }
  }, [
    pathPlotData,
    childrenData,
    girdCount,
    startItemIndex,
    inflowData,
    actFirstFlowID,
  ]);

  useEffect(() => {
    if (childrenData.length > 0) {
      setNextSendData({
        url: childrenData[0].url,
        path: childrenData[0].children_path,
        seq: childrenData[0].seq,
        through_count: childrenData[0].through_count,
      });
    }
  }, [childrenData]);

  useEffect(() => {
    const { current } = componentEl;
    if (current) {
      current.scrollBy({ left: current.scrollWidth, behavior: 'smooth' });
      setComponentHeight(current.scrollHeight + 30);
    }
  }, [componentEl.current, girdNode]);

  return (
    <>
      <Inform>
        <P3>
          <span className="ri-information-line"></span>
          데이터 수집 정책으로 08월 21일 부터 데이터를 수집중입니다. 날짜를
          2023년 08월 21일 이후로 선택해주세요.
        </P3>
      </Inform>
      <Compoenent>
        <LoadingModal isOpen={childrenLoading} />
        <LoadingModal isOpen={inflowLoading} />
        {loading ? (
          <Spinner />
        ) : (
          <Wrapper>
            <CanvasGrid ref={componentEl} $height={componentHeight}>
              {girdNode.length > 0 ? (
                girdNode.map((element) => {
                  return element.node;
                })
              ) : (
                <NoDataWrap>
                  <P3>
                    데이터가 없습니다.
                    <br />
                    날짜를 2023년 08월 21일 이후로 선택해주세요.
                  </P3>
                </NoDataWrap>
              )}
            </CanvasGrid>
          </Wrapper>
        )}
        <NextGrid>
          <P2>Next</P2>
          <NextButton
            onClick={() => {
              clickNext();
            }}
          >
            <span className="ri-arrow-right-line"></span>
          </NextButton>
        </NextGrid>
      </Compoenent>
    </>
  );
};

export default UxPathPlotCard;
