import React, {
  useEffect,
  useRef,
  useState,
  ReactNodeArray,
  useMemo,
} from 'react';
import styled from 'styled-components';

import CardLayout from '../../../Atoms/Layout/Card/CardLayout';

import FullPageScreenShot from '../../../Molecule/FullPageScreenShot';
import HeatmapElementBox from '../../../Molecule/HeatmapElementBox';
import HeatmapLayer from '../../../Molecule/HeatmapLayer';
import MouseHeatmapTooltip, {
  MouseHeatmapTooltipType,
} from '../../../Molecule/Tooltip/MouseHeatmapTooltip';
import Spinner from '../../../Molecule/Spinner';

import { PageElementDataPayload } from '../../../../api/pageAnalytics/uxAnalytics/type';
import { uxAnalyticsState } from '../../../../modules/PageAnalytics/UXAnalytics';
import OpacitySlider from '../../../Atoms/Slider';
import { P3 } from '../../../Atoms/Typo';

const PageScreenWrap = styled.div`
  width: 100%;
  border-radius: 14px;
  background-color: #f5f5f5;
`;

const ScreenWrapHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 70px;
  border-radius: 14px 14px 0 0;
  background-color: #eeeeee;
  padding: 0 30px;
  ${P3} {
    font-size: 15px;
    font-weight: 600;
    margin-right: 10px;
    height: 20px;
  }
`;

const ScreenBody = styled.div`
  position: relative;
  width: 100%;
  padding: 30px 30px 30px 60px;
`;

const LabelWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 24px;
  top: 31px;
  left: 27px;
  ${P3} {
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    line-height: 1.2;
  }
  .top {
    color: #ff3b56;
  }
  .bottom {
    color: #2962ff;
  }
`;

const LabelTitle = styled(P3)`
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  line-height: 1.2;
  margin-bottom: 20px;
`;

const Spectrum = styled.div`
  width: 10px;
  height: 160px;
  background: transparent
    linear-gradient(180deg, #ff3b56 0%, #ffc400 33%, #00ff66 67%, #2962ff 100%)
    0% 0% no-repeat padding-box;
  opacity: 1;
  margin: 9px 0;
`;

const Component = styled(CardLayout)`
  padding: 0px;
  position: relative;
  border-radius: 0;
`;

interface MouseHeatmapCardState {
  eventCoordinates: {
    x: number;
    y: number;
    value: number;
    // radius: number;
  }[];
  convertEventData: { [key: string]: { [key: string]: number } };
}

export interface MouseHeatmapCardProps {
  pageId: string;
  loadingStatics: boolean;
  pageEventStaticsData: uxAnalyticsState['mouseStaticsData'];
  pageElementData: uxAnalyticsState['elementData'];
  clickedElementPath: string;
  mouseoverElementPath: string;

  clickTooltipInfo: MouseHeatmapTooltipType | null;
  isActiveDataType: number;
  mouseoverTooltipInfo: MouseHeatmapTooltipType | null;
  isLoading: boolean;
  setClickedElementPath: React.Dispatch<React.SetStateAction<string>>;
  setMouseoverElementPath: React.Dispatch<React.SetStateAction<string>>;
  setClickTooltipInfo: React.Dispatch<
    React.SetStateAction<MouseHeatmapTooltipType | null>
  >;

  setMouseoverTooltipInfo: React.Dispatch<
    React.SetStateAction<MouseHeatmapTooltipType | null>
  >;
}

const MouseHeatmapCard = ({
  pageId,
  loadingStatics,
  pageEventStaticsData,
  pageElementData,
  clickedElementPath,
  mouseoverElementPath,
  isLoading,
  clickTooltipInfo,
  isActiveDataType,
  mouseoverTooltipInfo,
  setClickedElementPath,
  setMouseoverElementPath,
  setClickTooltipInfo,

  setMouseoverTooltipInfo,
}: MouseHeatmapCardProps) => {
  const fullPageScreenShotEl = useRef<HTMLImageElement>(null);
  const [isImgLoad, setIsImgLoad] = useState(false);
  const [imgSize, setImgSize] = useState({ width: 0, height: 0 });
  const [rebuildClickData, setRebuildClickData] = useState<{
    data: {
      x: number;
      y: number;
      value: number;
      // radius: number;
    }[];
    max: number;
  }>();
  const [rebuildElementData, setRebuildElementData] = useState<
    uxAnalyticsState['elementData'] | null
  >(null);
  const [heatmapOpacity, setHeatmapOpacity] = useState(1);

  const calculateElementCoordinates = (imgWidth: number, imgHeight: number) => {
    const temp: uxAnalyticsState['elementData'] = [];
    if (pageElementData.length > 0) {
      pageElementData.forEach((element) => {
        if (
          !(
            element.x < 0 ||
            element.y < 0 ||
            element.x > 1 ||
            element.y > 1 ||
            element.width > 1 ||
            element.height > 1 ||
            element.width < 0 ||
            element.height < 0 ||
            element.x + element.width > 1
          )
        ) {
          const x = element.x * imgWidth;
          const y = element.y * imgHeight;
          const width = element.width * imgWidth;
          const height = element.height * imgHeight;
          const pushdata = {
            x,
            y,
            width,
            height,
            depth: element.depth,
            element_path: element.element_path,
            cnt: element.cnt,
            rate: element.rate,
            rank: element.rank,
            duration: element.duration,
          };

          temp.push(pushdata);
        }
      });
    }
    if (temp.length > 0) {
      setRebuildElementData(temp);
    }
  };

  const calculateClickCoordinates = (imgWidth: number, imgHeight: number) => {
    const temp: MouseHeatmapCardState['eventCoordinates'] = [];
    let max = 1;
    if (pageEventStaticsData) {
      const xCategory = Object.keys(pageEventStaticsData.data);

      xCategory.forEach((elementX) => {
        if (elementX !== 'null') {
          const a = pageEventStaticsData.data[elementX];
          const yCategory = Object.keys(a);

          yCategory.forEach((elementY) => {
            if (max < a[elementY]) {
              max = a[elementY];
            }
            temp.push({
              x: Math.floor(Number(elementX) * imgWidth),
              y: Math.floor(Number(elementY) * imgHeight),
              value: a[elementY],
            });
          });
        }
      });
    }

    return { data: temp, max };
  };

  const handleImageLoaded = () => {
    setIsImgLoad(true);
  };

  const handleImageLoadStart = () => {
    setIsImgLoad(false);
  };

  const onClickElementBox = (element: PageElementDataPayload) => {
    setClickedElementPath(element.element_path);
    if (isActiveDataType === 0) {
      setClickTooltipInfo({
        data: [
          {
            id: 0,
            title: '클릭 순위',
            value: `${element.rank}위`,
          },
          {
            id: 1,
            title: '클릭 횟수',
            value: `${element.cnt || 0}회`,
          },
          {
            id: 2,
            title: '클릭 비율',
            value: `${element.rate}%`,
          },
        ],
        img_src: `https://voda-media.nerdfactory.ai/${localStorage.getItem(
          'voda_tenant'
        )}/auto/${pageId}/${encodeURIComponent(element.element_path)}`,
        isOpenTooltip: true,
        elementX: element.x / imgSize.width,
        elementY: element.y / imgSize.height,
        elementHeight: element.height / imgSize.height,
        elementWidth: element.width / imgSize.width,
      });
    }
  };

  const onMouseOverElementBox = (element: PageElementDataPayload) => {
    setMouseoverElementPath(element.element_path);
    if (isActiveDataType === 0) {
      setMouseoverTooltipInfo({
        data: [
          {
            id: 0,
            title: '클릭 순위',
            value: `${element.rank}위`,
          },
          {
            id: 1,
            title: '클릭 횟수',
            value: `${element.cnt || 0}회`,
          },
          {
            id: 2,
            title: '클릭 비율',
            value: `${element.rate}%`,
          },
        ],
        img_src: `https://voda-media.nerdfactory.ai/${localStorage.getItem(
          'voda_tenant'
        )}/auto/${pageId}/${encodeURIComponent(element.element_path)}`,
        isOpenTooltip: true,
        elementX: element.x / imgSize.width,
        elementY: element.y / imgSize.height,
        elementHeight: element.height / imgSize.height,
        elementWidth: element.width / imgSize.width,
      });
    }
  };

  const printElementBox = useMemo(() => {
    const result: ReactNodeArray = [];
    if (rebuildElementData) {
      rebuildElementData.forEach((element) => {
        result.push(
          <HeatmapElementBox
            key={element.element_path}
            isClicked={clickedElementPath === element.element_path}
            isMouseOver={mouseoverElementPath === element.element_path}
            x={element.x}
            y={element.y}
            width={element.width}
            height={element.height}
            depth={element.depth}
            onClick={() => {
              onClickElementBox(element);
            }}
            onMouseLeave={() => {
              setMouseoverElementPath('');
              setMouseoverTooltipInfo(null);
            }}
            onMouseOver={() => {
              onMouseOverElementBox(element);
            }}
          />
        );
      });
    }
    return result;
  }, [rebuildElementData, clickedElementPath, mouseoverElementPath]);

  const printTooltip = useMemo(() => {
    if (mouseoverTooltipInfo) {
      return (
        <MouseHeatmapTooltip {...mouseoverTooltipInfo} imgSize={imgSize} />
      );
    }
    if (clickTooltipInfo) {
      return <MouseHeatmapTooltip {...clickTooltipInfo} imgSize={imgSize} />;
    }
    return null;
  }, [mouseoverTooltipInfo, clickTooltipInfo]);

  const handleOpacity = (val: number) => {
    setHeatmapOpacity(val / 10);
  };

  useEffect(() => {
    const { current } = fullPageScreenShotEl;
    if (isImgLoad && current) {
      const width = current.offsetWidth;
      const height = current.offsetHeight;
      setImgSize({ width, height });
      setRebuildClickData(calculateClickCoordinates(width, height));
      calculateElementCoordinates(width, height);
    }
  }, [
    isImgLoad,
    fullPageScreenShotEl.current,
    pageEventStaticsData,
    pageElementData,
    loadingStatics,
  ]);

  return (
    <PageScreenWrap>
      <ScreenWrapHeader>
        <P3>불투명도</P3>
        <OpacitySlider
          type="mouse"
          defaultVal={10}
          opacityVal={handleOpacity}
        />
      </ScreenWrapHeader>
      <ScreenBody>
        <LabelWrapper>
          <LabelTitle>클릭비율(%)</LabelTitle>
          <P3 className="top">100</P3>
          <Spectrum />
          <P3 className="bottom">0</P3>
        </LabelWrapper>
        <Component>
          <FullPageScreenShot
            img_src={`https://voda-media.nerdfactory.ai/${localStorage.getItem(
              'voda_tenant'
            )}/auto/${pageId}/page_screenshot`}
            imgRef={fullPageScreenShotEl}
            onLoad={handleImageLoaded}
            onLoadStart={handleImageLoadStart}
          />
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <HeatmapLayer
                data={rebuildClickData?.data}
                max={rebuildClickData?.max}
                width={imgSize.width}
                height={imgSize.height}
                opacity={heatmapOpacity}
              />
              {printElementBox}
              {printTooltip}
            </>
          )}
        </Component>
      </ScreenBody>
    </PageScreenWrap>
  );
};

export default MouseHeatmapCard;
