import React from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as IconArrowFunnelSVG } from '../../../assets/icons/icon-arrow-funnel.svg';
import { ReactComponent as IconLaunchSVG } from '../../../assets/icons/icon-launch.svg';

// icon
const IconArrowFunnel = styled(IconArrowFunnelSVG)<{ $cardType: string }>`
  width: 7px;
  height: 8px;
  ${(props) => {
    switch (props.$cardType) {
      case 'conversion':
        return css`
          path {
            fill: #000000;
            /* fill: ${props.theme.colors.orange5}; */
          }
        `;
      case 'exit':
        return css`
          path {
            fill: ${props.theme.colors.danger5};
          }
        `;
      default:
        return css``;
    }
  }}
`;

const IconLaunch = styled(IconLaunchSVG)`
  width: 100%;
  height: 100%;
`;

const ETCButton = styled.button<{ $isETC: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 24px;
  top: 50%;
  transform: translate(0%, -50%);
  width: 18px;
  height: 18px;
  ${(props) => {
    if (props.$isETC) {
      return css``;
    }
    return css`
      display: none;
    `;
  }}
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const ConversionArrow = styled.div<{ $cardType: string }>`
  position: absolute;
  display: flex;
  left: -5px;
  top: 50%;
  transform: translate(0%, -50%);
  width: 7px;
  height: 8px;
  ${(props) => {
    switch (props.$cardType) {
      case 'conversion':
        return css``;
      case 'exit':
        return css``;
      default:
        return css`
          display: none;
        `;
    }
  }}
`;

const FunnelCardTitle = styled.div`
  width: 100%;
  height: 18px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.grey900};
  font-size: 15px;
  line-height: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  a {
    color: inherit;
  }
`;

const FunnelCardRate = styled.div<{ $cardType: string }>`
  font-weight: bold;
  color: #7851e7;
  font-size: 15px;
  line-height: normal;
  ${(props) => {
    if (props.$cardType === 'inflow') {
      return css`
        color: #2962ff;
      `;
    }
    return css``;
  }}
  span {
    font-weight: 400;
    color: #767676;
  }
`;

// const FunnelCardContainer = styled.div<{ $cardType: string; $rate: number }>`
const FunnelCardContainer = styled.div`
  position: relative;
  width: 350px;
  height: 60px;
  padding: 10px 12px;
  border-radius: 7px;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0px;
  }
  ${FunnelCardTitle} {
    margin-bottom: 2px;
  }

  border: 1px solid #dddddd;
  width: 70%;

  /* Desktop */
  @media ${(props) => props.theme.device.$desktop} {
    /* width: 280px; */
    height: 60px;
  }

  /* BigScreen */
  @media ${(props) => props.theme.device.$bigscreen} {
    /* width: 350px; */
    height: 60px;
  }

  background-color: white;
`;

const FunnelProgressBar = styled.div<{ $cardType: string; $rate: number }>`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 4px;
  border-radius: 0px 0px 10px 10px;

  ${(props) => {
    switch (props.$cardType) {
      case 'inflow':
        return css`
          background: linear-gradient(
            to right,
            #2962ff ${props.$rate}%,
            white ${props.$rate}% 100%
          );
        `;
      case 'conversion':
        return css`
          background: linear-gradient(
            to right,
            #7851e7 ${props.$rate}%,
            white ${props.$rate}% 100%
          );
        `;
      case 'exit':
        return css`
          background: linear-gradient(
            to right,
            rgba(241, 100, 100, 0.4) ${props.$rate}%,
            white ${props.$rate}% 100%
          );
          ${FunnelCardRate} {
            color: ${props.theme.colors.danger5};
          }
        `;
      default:
        return css``;
    }
  }}
`;

interface FunnelCardProps {
  url: string;
  count: number;
  rate: number;
  cardType: string;
  className: string;
  onClickOpenHandle: (cardType: string) => void;
}

const FunnelCard = ({
  url,
  count,
  rate,
  cardType,
  className,
  onClickOpenHandle,
}: FunnelCardProps) => {
  return (
    <FunnelCardContainer
      // $cardType={cardType}
      // $rate={rate}
      className={className}
    >
      <ConversionArrow $cardType={cardType}>
        <IconArrowFunnel $cardType={cardType} />
      </ConversionArrow>
      <FunnelCardTitle>
        {url !== 'ETC' && url !== 'direct' && url !== '이탈' ? (
          <a href={url} target="blank">
            {url}
          </a>
        ) : (
          url
        )}
      </FunnelCardTitle>
      <FunnelCardRate $cardType={cardType}>
        {count}
        <span> {Math.round(rate * 100) / 100}%</span>
      </FunnelCardRate>
      {/* <ETCButton
        $isETC={url === 'ETC'}
        onClick={() => {
          onClickOpenHandle(cardType);
        }}
      >
        <IconLaunch />
      </ETCButton> */}
      <FunnelProgressBar $cardType={cardType} $rate={rate} />
    </FunnelCardContainer>
  );
};

export default FunnelCard;
