import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { RootState } from '../../../modules';

import {
  GetGlobalSessionEnvStatisticsParamType,
  GetNationalSessionEnvStatisticsParamType,
} from '../../../api/userAnalytics/region/type';
import {
  getGlobalSessionEnvStatisticsAsync,
  getNationalSessionEnvStatisticsAsync,
} from '../../../modules/userAnalytics/region';

import ContentsLayout from '../../../components/Atoms/Layout/Contents/ContentsLayout';
import ContentBodyLayout from '../../../components/Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../../components/Atoms/Layout/Contents/ContentsItemLayout';
import Header from '../../../components/Organisms/Header';
import NationalSessionCard, {
  NationalSessionCardState,
} from '../../../components/Organisms/Card/NationalSessionCard';
import { GetExportStatisticsExcelParamType } from '../../../api/authentication/type';
import { getExportExcelAsync } from '../../../modules/authentication';

const Region = () => {
  // redux
  const dispatch = useDispatch();
  const { startDate, endDate, isDatePickerToggle } = useSelector(
    (state: RootState) => state.datePicker
  );
  const {
    isLoadingNational,
    isLoadingGlobal,
    nationalSessionEnvData,
    globalSessionEnvData,
  } = useSelector((state: RootState) => state.region);

  const onGetNationalSessionEnvStatistics = (
    info: GetNationalSessionEnvStatisticsParamType
  ) => {
    dispatch(getNationalSessionEnvStatisticsAsync.request(info));
  };

  const onGetGlobalSessionEnvStatistics = (
    info: GetGlobalSessionEnvStatisticsParamType
  ) => {
    dispatch(getGlobalSessionEnvStatisticsAsync.request(info));
  };

  const onGetExportExcel = (info: GetExportStatisticsExcelParamType) => {
    dispatch(getExportExcelAsync.request(info));
  };

  // national excel
  const onClickNationalExcel = (
    data: NationalSessionCardState['excelData']
  ) => {
    onGetExportExcel({
      time: `${moment(startDate)
        .format('YYYY-MM-DD')
        .replace(/-/g, '')}-${moment(endDate)
        .format('YYYY-MM-DD')
        .replace(/-/g, '')}`,
      type: 'national',
      payload: data,
    });
  };

  // global excel
  const onClickGlobalExcel = (data: NationalSessionCardState['excelData']) => {
    onGetExportExcel({
      time: `${moment(startDate)
        .format('YYYY-MM-DD')
        .replace(/-/g, '')}-${moment(endDate)
        .format('YYYY-MM-DD')
        .replace(/-/g, '')}`,
      type: 'global',
      payload: data,
    });
  };

  useEffect(() => {
    if (!isDatePickerToggle) {
      onGetNationalSessionEnvStatistics({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
      onGetGlobalSessionEnvStatistics({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      });
    }
  }, [isDatePickerToggle, startDate, endDate]);

  return (
    <ContentsLayout>
      <Header
        title="지역별 분석"
        icon=""
        iconClass="ri-user-received-line"
        isDatePicker
      />
      <ContentBodyLayout>
        <ContentsItemLayout desktop={12}>
          <NationalSessionCard
            nationalLoading={isLoadingNational}
            globalLoading={isLoadingGlobal}
            nationalData={nationalSessionEnvData}
            globalData={globalSessionEnvData}
            onClickNationalExcel={onClickNationalExcel}
            onClickGlobalExcel={onClickGlobalExcel}
          />
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default Region;
