import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Chart from 'react-apexcharts';
import { P1, P3 } from '../../../Atoms/Typo';
import DataError from '../../../Atoms/DataError';

const Component = styled.div<{
  $id: number | null;
  $idx?: number;
  $active?: boolean;
  $dashboard?: boolean;
  $labels?: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;

  .apexcharts-pie-series {
    path {
      filter: none;
    }
    filter: none;
  }
  .apexcharts-datalabels {
    ${(props) => {
      if (props.$labels) {
        return css`
          :nth-child(n + ${props.$labels + 4}) {
            display: none;
          }
        `;
      }
      return css``;
    }}
  }
  .apexcharts-pie-series {
    :nth-child(1) {
      /* transform: scale(1.11) translate(-10px, -9px); */
    }
  }
`;

const ChartDataLabelWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${P3} {
    color: ${(props) => props.theme.colors.grey600};
  } ;
`;

const ChartDataLabelTitle = styled.div`
  ${P3} {
    color: ${(props) => props.theme.colors.grey600};
  } ;
`;

const ChartDataLabelRate = styled.div<{ $id: number | null }>`
  ${P1} {
    font-size: 20px;
    font-weight: 800;
    color: ${(props) => props.theme.colors.grey900};
  }
`;

interface DounutChartProps {
  data: {
    [key: string]: string | number;
    cnt: number;
    rate: number;
  }[];
  colorList: string[];
  isEnv?: boolean;
  isDashboard?: boolean;
}

const DounutChart = ({
  data,
  colorList,
  isEnv,
  isDashboard,
}: DounutChartProps) => {
  const [seriesState, setSeries] = useState<number[]>([]);
  const [mouseOverKey, setMouseOverKey] = useState<number | null>(null);
  const [mouseClickKey, setMouseClickKey] = useState<number | null>(null);
  const [startAnchor, setStartAnchor] = useState(0);
  const [active, setActive] = useState(false);
  const [labelsNum, setLabelsNum] = useState<number>();

  const DonutRef = useRef<any>(null);

  const DonutChartonMouseClick = (e: any, chartContext: any, config: any) => {
    if (config.dataPointIndex !== -1) {
      setMouseClickKey(config.dataPointIndex);

      // datalabel color 활성화
      if (e.target.attributes.getNamedItem('selected').value === 'true') {
        setActive(true);
      } else setActive(false);
    }
  };

  const DonutChartOnMouseEnter = (e: any, chartContext: any, config: any) => {
    if (config.dataPointIndex !== -1) {
      const index = config.dataPointIndex;
      setMouseOverKey(index);
    } else {
      setMouseOverKey(null);
    }
  };

  const DonutChartOnMouseLeave = (e: any, chartContext: any, config: any) => {
    setMouseOverKey(null);
  };

  const options = {
    chart: {
      type: 'donut' as const,
      width: '100%',
      events: {
        dataPointSelection: DonutChartonMouseClick,
        dataPointMouseEnter: DonutChartOnMouseEnter,
        dataPointMouseLeave: DonutChartOnMouseLeave,
      },
    },
    colors: colorList,
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      // textAnchor: 'middle',
      style: {
        fontSize: '14px',
        fontFamily: 'Pretendard',
        fontWeight: 600,
        colors: ['#ffffff'],
      },
      background: {
        enabled: true,
        foreColor: '#222222',
        padding: 6,
        borderRadius: 12,
        borderWidth: 6,
        opacity: 1,
        dropShadow: {
          enabled: true,
          top: 0,
          left: 1,
          blur: 6,
          color: '#00000029',
          opacity: 1,
        },
      },
      dropShadow: {
        enabled: false,
      },
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        expandOnClick: false, // Disable click-to-detach for other slices

        donut: {
          size: '50%',
          // size: '83.4%',
        },
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  const printChart = useMemo(() => {
    if (seriesState.length > 0) {
      return (
        <Chart
          type="donut"
          series={seriesState}
          options={options}
          // width="100%"
          // height="100%"
          ref={DonutRef}
        />
      );
    }
    return <DataError />;
  }, [seriesState]);

  const printDataLabelMemo = useMemo(() => {
    if (mouseOverKey !== null && data.length > 0) {
      const keyArr = Object.keys(data[mouseOverKey]);
      const key = keyArr[0];

      return (
        <ChartDataLabelWrapper>
          <ChartDataLabelTitle>
            <P3>{data[mouseOverKey][key]}</P3>
          </ChartDataLabelTitle>
          <ChartDataLabelRate $id={mouseOverKey}>
            <P1>{data[mouseOverKey].rate}%</P1>
          </ChartDataLabelRate>
          <P3>{data[mouseOverKey].cnt.toLocaleString()}</P3>
        </ChartDataLabelWrapper>
      );
    }
    if (mouseClickKey !== null && data.length > 0) {
      const keyArr = Object.keys(data[mouseClickKey]);
      const key = keyArr[0];

      return (
        <ChartDataLabelWrapper>
          <ChartDataLabelTitle>
            <P3>{data[mouseClickKey][key]}</P3>
          </ChartDataLabelTitle>
          <ChartDataLabelRate $id={mouseClickKey}>
            <P1>{data[mouseClickKey].rate}%</P1>
          </ChartDataLabelRate>
          <P3>{data[mouseClickKey].cnt.toLocaleString()}</P3>
        </ChartDataLabelWrapper>
      );
    }
    if (data.length > 0) {
      const keyArr = Object.keys(data[0]);
      const key = keyArr[0];
      return (
        <ChartDataLabelWrapper>
          <ChartDataLabelTitle>
            <P3>{data[0][key]}</P3>
          </ChartDataLabelTitle>
          <ChartDataLabelRate $id={mouseOverKey}>
            <P1>{data[0].rate}%</P1>
          </ChartDataLabelRate>
          <P3>{data[0].cnt.toLocaleString()}</P3>
        </ChartDataLabelWrapper>
      );
    }
    return [];
  }, [mouseOverKey, data]);

  useEffect(() => {
    let sum = 0;
    if (data.length > 0) {
      const seriesTemp: number[] = [];
      const anchorArr: number[] = [];
      data.some((element, index) => {
        sum += element.rate;
        if (sum > 50 && index > 0) {
          anchorArr.push(index);
        }
        seriesTemp.push(element.rate);
        return false;
      });
      if (isEnv) {
        setStartAnchor(anchorArr[0] + 7); // 사용환경 분석 도넛카드
      } else setStartAnchor(anchorArr[0] + 5); // 유입경로 분석 도넛카드
      setSeries(seriesTemp);
      setLabelsNum(seriesTemp.length);
    }
  }, [data, isEnv]);

  useEffect(() => {
    const { current } = DonutRef;
    if (DonutRef.current && seriesState.length > 0 && isDashboard) {
      current.chart.updateOptions({
        dataLabels: {
          background: {
            padding: 6,
            borderRadius: 8,
            borderWidth: 2,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: '35%',
            },
          },
        },
      });
    }
  }, [DonutRef, seriesState, isDashboard]);

  return (
    <Component
      $id={mouseClickKey}
      $idx={startAnchor}
      $active={active}
      $dashboard={isDashboard}
      $labels={labelsNum}
    >
      {printChart}
      {isDashboard ? null : printDataLabelMemo}
    </Component>
  );
};

export default DounutChart;
