import React from 'react';

import { WeeklyUserCountPayload } from '../../../api/serviceAnalytics/userAnalysis/type';
import ContentBodyLayout from '../../Atoms/Layout/Contents/ContentBodyLayout';
import ContentsItemLayout from '../../Atoms/Layout/Contents/ContentsItemLayout';
import ContentsLayout from '../../Atoms/Layout/Contents/ContentsLayout';

import HeatmapChartCard from '../../Organisms/Card/HeatmapChartCard';
import MultiLineChartCard from '../../Organisms/Card/MultiLineChartCard';
import Header from '../../Organisms/Header';
import { TableChartState } from '../../Molecule/Chart/TableChart';

interface ServiceAnalysisTemplateProps {
  headerTitle: string;
  iconClass?: string;
  icon?: string;
  multiChartHeader: string;
  dayWeekChartHeader: string;
  heatmapChartHeader: string;
  multiChartData: {
    legendData: {
      title: string;
      status:
        | 'all'
        | 'return'
        | 'new'
        | 'all_future'
        | 'return_future'
        | 'new_future';
    }[];
    Data: {
      [key: string]: {
        total?: number;
        old: number;
        new: number;
        is_futrue?: boolean;
      };
    } | null;
    isLoading: boolean;
    lineLabel: string;
    bar1Label?: string;
    bar2Label?: string;

    onChangeFliter?: (timeSort: string) => void;
  };
  dayWeekMultiChartData: {
    legendData: {
      title: string;
      status:
        | 'all'
        | 'return'
        | 'new'
        | 'all_future'
        | 'return_future'
        | 'new_future';
    }[];
    Data: {
      [key: string]: {
        total?: number;
        old: number;
        new: number;
        is_futrue?: boolean;
      };
    } | null;
    isLoading: boolean;
    lineLabel: string;
    bar1Label?: string;
    bar2Label?: string;

    onChangeFliter?: (timeSort: string) => void;
  };
  heatmapChartData: {
    Data: WeeklyUserCountPayload | null;
    label1: string;
    label2: string;
    totalLabel: string;
    loading: boolean;
  };
  onClickExcel?: (data: TableChartState['excelData']) => void;
}

const ServiceAnalysisTemplate = ({
  headerTitle,
  iconClass,
  icon,
  multiChartHeader,
  dayWeekChartHeader,
  heatmapChartHeader,
  multiChartData,
  dayWeekMultiChartData,
  heatmapChartData,
  onClickExcel,
}: ServiceAnalysisTemplateProps) => {
  return (
    <ContentsLayout>
      <Header
        title={headerTitle}
        isDatePicker
        icon={icon}
        iconClass={iconClass}
      />
      <ContentBodyLayout>
        <ContentsItemLayout desktop={12}>
          <MultiLineChartCard
            headerTitle={multiChartHeader}
            onClickExcel={onClickExcel}
            notWeekly
            xLabels="voda-xLabel"
            {...multiChartData}
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={12}>
          <MultiLineChartCard
            isDayWeekChart
            headerTitle={dayWeekChartHeader}
            xLabels="voda-dayWeek-xLabel"
            {...dayWeekMultiChartData}
          />
        </ContentsItemLayout>
        <ContentsItemLayout desktop={12}>
          <HeatmapChartCard
            cardTitle={heatmapChartHeader}
            {...heatmapChartData}
          />
        </ContentsItemLayout>
      </ContentBodyLayout>
    </ContentsLayout>
  );
};

export default ServiceAnalysisTemplate;
