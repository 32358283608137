import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { P2, P3 } from '../../Atoms/Typo';

const Component = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-height: 220px;
`;

const TotalCount = styled.div<{ $type?: string }>`
  display: flex;
  align-items: baseline;
  padding-right: 5px;
  ${P3} {
    color: ${(props) => props.theme.colors.grey600};
    width: 100%;
  }

  ${(props) => {
    if (props.$type === 'detail') {
      return css`
        min-width: 100px;
      `;
    }
    return css``;
  }}
`;

const ItemWrap = styled.div<{
  $active?: number;
  $inflowPath?: boolean;
  $type?: string;
}>`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey400};
  padding: 0px 10px;
  padding-left: 0px;

  ${(props) => {
    if (props.$inflowPath) {
      switch (props.$type) {
        case 'search':
          return css`
            :hover {
              cursor: pointer;
              background-color: rgba(19, 137, 255, 0.2);
              .inflow-PC {
                border: 1px solid white;
              }
            }
          `;
        case 'social':
          return css`
            :hover {
              cursor: pointer;
              background-color: rgba(96, 80, 221, 0.1);
              .inflow-PC {
                border: 1px solid white;
              }
              /* & * {
                color: white;
              } */
            }
          `;

        default:
          break;
      }
      return css``;
    }
    return css``;
  }}

  ${(props) => {
    if (props.$active && props.$inflowPath) {
      switch (props.$type) {
        case 'search':
          return css`
            :nth-child(${props.$active}) {
              background-color: #2962ff;
              .inflow-PC {
                border: 1px solid white;
              }
              .active-arrow {
                display: block;
              }
              & * {
                color: white;
              }
            }
          `;
        case 'social':
          return css`
            :nth-child(${props.$active}) {
              background-color: #6050dd;
              .inflow-PC {
                border: 1px solid white;
              }
              .active-arrow {
                display: block;
              }
              & * {
                color: white;
              }
            }
          `;

        default:
          break;
      }
      return css``;
    }
    return css``;
  }}
  :hover {
    .active-arrow {
      display: block;
    }
  }
`;

const ItemActiveArrow = styled.div`
  display: none;
  position: absolute;
  top: 50%;
  right: 0px;
  width: 22px;
  height: 22px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 1px 1px 2px #00000029;
  transform: translate(50%, -50%);
  text-align: center;
  span {
    font-size: 15px;
    color: #222222 !important;
  }
`;

const Item = styled.div`
  display: flex;
  overflow: hidden;
  padding-right: 10px;
  span {
    font-size: 15px;
    color: ${(props) => props.theme.colors.grey600};
    margin-left: 3px;
    :hover {
      cursor: pointer;
    }
  }
`;

const Rank = styled(P2)<{ $type?: string }>`
  width: 18px;
  text-align: center;
  font-weight: bold;
  color: ${(props) => props.theme.colors.blue};
  margin-right: 10px;
  ${(props) => {
    switch (props.$type) {
      case 'search':
        return css`
          color: ${props.theme.colors.blue};
        `;
      case 'social':
        return css`
          color: ${props.theme.colors.purple7};
        `;
      case 'recommend':
        return css`
          color: #0dae9d;
        `;

      default:
        return css``;
    }
  }}
`;

const Device = styled.div<{ $type: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #2962ff;
  border-radius: 4px;
  min-width: 50px;
  height: 24px;
  margin-right: 10px;
  ${P3} {
    font-size: 14px;
  }

  ${(props) => {
    switch (props.$type) {
      case 'PC':
        return css`
          border: 1px solid #2962ff;
        `;
      case 'Mobile':
        return css`
          border: 1px solid #f99e32;
        `;
      case 'Tablet':
        return css`
          border: 1px solid #0c9e63;
        `;

      default:
        return css``;
    }
  }}
`;

const InflowName = styled(P2)`
  font-weight: 600;
`;

const DetailName = styled(P2)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Count = styled(P2)`
  font-size: 15px;
  white-space: normal;
`;

const Rate = styled(P2)`
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
  min-width: 43px;
  margin-left: 10px;
  span {
    font-size: 14px;
    color: ${(props) => props.theme.colors.grey600};
  }
`;

const DetailRate = styled(P2)<{ $cardType: string }>`
  display: flex;
  justify-content: flex-end;
  width: 50px;
  margin-left: 20px;
  color: ${(props) => props.theme.colors.blue};

  ${(props) => {
    switch (props.$cardType) {
      case 'search':
        return css`
          color: ${props.theme.colors.blue};
        `;
      case 'social':
        return css`
          color: #6050dd;
        `;
      case 'recommend':
        return css`
          color: #00aa98;
        `;

      default:
        return css``;
    }
  }}
`;

interface InflowTableProps {
  data: {
    name: string;
    device?: string;
    cnt: number;
    rate: number;
  }[];
  cardType: string;
  dataType?: string;
  onClickPath?: (name: string, device: string, id: number) => void;
  actID?: number;
}

const InflowTable = ({
  data,
  cardType,
  dataType,
  onClickPath,
  actID,
}: InflowTableProps) => {
  const [pathData, setPathData] = useState<
    {
      name: string;
      device: string;
      cnt: number;
      rate: number;
    }[]
  >([]);
  const [detailData, setDetailData] = useState<
    {
      name: string;
      cnt: number;
      rate: number;
    }[]
  >([]);

  const clickPathItem = (name: string, device: string, id: number) => {
    if (onClickPath) {
      onClickPath(name, device, id);
    }
  };

  const onClickLink = (url: string) => {
    window.open(url, '_blank', 'noopener, noreferrer');
  };

  const printTable = () => {
    switch (dataType) {
      case 'path':
        if (pathData.length > 0) {
          return pathData.map((element, idx) => {
            return (
              <ItemWrap
                key={element.name + element.device}
                $inflowPath
                $active={actID}
                $type={cardType}
                onClick={() => {
                  clickPathItem(element.name, element.device, idx);
                }}
              >
                <Item>
                  <Rank $type={cardType}>{idx + 1}</Rank>
                  <Device
                    className={`inflow-${element.device}`}
                    $type={element.device}
                  >
                    <P3>{element.device}</P3>
                  </Device>
                  <InflowName>{element.name}</InflowName>
                </Item>
                <TotalCount>
                  <Count>{element.cnt.toLocaleString()}</Count>
                  <P3>세션</P3>
                  <Rate>
                    {element.rate}
                    <span>%</span>
                  </Rate>
                </TotalCount>
                <ItemActiveArrow className="active-arrow">
                  <span className="ri-arrow-right-s-line"></span>
                </ItemActiveArrow>
              </ItemWrap>
            );
          });
        }
        return <div>데이터가 없습니다.</div>;
      case 'detail':
        if (detailData.length > 0) {
          return detailData.map((element, idx) => {
            return (
              <ItemWrap key={element.name}>
                <Item>
                  <Rank $type={cardType}>{idx + 1}</Rank>
                  <DetailName>{element.name}</DetailName>
                  {cardType !== 'search' && element.name !== 'Etc' ? (
                    <span
                      className="ri-external-link-line"
                      onClick={() => onClickLink(element.name)}
                    >
                      
                    </span>
                  ) : null}
                </Item>
                <TotalCount $type="detail">
                  <P2>{element.cnt.toLocaleString()}</P2>
                  <P3>세션</P3>
                  <DetailRate $cardType={cardType}>{element.rate}%</DetailRate>
                </TotalCount>
              </ItemWrap>
            );
          });
        }
        return <div>데이터가 없습니다.</div>;

      default:
        return null;
    }
  };

  useEffect(() => {
    const pathTemp: {
      name: string;
      device: string;
      cnt: number;
      rate: number;
    }[] = [];
    const detailTemp: {
      name: string;
      cnt: number;
      rate: number;
    }[] = [];
    let etcSession = 0;
    let etcRate = 0;

    switch (dataType) {
      case 'path':
        data.forEach((element) => {
          if (element.device) {
            pathTemp.push({
              name: element.name,
              device: element.device,
              cnt: element.cnt,
              rate: element.rate,
            });
          }
        });
        setPathData(pathTemp);
        break;
      case 'detail':
        data.forEach((element, idx) => {
          if (idx < 13) {
            detailTemp.push({
              name: element.name,
              cnt: element.cnt,
              rate: element.rate,
            });
          } else {
            etcSession += element.cnt;
            etcRate += element.rate;
          }
        });

        if (etcSession > 0 && etcRate > 0) {
          detailTemp.push({
            name: 'Etc',
            cnt: etcSession,
            rate: Math.round(etcRate * 1e12) / 1e12,
          });
        }
        setDetailData(detailTemp);
        break;

      default:
        break;
    }

    return () => {
      setDetailData([]);
    };
  }, [data]);

  return <Component>{printTable()}</Component>;
};

export default InflowTable;
