import { createReducer } from 'typesafe-actions';
import {
  GET_REPORT_AVG_PV,
  GET_REPORT_AVG_PV_ERROR,
  GET_REPORT_AVG_PV_SUCCESS,
  GET_REPORT_DURATION,
  GET_REPORT_DURATION_ERROR,
  GET_REPORT_DURATION_SUCCESS,
  GET_REPORT_SESSION,
  GET_REPORT_SESSION_ERROR,
  GET_REPORT_SESSION_SUCCESS,
  GET_REPORT_UV,
  GET_REPORT_UV_ERROR,
  GET_REPORT_UV_SUCCESS,
  SET_ERROR_NULL,
  GET_REPORT_INFLOW_GLOBAL,
  GET_REPORT_INFLOW_GLOBAL_ERROR,
  GET_REPORT_INFLOW_GLOBAL_SUCCESS,
  GET_REPORT_INFLOW_KEYWORD,
  GET_REPORT_INFLOW_KEYWORD_ERROR,
  GET_REPORT_INFLOW_KEYWORD_SUCCESS,
  GET_REPORT_INFLOW_NATIONAL,
  GET_REPORT_INFLOW_NATIONAL_ERROR,
  GET_REPORT_INFLOW_NATIONAL_SUCCESS,
  GET_REPORT_INFLOW_PATH,
  GET_REPORT_INFLOW_PATH_ERROR,
  GET_REPORT_INFLOW_PATH_SUCCESS,
  GET_REPORT_CLICK_RATE,
  GET_REPORT_CLICK_RATE_ERROR,
  GET_REPORT_CLICK_RATE_SUCCESS,
  GET_REPORT_GROWTH_PV,
  GET_REPORT_GROWTH_PV_ERROR,
  GET_REPORT_GROWTH_PV_SUCCESS,
  GET_REPORT_POPULAR_PAGE,
  GET_REPORT_POPULAR_PAGE_ERROR,
  GET_REPORT_POPULAR_PAGE_SUCCESS,
  GET_REPORT_MONTH,
  GET_REPORT_MONTH_SUCCESS,
  GET_REPORT_MONTH_ERROR,
} from './actions';
import { reportAction, reportState } from './types';

const initialState: reportState = {
  isLoadingDuration: false,
  isLoadingPV: false,
  isLoadingSession: false,
  isLoadingUV: false,
  isLoadingNational: false,
  isLoadingGlobal: false,
  isLoadingPath: false,
  isLoadingKeyword: false,
  isLoadingpopular: false,
  isLoadingGrowthPV: false,
  isLoadingClickRate: false,

  ReportDutraionData: null,
  ReportPVData: null,
  ReportSessionData: null,
  ReportUVData: null,
  ReportNationalData: null,
  ReportGlobalData: null,
  ReportPathData: null,
  ReportKeywordData: [],
  ReportPopularPageData: [],
  ReportGrowthPVData: [],
  ReportClickRateData: null,
  ReportMonthData: [],

  reportError: null,
};

const reducer = createReducer<reportState, reportAction>(initialState, {
  [GET_REPORT_UV]: (state) => ({
    ...state,
    isLoadingUV: true,
    ReportUVData: null,
    reportError: null,
  }),
  [GET_REPORT_UV_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingUV: false,
    ReportUVData: action.payload.payload,
  }),
  [GET_REPORT_UV_ERROR]: (state, action) => ({
    ...state,
    isLoadingUV: false,
    ReportUVData: null,
    reportError: action.payload,
  }),

  [GET_REPORT_SESSION]: (state) => ({
    ...state,
    isLoadingSession: true,
    ReportSessionData: null,
    reportError: null,
  }),
  [GET_REPORT_SESSION_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingSession: false,
    ReportSessionData: action.payload.payload,
  }),
  [GET_REPORT_SESSION_ERROR]: (state, action) => ({
    ...state,
    isLoadingSession: false,
    ReportSessionData: null,
    reportError: action.payload,
  }),

  [GET_REPORT_AVG_PV]: (state) => ({
    ...state,
    isLoadingPV: true,
    ReportPVData: null,
    reportError: null,
  }),
  [GET_REPORT_AVG_PV_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingPV: false,
    ReportPVData: action.payload.payload,
  }),
  [GET_REPORT_AVG_PV_ERROR]: (state, action) => ({
    ...state,
    isLoadingPV: false,
    ReportPVData: null,
    reportError: action.payload,
  }),

  [GET_REPORT_DURATION]: (state) => ({
    ...state,
    isLoadingDuration: true,
    ReportDutraionData: null,
    reportError: null,
  }),
  [GET_REPORT_DURATION_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingDuration: false,
    ReportDutraionData: action.payload.payload,
  }),
  [GET_REPORT_DURATION_ERROR]: (state, action) => ({
    ...state,
    isLoadingDuration: false,
    ReportDutraionData: null,
    reportError: action.payload,
  }),

  [GET_REPORT_INFLOW_NATIONAL]: (state) => ({
    ...state,
    isLoadingNational: true,
    ReportNationalData: null,
    reportError: null,
  }),
  [GET_REPORT_INFLOW_NATIONAL_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingNational: false,
    ReportNationalData: action.payload.payload,
  }),
  [GET_REPORT_INFLOW_NATIONAL_ERROR]: (state, action) => ({
    ...state,
    isLoadingNational: false,
    ReportNationalData: null,
    reportError: action.payload,
  }),

  [GET_REPORT_INFLOW_GLOBAL]: (state) => ({
    ...state,
    isLoadingGlobal: true,
    ReportGlobalData: null,
    reportError: null,
  }),
  [GET_REPORT_INFLOW_GLOBAL_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingGlobal: false,
    ReportGlobalData: action.payload.payload,
  }),
  [GET_REPORT_INFLOW_GLOBAL_ERROR]: (state, action) => ({
    ...state,
    isLoadingGlobal: false,
    ReportGlobalData: null,
    reportError: action.payload,
  }),

  [GET_REPORT_INFLOW_PATH]: (state) => ({
    ...state,
    isLoadingPath: true,
    ReportPathData: null,
    reportError: null,
  }),
  [GET_REPORT_INFLOW_PATH_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingPath: false,
    ReportPathData: action.payload.payload,
  }),
  [GET_REPORT_INFLOW_PATH_ERROR]: (state, action) => ({
    ...state,
    isLoadingPath: false,
    ReportPathData: null,
    reportError: action.payload,
  }),

  [GET_REPORT_INFLOW_KEYWORD]: (state) => ({
    ...state,
    isLoadingKeyword: true,
    ReportKeywordData: [],
    reportError: null,
  }),
  [GET_REPORT_INFLOW_KEYWORD_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingKeyword: false,
    ReportKeywordData: action.payload.payload,
  }),
  [GET_REPORT_INFLOW_KEYWORD_ERROR]: (state, action) => ({
    ...state,
    isLoadingKeyword: false,
    ReportKeywordData: [],
    reportError: action.payload,
  }),

  [GET_REPORT_POPULAR_PAGE]: (state) => ({
    ...state,
    isLoadingpopular: true,
    ReportPopularPageData: [],
    reportError: null,
  }),
  [GET_REPORT_POPULAR_PAGE_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingpopular: false,
    ReportPopularPageData: action.payload.payload,
  }),
  [GET_REPORT_POPULAR_PAGE_ERROR]: (state, action) => ({
    ...state,
    isLoadingpopular: false,
    ReportPopularPageData: [],
    reportError: action.payload,
  }),

  [GET_REPORT_GROWTH_PV]: (state) => ({
    ...state,
    isLoadingGrowthPV: true,
    ReportGrowthPVData: [],
    reportError: null,
  }),
  [GET_REPORT_GROWTH_PV_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingGrowthPV: false,
    ReportGrowthPVData: action.payload.payload,
  }),
  [GET_REPORT_GROWTH_PV_ERROR]: (state, action) => ({
    ...state,
    isLoadingGrowthPV: false,
    ReportGrowthPVData: [],
    reportError: action.payload,
  }),

  [GET_REPORT_CLICK_RATE]: (state) => ({
    ...state,
    isLoadingClickRate: true,
    ReportClickRateData: null,
    reportError: null,
  }),
  [GET_REPORT_CLICK_RATE_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingClickRate: false,
    ReportClickRateData: action.payload.payload,
  }),
  [GET_REPORT_CLICK_RATE_ERROR]: (state, action) => ({
    ...state,
    isLoadingClickRate: false,
    ReportClickRateData: null,
    reportError: action.payload,
  }),

  [GET_REPORT_MONTH]: (state) => ({
    ...state,
    ReportMonthData: [],
    reportError: null,
  }),
  [GET_REPORT_MONTH_SUCCESS]: (state, action) => ({
    ...state,
    ReportMonthData: action.payload.payload,
  }),
  [GET_REPORT_MONTH_ERROR]: (state, action) => ({
    ...state,
    ReportMonthData: [],
    reportError: action.payload,
  }),

  [SET_ERROR_NULL]: (state) => ({
    ...state,
    reportError: null,
  }),
});

export default reducer;
