import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Chart from 'react-apexcharts';
import ko from 'apexcharts/dist/locales/ko.json';
import { tooltip } from '../../../Atoms/ChartTooltip/MultiAreaChartTooltip';
import { IconSMArrowLeft, IconSMArrowRight } from '../../../Atoms/Icon';

const Component = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  /* min-height: 400px; */
  padding-left: 52px;
  padding-right: 52px;
  .apexcharts-svg {
    overflow: initial;
  }
  ${tooltip};

  .apexcharts-bar-series {
    .apexcharts-series {
      transform: translate(-4px, 0%);
      &:last-child {
        transform: translate(4px, 0%);
      }
    }
  }
`;

const LeftZoomButton = styled.div<{
  $isEnd: boolean;
}>`
  position: absolute;
  left: 0;
  bottom: 12px;
  width: 12px;
  height: 16px;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;

    path {
      ${(props) => {
        if (props.$isEnd) {
          return css`
            display: none;
            fill: ${props.theme.colors.grey500};
          `;
        }
        return css`
          fill: ${props.theme.colors.grey900};
        `;
      }}
    }
  }
`;
const RightZoomButton = styled.div<{ $isEnd: boolean }>`
  position: absolute;
  right: 0;
  bottom: 12px;
  width: 12px;
  height: 16px;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;

    path {
      ${(props) => {
        if (props.$isEnd) {
          return css`
            display: none;
            fill: ${props.theme.colors.grey500};
          `;
        }
        return css`
          fill: ${props.theme.colors.grey900};
        `;
      }}
    }
  }
`;

interface MultiAreaChartProps {
  Data: {
    [key: string]: {
      direct: number;
      search: number;
      social: number;
      recommend: number;
      holiday?: boolean;
    };
  } | null;

  areaLabel: string;
  area2Label?: string;
  area3Label?: string;
  area4Label?: string;
}
interface MultiAreaChartState {
  seriesType:
    | {
        name: string;
        type: string;
        data: {
          x: string;
          y: number;
          holiday?: boolean;
        }[];
      }[]
    | null;
}

const MultiAreaChart = ({
  Data,

  areaLabel,
  area2Label,
  area3Label,
  area4Label,
}: MultiAreaChartProps) => {
  const [stateStartIter, setStartIter] = useState(0);
  const [stateEndIter, setEndIter] = useState(-1);
  const [endIndex, setEndIndex] = useState(-1);

  const ChartRef = useRef<any>(null);

  const [rebuildSeries, setSeries] =
    useState<MultiAreaChartState['seriesType']>(null);

  const options = {
    dataLabels: {
      enabled: false,
    },
    noData: {
      text: '데이터를 불러오지 못했습니다.',
      align: 'center' as const,
      verticalAlign: 'middle' as const,
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#424242',
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Pretendard',
      },
    },
    chart: {
      locales: [ko],
      defaultLocale: 'ko',
      animations: {
        enabled: true,
        easing: 'easeinout' as const,
        speed: 100,
        animateGradually: {
          enabled: false,
          delay: 0,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 200,
        },
      },

      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      events: {
        mouseMove(event: any, chartContext: any, config: any) {
          const apexcharts_tooltip = chartContext.el.querySelector(
            '.apexcharts-tooltip'
          );
          const canvasEl = chartContext.el.querySelector('.apexcharts-canvas');
          const marker =
            chartContext.el.getElementsByClassName('apexcharts-marker');
          const dataPointIndex =
            config.dataPointIndex === -1 ? 0 : config.dataPointIndex;
          if (apexcharts_tooltip && canvasEl && marker[dataPointIndex]) {
            const canvasTop =
              canvasEl.getBoundingClientRect().top + window.pageYOffset;
            const canvasLeft =
              canvasEl.getBoundingClientRect().left + window.pageXOffset;
            const width = canvasEl.clientWidth;
            const markerTop =
              marker[dataPointIndex].getBoundingClientRect().top +
              window.pageYOffset;
            const markerLeft =
              marker[dataPointIndex].getBoundingClientRect().left +
              window.pageXOffset;
            if (canvasLeft + width / 2 < markerLeft) {
              apexcharts_tooltip.style.top = `${markerTop - canvasTop + 5}px`;
              apexcharts_tooltip.style.left = `${
                markerLeft - canvasLeft - apexcharts_tooltip.clientWidth + 5
              }px`;
            } else {
              apexcharts_tooltip.style.top = `${markerTop - canvasTop + 5}px`;
              apexcharts_tooltip.style.left = `${
                markerLeft - canvasLeft + 5
              }px`;
            }
          }
        },
        updated(chartContext: any, config: any) {
          const seriesData = config.config.series[0].data;
          const seriesTextWrapper = chartContext.el.getElementsByClassName(
            'apexcharts-xaxis-label'
          );

          if (seriesTextWrapper) {
            seriesData.forEach((element: any, idx: number) => {
              if (element.holiday) {
                const holiday = seriesTextWrapper.item(idx) as HTMLElement;
                if (holiday) {
                  holiday.setAttribute('fill', 'red');
                }
              }
            });
          }
        },
      },
    },

    legend: {
      show: false,
    },

    stroke: {
      width: 1,
    },

    markers: {
      size: 2,
      colors: ['#00258D', '#1389FF', '#7851E7', '#3fecb7'],
      strokeColors: ['#00258D', '#1389FF', '#7851E7', '#00BFA5'],
      strokeWidth: 1,
      strokeOpacity: 1,
      hover: {
        size: 5,
      },
    },
    colors: ['#00258D', '#1389FF', '#7851E7', '#00BFA5'],
    tooltip: {
      enabled: true,
      followCursor: false,
      shared: true,
      intersect: false,
      fixed: {
        enabled: true,
        position: 'topLeft',
      },

      custom({
        series,
        seriesIndex,
        dataPointIndex,
        w,
      }: {
        series: any;
        seriesIndex: any;
        dataPointIndex: any;
        w: any;
      }) {
        if (series[0] && series[1] && series[2] && series[3]) {
          const areaValue = series[0][dataPointIndex];
          const areaValue2 = series[1][dataPointIndex];
          const areaValue3 = series[2][dataPointIndex];
          const areaValue4 = series[3][dataPointIndex];

          let directRate = 0;
          let searchRate = 0;
          let socialRate = 0;
          let recommendRate = 0;

          const sum = areaValue + areaValue2 + areaValue3 + areaValue4;

          let result = '';
          if (sum !== 0) {
            directRate = Math.round((areaValue / sum) * 100);
            searchRate = Math.round((areaValue2 / sum) * 100);
            socialRate = Math.round((areaValue3 / sum) * 100);
            recommendRate = Math.round((areaValue4 / sum) * 100);
          }

          if (
            area2Label !== undefined &&
            area3Label !== undefined &&
            area4Label !== undefined
          ) {
            result = `<div class="tooltip">
            <div class="innerBox">
              <div class="title all">전체</div>
              <div class="value-all">${Number(sum).toLocaleString()}</div>
            </div>
            <div class="innerBox">
            <div class="title">
            <div class='dot direct'></div>
              ${areaLabel}</div>
              <div class="value-wrap">
                <div class="value">${Number(areaValue).toLocaleString()}</div>
                <div class="rate">(${directRate}%)</div>
              </div>
            </div>
            <div class="innerBox">
            <div class="title">
            <div class='dot search'></div>
              ${area2Label}</div>
              <div class="value-wrap">
                <div class="value">${Number(areaValue2).toLocaleString()}</div>
                <div class="rate">(${searchRate}%)</div>
              </div>
            </div>
            <div class="innerBox">
            <div class="title">
            <div class='dot social'></div>
              ${area3Label}</div>
              <div class="value-wrap">
                <div class="value">${Number(areaValue3).toLocaleString()}</div>
                <div class="rate">(${socialRate}%)</div>
              </div>
            </div>
            <div class="innerBox">
            <div class="title">
            <div class='dot recommend'></div>
              ${area4Label}</div>
              <div class="value-wrap">
                <div class="value">${Number(areaValue4).toLocaleString()}</div>
                <div class="rate">(${recommendRate}%)</div>
              </div>
            </div>
          </div>`;
          } else {
            result = `<div class="tooltip">
              <div class="innerBox">
                <div class="title">전체</div>
                <div class="value-all">${Number(sum).toLocaleString()}</div>
              </div>
            </div>`;
          }
          return result;
        }
        if (series[0]) {
          const seriesData = w.config.series[0].data;
          const areaValue = series[0][dataPointIndex];
          if (seriesData[dataPointIndex].isFuture) {
            return `<div class="tooltip">
              <div class="innerBox">
                <div class="title">${areaLabel}(추정)</div>
                <div class="value">${areaValue}</div>
              </div>
            </div>`;
          }

          return `<div class="tooltip">
              <div class="innerBox">
                <div class="title">${areaLabel}</div>
                <div class="value">${areaValue}</div>
              </div>
            </div>`;
        }
        return ``;
      },
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        right: -15,
        left: -30,
      },
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        color: '#e0e0e0',
        width: '100%',
      },
      labels: {
        rotate: 0,

        style: {
          fontFamily: 'Pretendard',
          fontWeight: 400,
          fontSize: '14px',
          colors: '#757575',
        },
        formatter(value: string) {
          return ``;
        },
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      min: 0,
      max: 100,
    },
    fill: {
      colors: ['#00258D', '#1389FF', '#7851E7', '#1DE9B6'],
      type: ['gradient', 'gradient', 'gradient', 'gradient'],
      gradient: {
        shade: 'light',
        type: 'vertical',
        inverseColors: false,
        opacityFrom: 0.4,
        opacityTo: 0.1,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '10%',
        borderRadius: 4,
      },
    },
    annotations: {
      position: 'back',
    },
  };

  const [optionsState, setOptions] = useState(options);

  const tempSeries = [{ name: '', type: 'area', data: [] }];

  // 멀티차트 화살표 버튼 클릭 시 series data update를 위한 functino
  const updateSeries = (
    series: MultiAreaChartState['seriesType'],
    startIter: number,
    endIter: number
  ) => {
    const { current } = ChartRef;

    if (series && current) {
      const seriesData0: {
        x: string;
        y: number;
        holiday?: boolean;
      }[] = [];
      const seriesData1: {
        x: string;
        y: number;
        holiday?: boolean;
      }[] = [];
      const seriesData2: {
        x: string;
        y: number;
        holiday?: boolean;
      }[] = [];
      const seriesData3: {
        x: string;
        y: number;
        holiday?: boolean;
      }[] = [];

      for (let index = startIter; index <= endIter; index += 1) {
        seriesData0.push(series[0].data[index]);
        if (
          area2Label !== undefined &&
          area3Label !== undefined &&
          area4Label !== undefined
        ) {
          seriesData1.push(series[1].data[index]);
          seriesData2.push(series[2].data[index]);
          seriesData3.push(series[3].data[index]);
        }
      }

      if (
        area2Label !== undefined &&
        area3Label !== undefined &&
        area4Label !== undefined
      ) {
        current.chart.updateSeries(
          [
            {
              name: areaLabel,
              type: 'area',
              data: seriesData0,
            },
            {
              name: area2Label,
              type: 'area',
              data: seriesData1,
            },
            {
              name: area3Label,
              type: 'area',
              data: seriesData2,
            },
            {
              name: area4Label,
              type: 'area',
              data: seriesData3,
            },
          ],
          true
        );
      } else {
        current.chart.updateSeries(
          [
            {
              name: areaLabel,
              type: 'area',
              data: seriesData0,
            },
          ],
          true
        );
      }
    }
  };

  // 리덕스 데이터 변경 시 호출 되는 function
  const updateOption = (
    series: MultiAreaChartState['seriesType'],
    startIter: number,
    endIter: number,
    yAxisMin: number,
    yAxisMax: number,
    max: number
  ) => {
    const { current } = ChartRef;
    let maxLength = 0;
    let ceilMax = '';
    let resultMax = 0;

    if (series && current) {
      const seriesData0: {
        x: string;
        y: number;
        holiday?: boolean;
      }[] = [];
      const seriesData1: {
        x: string;
        y: number;
        holiday?: boolean;
      }[] = [];
      const seriesData2: {
        x: string;
        y: number;
        holiday?: boolean;
      }[] = [];
      const seriesData3: {
        x: string;
        y: number;
        holiday?: boolean;
      }[] = [];

      for (let index = startIter; index <= endIter; index += 1) {
        seriesData0.push(series[0].data[index]);
        if (
          area2Label !== undefined &&
          area3Label !== undefined &&
          area4Label !== undefined
        ) {
          seriesData1.push(series[1].data[index]);
          seriesData2.push(series[2].data[index]);
          seriesData3.push(series[3].data[index]);
        }
      }
      let updateTempSeries: {
        name: string;
        type: string;
        data: {
          x: string;
          y: number;
          holiday?: boolean;
        }[];
      }[] = [];

      if (
        area2Label !== undefined &&
        area3Label !== undefined &&
        area4Label !== undefined
      ) {
        updateTempSeries = [
          {
            name: areaLabel,
            type: 'area',
            data: seriesData0,
          },

          {
            name: area2Label,
            type: 'area',
            data: seriesData1,
          },
          {
            name: area3Label,
            type: 'area',
            data: seriesData2,
          },
          {
            name: area4Label,
            type: 'area',
            data: seriesData3,
          },
        ];
      } else {
        updateTempSeries = [
          {
            name: areaLabel,
            type: 'area',
            data: seriesData0,
          },
        ];
      }

      if (max > 0) {
        maxLength = max.toString().length; // 2
        if (maxLength > 2) {
          ceilMax = `1${'0'.repeat(maxLength - 2)}`;
        } else ceilMax = `1${'0'.repeat(maxLength - 1)}`;

        resultMax = Math.ceil(max / Number(ceilMax)) * Number(ceilMax);
      }

      const tempOptions = {
        ...optionsState,
        series: updateTempSeries,

        stroke: {
          width: 1,
          curve: 'straight',
        },

        yaxis: {
          ...optionsState.yaxis,
          min: yAxisMin - 0.01,
          max: yAxisMax + 1,
        },
        xaxis: {
          ...optionsState.xaxis,

          labels: {
            ...optionsState.xaxis.labels,
            // formatter(value: string) {
            //   if (value) {
            //     console.log(value);
            //     if (value.split('-')[2] === '01') {
            //       return `${value.split('-')[1]}월 ${value.split('-')[2]}일`;
            //     }
            //     return `${value.split('-')[2]}일`;
            //   }
            //   return ``;
            // },
            formatter(value: string) {
              let month = '';
              let day = '';
              if (value) {
                const date = value.split('-').slice(1);
                if (date[0][0] === '0') {
                  month = date[0].substring(1);
                } else {
                  month = date[0].substring(0);
                }
                if (date[1][0] === '0') {
                  day = date[1].substring(1);
                } else {
                  day = date[1].substring(0);
                }
                return `${month}.${day}`;
              }
              return ``;
            },
          },
        },
        annotations: {
          ...optionsState.annotations,
          yaxis: [
            {
              y: resultMax,
              strokeDashArray: 0,
              borderColor: '#eeeeee',
              opacity: 1,
              width: '100%',
              offsetX: 0,
              label: {
                textAnchor: 'start',
                borderColor: 'transparent',
                borderWidth: 0,

                style: {
                  fontFamily: 'Pretendard',
                  fontWeight: 500,
                  background: 'transparent',
                  color: '#767676',
                  fontSize: '15px',
                },
                position: 'left',
                offsetX: -42,
                offsetY: 8,
                text: `${Math.floor(resultMax).toLocaleString()}`,
              },
            },
            {
              y: resultMax / 2,
              strokeDashArray: 0,
              borderColor: '#eeeeee',
              opacity: 1,
              width: '100%',
              offsetX: 0,
              label: {
                textAnchor: 'middle',
                borderColor: 'transparent',
                borderWidth: 0,

                style: {
                  fontFamily: 'Pretendard',
                  fontWeight: 500,
                  background: 'transparent',
                  color: '#767676',
                  fontSize: '15px',
                },
                position: 'left',
                offsetX: -25,
                offsetY: 8,
                text: `${Math.floor(resultMax / 2).toLocaleString()}`,
              },
            },
          ],
        },
      };

      current.chart.updateOptions(tempOptions);
    }
  };

  // 리덕스 데이터 변경 시 호출 되는 function
  const rebuildData = () => {
    const { current } = ChartRef;
    if (Data && current) {
      const categoryArr = Object.keys(Data);
      const areaData: {
        x: string;
        y: number;
        holiday?: boolean;
      }[] = [];
      const areaData2: {
        x: string;
        y: number;
        holiday?: boolean;
      }[] = [];
      const areaData3: {
        x: string;
        y: number;
        holiday?: boolean;
      }[] = [];
      const areaData4: {
        x: string;
        y: number;
        holiday?: boolean;
      }[] = [];

      const valueArr: number[] = [];
      let valueMax = 0;

      categoryArr.forEach((element) => {
        areaData.push({
          x: element,
          y: Data[element].direct,
          holiday: Data[element].holiday,
        });
        areaData2.push({
          x: element,
          y: Data[element].search,
          holiday: Data[element].holiday,
        });
        areaData3.push({
          x: element,
          y: Data[element].social,
          holiday: Data[element].holiday,
        });
        areaData4.push({
          x: element,
          y: Data[element].recommend,
          holiday: Data[element].holiday,
        });
      });

      let series: {
        name: string;
        type: string;
        data: {
          x: string;
          y: number;
          holiday?: boolean;
        }[];
      }[] = [];

      if (
        area2Label !== undefined &&
        area3Label !== undefined &&
        area4Label !== undefined
      ) {
        series = [
          { name: areaLabel, type: 'area', data: areaData },
          { name: area2Label, type: 'area', data: areaData2 },
          { name: area3Label, type: 'area', data: areaData3 },
          { name: area4Label, type: 'area', data: areaData4 },
        ];
      } else {
        series = [{ name: areaLabel, type: 'area', data: areaData }];
      }

      if (series.length > 0) {
        series.forEach((element) => {
          element.data.forEach((el) => {
            valueArr.push(el.y);
          });
        });
        valueMax = Math.max(...valueArr);
      }

      setSeries(series);
      setEndIndex(series[0].data.length - 1);

      let startIterTemp = 0;
      let endIterTemp = 0;

      if (series[0].data.length < 15) {
        setStartIter(0);
        setEndIter(series[0].data.length - 1);
        startIterTemp = 0;
        endIterTemp = series[0].data.length - 1;
      } else {
        setStartIter(series[0].data.length - 15);
        setEndIter(series[0].data.length - 1);
        startIterTemp = series[0].data.length - 15;
        endIterTemp = series[0].data.length - 1;
      }

      const series0 = series[0].data;
      let min = 0;
      series0.forEach((element, index) => {
        if (index === 0) {
          min = element.y;
        }

        if (element.y < min) {
          min = element.y;
        }
      });

      let yAxisMin = 0;
      let yAxisMax = 1;
      series.forEach((element) => {
        element.data.forEach((dataElement) => {
          if (dataElement.y > yAxisMax) {
            yAxisMax = dataElement.y;
          }
          if (dataElement.y < yAxisMin) {
            yAxisMin = dataElement.y;
          }
        });
      });
      updateOption(
        series,
        startIterTemp,
        endIterTemp,
        yAxisMin,
        yAxisMax,
        valueMax
      );
    }
  };

  // 리덕스 데이터 변경 시 호출 되는 function
  useEffect(() => {
    if (Data) {
      rebuildData();
    }
  }, [Data]);

  const onClickNext = () => {
    const { current } = ChartRef;
    let startIterTemp = 0;
    let endIterTemp = 0;

    if (current && rebuildSeries) {
      const startIter = stateEndIter + 1;
      startIterTemp = stateEndIter + 1;
      if (startIter > rebuildSeries[0].data.length - 1) {
        setEndIter(stateEndIter);
        endIterTemp = stateEndIter;
      } else if (startIter + 14 > rebuildSeries[0].data.length - 1) {
        if (rebuildSeries[0].data.length - 15 > -1) {
          setStartIter(rebuildSeries[0].data.length - 15);
          startIterTemp = rebuildSeries[0].data.length - 15;
        } else {
          setStartIter(startIter);
          startIterTemp = startIter;
        }
        setEndIter(rebuildSeries[0].data.length - 1);
        endIterTemp = rebuildSeries[0].data.length - 1;
        updateSeries(rebuildSeries, startIterTemp, endIterTemp);
      } else {
        setStartIter(startIter);
        setEndIter(startIter + 14);
        startIterTemp = startIter;
        endIterTemp = startIter + 14;
        updateSeries(rebuildSeries, startIterTemp, endIterTemp);
      }
    }
  };

  const onClickPre = () => {
    const { current } = ChartRef;
    let startIterTemp = 0;
    let endIterTemp = 0;

    if (current && rebuildSeries) {
      const endIter = stateStartIter - 1;
      endIterTemp = stateStartIter - 1;
      if (endIter === -1) {
        setStartIter(stateStartIter);
        startIterTemp = stateStartIter;
      } else if (endIter - 14 < 0) {
        setStartIter(0);
        startIterTemp = 0;
        if (rebuildSeries[0].data.length - 1 > 14) {
          setEndIter(14);
          endIterTemp = 14;
        } else {
          setEndIter(endIter);
          endIterTemp = endIter;
        }
        updateSeries(rebuildSeries, startIterTemp, endIterTemp);
      } else {
        setStartIter(endIter - 14);
        setEndIter(endIter);
        startIterTemp = endIter - 14;
        endIterTemp = endIter;
        updateSeries(rebuildSeries, startIterTemp, endIterTemp);
      }
    }
  };

  return (
    <Component>
      <Chart
        type="area"
        series={tempSeries}
        options={optionsState}
        width="100%"
        height="100%"
        ref={ChartRef}
      />
      <LeftZoomButton
        onClick={() => {
          onClickPre();
        }}
        $isEnd={stateStartIter === 0}
      >
        <IconSMArrowLeft />
      </LeftZoomButton>
      <RightZoomButton
        onClick={() => {
          onClickNext();
        }}
        $isEnd={stateEndIter === endIndex}
      >
        <IconSMArrowRight />
      </RightZoomButton>
    </Component>
  );
};

export default MultiAreaChart;
