import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import CardBodyLayout from '../../../Atoms/Layout/Card/CardBodyLayout';
import CardHeaderLayout from '../../../Atoms/Layout/Card/CardHeaderLayout';
import { H3, P2, P3 } from '../../../Atoms/Typo';
import Spinner from '../../../Molecule/Spinner';
import { GetPopularPageStatisticsResponseType } from '../../../../api/Dashboard/type';
import DataError from '../../../Atoms/DataError';
import DefaultLink from '../../../Atoms/Link';

const sliderInFromLeft = keyframes`
  from {
    left: -100%;
  }
  to {
    left: 0%;
  }
`;
const sliderOutFromLeft = keyframes`
  from {
    left: 0%;
  }
  to {
    left: -100%;
  }
`;
const sliderInFromRight = keyframes`
  from {
    left: 100%;
  }
  to {
    left: 0%;
  }
`;
const sliderOutFromRight = keyframes`
  from {
    left: 0%;
  }
  to {
    left: 100%;
  }
`;

const Component = styled(CardLayout)`
  height: 280px;
  padding: 25px;
`;

const HeaderLayout = styled(CardHeaderLayout)`
  ${H3} {
    font-size: 19px;
  }
`;

const BodyLayout = styled(CardBodyLayout)`
  padding-top: 10px;
`;

const PageImageWrapper = styled.div`
  position: relative;
  flex: 1 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const PvWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: end;
  margin-bottom: 5px;
  margin-top: 5px;
`;

const PageTitle = styled.div`
  display: flex;
  overflow: hidden;
  margin-bottom: 5px;
  ${P2} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    margin-left: 4px;
    color: #767676;
    font-size: 15px;
  }
`;

const ImageAbsolute = styled.div<{
  $id: number;
  $isSelect: number | null;
  $isRight: boolean;
}>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  img {
    max-width: 100%;
    max-height: 100%;
  }

  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  ${(props) => {
    if (props.$id === props.$isSelect) {
      if (props.$isRight) {
        return css`
          animation-name: ${sliderInFromRight};
        `;
      }
      return css`
        animation-name: ${sliderInFromLeft};
      `;
    }
    if (props.$id !== props.$isSelect && props.$isSelect !== null) {
      if (props.$id > props.$isSelect) {
        return css`
          animation-name: ${sliderOutFromRight};
        `;
      }
      return css`
        animation-name: ${sliderOutFromLeft};
      `;
    }
    if (props.$id === 0 && props.$isSelect === null) {
      return css`
        left: 0px;
        top: 0px;
      `;
    }
    return css`
      left: 100%;
      top: 0px;
    `;
  }}
`;

const ImageDiv = styled.div`
  position: relative;

  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
  ${P3} {
    color: #767676;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 115px;
  object-fit: cover;
  object-position: 50% 0%;
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
  backface-visibility: hidden;
  border: 1px solid #dddddd;
  border-radius: 6px;
`;

const ImageSlideButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8px;
`;

const NodataDiv = styled.div`
  width: 100%;
  height: 115px;
  border: 1px solid #dddddd;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageSlideButton = styled.button<{ $isSelect: boolean }>`
  cursor: pointer;
  margin-right: 8px;
  border: none;
  transition: all 0.5s ease-out;
  height: 100%;
  ${(props) => {
    if (props.$isSelect) {
      return css`
        background-color: ${props.theme.colors.primary};
        border-radius: 8px;
        width: 8px;
      `;
    }
    return css`
      background-color: ${props.theme.colors.grey400};
      width: 8px;
      border-radius: 8px;
    `;
  }}
`;

interface DashboardPopularPageCardProps {
  loading?: boolean;
  data: GetPopularPageStatisticsResponseType['payload'];
}

const DashboardPopularPageCard = ({
  loading,
  data,
}: DashboardPopularPageCardProps) => {
  // state
  const [imageHighlightId, setImageHighlightId] = useState(0);
  const [isRight, setIsRight] = useState(false);
  const [rebData, setRebData] = useState<
    GetPopularPageStatisticsResponseType['payload']
  >([]);

  const onClickImageSlidebutton = (id: number) => {
    if (imageHighlightId < id) {
      setIsRight(true); // 오른쪽에서 오고
    } else {
      setIsRight(false); // 왼쪽에서 오고
    }
    setImageHighlightId(id);
  };

  const onClickLink = (url: string) => {
    window.open(url, '_blank', 'noopener, noreferrer');
  };

  const printBody = () => {
    if (rebData.length > 0) {
      return (
        <BodyLayout>
          <PageImageWrapper>
            {rebData.map((element, idx) => {
              return (
                <ImageAbsolute
                  key={element.simple_url}
                  $id={idx}
                  $isSelect={imageHighlightId}
                  $isRight={isRight}
                >
                  <ImageDiv>
                    <PageTitle>
                      <P2>{element.page_name}</P2>
                      <span
                        className="ri-external-link-line"
                        onClick={() => onClickLink(element.simple_url)}
                      >
                        
                      </span>
                    </PageTitle>
                    {element.page_id === null ? (
                      <NodataDiv>
                        <P3>수집된 이미지가 없습니다.</P3>
                      </NodataDiv>
                    ) : (
                      <Image
                        src={`https://voda-media.nerdfactory.ai/${localStorage.getItem(
                          'voda_tenant'
                        )}/auto/${element.page_id}/${encodeURIComponent(
                          'page_screenshot'
                        )}`}
                      />
                    )}
                    <PvWrapper>
                      <P3>{element.pv_cnt.toLocaleString()} PV</P3>
                    </PvWrapper>
                  </ImageDiv>
                </ImageAbsolute>
              );
            })}
          </PageImageWrapper>
          <ImageSlideButtonWrapper>
            {rebData.map((element, idx) => {
              return (
                <ImageSlideButton
                  key={element.page_id}
                  $isSelect={imageHighlightId === idx}
                  onClick={() => {
                    onClickImageSlidebutton(idx);
                  }}
                />
              );
            })}
          </ImageSlideButtonWrapper>
        </BodyLayout>
      );
    }
    return <DataError />;
  };

  useEffect(() => {
    if (data.length > 0) {
      setRebData(data);
    }
    return () => {
      setRebData([]);
    };
  }, [data]);

  return (
    <Component>
      <HeaderLayout>
        <DefaultLink to="/userAnalytics/contents">
          <H3>
            많이 본 페이지<span className="ri-arrow-down-s-line"></span>
          </H3>
        </DefaultLink>
      </HeaderLayout>
      {loading ? <Spinner /> : printBody()}
    </Component>
  );
};

export default DashboardPopularPageCard;
