import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import ReportPageIcon from '../../../../assets/icons/icon-report-page.png';
import CardLayout from '../../../Atoms/Layout/Card/CardLayout';
import { P1, P2, P3 } from '../../../Atoms/Typo';
import Spinner from '../../../Molecule/Spinner';
import DefaultLink from '../../../Atoms/Link';
import {
  GetReportClickRateResponseType,
  GetReportGrowthPVResponseType,
  GetReportPopularPageResponseType,
} from '../../../../api/report/type';
import BarChart from '../../../Molecule/Chart/BarChart';
import DataError from '../../../Atoms/DataError';

const Component = styled(CardLayout)`
  padding: 0;
  flex-direction: row;
  border-radius: 14px;
  height: 610px;
  width: 100%;
`;

const SummaryGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 26%;
  padding: 40px;
  border-right: 1px solid #d7dbe6;
  border-radius: 14px 0px 0px 14px;
  background-color: #fcfcfc;
  img {
    width: 107px;
    height: 81px;
  }
`;

const InnerGrid = styled.div`
  width: 74%;
  padding: 40px 0;
  display: flex;
`;

const DataGrid = styled.div`
  /* flex: 1; */
  width: 33.333%;
  /* flex: 1 1 auto; */
  /* flex: 0 1 auto; */
  padding: 0 40px;
  border-right: 1px solid #d7dbe6;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  :last-child {
    border-right: none;
  }
`;

const PageTitle = styled(P2)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DataInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
`;

const PageItem = styled.div`
  width: 100%;
  height: 220px;
  display: flex;
  flex-direction: column;
  .ux {
    width: 150px;
    margin-bottom: 40px;
  }
`;

const ItemTitle = styled.div`
  display: flex;
  /* width: 100%; */
  margin-bottom: 15px;
  overflow: hidden;
  ${P2} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    color: #767676;
    font-size: 15px;
    margin-left: 4px;
    :hover {
      cursor: pointer;
    }
  }
`;

const RankingIndex = styled.div`
  min-width: 22px;
  height: 22px;
  text-align: center;
  border-radius: 4px;
  background-color: #7851e7;
  margin-right: 8px;
  ${P2} {
    font-size: 15px;
    color: ${(props) => props.theme.colors.white};
  }
`;

const Image = styled.img`
  width: 100%;
  height: 140px;
  object-fit: cover;
  object-position: 50% 0%;
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
  backface-visibility: hidden;
  margin-bottom: 10px;
`;

const NoDataImage = styled.div`
  width: 100%;
  height: 140px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GrowthPVWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CompareVal = styled.div<{ $compare: number | null }>`
  display: flex;
  width: 27px;
  align-items: center;
  justify-content: center;

  ${(props) => {
    if (props.$compare) {
      if (props.$compare > 0) {
        return css`
          ${P3} {
            font-size: 13px;
            color: #ff3b56;
          }
          .compare {
            border-bottom: 6px solid #ff3b56;
          }
        `;
      }
      if (props.$compare < 0) {
        return css`
          ${P3} {
            font-size: 13px;
            color: #2962ff;
          }
          .compare {
            border-bottom: 6px solid #2962ff;
            transform: rotate(180deg);
          }
        `;
      }
      if (props.$compare === 0) {
        return css`
          ${P3} {
            font-size: 13px;
            color: #6d6d6d;
          }
          .compare {
            display: none;
          }
        `;
      }
    }
    return css`
      ${P3} {
        font-size: 13px;
        color: #1d2fc7;
        font-weight: 600;
      }
      .compare {
        display: none;
      }
    `;
  }}
`;

const Triangle = styled.div`
  width: 0px;
  height: 0px;
  border-bottom: 6px solid red;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  margin-right: 3px;
`;

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(P1)`
  font-size: 20px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 25px;
`;

const GridTitle = styled(P2)`
  font-size: 15px;
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: 10px;
`;

const ClickSummary = styled(P3)`
  margin-top: 10px;
  font-size: 15px;
  color: #767676;
`;

const CompareSummary = styled(P3)`
  font-size: 13px;
  color: #f99e32;
  margin: 13px 0 26px;
`;

const Split = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d7dbe6;
`;

const AvgClickRate = styled.div`
  display: flex;
  align-items: center;
  height: 25px;
  ${P1} {
    color: #7851e7;
    font-weight: bold;
    font-size: 22px;
    margin-left: 10px;
  }
  ${P2} {
    font-size: 15px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  .ux-analytics {
    width: 150px;
    margin-left: 6px;
  }
  .ux {
    width: 150px;
    margin-bottom: 40px;
  }
`;

const DefaultLinkWrap = styled(DefaultLink)`
  :nth-child(1) {
    width: 42%;
  }
  :nth-child(2) {
    width: 58%;
  }
`;

const LinkButton = styled.button`
  border: none;
  width: 127px;
  height: 38px;
  background-color: #ffffff;
  border: 1px solid #cfcfcf;
  border-radius: 23px;
  color: #767676;
  font-size: 15px;
  :hover {
    cursor: pointer;
  }
`;

interface ReportPopularPageCardState {
  rebuildData: {
    [key: string]: number | boolean;
    isHighlight: boolean;
  }[];
}

interface ReportPopularPageCardProps {
  popularLoading?: boolean;
  growthLoading?: boolean;
  rateLoading?: boolean;

  popularData: GetReportPopularPageResponseType['payload'];
  growthData: GetReportGrowthPVResponseType['payload'];
  rateData: GetReportClickRateResponseType['payload'] | null;
}

const ReportPopularPageCard = ({
  popularLoading,
  growthLoading,
  rateLoading,
  popularData,
  growthData,
  rateData,
}: ReportPopularPageCardProps) => {
  // state
  const [rebPopularData, setRebPopularData] = useState<
    GetReportPopularPageResponseType['payload']
  >([]);
  const [rebGrowthData, setRebGrowthData] = useState<
    GetReportGrowthPVResponseType['payload']
  >([]);
  const [rebClickData, setRebClickData] = useState<
    ReportPopularPageCardState['rebuildData']
  >([]);
  const [clickRate, setClickRate] = useState(0);
  const [compareRate, setCompareRate] = useState(0);

  const [month, setMonth] = useState('');

  const onClickLink = (url: string) => {
    window.open(url, '_blank', 'noopener, noreferrer');
  };

  const printCompare = (rate: number | null) => {
    if (rate) {
      if (rate === 0) {
        return <P3>-</P3>;
      }
      return <P3>{Math.abs(rate)}</P3>;
    }
    return <P3>NEW</P3>;
  };

  useEffect(() => {
    if (popularData.length > 0) {
      setRebPopularData(popularData);
    } else setRebPopularData([]);
  }, [popularData]);

  useEffect(() => {
    if (growthData.length > 0) {
      setRebGrowthData(growthData);
    } else setRebGrowthData([]);
  }, [growthData]);

  useEffect(() => {
    const rebuildData: ReportPopularPageCardState['rebuildData'] = [];
    if (rateData && Object.keys(rateData).length > 0) {
      const month_obj = rateData.page_use_per;
      const month_arr = Object.keys(month_obj);
      const month_list: string[] = [];

      let max_uv = 0;

      month_arr.forEach((element, index) => {
        if (max_uv < month_obj[element]) {
          max_uv = month_obj[element];
        }

        month_list.push(`${element.split('-')[1]}월`);
        rebuildData.push({
          [`${element.split('-')[1]}월`]: month_obj[element],
          isHighlight: index === 2,
        });
      });

      setCompareRate(rateData.all_page_use_per);
      setClickRate(month_obj[month_arr[2]]);
      setMonth(month_list[2]);
    }
    setRebClickData(rebuildData);
  }, [rateData]);

  return (
    <Component>
      <SummaryGrid>
        <SummaryWrapper>
          <img alt="" src={ReportPageIcon} />
          <Title>사용자가 무엇을 보고 가나요?</Title>
        </SummaryWrapper>
        <ButtonWrapper>
          <DefaultLinkWrap to="/userAnalytics/contents">
            <LinkButton>
              콘텐츠 분석 <span className="ri-arrow-down-s-line"></span>
            </LinkButton>
          </DefaultLinkWrap>
          <DefaultLinkWrap to="/pageAnalytics/uxAnalysisList">
            <LinkButton className="ux-analytics">
              페이지 UX 분석 <span className="ri-arrow-down-s-line"></span>
            </LinkButton>
          </DefaultLinkWrap>
        </ButtonWrapper>
      </SummaryGrid>

      <InnerGrid>
        <DataGrid>
          <GridTitle>가장 많이 본 페이지</GridTitle>
          {popularLoading ? (
            <Spinner />
          ) : (
            <DataInner>
              {rebPopularData.length > 0 ? (
                rebPopularData.map((element, idx) => {
                  return (
                    <PageItem key={element.page_name} className="page-item">
                      <ItemTitle>
                        <RankingIndex>
                          <P2>{idx + 1}</P2>
                        </RankingIndex>
                        <PageTitle>{element.page_name}</PageTitle>
                        <span
                          className="ri-external-link-line"
                          onClick={() => onClickLink(element.simple_url)}
                        >
                          
                        </span>
                      </ItemTitle>
                      {element.page_id ? (
                        <Image
                          src={`https://voda-media.nerdfactory.ai/${localStorage.getItem(
                            'voda_tenant'
                          )}/auto/${element.page_id}/${encodeURIComponent(
                            'page_screenshot'
                          )}`}
                          crossOrigin="anonymous"
                        />
                      ) : (
                        <NoDataImage>
                          <P3>수집된 이미지가 없습니다.</P3>
                        </NoDataImage>
                      )}
                      {element.monthly_count !== null ? (
                        <P2>{element.monthly_count.toLocaleString()}PV</P2>
                      ) : null}
                    </PageItem>
                  );
                })
              ) : (
                <DataError />
              )}
            </DataInner>
          )}
        </DataGrid>

        <DataGrid>
          <GridTitle>인기 급상승 페이지</GridTitle>
          {growthLoading ? (
            <Spinner />
          ) : (
            <DataInner>
              {rebGrowthData.length > 0 ? (
                rebGrowthData.map((element, idx) => {
                  return (
                    <PageItem key={element.simple_url}>
                      <ItemTitle>
                        <RankingIndex>
                          <P2>{idx + 1}</P2>
                        </RankingIndex>
                        <P2>{element.page_name}</P2>
                        <span
                          className="ri-external-link-line"
                          onClick={() => onClickLink(element.simple_url)}
                        >
                          
                        </span>
                      </ItemTitle>
                      {element.page_id ? (
                        <Image
                          src={`https://voda-media.nerdfactory.ai/${localStorage.getItem(
                            'voda_tenant'
                          )}/auto/${element.page_id}/${encodeURIComponent(
                            'page_screenshot'
                          )}`}
                          crossOrigin="anonymous"
                        />
                      ) : (
                        <NoDataImage>
                          <P3>수집된 이미지가 없습니다.</P3>
                        </NoDataImage>
                      )}
                      <GrowthPVWrap>
                        {element.pv_count !== null ? (
                          <P2>{element.pv_count.toLocaleString()}PV</P2>
                        ) : null}
                        <CompareVal $compare={element.growth_count}>
                          <Triangle className="compare" />
                          {printCompare(element.growth_count)}
                        </CompareVal>
                      </GrowthPVWrap>
                    </PageItem>
                  );
                })
              ) : (
                <DataError />
              )}
            </DataInner>
          )}
        </DataGrid>

        <DataGrid>
          <GridTitle>유효 클릭 비율</GridTitle>
          {rateLoading ? (
            <Spinner />
          ) : (
            <DataInner>
              {rebClickData.length > 0 ? (
                <>
                  <PageItem>
                    <ClickSummary>
                      평균 유효 클릭 비율이{' '}
                      {clickRate > compareRate
                        ? '높습니다.'
                        : '낮습니다. 페이지 UX 분석에서 원인을 찾아보세요.'}
                    </ClickSummary>
                    <CompareSummary>
                      대부분의 웹사이트는 유효 클릭 비율이 {compareRate}%
                      이상입니다.
                    </CompareSummary>
                    <DefaultLink to="/pageAnalytics/uxAnalysisList">
                      <LinkButton className="ux">
                        페이지 UX 분석{' '}
                        <span className="ri-arrow-down-s-line"></span>
                      </LinkButton>
                    </DefaultLink>
                    <AvgClickRate>
                      <P2>{month} 평균 유효 클릭 비율</P2>
                      <P1>{clickRate}%</P1>
                    </AvgClickRate>
                  </PageItem>
                  <Split />
                  <PageItem>
                    <BarChart data={rebClickData} isLabelInChart={false} />
                  </PageItem>
                </>
              ) : (
                <DataError />
              )}
            </DataInner>
          )}
        </DataGrid>
      </InnerGrid>
    </Component>
  );
};

export default ReportPopularPageCard;
