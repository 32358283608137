import axios from 'axios';
import config from '../../config-api.json';
import {
  GetNationalSessionEnvStatisticsParamType,
  GetNationalSessionEnvStatisticsResponseType,
  GetGlobalSessionEnvStatisticsParamType,
  GetGlobalSessionEnvStatisticsResponseType,
} from './type';

const env = process.env.NODE_ENV;
let { apiInfo } = config;

if (env === 'development') {
  apiInfo = config.apiInfo_dev;
}

// 시도별 방문 통계
export async function apiGetNationalSessionEnvStatistics(
  info: GetNationalSessionEnvStatisticsParamType
) {
  const response = await axios.get<GetNationalSessionEnvStatisticsResponseType>(
    `${apiInfo.api_url}/national_session_env_statics/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}

// 국가별 방문 통계
export async function apiGetGlobalSessionEnvStatistics(
  info: GetGlobalSessionEnvStatisticsParamType
) {
  const response = await axios.get<GetGlobalSessionEnvStatisticsResponseType>(
    `${apiInfo.api_url}/global_session_env_statics/`,
    {
      params: {
        start_date: info.start_date,
        end_date: info.end_date,
      },
      headers: {
        Tenant: localStorage.getItem('voda_tenant'),
      },
      withCredentials: true,
    }
  );
  return response.data;
}
