import { createReducer } from 'typesafe-actions';
import {
  GET_PATH_PLOT,
  GET_PATH_PLOT_ERROR,
  GET_PATH_PLOT_SUCCESS,
  GET_PATH_INFLOW_CHILDREN,
  GET_PATH_INFLOW_CHILDREN_ERROR,
  GET_PATH_INFLOW_CHILDREN_SUCCESS,
  GET_PATH_INFLOW,
  GET_PATH_INFLOW_ERROR,
  GET_PATH_INFLOW_SUCCESS,
  SET_ERROR_NULL_PATHPLOT,
} from './actions';
import { pathPlotAction, pathPlotState } from './types';

const initialState: pathPlotState = {
  isLoadingPathPlot: false,
  isLoadingPathChildren: false,
  isloadingPathInflow: false,
  pathPlotData: null,
  pathChildrenData: [],
  pathInflowData: [],
  pathPlotError: null,
};

const reducer = createReducer<pathPlotState, pathPlotAction>(initialState, {
  [GET_PATH_PLOT]: (state) => ({
    ...state,
    isLoadingPathPlot: true,
    pathPlotData: null,
    pathPlotError: null,
  }),
  [GET_PATH_PLOT_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingPathPlot: false,
    pathPlotData: action.payload.payload,
    pathPlotError: null,
  }),
  [GET_PATH_PLOT_ERROR]: (state, action) => ({
    ...state,
    isLoadingPathPlot: false,
    pathPlotData: null,
    pathPlotError: action.payload,
  }),

  [GET_PATH_INFLOW_CHILDREN]: (state) => ({
    ...state,
    isLoadingPathChildren: true,
    pathChildrenData: [],
    pathPlotError: null,
  }),
  [GET_PATH_INFLOW_CHILDREN_SUCCESS]: (state, action) => ({
    ...state,
    isLoadingPathChildren: false,
    pathChildrenData: action.payload.payload,
    pathPlotError: null,
  }),
  [GET_PATH_INFLOW_CHILDREN_ERROR]: (state, action) => ({
    ...state,
    isLoadingPathChildren: false,
    pathChildrenData: [],
    pathPlotError: action.payload,
  }),

  [GET_PATH_INFLOW]: (state) => ({
    ...state,
    isloadingPathInflow: true,
    pathInflowData: [],
    pathPlotError: null,
  }),
  [GET_PATH_INFLOW_SUCCESS]: (state, action) => ({
    ...state,
    isloadingPathInflow: false,
    pathInflowData: action.payload.payload,
    pathPlotError: null,
  }),
  [GET_PATH_INFLOW_ERROR]: (state, action) => ({
    ...state,
    isloadingPathInflow: false,
    pathInflowData: [],
    pathPlotError: action.payload,
  }),

  [SET_ERROR_NULL_PATHPLOT]: (state) => ({
    ...state,
    pathPlotError: null,
  }),
});

export default reducer;
