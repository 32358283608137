import React from 'react';
import styled from 'styled-components';

const Component = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #d7dbe6;

  transition: box-shadow 0.3s ease-out;
  /* :hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.11);
  } */
`;

interface CardLayoutProps {
  card_ref?: React.RefObject<HTMLDivElement>;
  className?: string;
  onAniEnd?: React.AnimationEventHandler<HTMLDivElement>;
  children?: React.ReactNode;
}

const CardLayout = ({
  card_ref,
  className,
  onAniEnd,
  children,
}: CardLayoutProps) => {
  return (
    <Component ref={card_ref} className={className} onAnimationEnd={onAniEnd}>
      {children}
    </Component>
  );
};

export default CardLayout;
