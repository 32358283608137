import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Button } from '../../../Atoms/Button';
import { P2 } from '../../../Atoms/Typo';

const Component = styled.div<{ $dashboard?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 30px;
  ${(props) => {
    if (props.$dashboard) {
      return css`
        margin-right: 0px;
      `;
    }
    return css``;
  }}
`;

const FilterButton = styled(Button)<{ $active: boolean; $dashboard?: boolean }>`
  width: 30px;
  height: 30px;
  background-color: ${(props) => props.theme.colors.white};
  margin-right: 15px;
  ${P2} {
    color: ${(props) => props.theme.colors.grey500};
    font-weight: 600;
  }
  &:last-child {
    margin-right: 0px;
  }

  ${(props) => {
    if (props.$dashboard) {
      return css`
        width: 60px;
        :last-child {
          width: 75px;
        }
      `;
    }
    return css``;
  }}

  ${(props) => {
    if (props.$active) {
      return css`
        border-bottom: 2px solid ${props.theme.colors.black};
        ${P2} {
          color: ${props.theme.colors.grey900};
          font-weight: bold;
        }
      `;
    }
    return css``;
  }}
`;

interface FilterProps {
  dashboard?: boolean;
  buttonGroup: {
    id: number;
    timeSort: string;
    title: string;
  }[];
  onClick: (id: number, timeSort: string) => void;
}

const Filter = ({ dashboard, buttonGroup, onClick }: FilterProps) => {
  const [buttonId, setButtonId] = useState(0);

  const onClickButton = (id: number, timeSort: string) => {
    if (onClick) {
      onClick(id, timeSort);
      setButtonId(id);
    }
  };

  return (
    <Component $dashboard={dashboard}>
      {buttonGroup.map((element) => {
        return (
          <FilterButton
            $active={element.id === buttonId}
            $dashboard={dashboard}
            key={element.id}
            onClick={() => {
              onClickButton(element.id, element.timeSort);
            }}
          >
            <P2>{element.title}</P2>
          </FilterButton>
        );
      })}
      {/* <Group>
      </Group>
      <Sample /> */}
    </Component>
  );
};

export default Filter;
