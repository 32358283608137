import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../modules';
import {
  getForecastDateAsync,
  setEndDate,
  setIsDatePickerToggle,
  setStartDate,
} from '../../../modules/datePicker';

import DefaultDatePicker from '../../Molecule/DatePicker';
import ContentsHeading from '../../Molecule/Heading/Contents/ContentsHeading';

const Component = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding-top: 36px;
  margin-bottom: 30px;
`;

const RightSide = styled.div`
  display: flex;
`;

interface HeaderProps {
  title: string;
  isDatePicker: boolean;
  className?: string;
  iconClass?: string;
  icon?: string;

  children?: React.ReactNode;
}

const Header = ({
  title,
  className,
  iconClass,
  icon,
  isDatePicker,

  children,
}: HeaderProps) => {
  // redux
  const { startDate, endDate, futureDays } = useSelector(
    (state: RootState) => state.datePicker
  );
  const { created_time } = useSelector(
    (state: RootState) => state.authentication
  );
  const dispatch = useDispatch();

  const onSetStartDate = (date: any) => {
    dispatch(setStartDate(date));
  };

  const onSetEndDate = (date: any) => {
    dispatch(setEndDate(date));
  };

  const onSetIsDatePickerToggle = (isToggle: boolean) => {
    dispatch(setIsDatePickerToggle(isToggle));
  };

  const onGetForecastDate = () => {
    dispatch(getForecastDateAsync.request());
  };

  useEffect(() => {
    onGetForecastDate();
  }, []);

  return (
    <Component className={className}>
      <ContentsHeading title={title} icon={icon} iconClass={iconClass} />

      <RightSide>
        {children}
        {isDatePicker ? (
          <DefaultDatePicker
            startDate={startDate}
            endDate={endDate}
            createdTime={created_time}
            futureDateDays={futureDays}
            setStartDate={onSetStartDate}
            setEndDate={onSetEndDate}
            setIsDatePickerToggle={onSetIsDatePickerToggle}
          />
        ) : null}
      </RightSide>
    </Component>
  );
};

export default Header;
