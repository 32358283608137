import React from 'react';
import styled from 'styled-components';
import { P2 } from '../../../Atoms/Typo';

const Component = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 20px;
  ${P2} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 6px;
`;

const Title = styled(P2)`
  font-weight: 600;
`;

const Value = styled.div`
  display: flex;
  /* width: 100%; */
  justify-content: space-between;
  /* overflow: hidden; */
  ${P2} {
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    /* display: flex; */
    font-weight: 500;
    color: #767676;
  }
`;

const URLWrap = styled.div`
  display: flex;
  overflow: hidden;
  ${P2} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    margin-left: 5px;
    color: #767676;
  }
  :hover {
    cursor: pointer;
  }
`;

const PV = styled(P2)`
  /* width: 100%; */
  font-size: 15px;
  min-width: 70px;
  margin-left: 15px;
  text-align: end;
`;

interface PageThumbnailCardHeadingProps {
  title: string;
  url: string;
  pvCount: number;
  className?: string;
}

const PageThumbnailCardHeading = ({
  title,
  url,
  pvCount,
  className,
}: PageThumbnailCardHeadingProps) => {
  const onClickLink = (link: string) => {
    window.open(link, '_blank', 'noopener, noreferrer');
  };

  return (
    <Component className={className}>
      <Header>
        <Title>{title}</Title>
      </Header>
      <Value>
        <URLWrap
          onClick={() => {
            onClickLink(url);
          }}
        >
          <P2>{url}</P2>
          <span className="ri-external-link-line"></span>
        </URLWrap>
        <PV>{pvCount.toLocaleString()}PV</PV>
      </Value>
    </Component>
  );
};

export default PageThumbnailCardHeading;
