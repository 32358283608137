import React from 'react';
import styled, { css } from 'styled-components';
import CardLayout from '../../Atoms/Layout/Card/CardLayout';
import CardHeaderLayout from '../../Atoms/Layout/Card/CardHeaderLayout';
import CardBodyLayout from '../../Atoms/Layout/Card/CardBodyLayout';
import { P1, P2, P3 } from '../../Atoms/Typo';
import ButtonGroup from '../../Molecule/ButtonGroup';
import DefaultLink from '../../Atoms/Link';
import {
  GET_PAGE_ELEMENT_DATA,
  GET_PAGE_MOUSE_STATICS,
  uxAnalyticsState,
} from '../../../modules/PageAnalytics/UXAnalytics';
import { MouseHeatmapTooltipType } from '../../Molecule/Tooltip/MouseHeatmapTooltip';
import { ScrollEventsPayload } from '../../../api/pageAnalytics/uxAnalytics/scroll/type';
import MouseEventRankCard from './UXAnalysis/MouseEventRankCard';
import MouseScrollRankCard from './UXAnalysis/MouseScrollRankCard';
import { GET_SCROLL_EVENTS } from '../../../modules/PageAnalytics/UXAnalytics/Scroll';
import MouseHeatmapCard from './UXAnalysis/MouseHeatmapCard';
import ScrollHeatmapCard from './UXAnalysis/ScrollHeatmapCard';
import UserFlowCard from './UXAnalysis/UserFlowCard';
import { loadingState } from '../../../modules/loading';

const Component = styled(CardLayout)`
  padding: 0;
`;

const CardHeader = styled(CardHeaderLayout)`
  padding: 30px;
  border-bottom: 1px solid #d7dbe6;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: baseline;
  overflow: hidden;
  ${P1} {
    font-size: 20px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    /* width: 15px; */
    height: 15px;
    font-size: 15px;
    color: #767676;
    :hover {
      cursor: pointer;
    }
  }
`;

const CountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  margin-left: 10px;
  ${P3} {
    font-size: 15px;
    font-weight: 600;
    margin-right: 10px;
  }
`;

const Count = styled(P2)`
  font-size: 15px;
`;

const Split = styled.div`
  width: 1px;
  height: 11px;
  background-color: #b2b2b2;
  margin: 0px 15px;
`;

const BackButton = styled.button`
  width: 94px;
  height: 38px;
  border: 1px solid #cfcfcf;
  border-radius: 23px;
  font-size: 15px;
  color: #767676;
  background-color: white;
  margin-left: 20px;
  :hover {
    cursor: pointer;
  }
`;

const CardBody = styled(CardBodyLayout)`
  flex-direction: row;
  padding: 0px 30px 30px;
`;

const AnalysisTypeButtonWrapper = styled.div`
  width: 100%;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageScreenWrap = styled.div<{ $fullPage?: boolean }>`
  ${(props) => {
    if (props.$fullPage) {
      return css`
        width: 100%;
      `;
    }
    return css`
      width: 60%;
    `;
  }}
`;

const RankCardWrap = styled.div`
  width: 40%;
  padding-left: 40px;
`;

interface PageDetailAnalysisCardProps {
  pageTitle: string;
  pageLocation: string;
  pageId: string;
  loadingStatics: boolean;
  pageEventStaticsData: uxAnalyticsState['mouseStaticsData'];
  pageElementData: uxAnalyticsState['elementData'];
  clickedElementPath: string;
  mouseoverElementPath: string;

  flowData: {
    page: string;
    cnt: number;
    rate: number;
  }[];
  conversionData: {
    page: string;
    cnt: number;
    rate: number;
  }[];

  clickTooltipInfo: MouseHeatmapTooltipType | null;
  mouseoverTooltipInfo: MouseHeatmapTooltipType | null;
  scrollEventsData: ScrollEventsPayload | null;
  loading: loadingState;
  activeDataType: number;
  pageUsabilityData: {
    pageViewCount: number | null;
    effectiveClickCount: number | null;
    pageDuration: string | null;
  };
  setClickedElementPath: React.Dispatch<React.SetStateAction<string>>;
  setMouseoverElementPath: React.Dispatch<React.SetStateAction<string>>;
  setClickTooltipInfo: React.Dispatch<
    React.SetStateAction<MouseHeatmapTooltipType | null>
  >;

  setMouseoverTooltipInfo: React.Dispatch<
    React.SetStateAction<MouseHeatmapTooltipType | null>
  >;
  setActiveDataType: React.Dispatch<React.SetStateAction<number>>;
}

const PageDetailAnalysisCard = ({
  pageTitle,
  pageLocation,
  pageId,
  loadingStatics,
  pageEventStaticsData,
  pageElementData,
  clickedElementPath,
  mouseoverElementPath,
  flowData,
  conversionData,
  clickTooltipInfo,
  mouseoverTooltipInfo,
  scrollEventsData,
  loading,
  activeDataType,
  pageUsabilityData,
  setClickedElementPath,
  setMouseoverElementPath,
  setClickTooltipInfo,
  setMouseoverTooltipInfo,
  setActiveDataType,
}: PageDetailAnalysisCardProps) => {
  // const [heatmapOpacity, setHeatmapOpacity] = useState(1);

  const onClickDataTypeButton = (id: number) => {
    setActiveDataType(id);
  };

  const onClickLink = (link: string) => {
    window.open(link, '_blank', 'noopener, noreferrer');
  };

  const printChart = () => {
    switch (activeDataType) {
      case 0:
        return (
          <MouseHeatmapCard
            pageId={pageId}
            loadingStatics={loadingStatics}
            pageEventStaticsData={pageEventStaticsData}
            pageElementData={pageElementData}
            clickedElementPath={clickedElementPath}
            mouseoverElementPath={mouseoverElementPath}
            clickTooltipInfo={clickTooltipInfo}
            isActiveDataType={activeDataType}
            mouseoverTooltipInfo={mouseoverTooltipInfo}
            isLoading={
              loading[GET_PAGE_MOUSE_STATICS] || loading[GET_PAGE_ELEMENT_DATA]
            }
            setClickedElementPath={setClickedElementPath}
            setMouseoverElementPath={setMouseoverElementPath}
            setClickTooltipInfo={setClickTooltipInfo}
            setMouseoverTooltipInfo={setMouseoverTooltipInfo}
          />
        );
      case 1:
        return (
          <ScrollHeatmapCard
            isLoading={loading[GET_SCROLL_EVENTS]}
            pageId={pageId}
            data={scrollEventsData}
          />
        );
      case 2:
        return (
          <UserFlowCard
            pageId={pageId}
            pageLocation={pageLocation}
            pageName={pageTitle}
            flowData={flowData}
            conversionData={conversionData}
          />
        );

      default:
        return null;
    }
  };

  const printRankCard = () => {
    switch (activeDataType) {
      case 0:
        return (
          <MouseEventRankCard
            clickedElementPath={clickedElementPath}
            elementData={pageElementData}
            pageId={pageId}
            activeDataType={activeDataType}
            isLoading={
              loading[GET_PAGE_MOUSE_STATICS] || loading[GET_PAGE_ELEMENT_DATA]
            }
            setClickedElementPath={setClickedElementPath}
            setClickTooltipInfo={setClickTooltipInfo}
          />
        );
      case 1:
        return (
          <MouseScrollRankCard
            pageId={pageId}
            data={scrollEventsData}
            activeDataType={activeDataType}
            isLoading={loading[GET_SCROLL_EVENTS]}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Component>
      <CardHeader>
        <TitleWrapper>
          <P1>{pageTitle}</P1>
          <span
            className="ri-external-link-line"
            onClick={() => {
              onClickLink(pageLocation);
            }}
          >
            
          </span>
        </TitleWrapper>
        <CountWrapper>
          <P3>페이지뷰</P3>
          {pageUsabilityData.pageViewCount !== null ? (
            <Count>{pageUsabilityData.pageViewCount.toLocaleString()}PV</Count>
          ) : null}
          <Split />
          <P3>체류시간</P3>
          <Count>{pageUsabilityData.pageDuration}초</Count>
          <Split />
          <P3>유효 클릭</P3>
          {pageUsabilityData.effectiveClickCount !== null ? (
            <Count>
              {pageUsabilityData.effectiveClickCount.toLocaleString()}회
            </Count>
          ) : null}
          <DefaultLink to="/pageAnalytics/uxAnalysisList">
            <BackButton>
              <span className="ri-arrow-down-s-line"></span> 목록
            </BackButton>
          </DefaultLink>
        </CountWrapper>
      </CardHeader>
      <AnalysisTypeButtonWrapper>
        <ButtonGroup
          buttonData={[
            { id: 0, title: '클릭' },
            { id: 1, title: '스크롤' },
            { id: 2, title: '흐름 분석' },
          ]}
          onClick={onClickDataTypeButton}
          activeID={activeDataType}
        />
      </AnalysisTypeButtonWrapper>
      <CardBody>
        <PageScreenWrap
          $fullPage={activeDataType === 2}
          className="page-screen-wrapper"
        >
          {printChart()}
        </PageScreenWrap>
        {activeDataType === 2 ? null : (
          <RankCardWrap>{printRankCard()}</RankCardWrap>
        )}
      </CardBody>
    </Component>
  );
};

export default PageDetailAnalysisCard;
